import clsx from 'clsx';
import UccButton from '../PrimeReactComponent/UccButton';
import EditIcon from '@material-ui/icons/Edit';
// import ChannelHierarchy from '../Layout/ChannelHierarchy';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import NameString from './../NameString';

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Pluralize from 'pluralize';
import React from 'react';
import routes from '../Layout/Routes';
import { namestringFields } from '../../store/StoreMetadata';

import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import UccDisplayNamestring from '../PrimeReactComponent/UccDisplayNamestring';
import { formatDate } from 'moment';
import { MenuList } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

var moment = require('moment');
const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    display: 'inline-block',
    flexGrow: 1,
  },
  icons: {
    height: '30px',
    width: '30px',
    position: 'relative',
  },
  nameString: {
    position: '-webkit-sticky',
    position: 'sticky',
    fontSize: 16,
    padding: theme.spacing(2),
    marginTop: 13,
    marginLeft: 30,
    marginRight: 19,
    border: '2px solid #495057',
    borderRadius: '22px',
    minWidth: '300px',
    left: '600px',
    wordBreak: 'break-word',
    top: 80,
  },
  definitionList: {
    // width: '300px',
    wordBreak: 'break-word',
  },
  ddStyle: {
    fontSize: 18,
    fontWeight: 500,
    fontFamily: 'Avenir LT W01_65 Medium1475532',
    color: '#495057'
  },
  dtStyle: {
    fontSize: 18,
    fontWeight: 300,
    fontFamily: 'Avenir LT W01_35 Light1475496',
    color: '#495057'
  }
});

@inject('rootStore')
@observer
class ShowDefinitionList extends React.Component {

  showBetterNamestring(row) {
    NameString.prepNamestring(this.props.rootStore.channel.model, row.id);
    this.nameString = (<NameString store={this.props.rootStore.channel.model.crudStore} ret='string' />)
    return this.nameString;
  }

  disableEdit() {
    const model = this.props.rootStore.channel.model;
    if (!this.props.rootStore.channel.adminChannel) {
      if (this.props.rootStore.apiStore.currentUser.pll < this.props.model.permCreateAndEdit) return true;
      if (this.props.model.send2domo) {
        return this.props.model.crudStore.selectedRow.status.name == 'Active';
      }
    } else if (model.noEdit) {
      return model.noEdit
    } else {
      return false;
    }

  }

  render() {
    const { rootStore, classes, model, type, onClose, onEdit } = this.props;
    const channel = rootStore.channel;
    const columns = model.columns;
    const crudStore = model.crudStore
    const selectedRow = crudStore.selectedRow;
    if (_.isEmpty(selectedRow)) return null;
    const showFields = _.orderBy(_.filter(columns, c => (c.show)), ['show_order'], ['asc']);
    return (
      <Grid xs container direction="row" justify="flex-start" alignItems="flex-start" wrap="wrap" item={true} >
        <div >
          <Grid item xs>
            {!channel.adminChannel && <Typography component='h2' variant='h2' className={classes.h2}>View {Pluralize.singular(model.name)} {channel.adminChannel ? '' : 'Namestring'} Details</Typography>}
            <dl className={classes.definitionList}>
              {showFields.map(m => {

                return (<div key={m.label + '_pad'} style={{ paddingBottom: '30px' }}><div key={m.label + '_value'} className={classes.dtStyle}>{m.label}</div><div key={m.label + '_value'} className={classes.ddStyle}>{m.show_value(selectedRow)}</div></div>)
              })}
            </dl>
            <div className="root" style={{ paddingBottom: '30px' }}>
              {!this.disableEdit() && <div className="predraft" style={{ paddingLeft: '0px', marginLeft: '0px' }}>
                <UccButton
                  buttonType='draft'
                  label="Edit"
                  onClick={onEdit}
                  width='225px'
                  disable={this.disableEdit()}
                />
              </div>}
              <div className="cancel" style={{ paddingLeft: '0px', float: this.disableEdit() ? 'left' : 'right' }}>
                <UccButton
                  buttonType=''
                  label='Back To Grid'
                  onClick={onClose}
                  width={this.disableEdit() ? '312px' : '225px'}
                />
              </div>
            </div>
          </Grid>
        </div >
        {!channel.adminChannel &&
          <Grid item xs className={classes.nameString}>
            <div >
              <UccDisplayNamestring id='namestring' title='Namestring' string={this.showBetterNamestring(selectedRow)} />
              <div style={model.endpoint.toLowerCase() != 'ads' ? { display: 'none' } : { display: 'visible' }}>
                <UccDisplayNamestring id='short_utm_string' title=' Short UTM Parameter' string={NameString.getAdUtmString(this.props.model.crudStore, selectedRow, 'short')} />
                <UccDisplayNamestring id='utmstring' title=' UTM Parameter' string={NameString.getAdUtmString(this.props.model.crudStore, selectedRow, 'long')} />
              </div>
              <UccDisplayNamestring id='status' title='Status' displayCopyIcon={false} string={selectedRow?.status?.name} />
              <UccDisplayNamestring id='created_at' title='Date Created' displayCopyIcon={false} string={moment(selectedRow.created_at).format('llll')} />
              <UccDisplayNamestring id='push_date' title='Domo Publish Date' displayCopyIcon={false} string={!_.isEmpty(selectedRow.push_date) ? moment(selectedRow.push_date).format('llll') : '-'} />

              {_.isEmpty(selectedRow.facebook_id) && !_.isEmpty(selectedRow.facebook_detail) &&
                <UccDisplayNamestring id='publish_status' title='Facebook Error Details' displayCopyIcon={false} string={selectedRow.facebook_detail} />
              }
              {!_.isEmpty(selectedRow.facebook_id) && !_.isEmpty(selectedRow.facebook_created_at) &&
                <UccDisplayNamestring id='publish_date' title='Facebook Publish Date' displayCopyIcon={false} string={moment(selectedRow.facebook_created_at).format('llll')} />
              }
            </div>
          </Grid>
        }
      </Grid >

    );
  }
}

export default withStyles(styles)(ShowDefinitionList);



