
import './PrimeStyle.css';
import CrudToolbar from './../CrudAdmin/CrudToolbar';
import LinkIcon from '../../../../public/images/link-icon.png';
import IconButton from '@material-ui/core/IconButton';
import NameString from './../NameString';
import React, { Component } from 'react';
import routes from '../Layout/Routes';
import storeMetadata, { getInstanceUrl, getModelUrl, formFields, namestringFields } from '../../store/StoreMetadata';
import UccDisplayNamestring from './UccDisplayNamestring';
import UccToolTip from './UccToolTip';
import { Button } from 'primereact/button';
import { chop } from 'underscore.string';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { formatDate } from 'moment';
import { InputText } from 'primereact/inputtext';
import { Link } from 'mobx-router';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';

var moment = require('moment');
const styles = (theme) => ({
  h3utm: {
    margin: '12px 0 0 0',
    fontSize: 14,
  },
  ns: {
    margin: 0
  },
  tooltipstyle: {
    background: '#6c7cdc',
    borderRadius: '5px',
    width: '170px',
    paddingLeft: '10px',
    paddingRight: '10px',
    float: 'left',
    fontFamily: 'Avenir LT W01_35 Light1475496',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff',
    paddingBottom: 3,
    paddingTop: 3,
    marginTop: 11
  },
  headerContainer: {
    width: '100%',
    height: '50px',
    wordBreak: 'break-word',
  },
  divh3: {
    float: 'left',
    paddingTop: 18
  },
  h3: {
    margin: "0 10px 5px 0",
    paddingRight: '10',
    color: '#495057',
    fontSize: 16,
    fontWeight: 900,
  },
});

@inject('rootStore')
@observer
class UccDataTable extends Component {
  constructor(props) {
    super(props);
    const { channel, apiStore, uiStore, classes } = this.props.rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    this.showNamestringModal = this.showNamestringModal.bind(this);
    this.getTargeting = this.getTargeting.bind(this);
    // this.sliced_columns = this.props.columns.slice(0,3);
  }
  @observable selectedCampaignTypes = [];
  @observable dialogs = [];
  @observable nameString = '';
  @observable utmString = '';
  @observable newUtmString = '';
  @observable status = '';
  @observable push_date = '';
  @observable facebook_id = '';
  @observable facebook_created_at = '';
  @observable facebook_status = '';
  @observable facebook_details = '';

  @observable created_at = '';
  renderHeader() {
    return (<CrudToolbar model={this.props.model} />);
  }
  componentDidMount = async () => {
    const { apiStore, channel } = this.props.rootStore;
    this.prevModelName = apiStore.rootStore.channel.model.name;
    this.forceUpdate();
  }
  showNamestringModal = (e, col) => {
    const model = this.props.model;
    const crudStore = model.addCrudStore(
      this.props.rootStore,
      this.props.rootStore.channel,
      this.props.model,
      'namestringModalCrud',
    );

    crudStore.nsFields = namestringFields(model.columns);
    const row_id = col.id;
    if (!this.dialogs.includes(row_id)) this.dialogs.push(row_id);
    crudStore.nsFields = crudStore.getNsFieldValues('edit', crudStore.nsFields, col)
    crudStore.storeNsFieldsDatum(crudStore.nsFields);
    this.status = col.status.name;
    this.push_date = col.push_date;
    this.facebook_id = col.facebook_id;
    this.facebook_created_at = col.facebook_created_at;
    this.facebook_status = col.facebook_status;
    this.facebook_details = col.facebook_details;
    this.created_at = col.created_at;
    this.nameString = (
      <NameString
        store={crudStore}
        ret="string"
        type={model.endpoint.toLowerCase() == 'ads' ? 'ad' : ''}
      />
    );

    if (model.endpoint.toLowerCase() == 'ads') {
      this.utmPlatform = _.isEmpty(crudStore.storedData.social_ad_set) ?
        '' :
        ['SC', 'PIN'].includes(crudStore.storedData.social_ad_set.platform.abbrev) ?
          crudStore.storedData.social_ad_set.platform.name :
          '';
      this.utmString = NameString.getAdUtmString(crudStore, col)
      this.newUtmString = NameString.getNewUtmString(crudStore)
    };
  };
  getTargeting = (col) => {
    const row_id = col.id;
    return NameString.targetingString(this.props.model.crudStore);
  };
  closeNamestringModal = (e) => {
    this.dialogs = [];
    this.props.model.crudStore.storedData = {};
  };
  @action.bound
  onFilterChange(event, filterField, clearFlag = false) {

    const crudStore = this.props.model.crudStore;
    crudStore.selectedRowIds = [];
    crudStore.selectedRows = [];
    let valu = _.isEmpty(event.value) ? event.target.value : event.value;
    if (clearFlag) valu = '';
    let column = this.props.columns.find(c => c.id == filterField)
    if (typeof column != undefined && column.nestedFilterField) filterField = column.nestedFilterField;
    crudStore.storeData('filtered_' + filterField, valu);
    crudStore.setFirst(1);
    (clearFlag == true || valu == '') ? crudStore.clearFilter(filterField) : crudStore.setFilter(filterField);
    let additionalFilter = crudStore.storedData.isChild ? this.props.rootStore.channel.model.childModelFilter(this.props.rootStore) : {};
    const is_grand_parent_field = this.props.rootStore.channel.model.columns.filter(a => a.id == filterField).map(b => b.grand_parent_field);
    if (is_grand_parent_field) {
      additionalFilter.grand_parent = typeof this.props.rootStore.channel.model.grandParentModel != "undefined" ? this.props.rootStore.channel.model.grandParentModel : this.props.rootStore.channel.model.parentModel;
      // additionalFilter.parent = typeof this.props.rootStore.channel.model.parentModel != "undefined" ? this.props.rootStore.channel.model.parentModel : '';
      additionalFilter.gp_field = is_grand_parent_field;
    }
    crudStore.setDisplayClearFilter(filterField);
    crudStore.fetchDisplayedRows(additionalFilter, 1, crudStore.numberOfRowsPerPage, crudStore.sortField, crudStore.sortOrder);
    crudStore.fetchTotalRecords(additionalFilter, 1, crudStore.numberOfRowsPerPage, crudStore.sortField, crudStore.sortOrder);
    this.forceUpdate();
  }
  @action.bound
  onCustomSort(event, filterField) {
    const crudStore = this.props.model.crudStore;
    if (_.isEmpty(event.sortField)) return;
    crudStore.setFirst(1);
    crudStore.setSort(event);
    crudStore.fetchDisplayedRows({}, 1, crudStore.numberOfRowsPerPage, crudStore.sortField, crudStore.sortOrder);
    crudStore.fetchTotalRecords({}, 1, crudStore.numberOfRowsPerPage, crudStore.sortField, crudStore.sortOrder);
    this.forceUpdate();
  }
  @action.bound
  onPage = (e) => {
    const crudStore = this.props.model.crudStore
    crudStore.storedData['page', e.page];
    crudStore.setFirst(e.first + 1);
    crudStore.setCurrentPage(e.page + 1);
    crudStore.numberOfRowsPerPage = e.rows;
    crudStore.fetchDisplayedRows({}, crudStore.CurrentPage, crudStore.numberOfRowsPerPage, crudStore.sortField, crudStore.sortOrder);
    crudStore.fetchTotalRecords({}, crudStore.CurrentPage, crudStore.numberOfRowsPerPage, crudStore.sortField, crudStore.sortOrder);
    this.forceUpdate();
  }
  @action.bound
  filterElement(col) {
    if (typeof col.filter == 'function') {
      return col.filter(this, col);
    }
  }
  @action.bound
  toggleRow = (e) => {
    this.props.model.crudStore.toggleRowSelect(e);
  };
  getNamestring = (col) => {
    return (
      <div>
        <IconButton aria-label="Namestring" onClick={(e) => this.showNamestringModal(e, col)}>
          <img style={{ height: 20, width: 20 }} src={LinkIcon} />
        </IconButton>
      </div>
    );
  };
  columnStyle = (col) => {
    let label = !!col.grid_label ? col.grid_label : col.label;
    const len = label.length;
    let colsize;
    if (col.colSize) {
      colsize = col.colSize;
    } else {
      colsize = len + 1;
    }
    colsize = colsize + 'em';
    return {
      width: colsize,
      textAlign: 'left',
      textWrap: 'none',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: '#495057',
      height: '65px'
    };
  }

  handleEditLinkClick = (e, rowData) => {
    e.preventDefault();
    this.props.rootStore.channel.model.crudStore.selectedRows.push(rowData)
    this.props.rootStore.channel.model.crudStore.selectedRowIds = [rowData.id];
    this.props.rootStore.channel.model.crudStore.onEdit()
  };

  handleShowLinkClick = (e, rowData) => {
    e.preventDefault();
    this.props.rootStore.channel.model.crudStore.selectedRows.push(rowData)
    this.props.rootStore.channel.model.crudStore.selectedRowIds = [rowData.id];
    this.props.rootStore.channel.model.crudStore.onShow()
  };



  facebook_url = (brand, type, gridValue) => {
    const base_url = 'https://business.facebook.com/adsmanager/manage/';
    let part_url = 'campaigns';
    const params = []
    params.push(`act=${brand.facebook_ad_account_id}`);
    params.push(`business_id=${brand.facebook_business_id}`);
    params.push(`global_scope_id=${brand.facebook_business_id}`);
    params.push('nav_entry_point=bm_global_nav_shortcut');
    const selected = (function (type) {
      switch (type) {
        case 'facebook-campaigns':
          return `selected_campaign_ids=${gridValue}`;
        case 'facebook-adsets':
          part_url = 'adsets';
          return `selected_adset_ids=${gridValue}`;
        case 'facebook-ads':
          part_url = 'ads';
          return `selected_ad_ids=${gridValue}`;
      }
    })(type, gridValue);
    params.push(selected);
    return base_url + part_url + '?' + params.join('&');
  }


  @action.bound
  columnTemplate(rowData, column) {
    const dash = <span style={{ width: '100%', display: 'inline-block' }}>&#8211;</span>;
    const brand = this.props.rootStore.apiStore.currentBrand;
    switch (column.header) {
      case 'Namestring':
        return this.getNamestring(rowData);
      case 'Targeting':
        NameString.prepNamestring(this.props.model, rowData.id);
        const targeting = <UccToolTip title={this.getTargeting(rowData)} />;
        const targetingValue = _.isEmpty(targeting) ?
          (dash)
          : targeting;
        return (targetingValue);
      default:
        const gridValue = this.props.rootStore.apiStore.empty(column.orig.grid_value(rowData)) ?
          (<span style={{ width: '100%', display: 'inline-block' }}>&#8211;</span >) :
          <UccToolTip title={column.orig.grid_value(rowData)} />;
        // console.log(jsp(column))
        //console.log(jsp(gridValue.props.title));

        // tbd: move this linked function outside somewhere.
        const linked = function (column, that) {
          // console.log(column.grid_link)

          switch (column.grid_link) {
            case 'facebook-campaigns':
            case 'facebook-adsets':
            case 'facebook-ads':
              return (<a target="_blank" href={that.facebook_url(brand, column.grid_link, column.orig.grid_value(rowData))}> {gridValue}</a>);
            case 'edit':
              return (<a href="#" onClick={e => that.handleEditLinkClick(e, rowData)}>{gridValue}</a>);
            // might be used with ROUTING 2.0    
            // const uccRoute = that.props.rootStore.channel.adminChannel === true ? routes.adminChannelModelEdit : routes.channelModelEdit;
            // return (<Link
            //   view={uccRoute}
            //   store={that.props.rootStore.uiStore}
            //   params={{
            //     path: rowData.path,
            //     channel: that.props.rootStore.channel.endpoint,
            //     model: that.props.rootStore.channel.model.route,
            //     id: rowData.id,
            //     title: gridValue
            //   }} >{gridValue}</Link>)
            case 'draft_edit':
              // return (rowData.status && [2, 4, 5].includes(rowData.status.id)) ?
              //   (<><a href="#" onClick={e => that.handleEditLinkClick(e, rowData)}>{gridValue}</a></>) :
              //   (<><a href="#" onClick={e => that.handleShowLinkClick(e, rowData)}>{gridValue}</a></>);
              return (<><a href="#" onClick={e => that.handleShowLinkClick(e, rowData)}>{gridValue}</a></>);
            case 'email':
              return (<a href={"mailto:" + gridValue.props.title}>{gridValue.props.title}</a>);
            default:
              return gridValue;
          }
        }
        return linked(column, this);
    }
  }

  @action.bound
  dynamicColumns(col) {
    return (
      <Column
        orig={col}
        value={col}
        header={!!col.grid_label ? col.grid_label : col.label}
        body={this.columnTemplate}
        field={col.field}
        key={`${col.label} _header_key`}
        filterField={col.id}
        nestedFilterField={col.nestedFilterField}
        sortable={col.id == 'namestring' || col.sortable === false ? false : true}
        sortField={col.sortField ? col.sortField : col.id}
        sortOrder={-1}
        filter={col.filter ? true : false}
        filterPlaceholder={this.placeholder_text(col)}
        filterElement={this.filterElement(col)}
        style={this.columnStyle(col)}
        className="p-c"
        grid_link={col.grid_link}
      />
    );
  };
  placeholder_text = (col) => {
    return col.filter_placeholder ? col.filter_placeholder : (!!col.grid_label ? col.grid_label : col.label)
  }
  render() {
    const {
      classes,
      columns,
      order,
      orderBy,
      onRequestSort,
      rootStore,
      model,
      childIndex
    } = this.props;
    const { channel, apiStore, uiStore } = rootStore;
    const crudStore = model.crudStore;
    const header = this.renderHeader();
    const onSelectRow = (e) => {
      this.toggleRow(e);
    };
    return (
      <div data-qa={`${model.name} -index`} >
        <DataTable
          ref={(el) => (this.dt = el)}
          lazy={true}
          first={crudStore.first - 1}
          onLazyLoad={this.onLazyLoad}
          header={header}
          value={crudStore.displayedRows}
          totalRecords={crudStore.totalRecords}
          columnResizeMode="expand"
          paginator={true}
          rows={crudStore.numberOfRowsPerPage}
          onPage={this.onPage}
          selection={crudStore.selectedRows}
          onSelectionChange={crudStore.toggleRowSelect}
          selectionPageOnly={true}

          selectionMode={childIndex ? 'multiple' : false}
          autoLayout={false}
          rowHover
          dynamic={true}
          sortField={crudStore.sortField}
          sortOrder={crudStore.sortOrder == 'desc' ? -1 : 1}
          onSort={(e) => this.onCustomSort({ sortField: e.sortField, sortOrder: e.sortOrder })}
          resizableColumns={true}
          rowsPerPageOptions={[10, 25, 50, 100]}
          currentPageReportTemplate="Showing {last} of {totalRecords} entries"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          emptyMessage="No records found"
          metaKeySelection={false}
          loading={crudStore.fetchingDisplayedRows}
          style={{ boxShadow: '0 2px 4px 2px rgba(0, 0, 0, 0.5)', borderRadius: '10px' }}
          dataKey="id"
        >
          <Column
            selectionMode={crudStore.storedData['isChild'] ? false : 'multiple'}
            style={{
              width: '2em', textAlign: 'left',
            }}
          />
          {this.props.columns.map((col) => {
            return this.dynamicColumns(col)
          })}
        </DataTable>
        <Dialog header={channel.name + ' Namestring'}
          visible={this.dialogs.length > 0}
          style={{ minWidth: '20vw', maxWidth: '70vw', wordWrap: 'break-word' }}
          onHide={() => this.closeNamestringModal()} >
          <div>
            <UccDisplayNamestring id='namestring' title='Namestring' string={this.nameString} />
          </div>
          <div style={model.endpoint.toLowerCase() != 'ads' ? { display: 'none' } : { display: 'visible' }}>
            <UccDisplayNamestring id='short_utm_string' title=' Short UTM Parameter' string={this.newUtmString} />
          </div>
          <div style={model.endpoint.toLowerCase() != 'ads' ? { display: 'none' } : { display: 'visible' }}>
            <UccDisplayNamestring id='utmstring' title='UTM Parameter' string={this.utmString} />
          </div>
          <UccDisplayNamestring id='status' title='Status' displayCopyIcon={false} string={this.status} />
          <UccDisplayNamestring id='created_at' title='Date Created' displayCopyIcon={false} string={moment(this.created_at).format('llll')} />
          <UccDisplayNamestring id='push_date' title='Domo Publish Date' displayCopyIcon={false} string={!_.isEmpty(this.push_date) ? moment(this.push_date).format('llll') : '-'} />

          {_.isEmpty(this.facebook_id) && !_.isEmpty(this.facebook_detail) &&
            <UccDisplayNamestring id='publish_status' title='Facebook Error Details' displayCopyIcon={false} string={this.facebook_detail} />
          }
          {!_.isEmpty(this.facebook_id) && !_.isEmpty(this.facebook_created_at) &&
            <UccDisplayNamestring id='publish_date' title='Facebook Publish Date' displayCopyIcon={false} string={moment(this.facebook_created_at).format('llll')} />
          }
        </Dialog>
      </div >
    );
  }
}

export default withStyles(styles)(UccDataTable);