import React from 'react';
import UccButton from './UccButton';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';

@inject('rootStore')
@observer
class UccDoubleButton extends React.Component {
  constructor(props) {
    super(props);
    const crudStore = this.props.rootStore.channel.model.crudStore;
  }
  render() {
    const { rootStore, new_width, new_paddingLeft, cancelLeftPadding, new_paddingTop, new_margin, disabled } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const label = !this.props.label ? ((channel.admin || crudStore.initialAction == 'edit') && crudStore.action == 'edit') ? 'Update' : 'Submit' : this.props.label;
    const change_width = new_width ? new_width : '480px';
    const change_paddingLeft = new_paddingLeft ? new_paddingLeft : '20px';
    const change_paddingTop = new_paddingTop ? new_paddingTop : '16px';
    const new_margin_value = new_margin ? new_margin : '0px';
    return (
      <div className="root" style={{ width: change_width, paddingLeft: change_paddingLeft, paddingTop: change_paddingTop, margin: new_margin_value }}>
        <div className="cancel" style={{ paddingLeft: cancelLeftPadding }}>
          <UccButton
            buttonType=''
            label='cancel'
            form={this.props.form}
            onClick={this.props.onCancel}
            width='225px'
          />
        </div>
        <div className="submit">
          <UccButton
            buttonType='submit'
            label={label}
            form={this.props.form}
            onClick={this.props.onSubmit}
            width='225px'
            disable={disabled}
          />
        </div>


      </div>
    );
  }
}

export default UccDoubleButton;
