import Channel from './Channel';
import MenuItem from '@material-ui/core/MenuItem';
import NameString from '../components/NameString';
import React from 'react';
import RootStore from '../store/RootStore';
import routes from '../components/Layout/Routes';
import { observable, action, computed } from 'mobx';
import { RouterStore } from 'mobx-router';

class UiStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.previously_stored_targeting_data = [];
    this.previous_targeting_form_values = [];
  }

  @observable router = new RouterStore(this);

  @observable profileAnchorEl = null;

  @observable accordionAnchorEl = null;

  @observable appDrawerOpen = false;

  @observable createAnchorEl = null;

  @observable refreshingUser = null;

  @observable brandMenuAnchor = null;

  @observable lastError = null;

  @observable breadcrumbs = [];

  @computed get brandMenuOpen() {
    return this.brandMenuAnchor != null;
  }

  @action.bound
  toggleAuthenticated() {
    this.authenticated = !this.authenticated;
  }

  @action.bound
  setbrandMenuAnchor(anchorEl) {
    this.brandMenuAnchor = anchorEl;
  }

  @computed get profileMenuOpen() {
    return this.profileAnchorEl != null;
  }

  @action.bound
  setProfileAnchorEl(el) {
    this.profileAnchorEl = el;
  }

  @computed get accordionMenuOpen() {
    return this.accordionAnchorEl != null;
  }

  @action.bound
  setAccordionAnchorEl(el) {
    this.accordionAnchorEl = el;
  }

  @action.bound
  closeAppDrawer() {
    this.drawerOpen = false;
  }

  @action.bound
  toggleAppDrawer() {
    this.appDrawerOpen = !this.appDrawerOpen;
  }

  @action.bound
  setBreadcrumbs(breadcrumbs) {
    this.breadcrumbs = breadcrumbs;
  }

  isPrimeMenuOptionActive(value) {
    return value.status == undefined || value.status.name.toLowerCase() == 'active'
  }
  // @action.bound
  // makeOption(value) {
  //   return (<MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>)
  // }
  @action.bound
  makePrimeOption(value) {
    if (!this.isPrimeMenuOptionActive(value)) return;
    return ({ label: value.name, value: value.id.toString() })
  }

  @action.bound
  makeRegularOption(value) {
    return (<MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>);
  }

  @action.bound
  makePrimeRegularOption(value) {
    if (!this.isPrimeMenuOptionActive(value)) return;
    return ({ label: value.name, value: value.id.toString() })
  }

  @action.bound
  makePrimeOptionForEdit(value) {
    if (!this.isPrimeMenuOptionActive(value)) return;
    return ({ label: value.label, value: value.fieldName, disabled: value.menuItems != undefined && value.menuItems.length == 0 })
  }

  @action.bound
  makeChannelSpecificOption = (value, thing) => {
    const looks = this.rootStore.apiStore[thing].map(look => look.f_key);
    if (looks.includes(value.id)) {
      return (<MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>);
    }
  };

  @action.bound
  makePrimeChannelSpecificOption = (value, thing) => {
    if (!this.isPrimeMenuOptionActive(value)) return;
    const looks = this.rootStore.apiStore[thing].map(look => look.f_key);
    if (looks.includes(value.id)) {
      return ({ label: value.name, value: value.id.toString() })
    }
  };

  @action.bound
  makePlatformOptions = (rootStore, data) => {
    let getList = [];
    let finalOption = [];
    let selectOption = [];
    const options = _.compact(rootStore.apiStore.platforms.map((platform) => rootStore.uiStore.makePrimeChannelSpecificOption(platform, 'channelPlatforms')))
    if (_.isEmpty(data) || data == "" || data.length == 0) {
      finalOption = options;
    } else {
      selectOption = data["name"].toLowerCase().split(" & ");
      selectOption.filter(function (i) { data["name"].toLowerCase().includes("&") ? getList.push(options.filter((o) => (o.label.toLowerCase().includes(i)))) : getList.push(options.filter((o) => (o.label.toLowerCase() == i))) })
      getList.forEach(element => element.forEach(ele => finalOption.push(ele)))
    }

    return _.uniq(finalOption);
  }

  @action.bound
  makePrimeSpecificCampaignTypeOption = (value) => {
    if (!this.isPrimeMenuOptionActive(value)) return;

    const crudStore = this.rootStore.channel.model.crudStore;
    if (!crudStore.firstPage && crudStore.storedData['unique_attribute'] == 'campaign_type_id') return;
    const apiStore = this.rootStore.apiStore;
    const objective_id = crudStore.storedData.objective_id;
    let objectives = [];
    if (crudStore.toolbarAction != 'bulk_create' && crudStore.selectedRows.length > 1) {
      objectives = crudStore.selectedRows.map(m => !_.isEmpty(m.objective) ? m.objective.id : 0);
    } else if ((typeof objective_id == 'array' || typeof objective_id == 'object') && (!_.isEmpty(objective_id)) && crudStore.toolbarAction == 'bulk_create') {
      objectives = objective_id.map(m => m.toString())
    } else if (typeof objective_id == 'string') {
      objectives.push(objective_id);
    }

    objectives = objectives.filter(m => m != 0);
    const chLookFkeys = this.rootStore.apiStore.campaignTypeObjectives.filter(look => look.type_of == "CampaignType" && look.f_key == objective_id).map(look => look.value);
    let looks = this.rootStore.apiStore.campaignTypeChannels.map(look => look.f_key);
    let campaign_list;
    if (objectives.length == 0) {
      if (looks.includes(value.id)) {
        return ({ label: value.name, value: value.id.toString() })
      }
    } else if ((['copy_edit', 'bulk_edit', 'bulk_create'].includes(crudStore.toolbarAction))) {
      if (crudStore.selectedRows.length > 1 && objectives.length > 1) {
        campaign_list = objectives.map(obj_id => this.rootStore.apiStore.campaignTypeObjectives.filter(looks => looks.f_key == obj_id)).map(l => l.map(a => a.value))
        looks = this.getCommonElements(campaign_list);
        if (looks.includes(value.id)) {
          return ({ label: value.name, value: value.id.toString() })
        }
        // } else if (objectives.length == 0 && (objective_id == undefined || objective_id == "")) {
        //   return ({ label: value.name, value: value.id.toString() })
      } else {
        if (chLookFkeys.length >= 1 && chLookFkeys.includes(value.id.toString())) {
          return ({ label: value.name, value: value.id.toString() })
        }
      }
    } else if (crudStore.initialAction == 'edit' || crudStore.initialAction == 'new' || crudStore.initialAction == 'bulk_create') {
      if (looks.includes(value.id)) {
        return ({ label: value.name, value: value.id.toString() })
      }
    }

  };

  getCommonElements(arrays) {//Assumes that we are dealing with an array of arrays of integers
    var currentValues = {};
    var commonValues = {};
    for (var i = arrays[0].length - 1; i >= 0; i--) {//Iterating backwards for efficiency
      currentValues[arrays[0][i]] = 1; //Doesn't really matter what we set it to
    }
    for (var i = arrays.length - 1; i > 0; i--) {
      var currentArray = arrays[i];
      for (var j = currentArray.length - 1; j >= 0; j--) {
        if (currentArray[j] in currentValues) {
          commonValues[currentArray[j]] = 1; //Once again, the `1` doesn't matter
        }
      }
      currentValues = commonValues;
      commonValues = {};
    }
    return Object.keys(currentValues).map(function (value) {
      return parseInt(value);
    });
  }

  @action.bound
  tacticChanged(selected) {
    if (!selected) return;
    this.rootStore.channel.model.crudStore.storeData('tactic_id', selected);
    this.rootStore.apiStore.setTargetingFields(this.rootStore.channel.model.crudStore.storedData.tactic_id);
    if (this.rootStore.react) this.rootStore.react.forceUpdate();
  }


  @action.bound
  constructName() {
    const orig = this.rootStore.channel.model.crudStore.selectedRow.name;
    return this.rootStore.channel.model.crudStore.action === 'copy' ? 'copy --- ' + orig : orig
  }

  makeObjectiveOption = (objective) => {
    const crudStore = this.rootStore.channel.model.crudStore;
    const apiStore = this.rootStore.apiStore;
    const chLookFkeys = apiStore.channelObjectives.filter(look => look.value == crudStore.channel.codename).map(look => look.f_key);
    if (chLookFkeys.length < 1) return;
    let campaignTypeObjectives = apiStore.campaignTypeObjectives.filter(l => l.f_key == objective.id)
    if (crudStore.storedData.campaign_type) {
      campaignTypeObjectives = campaignTypeObjectives.filter(l => l.value == crudStore.storedData.campaign_type.id);
    }
    const ctLookFkeys = campaignTypeObjectives.map(look => look.f_key);
    if (ctLookFkeys.includes(objective.id) && chLookFkeys.includes(objective.id)) { //looks.includes(objective.id) ||
      return (<MenuItem key={objective.id} disabled={false} value={objective.id}>{objective.name}</MenuItem>)
    }
  }


  makePrimeObjectiveOption = (objective) => {
    if (!this.isPrimeMenuOptionActive(objective)) return;
    const crudStore = this.rootStore.channel.model.crudStore;
    const apiStore = this.rootStore.apiStore;
    const chLookFkeys = apiStore.channelObjectives.filter(look => look.value == crudStore.channel.codename).map(look => look.f_key);
    if (chLookFkeys.length < 1) return;
    let selected_campaigns = [];
    let ctLookFkeys;
    let campaignTypeObjectives;
    if (crudStore.selectedRows.length > 1) {
      selected_campaigns = crudStore.selectedRows.map(m => !_.isEmpty(m.campaign_type) ? m.campaign_type.id.toString() : 0);
    } else if (crudStore.toolbarAction == 'bulk_create') {
      if (Array.isArray(crudStore.storedData.campaign_type)) {
        selected_campaigns = crudStore.storedData.campaign_type.map(m => m.id.toString())
      } else {
        selected_campaigns = crudStore.storedData.campaign_type;
      }
    } else {
      selected_campaigns = [0]
    }
    if ((['copy_edit', 'bulk_edit', 'bulk_create'].includes(crudStore.toolbarAction)) && _.compact(selected_campaigns).length > 0) {
      campaignTypeObjectives = apiStore.campaignTypeObjectives.filter(item => selected_campaigns.includes(item.value)).filter(l => l.f_key == objective.id);
      if (campaignTypeObjectives.length == _.compact(selected_campaigns).filter(this.getUnique).length) {
        ctLookFkeys = campaignTypeObjectives.map(look => look.f_key);
        if (ctLookFkeys.includes(objective.id) && chLookFkeys.includes(objective.id)) {
          return ({ label: objective.name, value: objective.id.toString() })
        }
      }
    } else {
      campaignTypeObjectives = apiStore.campaignTypeObjectives.filter(l => l.f_key == objective.id);
      if (crudStore.storedData.campaign_type) {
        campaignTypeObjectives = campaignTypeObjectives.filter(l => l.value == crudStore.storedData.campaign_type.id);
      }

      ctLookFkeys = campaignTypeObjectives.map(look => look.f_key);
      if (ctLookFkeys.includes(objective.id) && chLookFkeys.includes(objective.id)) {
        return ({ label: objective.name, value: objective.id.toString() })
      }

      campaignTypeObjectives = apiStore.campaignTypeObjectives.filter(l => l.f_key == objective.id)
    }
  }

  makePrimeTestGroupOption = (testGroup) => {
    if (!this.isPrimeMenuOptionActive(testGroup)) return;
    const crudStore = this.rootStore.channel.model.crudStore;
    const apiStore = this.rootStore.apiStore;
    const selected_test_types = crudStore.selectedRows.map(m => !_.isEmpty(m.test_type) ? m.test_type.id.toString() : 0);
    let tgttLookFkeys;
    let tgttLooks;
    if (crudStore.selectedRows.length > 1 && crudStore.toolbarAction == 'copy_edit') {
      tgttLooks = apiStore.testGroupTestTypes.filter(item => selected_test_types.includes(item.value)).filter(l => l.f_key == testGroup.id);
      if (tgttLooks.length == selected_test_types.filter(this.getUnique).length) {
        tgttLookFkeys = tgttLooks.map(look => look.f_key);
        if (tgttLookFkeys.includes(testGroup.id)) {
          return ({ label: testGroup.name, value: testGroup.id.toString() })
        }
      }
    } else {
      tgttLooks = apiStore.testGroupTestTypes.filter(l => l.f_key == testGroup.id);
      if (crudStore.storedData.test_type) {
        tgttLooks = tgttLooks.filter(l => l.value == crudStore.storedData.test_type.id);
      }

      tgttLookFkeys = tgttLooks.map(look => look.f_key);
      if (tgttLookFkeys.includes(testGroup.id)) {
        return ({ label: testGroup.name, value: testGroup.id.toString() })
      }

      tgttLooks = apiStore.testGroupTestTypes.filter(l => l.f_key == testGroup.id)
    }
  }

  getUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  //TODO.. make this method and the makePrimeObjectiveOoption method into one that passes in the right stuffs.
  makePrimeObjectiveModifierOption = (objectiveModifier, instance_name) => {
    if (!this.isPrimeMenuOptionActive(objectiveModifier)) return;
    const crudStore = this.rootStore.channel.model.crudStore;
    const apiStore = this.rootStore.apiStore;
    const chLookFkeys = apiStore.channelObjectives.filter(look => look.value == crudStore.channel.codename).map(look => look.f_key);

    if (chLookFkeys.length < 1) return;
    if (instance_name == 'astellas') {
      if (!crudStore.storedData.paid_search_campaign) return;
      let objectiveModifierObjectives = apiStore.objectiveModifierObjectives.filter(l => l.f_key == objectiveModifier.id)
      if (crudStore.storedData.paid_search_campaign.objective) {
        objectiveModifierObjectives = objectiveModifierObjectives.filter(l => l.value == crudStore.storedData.paid_search_campaign.objective.id);
      }
      const objModLookFkeys = objectiveModifierObjectives.map(look => look.f_key);
      if (objModLookFkeys.includes(objectiveModifier.id) && chLookFkeys.includes(crudStore.storedData.paid_search_campaign.objective.id)) {
        return ({ label: objectiveModifier.name, value: objectiveModifier.id.toString() })
      }
    }
    else {
      if (!crudStore.storedData.objective) return;
      let objectiveModifierObjectives = apiStore.objectiveModifierObjectives.filter(l => l.f_key == objectiveModifier.id)
      if (crudStore.storedData.objective) {
        objectiveModifierObjectives = objectiveModifierObjectives.filter(l => l.value == crudStore.storedData.objective.id);
      }
      const objModLookFkeys = objectiveModifierObjectives.map(look => look.f_key);
      if (objModLookFkeys.includes(objectiveModifier.id) && chLookFkeys.includes(crudStore.storedData.objective.id)) {
        return ({ label: objectiveModifier.name, value: objectiveModifier.id.toString() })
      }
    }
  }

  makePrimeAdModifierOption = (adModifier) => {
    if (!this.isPrimeMenuOptionActive(adModifier)) return;
    const crudStore = this.rootStore.channel.model.crudStore;
    const apiStore = this.rootStore.apiStore;
    if (!crudStore.storedData.paid_search_campaign && !crudStore.storedData.objective_modifier) return;
    // const objective_modifier_id = !crudStore.storedData.paid_search_campaign ?
    //   crudStore.storedData.paid_search_campaign.objective_modifier_id :
    //   crudStore.storedData.objective_modifier.id; 
    if (!crudStore.storedData.objective_modifier) return;
    const objective_modifier_id = crudStore.storedData.objective_modifier.id;
    const amLookFkeys = apiStore.adModifierObjectiveModifiers.filter(look => look.value == objective_modifier_id).map(look => look.f_key);
    if (amLookFkeys.length < 1) return;
    if (amLookFkeys.includes(adModifier.id)) {
      return ({ label: adModifier.name, value: adModifier.id.toString() })
    }
  }

  makeAdModifierOptions = (objective_modifier) => {
    if (!this.isPrimeMenuOptionActive(objective_modifier)) return;
    const crudStore = this.rootStore.channel.model.crudStore;
    const apiStore = this.rootStore.apiStore;
    if (!crudStore.storedData.paid_search_campaign && !crudStore.storedData.objective_modifier) return;
    const am_ids = apiStore.adModifierObjectiveModifiers.filter(look => look.type_of == "ObjectiveModifier" && look.value == objective_modifier).map(look => look.f_key);
    const ams = am_ids.map(am_id => apiStore.adModifiers.find(am => am.id == am_id));
    const options = ams.map(am => [am.id, am.name])
    return _.compact(options);

  }

  makeObjectiveOptions = () => {
    const crudStore = this.rootStore.channel.model.crudStore;
    const objective_list = _.compact(this.rootStore.apiStore.objectives.map(o => this.makePrimeObjectiveOption(o)));
    return objective_list;
  }

  unmountComponent = () => {
    if (this.rootStore.channel.model.crudStore.action != 'index') {
      this.rootStore.channel.model.crudStore.setFormData(this.form);
    } else {
      this.rootStore.channel.model.crudStore.setStoredData('all')
    }
    // might be used with ROUTING 2.0
    // this.router.goTo(
    //   routes.adminChannelTabs,
    //   {
    //     channel: this.rootStore.channel.endpoint,
    //     model: this.rootStore.channel.model.route,
    //   },
    //   this.rootStore.channel[this.rootStore.channel.model.codename].crudStore,
    // );
  }

  humanize = (str) => {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }


}

export default UiStore;
