import React from 'react';
import Paper from '@material-ui/core/Paper';
import storeMetadata, { getInstanceUrl, getModelUrl, formFields, namestringFields, removeTrailingValue } from '../store/StoreMetadata';
import IconButton from '@material-ui/core/IconButton';
import ContentCopyIcon from '../icons/ContentCopy';
import Tooltip from '@material-ui/core/Tooltip';
import UccDisplayNamestring from './PrimeReactComponent/UccDisplayNamestring';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { formatDate } from 'moment';
import { withStyles } from '@material-ui/core/styles';
import './PrimeReactComponent/PrimeStyle.css';
import ReactHtmlParser from 'react-html-parser';


var moment = require('moment');
const styles = (theme) => ({
  nameString: {
    position: '-webkit-sticky',
    position: 'sticky',
    fontSize: 16,
    padding: theme.spacing(2),
    marginTop: 13,
    marginLeft: 30,
    marginRight: 19,
    border: '2px solid #495057',
    borderRadius: '22px',
    minWidth: '300px',
    wordBreak: 'break-word',
    top: 80,
  },
  bulkNameString: {
    marginTop: 13,
  },
  icon: {
    width: '55px',
    float: 'left',
    display: 'inline-block',
  },
  tooltipstyle: {
    background: '#6c7cdc',
    borderRadius: '5px',
    width: '170px',
    paddingLeft: '10px',
    paddingRight: '10px',
    float: 'left',
    fontFamily: 'Avenir LT W01_35 Light1475496',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff',
    paddingBottom: 3,
    paddingTop: 3,
    marginTop: 11
  },
  divh3: {
    float: 'left',
    paddingTop: 18
  },
  h3: {
    margin: "0 10px 5px 0",
    paddingRight: '10',
    color: '#495057',
    fontSize: 16,
    fontWeight: 900,
  },
  headerContainer: {
    width: '100%',
    height: '50px',
    wordBreak: 'break-word',
  },
  nnss: {
    // margin: "0 4px 10px 0",
    display: 'block',
    fontSize: 16,
    fontWeight: 300,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#495057",
    minWidth: '50px',
    wordBreak: 'break-word',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  iconButton: {
    marginLeft: 10
  }
});

@inject('rootStore')
@observer
class NameString extends React.Component {
  @observable theUtmString = ''
  constructor(props) {
    super(props);
    const { rootStore } = this.props;
    // this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    // this.resetState = this.resetState.bind(this);
  }
  static arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;
    a.slice().sort();
    b.slice().sort();
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
  static daaiteString = (date) => {
    if (date == null) return '';
    var e = new Date(date);
    return ('0' + (e.getUTCMonth() + 1)).slice(-2) + '.'
      + ('0' + e.getUTCDate()).slice(-2) + '.'
      + e.getFullYear();
  }

  static pivotShow = (row, field_name, attribute = 'targeting') => {
    const arr = row[field_name].map(dpt => row[attribute + 's'].find(t => t.id == dpt[attribute + '_id']).name + (!_.isEmpty(dpt.extra) ? " - " + dpt.extra + (row[attribute + 's'].find(targ => targ.id == dpt[attribute + '_id']).extra_field_type == 'number' ? ' Days' : '') : ''));

    return (
      arr.map(item => {
        return (
          <div key={item}>
            {item}
          </div>
        );
      })
    )
  }



  static controlId = (crudStore, control, dependent) => {
    let cid = null;
    if (crudStore.model[dependent] == 'parent') {
      if (crudStore.storedData[crudStore.model.parentModel] && crudStore.storedData[crudStore.model.parentModel][control]) {
        cid = crudStore.storedData[crudStore.model.parentModel][control].id;
      }
    } else {
      if (_.isEmpty(crudStore.storedData[`${control}_id`])) {
        if (crudStore.storedData[control]) {
          cid = crudStore.storedData[control].id;
        }
      } else {
        cid = crudStore.storedData[`${control}_id`];

      }
    }
    return cid;
  }



  static pivotNamestring = (crudStore = null, control = 'tactic', dependent = 'targeting') => {
    const dep_field = `${crudStore.model.controller}_${dependent}s`;
    const control_id = `${control}_id`;
    const dependent_id = `${dependent}_id`;
    const ad_modifiers = crudStore.rootStore.apiStore['adModifiers'];
    const storedDataLoc = _.isEmpty(crudStore.storedData[dep_field]) ?
      `${dep_field}_attributes` :
      dep_field
    const storedData = crudStore.model.targeting == 'parent' ?
      (_.isEmpty(crudStore.storedData[crudStore.model.parentModel]) ?
        '' :
        crudStore.storedData[crudStore.model.parentModel][crudStore.model.parentModel + '_' + dependent + 's']) :
      crudStore.storedData[storedDataLoc];
    if (_.isEmpty(storedData)) return '';
    const controlIdVal = controlId(crudStore, control, dependent);

    let pieces = [];
    let dependents = [];
    if (control == 'tactic') {
      dependents = crudStore.rootStore.apiStore.setNsTargetingFields(controlIdVal);
    } else {
      dependents = crudStore.rootStore.apiStore.setExtraFieldFields(controlIdVal);
      // dependents = crudStore.rootStore.apiStore.objectiveModifierFields;
    }
    const combo = { did: dependent_id, ad_modifiers: ad_modifiers, store: storedData }
    if (dependents.length < 1) return;
    dependents.map(function (t) {
      let sdVar = undefined;
      if (sdVar = storedData.find(sd => (!sd._destroy && sd[dependent_id] == t.id))) {
        let replacementString = ''
        if (t.extra_field_type == "") {
          replacementString = t.abbrev
        } else if (t.extra_field_type == 'select') {
          // this may not scale.. 
          replacementString = this?.ad_modifiers?.find(am => am.id == this.store[0].extra)?.abbrev
        } else {
          replacementString = _.startCase(_.toLower(sdVar.extra)).replace(/[^A-Z0-9]/ig, '');
        }
        // !_.isEmpty(sdVar.extra) ? pieces.push(control == 'tactic' ? t.abbrev.replace('XX', replacementString) : replacementString) : '';
        pieces.push(control == 'tactic' ? t.abbrev.replace('XX', replacementString) : replacementString);
      }

    }, combo);

    return _.compact(pieces).join('+');
  }





  static targetingString = (crudStore = null) => {
    // this method could be broken up to call its specific "if"s
    // also, crudStore.targetings now has a list of targetings for the record, so no need to look in apiStore 
    // and also less filtering is needed. Lets try to get this method down to a few lines. 
    // also since crudStore.targetings.. some crudStore setInitialData logic may be overkill too.

    // console.log(1, crudStore.storedData[`${crudStore.model.controller}_targetings`])
    // console.log(2, crudStore.storedData[`${crudStore.model.controller}_targetings_attributes`])
    // crudStore.model.targeting == 'parent' ?
    //   _.isEmpty(crudStore.storedData[crudStore.model.parentModel]) ?
    //     console.log('aaaaa') :
    //     console.log('bbbbb') :
    //   console.log('ccccc')

    const storedDataLoc = _.isEmpty(crudStore.storedData[`${crudStore.model.controller}_targetings`]) ?
      `${crudStore.model.controller}_targetings_attributes` :
      `${crudStore.model.controller}_targetings`

    const storedData = crudStore.model.targeting == 'parent' ?
      _.isEmpty(crudStore.storedData[crudStore.model.parentModel]) ?
        '' :
        crudStore.storedData[crudStore.model.parentModel][crudStore.model.parentModel + '_targetings'] :
      crudStore.storedData[storedDataLoc];

    if (_.isEmpty(storedData)) return;

    const tacticId = crudStore.model.targeting == 'parent' ?
      crudStore.storedData[crudStore.model.parentModel]?.tactic?.id :
      _.isEmpty(crudStore.storedData['tactic_id']) ?
        crudStore.storedData.tactic?.id :
        crudStore.storedData['tactic_id'];

    // if (.isEmpty(tacticId)) return;

    let pieces = [];
    let targetings = [];
    targetings = crudStore.rootStore.apiStore.setTargetingFields(tacticId);
    if (targetings.length < 1) return;
    // console.log(storedData.map(sd => sd.targeting_id))
    targetings.map(t => {
      let sdVar = undefined;
      // console.log(t.id)
      if (sdVar = storedData.find(sd => (!sd._destroy && sd.targeting_id == t.id))) {
        // console.log(t.id)
        const replacementString = _.startCase(_.toLower(sdVar.extra)).replace(/[^A-Z0-9]/ig, '');
        pieces.push(t.abbrev.replace('XX', replacementString))
      }

    });

    return _.compact(pieces).join('+');
  }

  // 3.2 features/UTA-446-utms comment
  static getAdUtmString(crudStore, row, type = 'long', index) {
    const utmString = [];
    if (crudStore.bulkCrudStores.length > 0) return;
    const storedData = crudStore.storedData;

    const utmContent = storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id;
    // console.log(crudStore.name + " adUtmString")
    const platform = _.isEmpty(storedData.social_ad_set) ?
      '' :
      ['SC', 'PIN'].includes(storedData.social_ad_set.platform.abbrev) ?
        storedData.social_ad_set.platform.abbrev :
        '';

    switch (platform) {
      case 'SC':
        utmString.push(`utm_medium=PaidSocial`);
        utmString.push('utm_source=Snapchat');
        utmString.push('utm_campaign={{campaign.name}}');
        utmString.push('utm_content=' + utmContent);
        if (type == 'long') {
          utmString.push('utm_term={{creative.name}}');
          utmString.push('ad_id={{ad.id}}');
        }
        break;
      case 'PIN':
        utmString.push(`utm_medium=PaidSocial`);
        utmString.push('utm_source=Pinterest');
        utmString.push('utm_campaign={campaignname}');
        utmString.push('utm_content=' + utmContent);
        if (type == 'long') {
          utmString.push('ad_id={adid}');
        }
        break
      default:
        utmString.push(`utm_medium=${crudStore.channel.abbrev}`);
        utmString.push('utm_source={{site_source_name}}');
        utmString.push('utm_campaign={{campaign.name}}');
        utmString.push('utm_content=' + utmContent);
        if (type == 'long') {
          utmString.push('utm_term={{ad.name}}');
          utmString.push('ad_id={{ad.id}}');
        }
    }
    if (type == 'long') {
      crudStore.setUtmString(NameString.formattedBrandUrl(crudStore) + utmString.join('&'))
    } else {
      crudStore.setShortUtmString(NameString.formattedBrandUrl(crudStore) + utmString.join('&'))
    }
    return (type == 'long') ? crudStore.utmstring_string : crudStore.short_utm_string;
  }

  static getNewUtmString(crudStore, index) {
    const bob = getAdUtmString(crudStore, '', 'short', index);
    // console.log('cs ' + crudStore.name + ' bob: ' + bob);
    return getAdUtmString(crudStore, '', 'short', index)
  }

  static formattedBrandUrl = (crudStore) => {
    const { brand_url } = crudStore.storedData;
    if (_.isEmpty(brand_url)) return '';
    if (brand_url.endsWith('?')) return brand_url;
    if (brand_url.includes('?')) return brand_url + '&';
    return brand_url + '?';
  }

  static prepNamestring = (model, row_id) => {
    const row = model.crudStore.getDisplayedRow(row_id);
    for (let key in row) {
      model.crudStore.storeData(key, row[key]);
    }
    let nsFields = namestringFields(model.columns);
    model.crudStore.nsFields = model.crudStore.getNsFieldValues('edit', nsFields, row);
    model.crudStore.storeInitialFieldsDatum(model.crudStore.nsFields);
  }

  coreNameString = (store, index) => {
    // const highlighted = _.compact(this.props.edited_column).map(h => h.slice(-3) == '_id' ? h.slice(0, -3) : h.slice(-4) == '_ids' ? h.slice(0, -4) + 's' : h);
    const highlighted = _.compact(this.props.edited_column).map(h => removeTrailingValue(h, '_'));
    const { nsFields } = store;
    const attr = this.props.bulkCreateAttribute;
    let result = _.map(
      _.filter(nsFields, f => f.ns_order), f => {
        return (
          highlighted.includes(f.id) || _.compact(this.props.edited_column).includes(f.id) ? ReactHtmlParser('&lt;strong&gt;' + f.ns(store) + '&lt;/strong&gt;')
            : (attr?.includes(f.id) || f.id?.includes(attr)) ? store.storedData?.[attr]?.abbrev
              : f.id == 'namestring_id' && !!index ? store.bulkCrudStores?.[index]?.storedData.namestring_id
                : f.ns(store)
        );
      });

    // const brand = store.rootStore.apiStore.currentBrand?.abbrev;
    // const group = store.rootStore.apiStore.currentUser?.group.abbrev;
    // const channel = store.channel?.abbrev;
    result = result.join("_");
    store.setNamestring(result);
    return result;
    // return _.map(_.filter(nsFields, f => f.ns_order), f => f.ns(store)).join("_");
  }

  wrapMultipleStrings = (arrayOfStrings) => {
    const all = arrayOfStrings.length > 0 && arrayOfStrings.map(ns => (<div className={this.props.classes.bulkNameString}>{ns}</div>));
    return all;
  };

  render() {
    const {
      classes, type, utmstring, columns, store, ret, edited_column
    } = this.props;

    const rootStore = store.rootStore;
    const channel = rootStore.channel;
    const model = channel.model;
    let nnss;
    let regularNamestrings = [], longUtmStrings = [], shortUtmStrings = [];

    const selectedAttributes = store.storedData?.[this.props.bulkCreateAttribute];

    const platform = _.isArray(store.storedData.social_ad_set) ?
      '' :
      _.isEmpty(store.storedData.social_ad_set) ?
        '' :
        ['SC', 'PIN'].includes(store.storedData.social_ad_set.platform.abbrev) ?
          store.storedData.social_ad_set.platform.name :
          '';

    // console.log(store.name + ' namestringer');
    if (selectedAttributes?.length > 0) {
      store.bulkCrudStores.forEach((cs, index) => {
        let newNamestring = this.coreNameString(cs)
        regularNamestrings.push(newNamestring);
        let newBulkLongUtmString = getAdUtmString(cs, null, null, index);
        longUtmStrings.push(newBulkLongUtmString);
        let newBulkShortUtmString = getAdUtmString(cs, null, 'short', index);
        shortUtmStrings.push(newBulkShortUtmString);
        cs.setUtmString(newBulkLongUtmString);
        cs.setShortUtmString(newBulkShortUtmString);
        // shortUtmStrings.push(getNewUtmString(store, index));
        // cs.setNamestring(newNamestring);
        store.setNamestring(newNamestring);
        store.setUtmString(newBulkLongUtmString);
        store.setShortUtmString(newBulkShortUtmString)
      }, this);
    } else {
      store.nsFields = namestringFields(model.columns);
      nnss = this.coreNameString(store);
      let newLongUtmString = getAdUtmString(store);
      longUtmStrings.push(newLongUtmString);
      let newShortUtmString = getAdUtmString(store, null, 'short');
      shortUtmStrings.push(newShortUtmString);
      store.setNamestring(nnss);
      store.setUtmString(newLongUtmString);
      store.setShortUtmString(newShortUtmString)
      // console.log(newLongUtmString)
    }
    if (regularNamestrings.length > 0) regularNamestrings.map(bulkNs => (

      <UccDisplayNamestring id={`namestring_${bulkNs}`} title='Namestring' titlePrefix={type} string={bulkNs} />
    ));

    const checkBulkTitle = `Namestring${regularNamestrings.length > 1 ? `s (${regularNamestrings.length})` : ''}`;


    if (ret == 'string') return (<div dangerouslySetInnerHTML={{ __html: nnss }}></div>);
    return (
      <div id='namestring-list' className={classes.nameString}>
        <UccDisplayNamestring id='namestring' title='Namestring' titlePrefix={type} string={nnss} />
        {regularNamestrings.length > 0 && regularNamestrings.map(
          ns => (<div className={classes.bulkNameString}>{ns}</div>)
        )}
        <div style={type.toLowerCase() != 'ad' ? { display: 'none' } : { display: 'visible' }}>
          <UccDisplayNamestring
            id='short_utm_string'
            title=' Short UTM Parameter'
            titlePrefix={platform}
            string={this.wrapMultipleStrings(shortUtmStrings)} />
        </div>
        <div style={type.toLowerCase() != 'ad' ? { display: 'none' } : { display: 'visible' }}>
          <UccDisplayNamestring
            id='utmstring'
            title=' UTM Parameter'
            titlePrefix={platform}
            string={this.wrapMultipleStrings(longUtmStrings)}
          />
        </div>
        {(store.storedData.status.name.toLowerCase() != 'predraft') &&
          <div>
            <UccDisplayNamestring id='status' title='Status' displayCopyIcon={false} string={store.storedData.status.name} />
            <UccDisplayNamestring id='created_at' title='Date Created' displayCopyIcon={false} string={store.storedData.created_at != 0 ? moment(store.storedData.created_at).format('llll') : ''} />
            <UccDisplayNamestring id='push_date' title='Domo Publish Date' displayCopyIcon={false} string={!_.isEmpty(store.storedData.push_date) ? moment(store.storedData.push_date).format('llll') : '-'} />
            {_.isEmpty(store.storedData.facebook_id) && !_.isEmpty(store.storedData.facebook_detail) &&
              <UccDisplayNamestring id='publish_status' title='Facebook Error Details' displayCopyIcon={false} string={store.storedData.facebook_detail} />
            }
            {!_.isEmpty(store.storedData.facebook_id) && _.isDate(store.storedData.facebook_created_at) &&
              <UccDisplayNamestring id='publish_date' title='Facebook Publish Date' displayCopyIcon={false} string={moment(store.storedData.facebook_created_at).format('llll')} />
            }
          </div>
        }
      </div>
    )
  }
}
export const arraysEqual = NameString.arraysEqual;
export const dateString = NameString.dateString;
export const pivotNamestring = NameString.pivotNamestring;
export const controlId = NameString.controlId;
export const targetingString = NameString.targetingString;
export const pivotShow = NameString.pivotShow;
export const getAdUtmString = NameString.getAdUtmString;
export const getNewUtmString = NameString.getNewUtmString;
export const formattedBrandUrl = NameString.formattedBrandUrl;
export const prepNameString = NameString.prepNameString;

export default withStyles(styles)(NameString);