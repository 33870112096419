import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './PrimeStyle.css';
import 'primeicons/primeicons.css';
import { observer, inject } from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';
import UccErrorHandling from './UccErrorHandling';
import UccMultiSelect from './UccMultiSelect';

@inject('rootStore')
@observer
class UccSelect extends Component {

  render() {
    const { rootStore, form, fieldName, label, classes, display, disabled } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const isBulkCreateSelection = crudStore.initialAction == 'bulk_create' && !crudStore.firstPage && crudStore.storedData?.['unique_attribute']?.replace('_id', '').replaceAll('_', '-') == this.props.core.replaceAll('_', '-');
    if (display === false) return (null);
    if (form && !form?.has(fieldName)) return '';

    const finalFirstOptionLabel = _.isEmpty(this.props.firstOptionLabel) ? `Select ${label}` : this.props.firstOptionLabel;
    const finalLabel = !form || (crudStore.initialAction == 'bulk_create' && crudStore.firstPage) ? label :
      !form?.$(fieldName)?.rules?.includes('required') && !!label
        ? `(Optional)  ${label}`
        : label;

    const val = (typeof crudStore.storedData[fieldName] == "undefined" || crudStore.storedData[fieldName] == null) ? crudStore.storedData[fieldName] : crudStore.storedData[fieldName].toString();
    const isError = !form ? !crudStore.displayError : (!!(form?.$(fieldName).error) && crudStore.storedData[fieldName].toString() === '' ? false : true)
    // if (crudStore.model.name == "Ad Groups") {
    //   crudStore.rootStore.apiStore.setExtraFieldFields(val);
    // }

    let menuItems = _.compact(this.props.menuItems);
    if (isBulkCreateSelection) {
      // console.log(finalLabel);
      return (
        <UccMultiSelect {...this.props} isBulkCreateSelection={true} disabled />
      );
    } else {
      return (
        <fieldset className="fieldset" id={`fieldset-${fieldName}`} style={{ width: '200px' }}  >
          <FormControl id={`formControl-${this.props.core}`}>
            <label className='label'>{finalLabel}</label>
            <div className={this.props.className === undefined ? 'container' : this.props.className}  >
              <Dropdown
                options={menuItems}
                onChange={this.props.onChange}
                placeholder={finalFirstOptionLabel}
                value={val == "" || val == 0 ? null : val}
                label={finalLabel}
                filter={typeof this.props.filter == "undefined" ? true : this.props.filter}
                className={this.props.className == '' ? this.props.className : 'uccItems'}
                panelClassName='changeLabelColor'
                style={(isError) ? { border: '' } : { border: '2px solid #FF5656' }}
                showClear={true}
                disabled={disabled}
              />
              <UccErrorHandling
                form={this.props.form}
                fieldName={fieldName}
              />
            </div>
          </FormControl>
        </fieldset>
      );
    }
  }
}

export default UccSelect;