import AcknowledgeDialog from '../lib/AcknowledgeDialog';
import AcknowledgeError from '../lib/AcknowledgeError';
import UserManagement from '../../../../public/images/admin-user-management.svg';
import AttrValues from '../../../../public/images/np_list_875440_000000.svg';
import CrudAdmin from './CrudAdmin';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import ParamNotFoundError from '../Layout/ParamNotFoundError';
import React from 'react';
import routes from '../Layout/Routes';
import Typography from '@material-ui/core/Typography';
import UiStore from '../../store/UiStore';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { TabView, TabPanel } from 'primereact/tabview';


const styles = theme => ({
  moveTypography: {
    paddingTop: theme.spacing(3),
    verticalAlign: 'middle',
    clear: 'both',
    color: 'black',
    marginBottom: theme.spacing(3),
  },
  tabs: {
    paddingTop: theme.spacing(1),
    marginLeft: '50px',
    marginBottom: theme.spacing(3)
  },
  tab: {
    fontWeight: 'bold'
  },
});


@inject('uiStore', 'rootStore')
@observer
class CrudTabs extends React.Component {

  constructor(props) {
    super(props);
    this.model = this.props.rootStore.channel.model;

  }
  componentWillUnmount = () => {
    this.props.rootStore.resetAction()
  }

  componentDidMount = async () => {
    if (_.isEmpty(this.model)) return;
    if (this.model.tabData) {
      await this.model.tabData(this.props.rootStore.apiStore);
    }
  }

  componentWillUpdate = async () => {
    // console.log('will update')
    if (this.model.tabData) {
      await this.model.tabData(this.props.rootStore.apiStore);
    }
  }

  handleChange = (event, newValue) => {
    const { rootStore, uiStore } = this.props;
    const model = rootStore.channel.models.find(m => m.tabIndex == event.index)
    const params = { channel: rootStore.channel.endpoint, model: model.endpoint };
    if (model.crudStore.selectedRowId && model.crudStore.action == 'show') params['id'] = model.crudStore.selectedRowId;
    model.crudStore.modalAcknowledgeMessage = '';
    model.crudStore.modalAcknowledgeOpen = false;

    model.crudStore.storeData('isChild', false);
    if (rootStore.channel.adminChannel) {
      uiStore.router.goTo(
        routes.adminChannelTabs,
        params,
        rootStore.channel[model.codename].crudStore,
      );
    }
    else {
      uiStore.router.goTo(
        routes.channelTabs,
        {
          brand_path: _.toLower(rootStore.apiStore.currentBrand.path),
          channel: rootStore.channel.endpoint,
          model: model.route,
        },
        rootStore.channel[model.codename].crudStore,
      );
    }

    if (rootStore.channel.adminChannel) {
      rootStore.channel[model.codename].crudStore.clearSelection();
    }
  }

  render() {
    const { classes, rootStore, uiStore } = this.props;
    const channel = rootStore.channel;
    const model = channel.model;
    if (_.isEmpty(model)) return (<ParamNotFoundError title="Model Not Found" solution="Either the model is misspelled in the url or you don't have access " />);

    const {
      modalAcknowledgeOpen,
      modalAcknowledgeLabel,
      modalAcknowledgeAction,
      modalAcknowledgeMessage,
      cancelAction,
    } = model.crudStore;

    if (rootStore.apiStore.fetchingBrands || rootStore.apiStore.datasetsProvisioning) {
      return (<div style={{ textAlign: 'center' }}><CircularProgress /></div>);
    }
    if (!rootStore.apiStore.channelPerm(channel)) return (
      <AcknowledgeError>
        <h3>
          Brand Not Found
        </h3>
      </AcknowledgeError>
    );
    return (
      <>
        {
          modalAcknowledgeOpen && (
            <AcknowledgeDialog
              message={modalAcknowledgeMessage}
              buttonLabel={modalAcknowledgeLabel}
              onAcknowledge={modalAcknowledgeAction}
              onCancel={cancelAction}
            />
          )
        }
        <div data-qa="main-tabs">
          {uiStore.router.currentPath.includes('admin/') ? <CrudAdmin /> :
            <TabView
              className="tab-view-style"
              activeIndex={rootStore.channel.model.tabIndex}
              onTabChange={this.handleChange}
            >
              {
                rootStore.channel.models.map(model => {
                  return (
                    <TabPanel className="tab-panel-style" header={model.tabLabel(model)} key={model.codename} disabled={model.tabDisabled(rootStore)}>
                      <CrudAdmin />
                    </TabPanel>
                  );
                })}
            </TabView>}
        </div>


      </>
    );
  }
}

CrudTabs.wrappedComponent.propTypes = {
  // uiStore: PropTypes.instanceOf(UiStore).isRequired,
  // crudAdminStore: PropTypes.instanceOf(CrudAdminStore).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CrudTabs);
