import ax from 'axios';
import { action, computed, observable } from 'mobx';
import 'mobx-react/batchingForReactDom';
import { RouterStore } from 'mobx-router';
import API from './Api';
import ApiStore from './ApiStore';
import ChannelHierarchyStore from './ChannelHierarchyStore';
import CrudAdminStore from './CrudAdminStore';
import NameString from '../components/NameString';
import Channel from './Channel';
import storeMetadata from './StoreMetadata';
import UiStore from './UiStore';
import Instance from './Instance';

class RootStore {
  constructor(routes, features) {
    this.apiStore = new ApiStore(this);
    this.stuff = ['a', 'b'];
    this.brands = features.brands;
    this.apiStore.brands = this.brands;
    this.routes = routes;
    this.instance = new Instance(features.instance)
    this.version = "3.8.0";
    this.features = features;
    this.display = new Channel(this, this.instance.metadata, 'display');
    this.email = new Channel(this, this.instance.metadata, 'email');
    this.miniApi = new API({ url: '' });
    this.paidSearch = new Channel(this, this.instance.metadata, 'paidSearch');
    this.paidSocial = new Channel(this, this.instance.metadata, 'paidSocial');
    this.programmatic = new Channel(this, this.instance.metadata, 'programmatic');

    this.channels = [];
    features.channels.map(c => this.channels.push(this[c.codename]));
    this.userManagement = new Channel(this, this.instance.metadata, 'userManagement');
    this.attrValues = new Channel(this, this.instance.metadata, 'attrValues');
    this.domoIntegrations = new Channel(this, this.instance.metadata, 'domoIntegrations');
    this.facebookIntegrations = new Channel(this, this.instance.metadata, 'facebookIntegrations');

    this.abTypesCrud = this.miniApi.endpoints.abTypes;
    this.adsCrud = this.miniApi.endpoints.ads;
    this.adModifiersCrud = this.miniApi.endpoints.adModifiers;
    this.adModifierObjectiveModifiersCrud = this.miniApi.endpoints.adModifierObjectiveModifiers;
    this.adTypeChannelsCrud = this.miniApi.endpoints.adTypeChannels;
    this.adTypesCrud = this.miniApi.endpoints.adTypes;
    this.areasCrud = this.miniApi.endpoints.areas;
    this.audiencesCrud = this.miniApi.endpoints.audiences;
    this.businessUnitsCrud = this.miniApi.endpoints.businessUnits;
    this.campaignTypesCrud = this.miniApi.endpoints.campaignTypes;
    this.channelDomoDatasetsCrud = this.miniApi.endpoints.channelDomoDatasets;
    this.chs = ChannelHierarchyStore;
    this.brandUserCrud = this.miniApi.endpoints.brandUsers;
    this.brandsCrud = this.miniApi.endpoints.brands;
    this.brandChannelsCrud = this.miniApi.endpoints.brandChannels;
    this.brandChannelGroupsCrud = this.miniApi.endpoints.brandChannelGroups;
    this.campaignTypeChannelsCrud = this.miniApi.endpoints.campaignTypeChannels;
    this.campaignTypeObjectivesCrud = this.miniApi.endpoints.campaignTypeObjectives;
    this.channelsCrud = this.miniApi.endpoints.channels;
    this.displayTypeChannelsCrud = this.miniApi.endpoints.displayTypeChannels;
    this.channelGendersCrud = this.miniApi.endpoints.channelGenders;
    this.channelObjectivesCrud = this.miniApi.endpoints.channelObjectives;
    this.channelPlatformsCrud = this.miniApi.endpoints.channelPlatforms;
    this.channelTacticsCrud = this.miniApi.endpoints.channelTactics;
    this.channelTargetingsCrud = this.miniApi.endpoints.channelTargetings;
    this.creativeTypesCrud = this.miniApi.endpoints.creativeTypes;
    this.currentUserCrud = this.miniApi.endpoints['users/current'];
    this.devicesCrud = this.miniApi.endpoints.devices;
    this.displayAdsCrud = this.miniApi.endpoints.displayAds;
    this.displayCreativesCrud = this.miniApi.endpoints.displayCreatives;
    this.displayPackagesCrud = this.miniApi.endpoints.displayPackages;
    this.displayPlacementsCrud = this.miniApi.endpoints.displayPlacements;
    this.displayTypesCrud = this.miniApi.endpoints.displayTypes;
    this.domoConnectionsCrud = this.miniApi.endpoints.domoConnections;
    this.emailServicePlatformsCrud = this.miniApi.endpoints.emailServicePlatforms;
    this.emailCampaignsCrud = this.miniApi.endpoints.emailCampaigns;
    this.emailTypesCrud = this.miniApi.endpoints.emailTypes;
    this.extraFieldObjectiveModifiersCrud = this.miniApi.endpoints.extraFieldObjectiveModifiers;
    this.extraFieldsCrud = this.miniApi.endpoints.extraFields;
    this.extraFieldModelsCrud = this.miniApi.endpoints.extraFieldModels;
    this.facebookConnectionsCrud = this.miniApi.endpoints.facebookConnections;
    this.facebookObjectsCrud = this.miniApi.endpoints.facebookObjects;
    this.gendersCrud = this.miniApi.endpoints.genders;
    this.geosCrud = this.miniApi.endpoints.geos;
    this.goalsCrud = this.miniApi.endpoints.goals;
    this.groupsCrud = this.miniApi.endpoints.groups;
    this.languagesCrud = this.miniApi.endpoints.languages;
    this.looksCrud = this.miniApi.endpoints.looks;
    this.marketingCampaignsCrud = this.miniApi.endpoints.marketingCampaigns;
    this.modelsCrud = this.miniApi.endpoints.models;
    this.namestringsCrud = this.miniApi.endpoints.namestrings;
    this.newAdsetsCrud = this.miniApi.endpoints.adSets;
    this.newAdsCrud = this.miniApi.endpoints.ads;
    this.newCampaignsCrud = this.miniApi.endpoints.socialCampaigns;
    this.ns = NameString;
    this.objectiveModifierObjectivesCrud = this.miniApi.endpoints.objectiveModifierObjectives;
    this.objectiveModifiersCrud = this.miniApi.endpoints.objectiveModifiers;
    this.objectivesCrud = this.miniApi.endpoints.objectives;
    this.optimizationGoalsCrud = this.miniApi.endpoints.optimizationGoals;
    this.paidSearchAdGroupsCrud = this.miniApi.endpoints.paidSearchAdGroups;
    this.paidSearchAdNamesCrud = this.miniApi.endpoints.paidSearchAdNames;
    this.paidSearchCampaignsCrud = this.miniApi.endpoints.paidSearchCampaigns;
    this.permissionLevelsCrud = this.miniApi.endpoints.permissionLevels;
    this.platformsCrud = this.miniApi.endpoints.platforms;

    this.programmaticCampaignsCrud = this.miniApi.endpoints.programmaticCampaigns;
    this.programmaticInsertionOrdersCrud = this.miniApi.endpoints.programmaticInsertionOrders;
    this.programmaticLineItemsCrud = this.miniApi.endpoints.programmaticLineItems;
    this.programmaticPlacementsCrud = this.miniApi.endpoints.programmaticPlacements;
    this.programmaticAdsCrud = this.miniApi.endpoints.programmaticAds;
    this.programmaticCreativesCrud = this.miniApi.endpoints.programmaticCreatives;



    this.publishersCrud = this.miniApi.endpoints.publishers;
    this.secondaryTacticsCrud = this.miniApi.endpoints.secondaryTactics;
    this.segmentsCrud = this.miniApi.endpoints.segments;
    this.sendTypesCrud = this.miniApi.endpoints.sendTypes;
    this.sizesCrud = this.miniApi.endpoints.sizes;
    this.storeMetadata = this.instance.metadata;
    this.tacticsCrud = this.miniApi.endpoints.tactics;
    this.tacticTargetingsCrud = this.miniApi.endpoints.tacticTargetings;
    this.targetingsCrud = this.miniApi.endpoints.targetings;
    this.displayPlacementsCrud = this.miniApi.endpoints.displayPlacements;

    this.uiStore = new UiStore(this);
    this.usersCrud = this.miniApi.endpoints.users;
    this.statusesCrud = this.miniApi.endpoints.statuses;

    this.testTypesCrud = this.miniApi.endpoints.testTypes;
    this.testGroupsCrud = this.miniApi.endpoints.testGroups;
    this.testGroupTestTypesCrud = this.miniApi.endpoints.testGroupTestTypes;
    this.messageTypesCrud = this.miniApi.endpoints.messageTypes;
    this.wavesCrud = this.miniApi.endpoints.waves;
  }

  @observable favoriteBrands = [];
  @observable serverError = null;
  @observable title = 'Universal Campaign Coder';
  @observable channel = null;
  @observable features = {};

  @action.bound
  setCurrentChannelAndModel(channel, model) {
    const zModel = model;

    const fchannel = (function (ch) {
      switch (ch) {
        case 'user-management':
          return 'userManagement';
        case 'attributes-values':
          return 'attrValues';
        case 'domo-integrations':
          return 'domoIntegrations';
        case 'facebook-integrations':
          return 'facebookIntegrations';
        case 'paid-search':
          return 'paidSearch';
        case 'paid-social':
          return 'paidSocial';
        default:
          return ch;
      }
    })(channel);
    const fmodel = (function (fchannel, zModel) {
      switch (fchannel) {
        case 'paidSocial':
          switch (zModel) {
            case 'campaigns':
              return 'SocCamp';
            case 'ads':
              return 'SocAd';
            case 'ad-sets':
              return 'SocAdSet';
          }
        case 'paidSearch':
          switch (zModel) {
            case 'campaigns':
            case 'paid-search-campaigns':
              return 'PaidSearchCamp';
            case 'ad-groups':
              return 'PaidSearchAdGroup';
            case 'ad-names':
              return 'PaidSearchAdName';
          }
        case 'programmatic':
          switch (zModel) {
            case 'campaigns':
              return 'ProgCamp';
            case 'insertion-orders':
              return 'ProgIO';
            case 'line-items':
              return 'ProgrammaticLineItem';
            case 'placements':
              return 'DispPlace';
            case 'ads':
              return 'DispAd';
            case 'creatives':
              return 'DispCreat';
          }
        case 'display':
          switch (zModel) {
            case 'placements':
              return 'DispPlace';
            case 'ads':
              return 'DispAd';
            case 'creatives':
              return 'DispCreat';
            case 'packages':
              return 'DispPack';
          }
        case 'email':
          switch (zModel) {
            case 'campaigns':
              return 'EmailCamp';
          }
        case 'userManagement':
          switch (zModel) {
            case 'users':
              return 'AdminUser';
            case 'groups':
              return 'AdminGroup';
            case 'brands':
              return 'AdminBrand';
            case 'permission-levels':
              return 'AdminPermissionLevel';
          }
        case 'attrValues':
          switch (zModel) {
            case 'ab-types':
              return 'AdminABTypes';
            case 'ad-modifiers':
              return 'AdminAdModifiers';
            case 'ad-types':
              return 'AdminAdTypes';
            case 'audiences':
              return 'AdminAudiences';
            case 'campaign-types':
              return 'AdminCampaignTypes';
            case 'channels':
              return 'AdminChannels';
            case 'creative-types':
              return 'AdminCreativeTypes';
            case 'devices':
              return 'AdminDevices';
            case 'email-service-platforms':
              return 'AdminEmailServicePlatforms';
            case 'extra-fields':
              return 'AdminExtraFields';
            case 'genders':
              return 'AdminGenders';
            case 'geos':
              return 'AdminGeos';
            case 'goals':
              return 'AdminGoals';
            case 'languages':
              return 'AdminLanguages';
            case 'marketing-campaigns':
              return 'AdminMarketingCampaigns';
            case 'models':
              return 'AdminModels';
            case 'objectives':
              return 'AdminObjectives';
            case 'objective-modifiers':
              return 'AdminObjectiveModifiers';
            case 'optimization-goals':
              return 'AdminOptimizationGoals';
            case 'platforms':
              return 'AdminPlatforms';
            case 'publishers':
              return 'AdminPublishers';
            case 'secondary-tactics':
              return 'AdminSecondaryTactics';
            case 'segments':
              return 'AdminSegments';
            case 'send-types':
              return 'AdminSendTypes';
            case 'sizes':
              return 'AdminSizes';
            case 'statuses':
              return 'AdminStatuses';
            case 'tactics':
              return 'AdminTactics';
            case 'targetings':
              return 'AdminTargetings';
            case 'waves':
              return 'AdminWaves';
            case 'extra-fields':
              return 'AdminExtraFields';
            case 'test-groups':
              return 'AdminTestGroups';
            case 'test-types':
              return 'AdminTestTypes';
            case 'message-types':
              return 'AdminMessageTypes';
            case 'email-types':
              return 'AdminEmailTypes';
            case 'display-types':
              return 'AdminDisplayTypes';
          }
        case 'domoIntegrations':
          switch (zModel) {
            case 'connections':
              return 'DomoConnections';
            case 'datasets':
              return 'DomoDatasets';
            case 'details':
              return 'DomoNamestrings';
          }
        case 'facebookIntegrations':
          switch (zModel) {
            case 'connections':
              return 'FacebookConnections';
            case 'datasets':
              return 'DomoDatasets';
            case 'objects':
              return 'FacebookObjects';
            case 'details':
              return 'DomoNamestrings';
          }
      }
    })(fchannel, zModel);

    // this.channel = (this.channel && this.channel.codename == fchannel) ? this.channel : this[fchannel];
    if (_.isEmpty(this.channel) || this.channel.codename != fchannel) {
      this.channel = this[fchannel];
    }
    this.channel.model = this[fchannel][fmodel];
  }

  @action.bound
  clear() {
    this.apiStore.clear();
  }
  @action.bound
  setChannel(channel) {
    this.channel = channel;
  }
  @action.bound
  setModel(model) {
    this.model = model;
  }

  @action.bound
  setTitle(title) {
    this.title = title;
  }

  @action.bound
  newCurrentUser(user) {
    this.fetchFavoriteBrands();
    this.apiStore.fetchBrands(user);
    this.apiStore.fetchBrandsChannels();
  }

  @computed get fetching() {
    return this.apiStore.fetching;
  }

  getRoutes() {
    return this.routes;
  }

  resetAction(action = 'index') {
    // this.campaignCrud.action = action;
    // this.adsetCrud.action = action;
    // this.adCrud.action = action;
  }

  @action.bound
  addFavorite(brand_id) {
    const url = '/brand-users' + '.json';
    const controller = 'brand_users';
    ax.post(url, { [controller]: { brand_id: brand_id } })
      .then((response) => {
        this.fetchFavoriteBrands();
      })
      .catch((error) => {
        this.setServerError(error);
        this.setServerValidationErrors(error.response.data);
      });
  }

  @action.bound
  fetchFavoriteBrands() {
    const url = '/brand-users' + '.json';
    ax.get(url, { params: {} })
      .then((response) => {
        this.setFavoriteBrands(response.data);
      })
      .catch((error) => {
        this.serverError = error;
      });
  }

  @action.bound
  setFavoriteBrands(brands) {
    this.favoriteBrands = brands.map((c) => c.brand_id);
  }

  isFavoriteBrand(brandId) {
    return this.favoriteBrands.includes(brandId);
  }
}

export default RootStore;
