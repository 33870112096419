
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import dvr from 'mobx-react-form/lib/validators/DVR';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import UccDoubleButton from '../../PrimeReactComponent/UccDoubleButton';
import { formElements, isDisabled, disableSubmitButton } from './../../../store/StoreMetadata';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import UccSelect from '../../PrimeReactComponent/UccSelect';
import UccMultiSelect from '../../PrimeReactComponent/UccMultiSelect';
import { kebabCaseToCamel } from '../../../store/Api';



const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: '7px',
    paddingRight: '6px',
    flexGrow: 1,
  },
  tall: {
    height: 30,
  },
  modifierWrapper: {
    // border: '1px solid black',
    width: 'min-content',
    marginLeft: 20,
    borderRadius: 4,
    width: 487,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  modifierField: {
    width: '468px'
  }
});

@inject('rootStore')
@observer
class BulkCreateForm extends React.Component {
  @observable suggestedEndDate = '';

  constructor(props) {
    super(props);
    const crudStore = this.props.rootStore.channel.model.crudStore;
    const hooks = crudStore.hooks(crudStore);
    this.form = crudStore.form;
    this.props.rootStore.react = this;
  }

  componentDidMount = async () => {
    const crudStore = this.props.rootStore.channel.model.crudStore;
    crudStore.formComponentDidMount();
  };

  componentWillUnmount = () => {
    const crudStore = this.props.rootStore.channel.model.crudStore;
    const apiStore = this.props.rootStore.apiStore;
    if (apiStore.adNameTabDisabled) crudStore.selectedRowIds = crudStore.selectedRowIds.slice();
    this.props.rootStore.uiStore.unmountComponent();
  }

  render() {
    const { classes, rootStore, type, datePicker, numButtons, extraFields } = this.props;
    const { channel, apiStore, uiStore } = rootStore;
    const { form } = this;
    const model = channel.model;
    const crudStore = model.crudStore;
    const specialPlatformCase = ['social_campaign', 'social_ad_set']
    const listToIgnore = ['ab_version', 'brand_url', 'display_type_ids', 'name', 'objective_id', 'region', 'version']
    const fullForm = formElements(rootStore, false).map(fe => fe.formField(rootStore));
    if (crudStore.loading) return (<div style={{ textAlign: 'center' }}><CircularProgress /></div>);

    const getBulkLabel = str => {
      // let result = str.replace(/(\_\w|\/\w)/g, (matches) => ` ${matches[1].toUpperCase()}`).replace(' Id', '');
      // result = result[0].toUpperCase() + result.substring(1);
      let result = _.compact(fullForm.filter((a) => a.props.fieldName == str).map((b) => b.props.label))
      return result;
    }

    const getApiParam = crudParam => {
      let apiParam = `${crudParam && kebabCaseToCamel(crudParam)}`;
      if (apiParam[apiParam.length - 1] !== 's') apiParam += 's';
      apiParam = apiParam == 'socialAdSets' ? 'adSets' : apiParam;
      return apiParam;
    }

    if (specialPlatformCase.includes(crudStore.model.parentModel) && rootStore[rootStore.channel.codename][crudStore.model.parentModelCode]?.crudStore?.selectedRowIds?.length == 1) {
      if (isDisabled(rootStore[rootStore.channel.codename][crudStore.model.parentModelCode].crudStore.selectedRows[0]['platform'])) {
        listToIgnore.push('platform_id')
      }
    } else if (model.codename == 'SocAdSet') {
      listToIgnore.push('platform_id');
    }
    // Each of the conditions listed here where i.form !== some_value should only remain active as long as bulk creation of text fields is not supported; can be removed when bulk creation of admin is built
    // const attrOptions = model.columns.filter(i => !!i.copyEditable && !!i.formField && i.form !== 'ab_version' && i.form !== 'objective_id' && i.form !== 'region' && i.form !== 'name' && i.form !== 'version' && i.form !== 'brand_url' && i.form !== 'display_type_ids').map(j => ({ label: j.label, value: j.form }));
    const attrOptions = model.columns.filter(i => !!i.copyEditable && !!i.formField && !listToIgnore.includes(i.form)).map(j => ({ label: j.label, value: j.form }));
    const selectedAttribute = crudStore.storedData?.['unique_attribute'];
    //console.log('selectedAttribute: ' + selectedAttribute);
    const crudParam = selectedAttribute?.replace('_id', '');
    //console.log('crudParam: ' + crudParam);
    const apiParam = getApiParam(crudParam);
    //console.log('apiParam: ' + apiParam);

    let opts = selectedAttribute ? _.compact(fullForm.filter((a) => a.props.fieldName == selectedAttribute).map((b) => b)[0].props.menuItems) : [];
    opts = (selectedAttribute == 'platform_id' && rootStore[rootStore.channel.codename][crudStore.model.parentModelCode]?.crudStore?.selectedRowIds?.length == 1) ? rootStore.uiStore.makePlatformOptions(rootStore, rootStore[rootStore.channel.codename][crudStore.model.parentModelCode].crudStore.selectedRows[0]['platform']) : opts
    return (
      <div className={classes.root}>
        <Grid xs={12} container direction="row" justify="flex-start" alignItems="stretch" wrap="nowrap">
          <Grid container alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <UccSelect
                core="unique_attribute"
                fieldName="unique_attribute"
                label="Unique Attribute"
                menuItems={attrOptions}
                onChange={(event) => {
                  crudStore.storeData('unique_attribute', event);
                }}
              />
              {!!selectedAttribute && (
                <UccMultiSelect
                  core={apiParam}
                  fieldName={selectedAttribute}
                  label={getBulkLabel(selectedAttribute)}
                  // menuItems={_.compact(rootStore.apiStore[apiParam].map((item) => rootStore.uiStore.makePrimeRegularOption(item)))}
                  menuItems={opts}
                  onChange={(event) => {
                    //console.log(event.target.value);

                    crudStore.storeData(selectedAttribute, event);
                    crudStore.storeData(crudParam, rootStore.apiStore[apiParam].filter(o => event.target.value.includes(o.id.toString())));

                  }}
                />
              )}
              <UccDoubleButton
                label="Continue"
                form={form}
                onSubmit={model.crudStore.continueBulkCreate}
                onCancel={model.crudStore.onCancel}
              />
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.tall}>&nbsp;</div>
      </div>
    );
  }
}

BulkCreateForm.wrappedComponent.propTypes = {
  // apiStore: PropTypes.instanceOf(ApiStore).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BulkCreateForm);
