
import { Button } from 'primereact/button';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import RootStore from '../../store/RootStore';
import Tooltip from '@material-ui/core/Tooltip';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import UccToolbarButton from './UccToolbarButton';

@inject('rootStore')
@observer
class UccToolbarDelete extends React.Component {
  constructor(props) {
    super(props);
    this.model = this.props.rootStore.channel.model;
  }

  @observable deleteCount = '';

  @action.bound
  getDeleteCount = async () => {
    const model = this.props.model
    model.crudStore.setToolbarAction('Delete')
    const dc = await model.crud(this.props.rootStore).getDeleteCount({ ids: model.crudStore.selectedRowIds })
    this.deleteCount = ReactDOMServer.renderToStaticMarkup(<ul data-qa="data_delete_ul" style={{ textAlign: 'left' }}>{(dc.data.map((c) => (<li key={c}>{c}</li>)))}</ul>);
    return;
  }
  disableDelete = () => {

    if (this.props.rootStore.apiStore.currentUser.pll < this.props.model.permDelete) return true;

    return (this.props.model.crudStore.selectedRowIds.length < 1);
  }

  render() {

    const { model } = this.props;
    const numSelected = model.crudStore.selectedRowIds.length;
    const crudStore = model.crudStore;
    const deleteCount = this.deleteCount;
    const id = 'toolbar-delete';

    return (
      <UccToolbarButton id={id} disable={this.disableDelete()} selectedRowIds={model.crudStore.selectedRowIds} model={model} label="Delete" icon="pi pi-trash" onClick={async (e) => {
        await this.getDeleteCount();
        return crudStore.confirmAction(
          crudStore.onDelete,
          `<span data-qa = "data_delete" style="color:gray">Are You Sure?</span><div style="color:black;margin: 10px;"><nobr>You are about to delete the following:</nobr> ${this.deleteCount}</div>`,
          'Confirm',
        )
      }} />
    );
  }
}


export default UccToolbarDelete;
