import GenericForm from './../components/CrudAdmin/forms/GenericForm';
import AdminUserManagement from '../../../public/images/admin-user-management.svg';
import AdminAttrValues from '../../../public/images/np_list_875440_000000.svg';
import DomoLogo from '../../../public/images/domo_logo.png';
import FacebookLogo from '../../../public/images/facebook_logo.png';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../components/PrimeReactComponent/UccInputText';
import UccSelect from './../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../components/PrimeReactComponent/UccObjectiveModifier';

import { formatDate } from 'moment';

import jsp from '../lib/jsp';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from '../lib/Calendar';
import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from './StoreMetadata.js'

var moment = require('moment');
const abbrev_regex = 'regex:/^\\w+$/';
const extra_regex = 'regex:/^[a-zA-Z0-9_]+$/';


export const astellasMetadata = {
  // .----.  .--.  .-..----.     .----. .----.  .---. .-.  .--.  .-.
  // | {}  }/ {} \ | || {}  \   { {__  /  {}  \/  ___}| | / {} \ | |
  // | .--'/  /\  \| ||     /   .-._} }\      /\     }| |/  /\  \| `--.
  // `-'   `-'  `-'`-'`----'    `----'  `----'  `---' `-'`-'  `-'`----'

  // don't change codenames!
  channels: [
    {
      abbrev: 'PaidSocial',
      codename: 'paidSocial',
      disabled: true,
      endpoint: 'social',
      icon: 'PaidSocial',
      name: 'Paid Social',
      models: [
        {
          name: 'Campaign Placeholder',
          codename: 'SocCamp',
          columns: [
            {
              domo_field: 'ns_type',
              domo_value: () => 'SocialCampaign',
              rules: '',
            }
          ]
        }
        //nope
      ],
    },
    //////////////////////////////////////////////////
    // ______  _________ _______  _______  _        _______
    // (  __  \ \__   __/(  ____ \(  ____ )( \      (  ___  )|\     /|
    // | (  \  )   ) (   | (    \/| (    )|| (      | (   ) |( \   / )
    // | |   ) |   | |   | (_____ | (____)|| |      | (___) | \ (_) /
    // | |   | |   | |   (_____  )|  _____)| |      |  ___  |  \   /
    // | |   ) |   | |         ) || (      | |      | (   ) |   ) (
    // | (__/  )___) (___/\____) || )      | (____/\| )   ( |   | |
    // (______/ \_______/\_______)|/       (_______/|/     \|   \_/

    {
      name: 'Display',
      endpoint: 'display',
      codename: 'display',
      abbrev: 'Display',
      icon: 'Display',
      showViewIcon: true,
      models: [
        {
          // ______ _                                     _
          // | ___ \ |                                   | |
          // | |_/ / | __ _  ___ ___ _ __ ___   ___ _ __ | |_ ___
          // |  __/| |/ _` | / __/ _ \ '_ ` _ \ / _ \ '_ \| __ / __ |
          // | |   | | (_| | (_|  __/ | | | | |  __/ | | | |_\__ \
          // \_|   |_|\__,_|\___\___|_| |_| |_|\___|_| |_|\__|___/

          allFilterData: (apiStore) => apiStore['displayPlacementsFilterOptions'],
          codename: 'DispPlace',
          controller: 'display_placement',
          crud: (rootStore) => rootStore.displayPlacementsCrud,
          domoNsType: 'DisplayPlacement',
          endpoint: 'display-placements',
          filterOptionsCall: (apiStore) => (val) => apiStore['displayPlacementsFilterOptions'] = val,
          form: (rootStore) => <GenericForm type="Placement" datePicker={2} numButtons={3} extraFields={['size_id']} />,
          genericModelPromiseEndpoints: [
            'audiences',
            'campaign-types',
            'campaign-type-channels',
            'campaign-type-objectives',
            'channel-genders',
            'channel-objectives',
            'channel-tactics',
            'channel-targetings',
            'creative-types',
            'devices',
            'display-types',
            'display-type-channels',
            'genders',
            'geos',
            'languages',
            'marketing-campaigns',
            'objectives',
            'publishers',
            'sizes',
            'statuses',
            'tactics',
            'tactic-targetings',
            'targetings',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.displayPlacementsCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.displayAdsCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
          name: 'Placements',
          nameField: 'name',
          noDataText: (root) => '',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'placements',
          send2domo: true,
          singular: 'placement',
          showFormat: 'definitionList',
          tabDisabled: () => false,
          tabIndex: 0,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 11,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'OLD NSID/ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 6,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 260,
              utaType: 'namestring_id'
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 2,
              grid_type: 'namestring',
              filter: false,
              id: 'namestring',
              filter: false,
              filterField: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'DisplayPlacement',
              rules: 'required',
            },
            {
              default: 1,
              domo_field: 'channel',
              domo_value: (crudStore) => abbrev(crudStore.channel),
              form: 'channel_id',
              id: 'channel',
              label: 'Channel',
              name: 'channel',
              ns: () => 'hmm',
              rules: 'required',
              type: 'hidden',
              utaType: 'channel_id',
            },
            {
              colSize: 10.5,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              grid: true,
              grid_order: 180,
              label: 'Created By',
              grid_value: (row) => property(row.user, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'created_by',
              name: 'created_by',
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.Users
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              colSize: 9,
              domo_field: 'status',
              domo_value: () => 'Inactive',
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 7,
              label: 'Status',
              name: 'status_id',
              options: (apiStore) => apiStore.statuses,
              id: 'status_id',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              form: 'group_id',
              type: 'hidden',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              utaType: 'group',
              rules: 'required',
            },
            {
              name: 'company',
              ns: () => 'Astellas',
              ns_order: 10,
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              ns_order: 20,
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'business_unit',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
              name: 'business_unit',
              rules: 'required'
            },
            {
              domo_field: 'area',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
              name: 'area',
              rules: 'required'
            },
            {
              belongsTo: ['audience', 'audiences'],
              form: 'audience_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="audience"
                fieldName="audience_id"
                form={rootStore.channel.model.crudStore.form}
                label="Audience"
                menuItems={_.compact(rootStore.apiStore.audiences.map((audience) => rootStore.uiStore.makePrimeRegularOption(audience)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('audience_id', event)}
              />),
              formOrder: 30,
              label: 'Audience',
              rules: 'required|integer',

              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'audience',
              domo_value: (crudStore) => property(crudStore.storedData.audience, 'name'),
              grid: true,
              grid_order: 30,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.audiences,
              grid_value: (row) => property(row.audience, 'name'),
              id: 'audience',
              label: 'Audience',
              name: 'audience',
              ns: (crudStore) => abbrev(crudStore.storedData.audience),
              ns_order: 40,
              show: true,
              show_value: (row) => property(row.audience, 'name'),
              show_order: 30,
              rules: 'required',
            },

            {
              belongsTo: ['campaign_type', 'campaignTypes'],
              callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
              edit_callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
              form: 'campaign_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="campaign-type"
                fieldName="campaign_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Type"
                menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType)))}
                onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
              />),
              formOrder: 40,
              label: 'Campaign Type',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 13,
              domo_field: 'campaign_type',
              domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
              grid: true,
              grid_order: 40,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.campaignTypes,
              grid_value: (row) => property(row.campaign_type, 'name'),
              id: 'campaign_type',
              label: 'Campaign Type',
              name: 'campaign_type',
              ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
              ns_order: 30,
              show: true,
              show_value: (row) => property(row.campaign_type, 'name'),
              show_order: 40,
              rules: 'required',
            },
            {
              colSize: 10.5,
              domo_field: 'objective',
              domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
              grid: true,
              grid_order: 50,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.objectives,
              grid_value: (row) => property(row.objective, 'name'),
              id: 'objective',
              label: 'Objective',
              name: 'objective',
              ns: (crudStore) => abbrev(crudStore.storedData.objective),
              ns_order: 50,
              rules: 'required',
            },
            {
              belongsTo: ['objective', 'objectives'],
              form: 'objective_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="objective"
                fieldName="objective_id"
                form={rootStore.channel.model.crudStore.form}
                label="Objective"
                menuItems={_.compact(rootStore.apiStore.objectives.map(objective => rootStore.uiStore.makePrimeObjectiveOption(objective)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
              />),
              formOrder: 50,
              label: 'Objective',
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.objective, 'name'),
              show_order: 50,
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              // domo_field: 'ad_name',
              // domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              form: 'name',
              copyEditable: true,
              // formField: (rootStore) => (
              //   <UccInputText
              //     core="name"
              //     fieldName="name"
              //     form={rootStore.channel.model.crudStore.form}
              //     label="Placement Description"
              //     onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              //   />),
              // formOrder: 60,
              grid: true,
              filter: true,
              grid_order: 60,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Description',
              filter_placeholder: 'Description',
              filter: (dt, col) => inputTextFilter(dt, col),
              name: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 60,
              rules: 'string',
              utaType: 'name',
            },
            {
              domo_field: 'ad_name',
              domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
              grid: true,
              grid_order: 65,
              grid_value: (row) => property(row.marketing_campaign, 'name'),
              id: 'marketing_campaign_id',
              label: 'Campaign Name',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.marketingCampaigns,
              name: 'marketing_campaign_id',
              ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
              // ns_order: 60,
              show: true,
              show_value: (row) => property(row.marketing_campaign, 'name'),
              show_order: 60,
              utaType: 'name',
              utaType: 'fk',
            },
            {
              belongsTo: ['marketing_campaign', 'marketingCampaigns'],
              form: 'marketing_campaign_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="marketing-campaign"
                fieldName="marketing_campaign_id"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Name"
                menuItems={_.compact(rootStore.apiStore.marketingCampaigns.map(marketingCampaign => rootStore.uiStore.makePrimeRegularOption(marketingCampaign)))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
              />),
              formOrder: 60,
              label: "Campaign Name",
              rules: 'integer',
              utaType: 'fk',
            },
            {
              domo_field: 'publisher',
              domo_value: (crudStore) => property(crudStore.storedData.publisher, 'name'),
              grid: true,
              grid_order: 70,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.publishers,
              grid_value: (row) => property(row.publisher, 'name'),
              id: 'publisher',
              label: 'Publisher',
              name: 'publisher',
              ns: (crudStore) => abbrev(crudStore.storedData.publisher),
              ns_order: 70,
              rules: '',
            },
            {
              belongsTo: ['publisher', 'publishers'],
              copyEditable: true,
              form: 'publisher_id',
              formField: (rootStore) => (<UccSelect
                core="publisher"
                fieldName="publisher_id"
                form={rootStore.channel.model.crudStore.form}
                label="Publisher"
                menuItems={_.compact(rootStore.apiStore.publishers.map(publisher => rootStore.uiStore.makePrimeOption(publisher)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('publisher_id', event)}
              />),
              formOrder: 70,
              label: 'Publisher',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.publisher, 'name'),
              show_order: 70,
              utaType: 'fk',
            },
            {
              belongsTo: ['tactic', 'tactics'],
              form: 'tactic_id',
              formField: (rootStore) => (<UccSelect
                core="tactic"
                fieldName="tactic_id"
                form={rootStore.channel.model.crudStore.form}
                label="Tactic"
                menuItems={_.compact(rootStore.apiStore.tactics.map(tactic => rootStore.uiStore.makePrimeChannelSpecificOption(tactic, 'channelTactics')))}
                onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
              />),
              formOrder: 80,
              label: 'Tactic',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 9,
              domo_field: 'tactic',
              domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
              grid: true,
              grid_order: 80,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.tactics,
              grid_value: (row) => property(row.tactic, 'name'),
              id: 'tactic',
              label: 'Tactic',
              name: 'tactic',
              ns: (crudStore) => abbrev(crudStore.storedData.tactic),
              ns_order: 80,
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.tactic, 'name'),
              show_order: 80,
            },
            {
              domo_field: 'suppression',
              domo_value: (crudStore) => crudStore.suppressionLabel(),
              form: 'suppression',
              grid: false,
              id: 'suppression',
              label: 'Suppression',
              name: 'suppression',
              ns: (crudStore) => crudStore.suppressionLabel(),
              ns_order: 90,
              show: true,
              show_value: (row) => row.suppression ? 'On' : 'Off',
              show_order: 82,
              type: 'checkbox',
              utaType: 'boolean',
            },
            {
              domo_field: 'targeting',
              domo_value: (crudStore) => NameString.targetingString(crudStore),
              form: 'display_placement_targetings_attributes',
              formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} />),
              formOrder: 80,
              grid: true,
              grid_order: 80,
              id: 'targeting_ids',
              label: 'Targeting',
              multiple: [],
              name: 'display_placement_targetings_attributes',
              ns: (crudStore) => NameString.targetingString(crudStore),
              ns_order: 95,
              show: true,
              show_value: (row) => NameString.pivotShow(row, "display_placement_targetings"),
              show_order: 87,
              sortable: false,
              utaType: 'targeting_array',
            },
            {
              form: 'display_placement_targetings_attributes[].id',
            },
            {
              form: 'display_placement_targetings_attributes[].display_ad_id',
            },
            {
              form: 'display_placement_targetings_attributes[].targeting_id',
            },
            {
              form: 'display_placement_targetings_attributes[].extra',
            },
            {
              form: 'display_placement_targetings_attributes[]._destroy',
              default: 'false'
            },
            {
              form: 'display_placement_targetings_attributes[].changed',
              default: 'false'
            },
            {
              form: 'display_placement_targetings_attributes[].on',
            },

            {
              belongsTo: ['creative_type', 'creativeTypes'],
              form: 'creative_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="creative-type"
                fieldName="creative_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Creative Type"
                menuItems={_.compact(rootStore.apiStore.creativeTypes.map((creativeType) => rootStore.uiStore.makePrimeOption(creativeType)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('creative_type_id', event)}
              />),
              formOrder: 105,
              label: 'Creative Type',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 11,
              domo_field: 'creative_type',
              domo_value: (crudStore) => property(crudStore.storedData.creative_type, 'name'),
              grid: true,
              grid_order: 105,
              grid_value: (row) => property(row.creative_type, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.creativeTypes,
              id: 'creative_type',
              label: 'Creative Type',
              name: 'creative_type',
              ns: (crudStore) => abbrev(crudStore.storedData.creative_type),
              ns_order: 105,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.creative_type, 'name'),
              show_order: 105,
            },
            {
              belongsTo: ['gender', 'genders'],
              form: 'gender_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="gender"
                fieldName="gender_id"
                form={rootStore.channel.model.crudStore.form}
                label="Gender"
                menuItems={_.compact(rootStore.apiStore.genders.map((gender) => rootStore.uiStore.makePrimeChannelSpecificOption(gender, 'channelGenders')))}
                onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
              />),
              formOrder: 110,
              label: 'Gender',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 9.5,
              domo_field: 'gender',
              domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
              grid: true,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.genders,
              grid_order: 110,
              grid_value: (row) => property(row.gender, 'name'),
              id: 'gender',
              label: 'Gender',
              name: 'gender',
              ns: (crudStore) => abbrev(crudStore.storedData.gender),
              ns_order: 110,
              show: true,
              show_value: (row) => property(row.gender, 'name'),
              show_order: 110,
              rules: 'required|integer',
            },
            {
              belongsTo: ['device', 'devices'],
              form: 'device_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="device"
                fieldName="device_id"
                form={rootStore.channel.model.crudStore.form}
                label="Device"
                menuItems={_.compact(rootStore.apiStore.devices.map(device => rootStore.uiStore.makePrimeOption(device)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('device_id', event)}
              />),
              formOrder: 120,
              label: 'Device',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 9.5,
              domo_field: 'device',
              domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
              grid: true,
              grid_order: 120,
              grid_value: (row) => property(row.device, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.devices,
              id: 'device',
              label: 'Device',
              name: 'device',
              show: true,
              show_value: (row) => property(row.device, 'name'),
              show_order: 120,
              rules: 'integer',
            },
            {
              belongsTo: ['geo', 'geos'],
              form: 'geo_id',
              copyEditable: true,
              formField: (rootStore) => (
                <UccSelect
                  core="geo"
                  fieldName="geo_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Geo"
                  menuItems={_.compact(rootStore.apiStore.geos.map(geo => rootStore.uiStore.makePrimeOption(geo)))}
                  onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
                />
              ),
              formOrder: 130,
              label: 'Geo',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 8.5,
              domo_field: 'geo',
              domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
              grid: true,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.geos,
              grid_order: 130,
              grid_value: (row) => property(row.geo, 'name'),
              id: 'geo',
              label: 'Geo',
              name: 'geo',
              show: true,
              show_value: (row) => property(row.geo, 'name'),
              show_order: 130,
              rules: 'integer',
            },
            {
              belongsTo: ['language', 'languages'],
              form: 'language_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="language"
                fieldName="language_id"
                form={rootStore.channel.model.crudStore.form}
                label="Language"
                menuItems={_.compact(rootStore.apiStore.languages.map(language => rootStore.uiStore.makePrimeRegularOption(language)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('language_id', event)}
              />),
              formOrder: 140,
              label: 'Language',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 11,
              domo_field: 'language',
              domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
              grid: true,
              grid_order: 140,
              grid_value: (row) => property(row.language, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.languages,
              id: 'language',
              label: 'Language',
              name: 'language',
              show: true,
              show_value: (row) => property(row.language, 'name'),
              show_order: 140,
              rules: 'integer',
            },
            {
              belongsTo: ['size', 'sizes'],
              copyEditable: true,
              hideForm: true,
              formField: (rootStore) => (<UccSelect
                core="size"
                fieldName="size_id"
                form={rootStore.channel.model.crudStore.form}
                label="Size"
                menuItems={_.compact(rootStore.apiStore.sizes.map(size => rootStore.uiStore.makePrimeRegularOption(size)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('size_id', event)}
              />),
              form: 'size_id',
              label: 'Size',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 8,
              domo_field: 'size',
              domo_value: (crudStore) => property(crudStore.storedData.size, 'name'),
              grid: true,
              grid_order: 190,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.sizes,
              grid_value: (row) => property(row.size, 'name'),
              id: 'size',
              label: 'Size',
              name: 'size',
              ns: (crudStore) => abbrev(crudStore.storedData.size),
              ns_order: 190,
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.size, 'name'),
              show_order: 190,
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ].concat(
            dateField('start_date', 'Start Date', 'blank', false, 160, 160, 'dns', 200),
            dateField('end_date', 'End Date', 'blank', false, 170, 170, 'dns', 210),
          ),
        },
        // ___   _            _                 _       _
        // |   \ (_) ___ _ __ | | __ _  _  _    /_\   __| | ___
        // | |) || |(_-<| '_ \| |/ _` || || |  / _ \ / _` |(_-<
        // |___/ |_|/__/| .__/|_|\__,_| \_, | /_/ \_\\__,_|/__/
        //              |_|             |__/
        {
          allFilterData: (apiStore) => apiStore['displayAdsFilterOptions'],
          codename: 'DispAd',
          controller: 'display_ad',
          crud: (rootStore) => rootStore.displayAdsCrud,
          domoNsType: 'DisplayAd',
          endpoint: 'display-ads',
          filterOptionsCall: (apiStore) => (val) => apiStore['displayAdsFilterOptions'] = val,
          form: (rootStore) => <GenericForm type="Display Ad" datePicker={2} numButtons={3} extraFields={['size_id', 'veeva_document_number']} />,
          genericModelPromiseEndpoints: [
            'audiences',
            'campaign-types',
            'campaign-type-channels',
            'campaign-type-objectives',
            'channel-genders',
            'channel-objectives',
            'channel-tactics',
            'channel-targetings',
            'creative-types',
            'devices',
            'display-types',
            'display-type-channels',
            'genders',
            'geos',
            'languages',
            'marketing-campaigns',
            'objectives',
            'publishers',
            'sizes',
            'statuses',
            'tactics',
            'tactic-targetings',
            'targetings',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.displayPlacementsCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.displayAdsCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
          name: 'Ads',
          nameField: 'name',
          noDataText: (root) => '',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'ads',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'ad',
          tabDisabled: () => false,
          tabIndex: 1,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 11,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'OLD NSID/ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 4,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },

            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 3,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'DisplayAd',
              rules: 'required',
            },
            {
              default: 1,
              domo_field: 'channel',
              domo_value: (crudStore) => abbrev(crudStore.channel),
              form: 'channel_id',
              id: 'channel',
              label: 'Channel',
              name: 'channel',
              ns: () => 'hmm',
              rules: 'required',
              type: 'hidden',
              utaType: 'channel_id',
            },
            {
              colSize: 10.5,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              name: 'created_by',
              label: 'Created By',
              id: 'created_by',
              grid: true,
              grid_order: 190,
              grid_value: (row) => property(row.user, 'name'),
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              colSize: 9,
              domo_field: 'status',
              domo_value: () => 'Inactive',
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 6,
              label: 'Status',
              name: 'status_id',
              options: (apiStore) => apiStore.statuses,
              id: 'status_id',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              form: 'group_id',
              type: 'hidden',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              utaType: 'group',
              rules: 'required',
            },
            {
              name: 'company',
              ns: () => 'Astellas',
              ns_order: 5,
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              ns_order: 10,
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'business_unit',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
              name: 'business_unit',
              rules: 'required'
            },
            {
              domo_field: 'area',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
              name: 'area',
              rules: 'required'
            },
            {
              belongsTo: ['audience', 'audiences'],
              form: 'audience_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="audience"
                fieldName="audience_id"
                form={rootStore.channel.model.crudStore.form}
                label="Audience"
                menuItems={_.compact(rootStore.apiStore.audiences.map((audience) => rootStore.uiStore.makePrimeRegularOption(audience)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('audience_id', event)}
              />),
              formOrder: 20,
              label: 'Audience',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'audience',
              domo_value: (crudStore) => property(crudStore.storedData.audience, 'name'),
              grid: true,
              grid_order: 20,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.audiences,
              grid_value: (row) => property(row.audience, 'name'),
              id: 'audience',
              label: 'Audience',
              name: 'audience',
              ns: (crudStore) => abbrev(crudStore.storedData.audience),
              ns_order: 20,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.audience, 'name'),
              show_order: 20,
            },
            {
              belongsTo: ['campaign_type', 'campaignTypes'],
              callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
              form: 'campaign_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="campaign-type"
                fieldName="campaign_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Type"
                menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType)))}
                onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
              />),
              formOrder: 30,
              label: 'Campaign Type',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'campaign_type',
              domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
              grid: true,
              grid_order: 30,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.campaignTypes,
              grid_value: (row) => property(row.campaign_type, 'name'),
              id: 'campaign_type',
              label: 'Campaign Type',
              name: 'campaign_type',
              show: true,
              show_value: (row) => property(row.campaign_type, 'name'),
              show_order: 30,
            },
            {
              colSize: 10.5,
              domo_field: 'objective',
              domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
              grid: true,
              grid_order: 40,
              grid_value: (row) => property(row.objective, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.objectives,
              id: 'objective',
              label: 'Objective',
              name: 'objective',
              // ns: (crudStore) => abbrev(crudStore.storedData.objective),
              // ns_order: 40,
            },
            {
              belongsTo: ['objective', 'objectives'],
              form: 'objective_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="objective"
                fieldName="objective_id"
                form={rootStore.channel.model.crudStore.form}
                label="Objective"
                menuItems={_.compact(rootStore.apiStore.objectives.map((objective) => rootStore.uiStore.makePrimeObjectiveOption(objective)))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_id', event)}
              />),
              formOrder: 40,
              label: 'Objective',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.objective, 'name'),
              show_order: 40,
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'ad_name',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              form: 'name',
              copyEditable: true,
              formField: (rootStore) => (
                <UccInputText
                  core="name"
                  fieldName="name"
                  form={rootStore.channel.model.crudStore.form}
                  label="Ad Description"
                  onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
                />),
              formOrder: 50,
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Description',
              filter: (dt, col) => inputTextFilter(dt, col),
              name: 'name',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              ns_order: 50,
              rules: 'required|string',
              show: true,
              show_value: (row) => row.name,
              show_order: 50,
              utaType: 'name',
            },  // no publisher for Astellas.
            {
              belongsTo: ['tactic', 'tactics'],
              form: 'tactic_id',
              formField: (rootStore) => (<UccSelect
                core="tactic"
                fieldName="tactic_id"
                form={rootStore.channel.model.crudStore.form}
                label="Tactic"
                menuItems={_.compact(rootStore.apiStore.tactics.map((tactic) => rootStore.uiStore.makePrimeChannelSpecificOption(tactic, 'channelTactics')))}
                onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
              />),
              formOrder: 60,
              label: 'Tactic',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 9,
              domo_field: 'tactic',
              domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
              grid: true,
              grid_order: 60,
              grid_value: (row) => property(row.tactic, 'name'),
              id: 'tactic',
              label: 'Tactic',
              name: 'tactic',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.tactics,
              show: true,
              show_value: (row) => property(row.tactic, 'name'),
              show_order: 60,
              rules: 'integer',
            },
            {
              domo_field: 'suppression',
              domo_value: (crudStore) => crudStore.suppressionLabel(),
              form: 'suppression',
              grid: false,
              id: 'suppression',
              label: 'Suppression',
              name: 'suppression',
              type: 'checkbox',
              show: true,
              show_value: (row) => row.suppression ? 'On' : 'Off',
              show_order: 65,
              utaType: 'boolean',
            },
            {
              domo_field: 'targeting',
              domo_value: (crudStore) => NameString.targetingString(crudStore),
              form: 'display_ad_targetings_attributes',
              formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} />),
              formOrder: 80,
              grid: true,
              grid_order: 80,
              id: 'targeting_ids',
              label: 'Targeting',
              multiple: [],
              name: 'display_ad_targetings_attributes',
              ns: (crudStore) => NameString.targetingString(crudStore),
              // ns_order: 80,
              show: true,
              show_value: (row) => NameString.pivotShow(row, "display_ad_targetings"),
              show_order: 80,
              sortable: false,
              utaType: 'targeting_array',
            },
            {
              form: 'display_ad_targetings_attributes[].id',
            },
            {
              form: 'display_ad_targetings_attributes[].display_ad_id',
            },
            {
              form: 'display_ad_targetings_attributes[].targeting_id',
            },
            {
              form: 'display_ad_targetings_attributes[].extra',
            },
            {
              form: 'display_ad_targetings_attributes[]._destroy',
              default: 'false'
            },
            {
              form: 'display_ad_targetings_attributes[].changed',
              default: 'false'
            },
            {
              form: 'display_ad_targetings_attributes[].on',
            },  //no geo or language for astellas
            {
              belongsTo: ['gender', 'genders'],
              form: 'gender_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="gender"
                fieldName="gender_id"
                form={rootStore.channel.model.crudStore.form}
                label="Gender"
                menuItems={_.compact(rootStore.apiStore.genders.map((gender) => rootStore.uiStore.makePrimeChannelSpecificOption(gender, 'channelGenders')))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('gender_id', event)}
              />),
              formOrder: 90,
              label: 'Gender',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 9.5,
              domo_field: 'gender',
              domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
              grid: true,
              grid_order: 90,
              grid_value: (row) => property(row.gender, 'name'),
              id: 'gender',
              label: 'Gender',
              name: 'gender',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.genders,
              ns: (crudStore) => abbrev(crudStore.storedData.gender),
              ns_order: 90,
              show: true,
              show_value: (row) => property(row.gender, 'name'),
              show_order: 90,
              rules: 'required|integer',
            },
            {
              belongsTo: ['device', 'devices'],
              form: 'device_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="device"
                fieldName="device_id"
                form={rootStore.channel.model.crudStore.form}
                label="Device"
                menuItems={_.compact(rootStore.apiStore.devices.map((device) => rootStore.uiStore.makePrimeOption(device)))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('device_id', event)}
              />),
              formOrder: 100,
              label: 'Device',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 9.5,
              domo_field: 'device',
              domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
              grid: true,
              grid_order: 100,
              grid_value: (row) => property(row.device, 'name'),
              id: 'device',
              label: 'Device',
              name: 'device',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.devices,
              show: true,
              show_value: (row) => property(row.device, 'name'),
              show_order: 100,
              rules: 'integer',
            },
            {
              belongsTo: ['creative_type', 'creativeTypes'],
              form: 'creative_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="creative-type"
                fieldName="creative_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Creative Type"
                menuItems={_.compact(rootStore.apiStore.creativeTypes.map((creativeType) => rootStore.uiStore.makePrimeOption(creativeType)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('creative_type_id', event)}
              />),
              formOrder: 110,
              label: 'Creative Type',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 12,
              domo_field: 'creative_type',
              domo_value: (crudStore) => property(crudStore.storedData.creative_type, 'name'),
              grid: true,
              grid_order: 110,
              grid_value: (row) => property(row.creative_type, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.creativeTypes,
              id: 'creative_type',
              label: 'Creative Type',
              name: 'creative_type',
              show: true,
              show_value: (row) => property(row.creative_type, 'name'),
              show_order: 110,
              rules: 'integer',
            },

            {
              belongsTo: ['size', 'sizes'],
              form: 'size_id',
              copyEditable: true,
              hideForm: true,
              formField: (rootStore) => (<UccSelect
                core="size"
                fieldName="size_id"
                form={rootStore.channel.model.crudStore.form}
                label="Size"
                menuItems={_.compact(rootStore.apiStore.sizes.map(size => rootStore.uiStore.makePrimeRegularOption(size)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('size_id', event)}
              />),
              //form still in jsx
              label: 'Size',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 8,
              domo_field: 'size',
              domo_value: (crudStore) => property(crudStore.storedData.size, 'name'),
              grid: true,
              grid_order: 150,
              grid_value: (row) => property(row.size, 'name'),
              id: 'size',
              label: 'Size',
              name: 'size',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.sizes,
              ns: (crudStore) => abbrev(crudStore.storedData.size),
              ns_order: 150,
              show: true,
              show_value: (row) => property(row.size, 'name'),
              show_order: 110,
              rules: 'required|integer',
            },
            {
              colSize: 15,
              domo_field: 'veeva_document_number',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.veeva_document_number),
              form: 'veeva_document_number',
              copyEditable: true,
              hideForm: true,
              formField: (rootStore) => (<UccInputText
                core="veeva_document_number"
                fieldName="veeva_document_number"
                form={rootStore.channel.model.crudStore.form}
                label="Veeva Document Number"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('veeva_document_number', event)}
              />),
              formOrder: 150,
              grid: true,
              grid_order: 150,
              grid_value: (row) => row.veeva_document_number,
              id: 'veeva_document_number',
              name: 'veeva_document_number',
              ns: (crudStore) => crudStore.storedData.veeva_document_number,
              label: 'Veeva Document Number',
              utaType: 'string',
              rules: 'required|string',
              filter: (dt, col) => inputTextFilter(dt, col),
              show: true,
              show_value: (row) => row.veeva_document_number,
              show_order: 140,
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ].concat(
            dateField('start_date', 'Start Date', 'blank', true, 130, 130, 'dns'),
            dateField('end_date', 'End Date', 'blank', false, 140, 140, 'dns'),
          ),
        },

        //    _    _    _    _    _    _    _    _
        //   / \  / \  / \  / \  / \  / \  / \  / \
        //  ( C )( r )( e )( a )( t )( i )( v )( e )
        //   \_/  \_/  \_/  \_/  \_/  \_/  \_/  \_/

        {
          allFilterData: (apiStore) => apiStore['displayCreativesFilterOptions'],
          codename: 'DispCreat',
          controller: 'display_creative',
          crud: (rootStore) => rootStore.displayCreativesCrud,
          domoNsType: 'DisplayCreative',
          endpoint: 'display-creatives',
          filterOptionsCall: (apiStore) => (val) => apiStore['displayCreativeFilterOptions'] = val,
          form: (rootStore) => <GenericForm type="Creative" datePicker={2} numButtons={3} extraFields={['size_id']} />,
          genericModelPromiseEndpoints: [
            'audiences',
            'campaign-types',
            'campaign-type-channels',
            'campaign-type-objectives',
            'channel-genders',
            'channel-objectives',
            'channel-tactics',
            'channel-targetings',
            'creative-types',
            'devices',
            'genders',
            'geos',
            'languages',
            'marketing-campaigns',
            'message-types',
            'objectives',
            'publishers',
            'sizes',
            'statuses',
            'tactics',
            'tactic-targetings',
            'targetings',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.displayCreativesCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
          name: 'Creatives',
          nameField: 'name',
          noDataText: (root) => '',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'creatives',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'creative',
          tabDisabled: () => false,
          tabIndex: 2,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,

          columns: [
            {
              colSize: 11,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'OLD NSID/ID',
              name: 'id',
              ns: (row) => row.id,
              rules: 'integer|required',
              permListAndView: 80,
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 5,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 2,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'DisplayCreative',
              rules: 'required',
            },
            {
              default: 1,
              domo_field: 'channel',
              domo_value: (crudStore) => abbrev(crudStore.channel),
              form: 'channel_id',
              id: 'channel',
              label: 'Channel',
              name: 'channel',
              ns: () => 'hmm',
              rules: 'required',
              type: 'hidden',
              utaType: 'channel_id',
            },
            {
              colSize: 11.5,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              name: 'created_by',
              label: 'Created By',
              options: (apiStore) => apiStore.Users,
              id: 'created_by',
              grid: true,
              grid_order: 190,
              grid_value: (row) => property(row.user, 'name'),
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              colSize: 9,
              domo_field: 'status',
              domo_value: () => 'Inactive',
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 8,
              label: 'Status',
              name: 'status_id',
              options: (apiStore) => apiStore.statuses,
              id: 'status_id',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              form: 'group_id',
              type: 'hidden',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              utaType: 'group',
              rules: 'required',
            },
            {
              name: 'company',
              ns: () => 'Astellas',
              ns_order: 10,
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              ns_order: 20,
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'business_unit',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
              name: 'business_unit',
              rules: 'required'
            },
            {
              domo_field: 'area',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
              name: 'area',
              rules: 'required'
            },
            {
              belongsTo: ['audience', 'audiences'],
              form: 'audience_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="audience"
                fieldName="audience_id"

                form={rootStore.channel.model.crudStore.form}
                label="Audience"
                menuItems={_.compact(rootStore.apiStore.audiences.map((audience) => rootStore.uiStore.makePrimeRegularOption(audience)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('audience_id', event)}
              />),
              formOrder: 30,
              label: 'Audience',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'audience',
              domo_value: (crudStore) => property(crudStore.storedData.audience, 'name'),
              grid: true,
              grid_order: 30,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.audiences,
              grid_value: (row) => property(row.audience, 'name'),
              id: 'audience',
              label: 'Audience',
              name: 'audience',
              ns: (crudStore) => abbrev(crudStore.storedData.audience),
              ns_order: 30,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.audience, 'name'),
              show_order: 30,
            },
            {
              belongsTo: ['campaign_type', 'campaignTypes'],
              form: 'campaign_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="campaign-type"
                fieldName="campaign_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Type"
                menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType)))}
                onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
              />),
              formOrder: 40,
              label: 'Campaign Type',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 13,
              domo_field: 'campaign_type',
              domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
              grid: true,
              grid_order: 40,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.campaignTypes,
              grid_value: (row) => property(row.campaign_type, 'name'),
              id: 'campaign_type',
              label: 'Campaign Type',
              name: 'campaign_type',

            },
            {
              colSize: 10.5,
              domo_field: 'objective',
              domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
              grid: true,
              grid_order: 50,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.objectives,
              grid_value: (row) => property(row.objective, 'name'),
              id: 'objective',
              label: 'Objective',
              name: 'objective',
              show: true,
              show_value: (row) => property(row.objective, 'name'),
              show_order: 50,
              rules: 'integer',
            },
            {
              belongsTo: ['objective', 'objectives'],
              form: 'objective_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="objective"
                fieldName="objective_id"
                form={rootStore.channel.model.crudStore.form}
                label="Objective"
                menuItems={_.compact(rootStore.apiStore.objectives.map(objective => rootStore.uiStore.makePrimeObjectiveOption(objective)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
              />),
              formOrder: 50,
              label: 'Objective',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              grid: true,
              grid_order: 60,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Description',
              filter: (dt, col) => inputTextFilter(dt, col),
              name: 'name',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              // ns_order: 60,
              rules: 'required|string',
              show: true,
              show_value: (row) => row.name,
              show_order: 60,
              utaType: 'name',
            },
            {
              domo_field: 'ad_name',
              domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
              grid: true,
              grid_order: 60,
              grid_value: (row) => property(row.marketing_campaign, 'name'),
              id: 'marketing_campaign_id',
              label: 'Campaign Name',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.marketingCampaigns,
              name: 'marketing_campaign_id',
              ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
              ns_order: 60,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.marketing_campaign, 'name'),
              show_order: 60,
              utaType: 'fk',
            },
            {
              belongsTo: ['marketing_campaign', 'marketingCampaigns'],
              form: 'marketing_campaign_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="marketing-campaign"
                fieldName="marketing_campaign_id"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Name"
                menuItems={_.compact(rootStore.apiStore.marketingCampaigns.map(marketingCampaign => rootStore.uiStore.makePrimeRegularOption(marketingCampaign)))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
              />),
              formOrder: 60,
              label: 'Campaign Name',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              belongsTo: ['message_type', 'messageTypes'],
              form: 'message_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="message-type"
                fieldName="message_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Primary Topic"
                menuItems={_.compact(rootStore.apiStore.messageTypes.map((message_type) => rootStore.uiStore.makePrimeRegularOption(message_type)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('message_type_id', event)}
              />),
              formOrder: 65,
              label: 'Primary Topic',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 11,
              domo_field: 'message_type',
              domo_value: (crudStore) => property(crudStore.storedData.message_type, 'name'),
              grid: true,
              grid_order: 65,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.messageTypes,
              grid_value: (row) => property(row.message_type, 'name'),
              id: 'message_type',
              label: 'Primary Topic',
              name: 'message_type',
              ns: (crudStore) => abbrev(crudStore.storedData.message_type),
              show: true,
              show_value: (row) => property(row.message_type, 'name'),
              show_order: 65,
              ns_order: 65,
            },
            {
              belongsTo: ['tactic', 'tactics'],
              form: 'tactic_id',
              formField: (rootStore) => (
                <UccSelect
                  core="tactic"
                  fieldName="tactic_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Tactic"
                  menuItems={_.compact(rootStore.apiStore.tactics.map(tactic => rootStore.uiStore.makePrimeChannelSpecificOption(tactic, 'channelTactics')))}
                  onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
                />),
              formOrder: 70,
              label: 'Tactic',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 9,
              domo_field: 'tactic',
              domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
              grid: true,
              grid_order: 70,
              grid_value: (row) => property(row.tactic, 'name'),
              id: 'tactic',
              label: 'Tactic',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.tactics,
              name: 'tactic',
              show: true,
              show_value: (row) => property(row.tactic, 'name'),
              show_order: 70,
              rules: 'integer',
            },
            {
              domo_field: 'suppression',
              domo_value: (crudStore) => crudStore.suppressionLabel(),
              form: 'suppression',
              grid: false,
              id: 'suppression',
              label: 'Suppression',
              name: 'suppression',
              type: 'checkbox',
              show: true,
              show_value: (row) => row.suppression ? 'On' : 'Off',
              show_order: 75,
              utaType: 'boolean',
            },
            {
              domo_field: 'targeting',
              domo_value: (crudStore) => NameString.targetingString(crudStore),
              form: 'display_creative_targetings_attributes',
              formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} />),
              formOrder: 80,
              grid: true,
              grid_order: 80,
              id: 'targeting_ids',
              label: 'Targeting',
              multiple: [],
              name: 'display_creative_targetings_attributes',
              // ns: (crudStore) => NameString.targetingString(crudStore),
              // ns_order: 80,
              show: true,
              show_value: (row) => NameString.pivotShow(row, "display_creative_targetings"),
              show_order: 80,
              sortable: false,
              utaType: 'targeting_array',
            },
            {
              form: 'display_creative_targetings_attributes[].id',
            },
            {
              form: 'display_creative_targetings_attributes[].display_ad_id',
            },
            {
              form: 'display_creative_targetings_attributes[].targeting_id',
            },
            {
              form: 'display_creative_targetings_attributes[].extra',
            },
            {
              form: 'display_creative_targetings_attributes[]._destroy',
              default: 'false'
            },
            {
              form: 'display_creative_targetings_attributes[].changed',
              default: 'false'
            },
            {
              form: 'display_creative_targetings_attributes[].on',
            },

            {
              belongsTo: ['gender', 'genders'],
              form: 'gender_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="gender"
                fieldName="gender_id"
                form={rootStore.channel.model.crudStore.form}
                label="Gender"
                menuItems={_.compact(rootStore.apiStore.genders.map((gender) => rootStore.uiStore.makePrimeChannelSpecificOption(gender, 'channelGenders')))}
                onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
              />),
              formOrder: 90,
              label: 'Gender',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 9.5,
              domo_field: 'gender',
              domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
              grid: true,
              grid_order: 90,
              grid_value: (row) => property(row.gender, 'name'),
              id: 'gender',
              label: 'Gender',
              name: 'gender',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.genders,
              ns: (crudStore) => abbrev(crudStore.storedData.gender),
              ns_order: 90,
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.gender, 'name'),
              show_order: 90,
            },

            {
              belongsTo: ['device', 'devices'],
              form: 'device_id',
              formOrder: 100,
              label: 'Device',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 9.5,
              domo_field: 'device',
              domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
              grid: true,
              grid_order: 100,
              grid_value: (row) => property(row.device, 'name'),
              id: 'device',
              label: 'Device',
              name: 'device',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.devices,
              // ns: (crudStore) => abbrev(crudStore.storedData.device),
              // ns_order: 80,
              rules: 'integer',
              sortable: true,
              show: true,
              show_value: (row) => property(row.device, 'name'),
              show_order: 100,
            },


            {
              belongsTo: ['creative_type', 'creativeTypes'],
              form: 'creative_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="creative-type"
                fieldName="creative_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Creative Type"
                menuItems={_.compact(rootStore.apiStore.creativeTypes.map((creativeType) => rootStore.uiStore.makePrimeOption(creativeType)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('creative_type_id', event)}
              />),
              formOrder: 110,
              label: 'Creative Type',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 12,
              domo_field: 'creative_type',
              domo_value: (crudStore) => property(crudStore.storedData.creative_type, 'name'),
              grid: true,
              grid_order: 110,
              grid_value: (row) => property(row.creative_type, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.creativeTypes,
              id: 'creative_type',
              label: 'Creative Type',
              name: 'creative_type',
              // ns: (crudStore) => abbrev(crudStore.storedData.creative_type),
              // ns_order: 40,
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.creative_type, 'name'),
              show_order: 110,
            },
            /// version

            {
              domo_field: 'version',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.version),
              form: 'version',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="version"
                fieldName="version"
                form={rootStore.channel.model.crudStore.form}
                label="Version"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('version', event)}
              />
              ),
              formOrder: 140,
              grid: true,
              grid_order: 140,
              grid_value: (row) => row.version,
              id: 'version',
              label: 'Version',
              filter: (dt, col) => inputTextFilter(dt, col),
              name: 'version',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.version),
              ns_order: 140,
              rules: 'string',
              show: true,
              show_value: (row) => row.version,
              show_order: 140,
              utaType: 'text',
            },
            {
              colSize: 15,
              domo_field: 'veeva_document_number',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.veeva_document_number),
              form: 'veeva_document_number',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="veeva_document_number"
                fieldName="veeva_document_number"
                form={rootStore.channel.model.crudStore.form}
                label="Veeva Document Number"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('veeva_document_number', event)}
              />),
              formOrder: 150,
              grid: true,
              grid_order: 150,
              grid_value: (row) => row.veeva_document_number,
              id: 'veeva_document_number',
              name: 'veeva_document_number',
              ns: (crudStore) => crudStore.storedData.veeva_document_number,
              label: 'Veeva Document Number',
              utaType: 'string',
              rules: 'required|string',
              filter: (dt, col) => inputTextFilter(dt, col),
              show: true,
              show_value: (row) => row.veeva_document_number,
              show_order: 140,
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ].concat(
            dateField('start_date', 'Start Date', 'blank', true, 120, 120, 'S.', 200),
            dateField('end_date', 'End Date', 'blank', true, 130, 130, 'E.', 210),
          ),
        },

        // ,---.          |
        // |---',---.,---.|__/ ,---.,---.,---.,---.
        // |    ,---||    |  \ ,---||   ||---'`---.
        // `    `---^`---'`   ``---^`---|`---'`---'
        //                          `---'
        {
          allFilterData: (apiStore) => apiStore['displayPackagesFilterOptions'],
          codename: 'DispPack',
          controller: 'display_package',
          crud: (rootStore) => rootStore.displayPackagesCrud,
          domoNsType: 'DisplayPackage',
          endpoint: 'display-packages',
          filterOptionsCall: (apiStore) => (val) => apiStore['displayPackagesFilterOptions'] = val,
          form: () => <GenericForm type="Package" datePicker={2} numButtons={3} />,
          genericModelPromiseEndpoints: [
            'audiences',
            'campaign-types',
            'campaign-type-channels',
            'creative-types',
            'publishers',
            'statuses',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.displayPackagesCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
          name: 'Packages',
          nameField: 'name',
          noDataText: (root) => '',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'packages',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'package',
          tabDisabled: () => false,
          tabIndex: 3,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,

          columns: [
            {
              colSize: 11,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'OLD NSID/ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 5,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 2,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'DisplayPackage',
              rules: 'required',
            },
            {
              default: 1,
              domo_field: 'channel',
              domo_value: (crudStore) => abbrev(crudStore.channel),
              form: 'channel_id',
              id: 'channel',
              label: 'Channel',
              name: 'channel',
              ns: () => 'hmm',
              rules: 'required',
              type: 'hidden',
              utaType: 'channel_id',
            },
            {
              colSize: 11,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              name: 'created_by',
              label: 'Created By',
              options: (apiStore) => apiStore.Users,
              id: 'created_by',
              grid: true,
              grid_order: 190,
              grid_value: (row) => property(row.user, 'name'),
              ns: () => 'user',
              rules: 'required',
              utaType: 'user',
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              colSize: 9,
              domo_field: 'status',
              domo_value: () => 'Inactive',
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 6,
              label: 'Status',
              name: 'status_id',
              options: (apiStore) => apiStore.statuses,
              id: 'status_id',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              form: 'group_id',
              type: 'hidden',
              rules: 'required',
              utaType: 'group',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              rules: 'required',
              utaType: 'group',
            },
            {
              name: 'company',
              ns: () => 'Astellas',
              ns_order: 10,
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              ns_order: 20,
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'business_unit',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
              name: 'business_unit',
              rules: 'required'
            },
            {
              domo_field: 'area',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
              name: 'area',
              rules: 'required'
            },
            {
              belongsTo: ['audience', 'audiences'],
              form: 'audience_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="audience"
                fieldName="audience_id"

                form={rootStore.channel.model.crudStore.form}
                label="Audience"
                menuItems={_.compact(rootStore.apiStore.audiences.map((audience) => rootStore.uiStore.makePrimeRegularOption(audience)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('audience_id', event)}
              />),
              formOrder: 30,
              label: 'Audience',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'audience',
              domo_value: (crudStore) => property(crudStore.storedData.audience, 'name'),
              grid: true,
              grid_order: 30,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.audiences,
              grid_value: (row) => property(row.audience, 'name'),
              id: 'audience',
              label: 'Audience',
              name: 'audience',
              ns: (crudStore) => abbrev(crudStore.storedData.audience),
              ns_order: 40,
              show: true,
              show_value: (row) => property(row.audience, 'name'),
              show_order: 30,
              rules: 'required',
            },
            {
              belongsTo: ['campaign_type', 'campaignTypes'],
              form: 'campaign_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="campaign-type"
                fieldName="campaign_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Type"
                menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType)))}
                onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
              />),
              formOrder: 40,
              label: 'Campaign Type',
              rules: 'integer|required',
              utaType: 'fk',
            },
            {
              colSize: 13,
              domo_field: 'campaign_type',
              domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
              grid: true,
              grid_order: 40,
              grid_value: (row) => property(row.campaign_type, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.campaignTypes,
              id: 'campaign_type',
              label: 'Campaign Type',
              name: 'campaign_type',
              ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
              ns_order: 30,
              show: true,
              show_value: (row) => property(row.campaign_type, 'name'),
              show_order: 40,
            },
            {
              colSize: 10.5,
              domo_field: 'publisher',
              domo_value: (crudStore) => property(crudStore.storedData.publisher, 'name'),
              grid: true,
              grid_order: 50,
              grid_value: (row) => property(row.publisher, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.publishers,
              id: 'publisher',
              label: 'Publisher',
              name: 'publisher',
              ns: (crudStore) => abbrev(crudStore.storedData.publisher),
              ns_order: 50,
            },
            {
              belongsTo: ['publisher', 'publishers'],
              form: 'publisher_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="publisher"
                fieldName="publisher_id"
                form={rootStore.channel.model.crudStore.form}
                label="Publisher"
                menuItems={_.compact(rootStore.apiStore.publishers.map(publisher => rootStore.uiStore.makePrimeOption(publisher)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('publisher_id', event)}
              />),
              formOrder: 50,
              label: 'Publisher',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.publisher, 'name'),
              show_order: 50,
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'ad_name',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              form: 'name',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Package Description"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 70,
              grid: true,
              grid_order: 70,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Description',
              filter: (dt, col) => inputTextFilter(dt, col),
              name: 'name',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              ns_order: 70,
              rules: 'required|string',
              show: true,
              show_value: (row) => row.name,
              show_order: 70,
              utaType: 'name',
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },

          ].concat(
            dateField('start_date', 'Start Date', 'blank', false, 80, 80, 'dns'),
            dateField('end_date', 'End Date', 'blank', false, 80, 80, 'dns'),
          ),
          // concat(() => )
        },
      ],
    },

    // ___________              .__.__
    // \_   _____/ _____ _____  |__|  |
    //  |    __)_ /     \\__  \ |  |  |
    //  |        \  Y Y  \/ __ \|  |  |__
    // /_______  /__|_|  (____  /__|____/
    //         \/      \/     \/
    {
      name: 'Email',
      endpoint: 'email',
      codename: 'email',
      abbrev: 'Email',
      icon: 'Email',
      disabled: false,
      showViewIcon: true,
      models: [
        {
          allFilterData: (apiStore) => apiStore['emailCampaignsFilterOptions'],
          codename: 'EmailCamp',
          controller: 'email_campaign',
          crud: (rootStore) => rootStore.emailCampaignsCrud,
          domoNsType: 'EmailCampaign',
          endpoint: 'email-campaigns',
          filterOptionsCall: (apiStore) => (val) => apiStore['emailCampaignsFilterOptions'] = val,
          form: () => <GenericForm type="Email" datePicker={1} numButtons={3} />,
          genericModelPromiseEndpoints: [
            'ab-types',
            'campaign-types',
            'ad-types',
            'email-service-platforms',
            'geos',
            'campaign-type-objectives',
            'channel-objectives',
            'channel-platforms',
            'channel-tactics',
            'channel-targetings',
            'tactic-targetings',
            'objectives',
            'tactics',
            'targetings',
            'campaign-type-channels',
            'channel-genders',
            'genders',
            'ad-type-channels',
            'users',
            'audiences',
            'statuses',
            'test-types',
            'test-groups',
            'segments',
            'message-types',
            'marketing-campaigns',
            'waves',
            'send-types',
            'test-group-test-types',
            'email-types',
          ],
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
            apiStore.emailCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
          name: 'Email',
          nameField: 'name',
          noDataText: (root) => '',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'campaigns',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'Email',
          tabDisabled: () => false,
          tabIndex: 0,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 11,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'OLD NSID/ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 4,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 2,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'EmailCampaign',
              rules: 'required',
            },

            {
              domo_field: 'channel',
              domo_value: (crudStore) => abbrev(crudStore.storedData.channel),
              ns: () => 'hmm',
              name: 'channel',
              id: 'channel',
              utaType: 'channel',
              rules: 'required',
            },
            {
              colSize: 10.5,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              name: 'created_by',
              label: 'Created By',
              id: 'created_by',
              options: (apiStore) => apiStore.Users,
              grid: true,
              grid_order: 190,
              grid_value: (row) => property(row.user, 'name'),
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              colSize: 9,
              domo_field: 'status',
              domo_value: () => 'Inactive',
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 7,
              label: 'Status',
              name: 'status_id',
              options: (apiStore) => apiStore.statuses,
              id: 'status_id',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              form: 'group_id',
              type: 'hidden',
              utaType: 'group',
              rules: 'required',
            },

            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              utaType: 'group',
              rules: 'required',
            },
            {
              name: 'company',
              ns: () => 'Astellas',
              //ns_order: 10,
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              ns_order: 10,
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'business_unit',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
              name: 'business_unit',
              rules: 'required'
            },
            {
              domo_field: 'area',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
              name: 'area',
              rules: 'required'
            },
            {
              colSize: 10.5,
              domo_field: 'additional_field',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              form: 'name',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Description"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_value: (row) => row.name,
              id: 'name',
              name: 'name',
              label: 'Description',
              filter_placeholder: 'Description',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'string',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
              utaType: 'name',
            },
            {
              domo_field: 'campaign_name',
              domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
              grid: true,
              grid_order: 15,
              grid_value: (row) => property(row.marketing_campaign, 'name'),
              id: 'marketing_campaign_id',
              label: 'Campaign Name',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.marketingCampaigns,
              name: 'marketing_campaign_id',
              ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
              ns_order: 60,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.marketing_campaign, 'name'),
              show_order: 15,
              utaType: 'name',
              utaType: 'fk',
            },
            {
              belongsTo: ['marketing_campaign', 'marketingCampaigns'],
              form: 'marketing_campaign_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="marketing-campaign"
                fieldName="marketing_campaign_id"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Name"
                menuItems={_.compact(rootStore.apiStore.marketingCampaigns.map(marketingCampaign => rootStore.uiStore.makePrimeRegularOption(marketingCampaign)))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
              />),
              formOrder: 2,
              label: 'Campaign Name',
              rules: 'required|integer',
              utaType: 'fk',
            },

            {
              colSize: 10.5,
              domo_field: 'audience',
              domo_value: (crudStore) => property(crudStore.storedData.audience, 'name'),
              grid: true,
              grid_order: 25,
              grid_value: (row) => property(row.audience, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.audiences,
              id: 'audience',
              label: 'Audience',
              name: 'audience',
              ns: (crudStore) => abbrev(crudStore.storedData.audience),
            },
            {
              belongsTo: ['audience', 'audiences'],
              form: 'audience_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="audience"
                fieldName="audience_id"
                form={rootStore.channel.model.crudStore.form}
                label="Audience"
                menuItems={_.compact(rootStore.apiStore.audiences.map((audience) => rootStore.uiStore.makePrimeRegularOption(audience)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('audience_id', event)}
              />),
              formOrder: 25,
              label: 'Audience',
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.audience, 'name'),
              show_order: 25,
              utaType: 'fk',
            },

            {
              belongsTo: ['segment', 'segments'],
              form: 'segment_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="segment"
                fieldName="segment_id"
                form={rootStore.channel.model.crudStore.form}
                label="Segment"
                menuItems={_.sortBy(_.compact(rootStore.apiStore.segments.map((segment) => rootStore.uiStore.makePrimeRegularOption(segment))), ['label'])}
                onChange={event => rootStore.channel.model.crudStore.storeData('segment_id', event)}
              />),
              formOrder: 27.5,
              label: 'Segment',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'segment',
              domo_value: (crudStore) => property(crudStore.storedData.segment, 'name'),
              grid: true,
              grid_order: 27.5,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.segments,
              grid_value: (row) => property(row.segment, 'name'),
              id: 'segment',
              label: 'Segment',
              name: 'segment',
              ns: (crudStore) => abbrev(crudStore.storedData.segment),
              show: true,
              show_value: (row) => property(row.segment, 'name'),
              show_order: 27.5,
            },
            {
              belongsTo: ['email_service_platform', 'emailServicePlatforms'],
              form: 'email_service_platform_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="email-service-platform"
                fieldName="email_service_platform_id"
                form={rootStore.channel.model.crudStore.form}
                label="Source"
                menuItems={rootStore.apiStore.emailServicePlatforms.map(platform => rootStore.uiStore.makePrimeOption(platform))}
                onChange={event => rootStore.channel.model.crudStore.storeData('email_service_platform_id', event)}
              />),
              formOrder: 30,
              label: 'Source',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10,
              domo_field: 'platform',
              domo_value: (crudStore) => property(crudStore.storedData.email_service_platform, 'name'),
              grid: true,
              grid_order: 30,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.emailServicePlatforms,
              grid_value: (row) => property(row.email_service_platform, 'name'),
              id: 'email_service_platform',
              label: 'Source',
              name: 'email_service_platform',
              ns: (crudStore) => abbrev(crudStore.storedData.email_service_platform),
              show: true,
              show_value: (row) => property(row.email_service_platform, 'name'),
              show_order: 30,
            },
            {
              belongsTo: ['send_type', 'sendTypes'],
              form: 'send_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="send-type"
                fieldName="send_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Send Type"
                menuItems={rootStore.apiStore.sendTypes.map(sendType => rootStore.uiStore.makePrimeOption(sendType))}
                onChange={event => rootStore.channel.model.crudStore.storeData('send_type_id', event)}
              />),
              formOrder: 35,
              label: 'Send Type',
              rules: 'integer|required',
              utaType: 'fk',
            },
            {
              colSize: 10,
              domo_field: 'send_type',
              domo_value: (crudStore) => property(crudStore.storedData.send_type, 'name'),
              grid: true,
              grid_order: 35,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.sendTypes,
              grid_value: (row) => property(row.send_type, 'name'),
              id: 'send_type',
              label: 'Send Type',
              name: 'send_type',
              ns: (crudStore) =>
                abbrev(crudStore.storedData.send_type),
              ns_order: 70
            },
            {
              belongsTo: ['campaign_type', 'campaignTypes'],
              form: 'campaign_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="campaign-type"
                fieldName="campaign_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Type"
                menuItems={rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType))}
                onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)} />),
              formOrder: 40,
              label: 'Campaign Type',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 13,
              domo_field: 'campaign_type',
              domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
              grid: true,
              grid_order: 40,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.campaignTypes,
              grid_value: (row) => property(row.campaign_type, 'name'),
              id: 'campaign_type',
              label: 'Campaign Type',
              name: 'campaign_type',
              ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
              show: true,
              show_value: (row) => property(row.campaign_type, 'name'),
              show_order: 40,
              // ns_order: 30,
            },
            {
              colSize: 10.5,
              domo_field: 'objective',
              domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
              grid: true,
              grid_order: 50,
              grid_value: (row) => property(row.objective, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.objectives,
              id: 'objective',
              label: 'Objective',
              name: 'objective',
              ns: (crudStore) => abbrev(crudStore.storedData.objective),
            },
            {
              belongsTo: ['objective', 'objectives'],
              form: 'objective_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="objective"
                fieldName="objective_id"
                form={rootStore.channel.model.crudStore.form}
                label="Objective"
                menuItems={rootStore.uiStore.makeObjectiveOptions()}
                onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
              />),
              formOrder: 50,
              label: 'Objective',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.campaign_type, 'name'),
              show_order: 50,
              utaType: 'fk',
            },
            {
              belongsTo: ['message_type', 'messageTypes'],
              form: 'message_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="message-type"
                fieldName="message_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Primary Topic"
                menuItems={_.compact(rootStore.apiStore.messageTypes.map((message_type) => rootStore.uiStore.makePrimeRegularOption(message_type)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('message_type_id', event)}
              />),
              formOrder: 55,
              label: 'Primary Topic',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 11,
              domo_field: 'message_type',
              domo_value: (crudStore) => property(crudStore.storedData.message_type, 'name'),
              grid: true,
              grid_order: 55,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.messageTypes,
              grid_value: (row) => property(row.message_type, 'name'),
              id: 'message_type',
              label: 'Primary Topic',
              name: 'message_type',
              ns: (crudStore) => abbrev(crudStore.storedData.message_type_id),
              show: true,
              show_value: (row) => property(row.message_type, 'name'),
              show_order: 55,
              // ns_order: 15,
            },
            {
              belongsTo: ['tactic', 'tactics'],
              form: 'tactic_id',
              formField: (rootStore) => (
                <UccSelect
                  core="tactic"
                  fieldName="tactic_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Tactic"
                  menuItems={_.compact(rootStore.apiStore.tactics.map((tactic) => rootStore.uiStore.makePrimeChannelSpecificOption(tactic, 'channelTactics')))}
                  onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
                />),
              formOrder: 60,
              label: 'Tactic',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 9.5,
              domo_field: 'tactic',
              domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
              grid: true,
              grid_order: 60,
              grid_value: (row) => property(row.tactic, 'name'),
              id: 'tactic',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.tactics,
              label: 'Tactic',
              name: 'tactic',
              ns: (crudStore) => abbrev(crudStore.storedData.tactic),
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.tactic, 'name'),
              show_order: 60,
            },
            {
              domo_field: 'suppression',
              domo_value: (crudStore) => crudStore.suppressionLabel(),
              form: 'suppression',
              grid: false,
              id: 'suppression',
              label: 'Suppression',
              name: 'suppression',
              ns: (crudStore) => crudStore.suppressionLabel(),
              type: 'checkbox',
              utaType: 'boolean',
              show: true,
              show_value: (row) => row.suppression ? 'On' : 'Off',
              show_order: 65,
            },
            {
              domo_field: 'targeting',
              domo_value: (crudStore) => NameString.targetingString(crudStore),
              form: 'email_campaign_targetings_attributes',
              formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} />),
              formOrder: 80,
              grid: true,
              grid_order: 80,
              id: 'targeting_ids',
              label: 'Targeting',
              multiple: [],
              name: 'email_campaign_targetings_attributes',
              // ns: (crudStore) => NameString.targetingString(crudStore),
              // ns_order: 80,
              show: true,
              show_value: (row) => NameString.pivotShow(row, "email_campaign_targetings"),
              show_order: 80,
              sortable: false,
              utaType: 'targeting_array',
            },
            {
              form: 'email_campaign_targetings_attributes[].id',
            },
            {
              form: 'email_campaign_targetings_attributes[].display_ad_id',
            },
            {
              form: 'email_campaign_targetings_attributes[].targeting_id',
            },
            {
              form: 'email_campaign_targetings_attributes[].extra',
            },
            {
              form: 'email_campaign_targetings_attributes[]._destroy',
              default: 'false'
            },
            {
              form: 'email_campaign_targetings_attributes[].changed',
              default: 'false'
            },
            {
              form: 'email_campaign_targetings_attributes[].on',
            },

            {
              belongsTo: ['geo', 'geos'],
              form: 'geo_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="geo"
                fieldName="geo_id"

                form={rootStore.channel.model.crudStore.form}
                label="Geo"
                menuItems={rootStore.apiStore.geos.map(geo => rootStore.uiStore.makePrimeOption(geo))}
                onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
              />),
              formOrder: 80,
              label: 'Geo',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 9.5,
              domo_field: 'geo',
              domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
              grid: true,
              grid_order: 80,
              grid_value: (row) => property(row.geo, 'name'),
              id: 'geo',
              label: 'Geo',
              name: 'geo',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.geos,
              ns: (crudStore) => abbrev(crudStore.storedData.geo),
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.geo, 'name'),
              show_order: 80,
            },
            {
              belongsTo: ['email_type', 'emailTypes'],
              form: 'email_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="email-type"
                fieldName="email_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Email Type"
                menuItems={rootStore.apiStore.emailTypes.map(emailType => rootStore.uiStore.makePrimeOption(emailType))}
                onChange={event => rootStore.channel.model.crudStore.storeData('email_type_id', event)}
              />),
              formOrder: 120,
              label: 'Email Type',
              rules: 'integer|required',
              utaType: 'fk',
            },
            {
              colSize: 10,
              domo_field: 'email_type',
              domo_value: (crudStore) => property(crudStore.storedData.email_type, 'name'),
              grid: true,
              grid_order: 120,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.emailTypes,
              grid_value: (row) => property(row.email_type, 'name'),
              id: 'email_type',
              label: 'Email Type',
              name: 'email_type',
              ns: (crudStore) => abbrev(crudStore.storedData.email_type),
            },
            {
              belongsTo: ['test_group', 'testGroups'],
              form: 'test_group_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="test-group"
                fieldName="test_group_id"
                form={rootStore.channel.model.crudStore.form}
                label="Test Group"
                menuItems={_.compact(rootStore.apiStore.testGroups.map(testGroup => rootStore.uiStore.makePrimeTestGroupOption(testGroup)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('test_group_id', event)}
              />),
              formOrder: 140,
              label: 'Test Group',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 9.5,
              domo_field: 'test_group',
              domo_value: (crudStore) => { return property(crudStore.storedData.test_group, 'name'); },
              grid: true,
              grid_order: 140,
              grid_value: (row) => property(row.test_group, 'name'),
              id: 'test_group',
              label: 'Test Group',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.testGroups,
              name: 'test_group',
              ns: (crudStore) => abbrev(crudStore.storedData.test_group),
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.test_group, 'name'),
              show_order: 140,
            },
            {
              belongsTo: ['test_type', 'testTypes'],
              callback: (crudStore, thing, selected) => crudStore.clearTestGroup(crudStore, thing, selected),
              edit_callback: (crudStore, thing, selected) => crudStore.clearTestGroup(crudStore, thing, selected),
              form: 'test_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="test-type"
                fieldName="test_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Test Type"
                menuItems={_.compact(rootStore.apiStore.testTypes.map((testType) => rootStore.uiStore.makePrimeRegularOption(testType)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('test_type_id', event)}
              />),
              formOrder: 130,
              label: 'Test Type',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 9.5,
              domo_field: 'test_type',
              domo_value: (crudStore) => { return property(crudStore.storedData.test_type, 'name'); },
              grid: true,
              grid_order: 130,
              grid_value: (row) => property(row.test_type, 'name'),
              id: 'test_type',
              label: 'Test Type',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.testTypes,
              name: 'test_type',
              ns: (crudStore) => abbrev(crudStore.storedData.test_type),
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.test_type, 'name'),
              show_order: 140,
            },
            {
              belongsTo: ['wave', 'waves'],
              form: 'wave_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="wave"
                fieldName="wave_id"
                form={rootStore.channel.model.crudStore.form}
                label="Wave"
                menuItems={_.compact(rootStore.apiStore.waves.map(wave => rootStore.uiStore.makePrimeOption(wave)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('wave_id', event)}
              />),
              formOrder: 140,
              label: 'Wave',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 6,
              domo_field: 'wave',
              domo_value: (crudStore) => { return property(crudStore.storedData.wave, 'name'); },
              grid: true,
              grid_order: 140,
              grid_value: (row) => property(row.wave, 'name'),
              id: 'wave',
              label: 'Wave',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.waves,
              name: 'wave',
              ns: (crudStore) => abbrev(crudStore.storedData.wave),
              ns_order: 80,
              rules: 'integer',
            },
            {
              colSize: 15,
              domo_field: 'veeva_document_number',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.veeva_document_number),
              form: 'veeva_document_number',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="veeva_document_number"
                fieldName="veeva_document_number"
                form={rootStore.channel.model.crudStore.form}
                label="Veeva Document Number"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('veeva_document_number', event)}
              />),
              formOrder: 150,
              grid: true,
              grid_order: 150,
              grid_value: (row) => row.veeva_document_number,
              id: 'veeva_document_number',
              name: 'veeva_document_number',
              ns: (crudStore) => crudStore.storedData.veeva_document_number,
              label: 'Veeva Document Number',
              utaType: 'string',
              rules: 'required|string',
              filter: (dt, col) => inputTextFilter(dt, col),
              show: true,
              show_value: (row) => row.veeva_document_number,
              show_order: 140,
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ].concat(
            dateField('send_date', 'Scheduled Date', 'blank', true, 10, 20, '', 10, 12, 'YYYYMMDD'),
          ),
        },
      ],
    },


    //                               ___                                                         ___       
    //                     .-.      (   )                                                       (   )      
    //    .-..     .---.  ( __)   .-.| |        .--.      .--.     .---.   ___ .-.      .--.     | | .-.   
    //   /    \   / .-, \ (''")  /   \ |      /  _  \    /    \   / .-, \ (   )   \    /    \    | |/   \  
    //  ' .-,  ; (__) ; |  | |  |  .-. |     . .' `. ;  |  .-. ; (__) ; |  | ' .-. ;  |  .-. ;   |  .-. .  
    //  | |  . |   .'`  |  | |  | |  | |     | '   | |  |  | | |   .'`  |  |  / (___) |  |(___)  | |  | |  
    //  | |  | |  / .'| |  | |  | |  | |     _\_`.(___) |  |/  |  / .'| |  | |        |  |       | |  | |  
    //  | |  | | | /  | |  | |  | |  | |    (   ). '.   |  ' _.' | /  | |  | |        |  | ___   | |  | |  
    //  | |  ' | ; |  ; |  | |  | '  | |     | |  `\ |  |  .'.-. ; |  ; |  | |        |  '(   )  | |  | |  
    //  | `-'  ' ' `-'  |  | |  ' `-'  /     ; '._,' '  '  `-' / ' `-'  |  | |        '  `-' |   | |  | |  
    //  | \__.'  `.__.'_. (___)  `.__,'       '.___.'    `.__.'  `.__.'_. (___)        `.__,'   (___)(___) 
    //  | |                                                                                                
    // (___)                                                                                               


    {
      name: 'Paid Search',
      endpoint: 'paid-search',
      codename: 'paidSearch',
      abbrev: 'PaidSearch',
      icon: 'PaidSearch',
      disabled: false,
      invisible: false,
      showViewIcon: true,
      models: [
        {
          allFilterData: (apiStore) => apiStore['paidSearchCampaignsFilterOptions'],
          codename: 'PaidSearchCamp',
          controller: 'paid_search_campaign',
          crud: (rootStore) => rootStore.paidSearchCampaignsCrud,
          domoNsType: 'PaidSearchCampaign',
          dontDisplayDate: true,
          endpoint: 'paid-search-campaigns',
          filterOptionsCall: (apiStore) => (val) => apiStore['paidSearchCampaignsFilterOptions'] = val,
          form: () => <GenericForm type="Campaign" numButtons={3} />,
          genericModelPromiseEndpoints: [
            'areas',
            'audiences',
            'business-units',
            'campaign-types',
            'campaign-type-channels',
            'campaign-type-objectives',
            'channel-objectives',
            'channel-platforms',
            'devices',
            'extra-field-objective-modifiers',
            'geos',
            'goals',
            'languages',
            'objectives',
            'objective-modifiers',
            'objective-modifier-objectives',
            'platforms',
            'secondary-tactics',
            'segments',
            'statuses',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.paidSearchCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.extraFieldsCrud.getAll({ model_id: '9' }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
          name: 'Paid Search Campaigns',
          name: 'Campaigns',
          nameField: 'name',
          noDataText: (root) => '',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'campaigns',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'campaign',
          tabData: (apiStore) => apiStore.genericTabPromiseResults(),
          tabPromiseEndpoints: (rootStore, brand_param) => {
            const allFilter = _.merge(
              brand_param,
              rootStore.paidSearch.PaidSearchAdGroup.modelFilter(rootStore),
            );
            return [
              rootStore.paidSearchCampaignsCrud.getTotal(brand_param),
              rootStore.paidSearchAdGroupsCrud.getTotal(allFilter, ''),
            ];
          },
          tabDisabled: () => false,
          tabIndex: 0,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 8,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'OLD NSID/ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 4,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              colSize: 9,
              domo_field: 'status',
              domo_value: () => 'Inactive',
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 7,
              label: 'Status',
              name: 'status_id',
              options: (apiStore) => apiStore.statuses,
              id: 'status_id',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 7,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 2,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'PaidSearchCampaign',
              rules: 'required',
            },

            {
              domo_field: 'channel',
              domo_value: () => 'PaidSearch',
              ns: () => 'PaidSearch',
              name: 'channel',
              id: 'channel',
              utaType: 'channel',
              rules: 'required',
            },
            {
              form: 'group_id',
              type: 'hidden',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              utaType: 'group',
              rules: 'required',
            },
            {
              name: 'company',
              ns: () => 'Astellas',
              ns_order: 5,
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              ns_order: 10,
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'business_unit',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
              name: 'business_unit',
              rules: 'required'
            },
            {
              domo_field: 'area',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
              name: 'area',
              rules: 'required'
            },
            {
              belongsTo: ['audience', 'audiences'],
              form: 'audience_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="audience"
                fieldName="audience_id"
                form={rootStore.channel.model.crudStore.form}

                label="Audience"
                menuItems={_.compact(rootStore.apiStore.audiences.map((audience) => rootStore.uiStore.makePrimeRegularOption(audience)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('audience_id', event)}
              />),
              formOrder: 15,
              label: 'Audience',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              ns: (crudStore) => 'Search',
              ns_order: 43,
            },
            {
              colSize: 10.5,
              domo_field: 'audience',
              domo_value: (crudStore) => property(crudStore.storedData.audience, 'name'),
              grid: true,
              grid_order: 15,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.audiences,
              grid_value: (row) => property(row.audience, 'name'),
              id: 'audience',
              label: 'Audience',
              name: 'audience',
              ns: (crudStore) => plusSigns([abbrev(crudStore.storedData.audience), abbrev(crudStore.storedData.segment)]),
              ns_order: 47,
              show: true,
              show_value: (row) => property(row.audience, 'name'),
              show_order: 15,
            },
            {
              belongsTo: ['segment', 'segments'],
              form: 'segment_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="segment"
                fieldName="segment_id"
                form={rootStore.channel.model.crudStore.form}
                label="Segment"
                menuItems={_.sortBy(_.compact(rootStore.apiStore.segments.map((segment) => rootStore.uiStore.makePrimeRegularOption(segment))), ['label'])}
                onChange={event => rootStore.channel.model.crudStore.storeData('segment_id', event)}
              />),
              formOrder: 17,
              label: 'Segment',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'segment',
              domo_value: (crudStore) => property(crudStore.storedData.segment, 'name'),
              grid: true,
              grid_order: 15,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.segments,
              grid_value: (row) => property(row.segment, 'name'),
              id: 'segment',
              label: 'Segment',
              name: 'segment',
              ns: (crudStore) => abbrev(crudStore.storedData.segment),
              show: true,
              show_value: (row) => property(row.segment, 'name'),
              show_order: 17,
              // ns_order: 15,
            },
            {
              belongsTo: ['campaign_type', 'campaignTypes'],
              form: 'campaign_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="campaign-type"
                fieldName="campaign_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Type"
                menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('campaign_type_id', event)}
              />),
              formOrder: 20,
              name: 'campaign_type_id',
              label: 'Campaign Type',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 11,
              domo_field: 'campaign_type',
              domo_value: (crudStore) => abbrev(crudStore.storedData.campaign_type),
              filter: (dt, col) => multiSelectFilter(dt, col),
              filter_placeholder: 'Type',
              grid: true,
              grid_order: 20,
              grid_value: (row) => property(row.campaign_type, 'name'),
              id: 'campaign_type',
              label: 'Campaign Type',
              name: 'campaign_type',
              ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
              ns_order: 60,
              options: (apiStore) => apiStore.campaignTypes,
              show: true,
              show_value: (row) => property(row.campaign_type, 'name'),
              show_order: 20,
            },
            {
              belongsTo: ['platform', 'platforms'],
              form: 'platform_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="platform"
                fieldName="platform_id"
                label="Platform"
                form={rootStore.channel.model.crudStore.form}
                menuItems={_.compact(rootStore.apiStore.platforms.map((platform) => rootStore.uiStore.makePrimeChannelSpecificOption(platform, 'channelPlatforms')))}
                filter={false}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
              />),
              formOrder: 40,
              label: 'Platform',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 10,
              domo_field: 'platform',
              domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
              grid: true,
              grid_order: 40,
              grid_value: (row) => property(row.platform, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.platforms,
              id: 'platform',
              label: 'Platform',
              name: 'platform',
              ns: (crudStore) => abbrev(crudStore.storedData.platform),
              show: true,
              show_value: (row) => property(row.platform, 'name'),
              show_order: 40,
              ns_order: 40,
            },
            {
              belongsTo: ['objective', 'objectives'],
              callback: (crudStore, thing, selected) => crudStore.clearObjectiveModifier(crudStore, thing, selected),
              form: 'objective_id',

              formField: (rootStore) => (<UccSelect
                core="objective"
                fieldName="objective_id"
                form={rootStore.channel.model.crudStore.form}
                label="Tactic"
                menuItems={_.compact(rootStore.apiStore.objectives.map(objective => rootStore.uiStore.makePrimeObjectiveOption(objective)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
              />),
              formOrder: 50,
              label: 'Tactic',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 9,
              domo_field: 'objective',
              domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
              grid: true,
              grid_order: 50,
              grid_value: (row) => property(row.objective, 'name'),
              id: 'objective',
              label: 'Tactic',
              filter: (dt, col) => multiSelectFilter(dt, col),
              name: 'objective',
              ns: (crudStore) => plusSigns([abbrev(crudStore.storedData.objective), abbrev(crudStore.storedData.secondary_tactic)]),
              ns_order: 50,
              rules: 'required',
              options: (apiStore) => apiStore.objectives,
              show: true,
              show_value: (row) => property(row.objective, 'name'),
              show_order: 20,
            },
            {
              belongsTo: ['secondary_tactic', 'secondaryTactics'],
              callback: (crudStore, thing, selected) => crudStore.clearObjectiveModifier(crudStore, thing, selected),
              form: 'secondary_tactic_id',
              formField: (rootStore) => (<UccSelect
                core="secondary-tactic"
                fieldName="secondary_tactic_id"
                form={rootStore.channel.model.crudStore.form}
                label="Secondary Tactic"
                menuItems={_.compact(rootStore.apiStore.secondaryTactics.map(secondary_tactic => rootStore.uiStore.makePrimeRegularOption(secondary_tactic)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('secondary_tactic_id', event)}
              />),
              formOrder: 50,
              label: 'Secondary Tactic',
              rules: 'integer',
              utaType: 'fk',
            },

            {
              colSize: 13.5,
              domo_field: 'secondary_tactic',
              domo_value: (crudStore) => property(crudStore.storedData.secondary_tactic, 'name'),
              grid: true,
              grid_order: 50,
              grid_value: (row) => property(row.secondary_tactic, 'name'),
              id: 'secondary_tactic',
              label: 'Secondary Tactic',
              filter: (dt, col) => multiSelectFilter(dt, col),
              name: 'secondary_tactic',
              ns: (crudStore) => abbrev(crudStore.storedData.secondary_tactic),
              // ns_order: 50,
              options: (apiStore) => apiStore.secondaryTactics,
              show: true,
              show_value: (row) => property(row.secondary_tactic, 'name'),
              show_order: 50,
            },
            {
              colSize: 9.5,
              contrivance: (crudStore) => objectiveModifierNs('campaign', crudStore.storedData),
              domo_field: 'objective_modifier',
              domo_value: (crudStore) => objectiveModifierNs('campaign', crudStore.storedData),
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'obj_mod_string',
              grid: true,
              grid_order: 60,
              grid_value: (row) => row.obj_mod_string,
              id: 'obj_mod_string',
              label: 'Category',
              name: 'objective_modifier',
              ns: (crudStore) => objectiveModifierNs('campaign', crudStore.storedData),
              // ns_order: 60,
              options: (apiStore) => apiStore.objectiveModifiers,
              placeholder: 'Objective Modifier',
              show: true,
              show_value: (row) => row.obj_mod_string,
              show_order: 60,
              type: 'hidden',
            },
            // {
            //   form: 'category',
            //   formField: (rootStore) => (<UccInputText
            //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'category')}
            //     core="category"
            //     fieldName="category"
            //     form={rootStore.channel.model.crudStore.form}
            //     label="Category"
            //     onInput={(event) => rootStore.channel.model.crudStore.storeData('category', event)}
            //   // className={classes.modifierField}
            //   />),
            //   formOrder: 64,
            //   filter: (dt, col) => inputTextFilter(dt, col),
            //   grid_value: (row) => row.category,
            //   id: 'category',
            //   label: 'Category',
            //   name: 'category',
            //   ns: (crudStore) => crudStore.storedData.category,
            //   rules: 'string',
            //   show: true,
            //   show_value: (row) => row.category,
            //   show_order: 64,
            //   utaType: 'text',
            // },

            {
              colSize: 10,
              copyEditable: true,
              domo_field: 'funnel_segment',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.funnel_segment),
              form: 'funnel_segment',
              formField: (rootStore) => (<UccInputText
                label="Funnel Segment"
                core="funnel_segment"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('funnel_segment', event)}
                fieldName="funnel_segment"
              />),
              formOrder: 80,
              grid: true,
              grid_order: 80,
              grid_value: (row) => row.funnel_segment,
              grid_label: 'Funnel Segment',
              filter_placeholder: 'Funnel Segment',
              id: 'funnel_segment',
              name: 'funnel_segment',
              filter: (dt, col) => inputTextFilter(dt, col),
              label: 'Funnel Segment',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.funnel_segment),
              ns_order: 55,
              utaType: 'string',
              rules: 'string',
              show: true,
              show_value: (row) => row.funnel_segment,
              show_order: 80,
            },

            {
              belongsTo: ['geo', 'geos'],
              form: 'geo_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="geo"
                fieldName="geo_id"
                form={rootStore.channel.model.crudStore.form}
                label="Geo"
                // menuItems={apiStore.geos.map((p) => ({ label: p.name, value: p.id }))}
                menuItems={rootStore.apiStore.geos.map((geo) => rootStore.uiStore.makePrimeRegularOption(geo))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('geo_id', event)}
              />),
              formOrder: 90,
              label: 'Geos',
              rules: 'integer',

              utaType: 'fk',
            },
            {
              colSize: 8.5,
              domo_field: 'geo',
              domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
              grid: true,
              grid_order: 90,
              grid_value: (row) => property(row.geo, 'name'),
              id: 'geo',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.geos,
              label: 'Geo',
              name: 'geo',
              ns: (crudStore) => abbrev(crudStore.storedData.geo),
              rules: 'integer',
              sortable: true,
              show: true,
              show_value: (row) => property(row.geo, 'name'),
              show_order: 90,
              utaType: 'geos'
            },
            {
              belongsTo: ['language', 'languages'],
              form: 'language_id',
              copyEditable: true,
              formField: (rootStore) => (
                <UccSelect
                  core="language"
                  fieldName="language_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Language"
                  menuItems={rootStore.apiStore.languages.map((language) => rootStore.uiStore.makePrimeRegularOption(language))}
                  onChange={(event) => rootStore.channel.model.crudStore.storeData('language_id', event)}
                />),
              formOrder: 100,
              label: 'Language',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'language',
              domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
              grid: true,
              grid_order: 100,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.languages,
              grid_value: (row) => property(row.language, 'name'),
              id: 'language',
              label: 'Language',
              name: 'language',
              ns: (crudStore) => abbrev(crudStore.storedData.language),
              show: true,
              show_value: (row) => property(row.language, 'name'),
              show_order: 100,
              utaType: 'languages'
            },
            {
              colSize: 11,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              grid: true,
              grid_order: 150,
              grid_value: (row) => property(row.user, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'created_by',
              label: 'Created By',
              name: 'created_by',
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.Users,
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ],
        },

        //   _____       _      _____                                 
        //  (_____)     (_)    (_____)  _                        ____ 
        // (_)___(_)  __(_)   (_)  ___ (_)__  ___   _   _  ____ (____)
        // (_______) (____)   (_) (___)(____)(___) (_) (_)(____)(_)__ 
        // (_)   (_)(_)_(_)   (_)___(_)(_)  (_)_(_)(_)_(_)(_)_(_)_(__)
        // (_)   (_) (____)    (_____) (_)   (___)  (___) (____)(____)
        //                                                (_)         
        //                                                (_)         

        {
          allFilterData: (apiStore) => apiStore['paidSearchAdGroupsFilterOptions'],
          codename: 'PaidSearchAdGroup',
          controller: 'paid_search_ad_group',
          crud: (rootStore) => rootStore.paidSearchAdGroupsCrud,
          dontDisplayDate: true,
          disableBulkCreate: true,
          disableBulkEdit: true,
          disableCopyEdit: true,
          domoNsType: 'PaidSearchAdGroup',
          endpoint: 'paid-search-ad-groups',
          filterOptionsCall: (apiStore) => (val) => apiStore['paidSearchAdGroupsFilterOptions'] = val,
          form: () => <GenericForm type="Ad Group" numButtons={3} />,
          genericModelPromiseEndpoints: [
            'ad-modifiers',
            'ad-modifier-objective-modifiers',
            'campaign-types',
            'campaign-type-channels',
            'campaign-type-objectives',
            'channel-objectives',
            'channel-platforms',
            'devices',
            'extra-field-objective-modifiers',
            'geos',
            'goals',
            'languages',
            'objectives',
            'objective-modifiers',
            'objective-modifier-objectives',
            'paid-search-ad-groups',
            'paid-search-campaigns',
            'platforms',
            'statuses',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.paidSearchCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.paidSearchAdGroupsCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.extraFieldsCrud.getAll({ model_id: '10' }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, paid_search_campaign_id: root.paidSearch.PaidSearchCamp.crudStore.selectedRowIds, }; },
          name: 'Ad Groups',
          nameField: 'name',
          noDataText: (root) => '',
          parentModel: 'paid_search_campaign',
          parentModelCode: 'PaidSearchCamp',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'ad-groups',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'ad group',
          tabData: (apiStore) => apiStore.genericTabPromiseResults(),
          tabPromiseEndpoints: (rootStore, brand_param) => {
            const allFilter = _.merge(
              brand_param,
              rootStore.paidSearch.PaidSearchAdGroup.modelFilter(rootStore),
            );
            return [
              rootStore.paidSearchCampaignsCrud.getTotal(brand_param),
              rootStore.paidSearchAdGroupsCrud.getTotal(allFilter, ''),
            ];
          },
          tabDisabled: (r) => r.apiStore.adGroupTabDisabled ? true : false,
          tabIndex: 1,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 8,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'OLD NSID/ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 4,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              name: 'company',
              ns: () => 'Astellas',
              ns_order: 10,
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              colSize: 9,
              domo_field: 'status',
              domo_value: () => 'Inactive',
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 3,
              label: 'Status',
              name: 'status_id',
              options: (apiStore) => apiStore.statuses,
              id: 'status_id',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 1,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'PaidSearchAdGroup',
              rules: 'required',
            },

            {
              domo_field: 'channel',
              domo_value: () => 'PaidSearch',
              // ns: () => 'PaidSearch',
              name: 'channel',
              id: 'channel',
              utaType: 'channel',
              rules: 'required',
            },
            {
              colSize: 11,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              grid: true,
              grid_order: 150,
              grid_value: (row) => property(row.user, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'created_by',
              label: 'Created By',
              name: 'created_by',
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.Users,
            },

            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.rootStore.apiStore.currentGroup),
              name: 'group',
              utaType: 'group',
              rules: 'required',
            },
            {
              name: 'company',
              ns: () => 'Astellas',
              // ns_order: 5,
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => abbrev(crudStore.rootStore.apiStore.currentBrand),
              name: 'brand',
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.rootStore.apiStore.currentBrand, 'id'),
              name: 'brand_id',
              ns: () => 'brand_id',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'business_unit',
              domo_value: (crudStore) => property(property(crudStore.rootStore.apiStore.currentBrand, 'business_unit'), 'name'),
              name: 'business_unit',
              rules: ''
            },
            {
              domo_field: 'area',
              domo_value: (crudStore) => property(property(crudStore.rootStore.apiStore.currentBrand, 'area'), 'name'),
              name: 'area',
              rules: ''
            },

            {
              belongsTo: ['paid_search_campaign', 'paidSearchCampaigns'],
              form: 'paid_search_campaign_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="paid_search_campaign"
                fieldName="paid_search_campaign_id"

                label="Paid Search Campaign"
                form={rootStore.channel.model.crudStore.form}
                menuItems={rootStore.channel.model.crudStore.makePrimePaidSearchCampaignOptions(rootStore.apiStore.paidSearchCampaigns)}
                // menuItems={uiStore.makePrimeRegularOption(apiStore.paidSearchCampaigns)}
                filter={false}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('paid_search_campaign_id', event)}
              />),
              formOrder: 20,
              label: 'Campaign',
              rules: 'required|integer',
              utaType: 'fk',
              subType: 'inherit',

            },
            {
              name: 'paid_search_campaign',
              ns: (crudStore) => 'PARENT'
            },
            {
              colSize: 12,
              grid: true,
              grid_order: 4,
              grid_value: (row) => property(row.paid_search_campaign, 'namestring_string'),
              filter: (dt, col) => inputTextFilter(dt, col),
              id: 'paid_search_campaigns.namestring_string',
              label: 'Campaign Namestring',
              name: 'campaign_name',
              ns: () => 'PARENT',
              utaType: 'integer',
              show: true,
              show_value: (row) => property(row.paid_search_campaign, 'namestring_string'),
              show_order: 20,
            },
            {
              domo_field: 'audience',
              domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'audience'),
              id: 'audience',
              name: 'audience',
              utaType: 'parent',
            },
            {
              domo_field: 'campaign_type',
              domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'campaign_type'),
              id: 'campaign_type',
              name: 'campaign_type',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'segment',
              domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'segment'),
              id: 'segment',
              name: 'segment',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'secondary_tactic',
              domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'secondary_tactic'),
              id: 'secondary_tactic',
              name: 'secondary_tactic',
              utaType: 'parent',
              rules: ''
            },
            {
              // this is tactic?
              domo_field: 'objective',
              domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'objective'),
              id: 'objective',
              name: 'objective',
              utaType: 'parent',
              rules: 'required'
            },
            {
              domo_field: 'geo',
              domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'geo'),
              id: 'geo',
              name: 'geo',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'language',
              domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'language'),
              id: 'language',
              name: 'language',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'platform',
              domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'platform'),
              id: 'platform',
              name: 'platform',
              utaType: 'parent',
              rules: ''
            },
            {
              belongsTo: ['objective_modifier', 'objectiveModifiers'],
              form: 'objective_modifier_id',
              formField: (rootStore) => (<UccSelect
                core="objective-modifier"
                fieldName="objective_modifier_id"
                label="Keyword Category Group"
                form={rootStore.channel.model.crudStore.form}
                menuItems={_.sortBy(_.compact(rootStore.apiStore.objectiveModifiers.map((objective_modifier) => rootStore.uiStore.makePrimeObjectiveModifierOption(objective_modifier, rootStore.instance.name))), ['label'])}
                filter={false}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_modifier_id', event)}
              />),
              formOrder: 50,
              label: 'Keyword Category Group',
              rules: 'required|integer',
              utaType: 'fk',
            },

            {
              colSize: 12,
              // contrivance: (crudStore) => abbrev(crudStore.storedData.objective_modifier),
              domo_field: 'objective_modifier',
              domo_value: (crudStore) => abbrev(crudStore.storedData.objective_modifier),
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'obj_mod_string',
              grid: true,
              grid_order: 60,
              grid_value: (row) => property(row.objective_modifier, 'name'),
              id: 'objective_modifier_id',
              label: 'Keyword Category Group',
              name: 'objective_modifier',
              ns: (crudStore) => abbrev(crudStore.storedData.objective_modifier),
              ns_order: 60,
              options: (apiStore) => apiStore.objectiveModifiers,
              placeholder: 'Keyword Category Group',
              show: true,
              show_value: (row) => property(row.objective_modifier, 'name'),
              show_order: 60,
              type: 'hidden',
            },
            // {
            //   domo_field: 'objective_modifier',
            //   domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'objective_modifier'), 'name'),
            //   filter: (dt, col) => multiSelectFilter(dt, col),
            //   grid: true,
            //   grid_order: 20,
            //   grid_value: (row) => property(row.paid_search_campaign.objective_modifier, 'name'),
            //   id: 'objective_modifier',
            //   label: 'Category',
            //   name: 'objective_modifier',
            //   ns: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'objective_modifier'),
            //   ns_order: 20,
            //   options: (apiStore) => apiStore.objectiveModifiers,
            //   // sortField: 'objective_modifiers.name',
            //   utaType: 'parent'
            // },

            {
              belongsTo: ['ad_modifier', 'adModifiers'],
              form: 'ad_modifier_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                // note this code can be used to not display this field in certain cases. better test it if u use it.
                // display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'ad_modifier_id')}
                core="ad-modifier"
                // display={rootStore.apiStore.displayChildExtraField(rootStore.channel.model.crudStore, 'paid_search_campaign_id', 'ad_modifier_id')}
                fieldName="ad_modifier_id"

                label="Matchtype"
                form={rootStore.channel.model.crudStore.form}
                // menuItems={_.compact(rootStore.apiStore.adModifiers.map(ad_modifier => rootStore.uiStore.makePrimeRegularOption(ad_modifier)))}
                // note: the client may decide to only show options based on campaign objective_modifiers (looks). this line enables that.
                menuItems={_.sortBy(_.compact(rootStore.apiStore.adModifiers.map(ad_modifier => rootStore.uiStore.makePrimeAdModifierOption(ad_modifier))), ['label'])}
                filter={false}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('ad_modifier_id', event)}
              />),
              formOrder: 60,
              label: 'Matchtype',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 11,
              domo_field: 'ad_modifier',
              domo_value: (crudStore) => property(crudStore.storedData.ad_modifier, 'name'),
              grid: true,
              grid_order: 140,
              grid_value: (row) => property(row.ad_modifier, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.adModifiers,
              id: 'ad_modifier',
              label: 'Matchtype',
              name: 'ad_modifier',
              ns: (crudStore) => abbrev(crudStore.storedData.ad_modifier),
              ns_order: 140,
              show: true,
              show_value: (row) => property(row.ad_modifier, 'name'),
              show_order: 140,
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ],
        },

        //    ('-.     _ .-') _             .-') _    ('-.     _   .-')       ('-.    .-')    
        //    ( OO ).-.( (  OO) )           ( OO ) )  ( OO ).-.( '.( OO )_   _(  OO)  ( OO ).  
        //   / . --. / \     .'_       ,--./ ,--,'   / . --. / ,--.   ,--.)(,------.(_)---\_) 
        //   | \-.  \  ,`'--..._)      |   \ |  |\   | \-.  \  |   `.'   |  |  .---'/    _ |  
        // .-'-'  |  | |  |  \  '      |    \|  | ).-'-'  |  | |         |  |  |    \  :` `.  
        //  \| |_.'  | |  |   ' |      |  .     |/  \| |_.'  | |  |'.'|  | (|  '--.  '..`''.) 
        //   |  .-.  | |  |   / :      |  |\    |    |  .-.  | |  |   |  |  |  .--' .-._)   \ 
        //   |  | |  | |  '--'  /      |  | \   |    |  | |  | |  |   |  |  |  `---.\       / 
        //   `--' `--' `-------'       `--'  `--'    `--' `--' `--'   `--'  `------' `-----'  
        {
          allFilterData: (apiStore) => apiStore['paidSearchAdNamesFilterOptions'],
          codename: 'PaidSearchAdName',
          controller: 'paid_search_ad_name',
          crud: (rootStore) => rootStore.paidSearchAdNamesCrud,
          domoNsType: 'PaidSearchAdName',
          endpoint: 'paid-search-ad-names',
          filterOptionsCall: (apiStore) => (val) => apiStore['paidSearchAdNamesFilterOptions'] = val,
          form: (rootStore) => <GenericForm type="Ad Name" datePicker={2} numButtons={3} extraFields={['version']} />,
          genericModelPromiseEndpoints: [
            'marketing-campaigns',
            'paid-search-ad-groups',
            'paid-search-ad-names',
            'paid-search-campaigns',
            'statuses',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.paidSearchAdNamesCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          grandParentModel: 'paid_search_campaign',
          modelFilter: (root) => _.merge({ paid_search_ad_group_id: root.paidSearch.PaidSearchAdGroup.crudStore.selectedRowIds, }, { paid_search_campaign_id: root.paidSearch.PaidSearchCamp.crudStore.selectedRowIds, }, { brand_id: root.apiStore.currentBrandId }),
          name: 'Ad Names',
          nameField: 'name',
          noDataText: (root) => '',
          parentModel: 'paid_search_ad_group',
          parentModelCode: 'PaidSearchAdGroup',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'ad-names',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'ad name',

          tabData: (apiStore) => apiStore.genericTabPromiseResults(),
          tabPromiseEndpoints: (rootStore, brand_param) => {
            const allFilter = _.merge(
              brand_param,
              rootStore.paidSearch.PaidSearchAdGroup.modelFilter(rootStore),
            );
            return [
              rootStore.paidSearchCampaignsCrud.getTotal(brand_param),
              rootStore.paidSearchAdGroupsCrud.getTotal(allFilter, ''),
            ];
          },
          tabDisabled: (r) => (r.apiStore.adNameTabDisabled) ? true : false,
          tabIndex: 2,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 8,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'OLD NSID/ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 4,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              colSize: 9,
              domo_field: 'status',
              domo_value: () => 'Inactive',
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 5,
              label: 'Status',
              name: 'status_id',
              options: (apiStore) => apiStore.statuses,
              id: 'status_id',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 2,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'PaidSearchAdName',
              rules: 'required',
            },

            {
              domo_field: 'channel',
              domo_value: () => 'PaidSearch',
              // ns: () => 'PaidSearch',
              name: 'channel',
              id: 'channel',
              utaType: 'channel',
              rules: 'required',
            },
            {
              colSize: 9,
              domo_field: 'campaign_name',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group, 'paid_search_campaign'), 'name'),
              form: 'campaign_name',
              filter_placeholder: 'Campaign',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStoresocialCampaigns,
              id: 'paid_search_campaign_id',
              name: 'name',
              label: 'Description',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              utaType: 'name',
              rules: 'string',
            },

            {
              colSize: 11,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              grid: true,
              grid_order: 150,
              grid_value: (row) => property(row.user, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'created_by',
              label: 'Created By',
              name: 'created_by',
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.Users,
            },
            {
              name: 'company',
              ns: () => 'Astellas',
              ns_order: 10,
            },
            {
              form: 'group_id',
              type: 'hidden',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              utaType: 'group',
              rules: 'required',
            },

            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              ns_order: 20,
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'business_unit',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
              name: 'business_unit',
              rules: 'required'
            },
            {
              domo_field: 'area',
              domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
              name: 'area',
              rules: 'required'
            },
            {
              colSize: 14,
              domo_field: 'grand_parent_namestring',
              domo_value: (crudStore) => property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'namestring_string'),
              form: 'campaign_name',
              grid: true,
              grid_order: 5,
              grid_value: (row) => `${property(row.paid_search_ad_group.paid_search_campaign, 'namestring_string')} `,
              grid_label: 'Campaign Namestring',
              filter_placeholder: 'Campaign',
              id: 'a.b.namestring_string',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              label: 'Description',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              utaType: 'name',
              rules: 'string',
            },
            {
              belongsTo: ['paid_search_ad_group', 'paidSearchAdGroups'],
              copyEditable: false,
              form: 'paid_search_ad_group_id',
              formField: (rootStore) => (<UccSelect
                core="paid_search_ad_group"
                fieldName="paid_search_ad_group_id"

                form={rootStore.channel.model.crudStore.form}
                label="Ad Group Namestring"
                menuItems={rootStore.channel.model.crudStore.makePrimeAdGroupOptions(rootStore.apiStore.paidSearchAdGroups)}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('paid_search_ad_group_id', event)}
              />),
              formOrder: 1,
              label: "Ad Group Namestring",
              rules: 'required|integer',
              show: true,
              show_value: (row) => `${property(row.paid_search_ad_group.paid_search_campaign, 'namestring_string')} `,
              show_order: 10,
              utaType: 'fk',
              subType: 'inherit',
            },
            {
              domo_field: 'audience',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'audience'), 'name'),
              id: 'audience',
              name: 'audience',
              utaType: 'parent',
            },
            {
              domo_field: 'campaign_type',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'campaign_type'), 'name'),
              id: 'campaign_type',
              name: 'campaign_type',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'segment',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'segment'), 'name'),
              id: 'segment',
              name: 'segment',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'ad_modifier',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group, 'ad_modifier'), 'name'),
              id: 'ad_modifier',
              name: 'ad_modifier',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'objective_modifier',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'objective_modifier'), 'name'),
              id: 'objective_modifier',
              name: 'objective_modifier',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'secondary_tactic',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'secondary_tactic'), 'name'),
              id: 'secondary_tactic',
              name: 'secondary_tactic',
              utaType: 'parent',
              rules: ''
            },
            {
              // this is tactic?
              domo_field: 'objective',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'objective'), 'name'),
              id: 'objective',
              name: 'objective',
              utaType: 'parent',
              rules: 'required'
            },
            {
              domo_field: 'geo',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'geo'), 'name'),
              id: 'geo',
              name: 'geo',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'platform',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'platform'), 'name'),
              id: 'platform',
              name: 'platform',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'language',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'language'), 'name'),
              id: 'language',
              name: 'language',
              utaType: 'parent',
              rules: ''
            },
            {
              colSize: 14,
              domo_field: 'parent_namestring',
              domo_value: (crudStore) => property(crudStore.storedData.paid_search_ad_group, 'namestring_string'),
              grid: true,
              grid_order: 6,
              grid_value: (row) => `${property(row.paid_search_ad_group, 'namestring_string')} `,
              grid_type: 'namestring',
              grid_label: 'Ad Group Namestring',
              id: 'namestring_string',
              label: 'Ad Group Namestring',
              name: 'paid_search_ad_group',
              filter: (dt, col) => inputTextFilter(dt, col),
              ns: (crudStore) => HeadedCamelCase(property(crudStore.storedData.paid_search_ad_group, 'name')),

              rules: 'required|integer',
              utaType: 'integer',
            },
            {
              colSize: 14,
              domo_field: 'additional_field',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.purpose),
              form: 'purpose',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="purpose"
                fieldName="purpose"
                form={rootStore.channel.model.crudStore.form}
                label="Purpose / Differentiator"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('purpose', event)}
              />),
              formOrder: 120,
              grid: true,
              grid_order: 120,
              grid_value: (row) => row.purpose,
              id: 'purpose',
              name: 'purpose',
              label: 'Purpose / Differentiator',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.purpose),
              ns_order: 120,
              utaType: 'string',
              rules: 'string',
              filter: (dt, col) => inputTextFilter(dt, col),
              show: true,
              show_value: (row) => row.purpose,
              show_order: 120,
            },
            {
              domo_field: 'ab_version',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.version),
              form: 'version',
              formOrder: 160,
              grid: true,
              grid_order: 145,
              grid_value: (row) => row.version,
              id: 'version',
              label: 'Version',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="version"
                fieldName="version"
                form={rootStore.channel.model.crudStore.form}
                label="Version"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('version', event)}
              />),
              filter: (dt, col) => inputTextFilter(dt, col),
              hideForm: true,
              name: 'version',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.version),
              ns_order: 160,
              rules: 'string',
              show: true,
              show_value: (row) => row.version,
              show_order: 220,
              utaType: 'text',
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ].concat(
            dateField('start_date', 'Start Date', 'blank', true, 130, 130, 'S.', 200),
            dateField('end_date', 'End Date', 'blank', false, 140, 140, 'E.', 210),
          ),
        },
      ],
    },
    {
      name: 'Affiliate',
      endpoint: 'affiliate',
      codename: 'affiliate',
      abbrev: 'affiliate',
      icon: 'Affiliate',
      disabled: true,
      invisible: true,
      showViewIcon: true,
      models: [
        {
          name: 'Affiliate Campaigns',
          singular: 'campaign',
          endpoint: 'affiliate-campaigns',
        },
      ],
    },


    // ____ ___                        _____                   __   
    // |    |   \______ ___________    /     \    ____   ______/  |_ 
    // |    |   /  ___// __ \_  __ \  /  \ /  \  / ___\ /     \   __\
    // |    |  /\___ \\  ___/|  | \/ /    Y    \/ /_/  >  Y Y  \  |  
    // |______//____  >\___  >__|    \____|__  /\___  /|__|_|  /__|  
    //              \/     \/                \//_____/       \/      

    {
      name: 'User Management',
      abbrev: 'UserManagement',
      codename: 'userManagement',
      endpoint: 'user-management',
      icon: AdminUserManagement,
      adminChannel: true,
      showViewIcon: true,
      subtitle: 'Manage Users, Groups, Brands and adjust permissions',
      models: [
        {
          allFilterData: (apiStore) => apiStore['usersFilterOptions'],
          childModelEndpoints: ['groups'], // set to endpoints
          childModelFilter: (root) => childModelFilter(root, 'user_id'),
          codename: 'AdminUser',
          controller: 'user',
          endpoint: 'users',
          form: (rootStore) => <GenericForm numButtons={2} />,
          genericModelPromiseEndpoints: [
            'areas',
            'brands',
            'brand-channels',
            'business-units',
            'channels',
            'groups',
            'permission-levels',
            'users',
          ],
          modelPromiseEndpoints: (apiStore) => ([
            apiStore.brandsCrud.getFilterOptions('', ''),
            apiStore.usersCrud.getFilterOptions('', ''),
            apiStore.groupsCrud.getFilterOptions('', ''),
            apiStore.channelsCrud.getFilterOptions('', ''),
          ]),
          modelFilter: (root) => { return {}; },
          name: 'Users',
          onCreateCallback: (root) => root.apiStore.sendWelcomeEmail(root.channel.model.crudStore.form),
          permCreateAndEdit: 20,
          permDelete: 60,
          permListAndView: 20,
          permRequest: false,
          route: 'users',
          send2domo: false,
          singular: 'user',
          sortField: 'name',
          tabIndex: 0,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults('admin'),
          crud: (rootStore) => rootStore.usersCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              form: 'name',
              formField: (rootStore) => (
                <UccInputText
                  core="name"
                  fieldName="name"
                  form={rootStore.channel.model.crudStore.form}
                  label="Name"
                  onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
                />
              ),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 20,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Name',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
              utaType: 'string',
            },
            {
              form: 'email',
              formField: (rootStore) => (
                <UccInputText
                  core="email"
                  fieldName="email"
                  form={rootStore.channel.model.crudStore.form}
                  label="Email"
                  onInput={(event) => rootStore.channel.model.crudStore.storeData('email', event)}
                />
              ),
              grid: true,
              grid_link: 'email',
              grid_order: 30,
              grid_value: (row) => row.email,
              id: 'email',
              label: 'Email',
              name: 'email',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              show: true,
              show_value: (row) => row.email,
              show_order: 30,
              utaType: 'string',
            },
            {
              belongsTo: ['permission_level', 'permissionLevels'],
              copyEditable: true,

              formOrder: 70,
              default: 3,
              form: 'permission_level_id',
              formField: (rootStore) => (
                <UccSelect
                  core="permission_level_id"
                  fieldName="permission_level_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Permission Level"
                  menuItems={rootStore.apiStore.permissionLevels.filter(p => p.id != 1 && p.id != 2).filter(p => p.level <= rootStore.apiStore.currentUser.pll).map((p) => ({
                    label: p.level_name,
                    value: `${p.id}`
                  }))}
                  onChange={(event) => rootStore.channel.model.crudStore.storeData('permission_level_id', event)}
                />
              ),
              label: 'Permission Level',
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.permission_level, 'level_name'),
              show_order: 70,
              utaType: 'fk',
            },
            {
              grid: true,
              grid_order: 70,
              grid_value: (row) => property(row.permission_level, 'level_name'),
              id: 'permission_level',
              label: 'Permission Level',
              filter: (dt, col) => multiSelectFilter(dt, col),
              filter_label: 'level_name',
              name: 'permission_level',
              rules: 'required',
              options: (apiStore) => apiStore.permissionLevels,
              utaType: 'other',
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',
            },
            {
              belongsTo: ['groups', 'groups'],
              copyEditable: true,
              form: 'group_ids',
              formField: (rootStore) => (
                <UccMultiSelect
                  core="groups"
                  fieldName="group_ids"
                  form={rootStore.channel.model.crudStore.form}
                  filter={true}
                  label="Groups"
                  menuItems={_.sortBy(rootStore.apiStore.groups, ['name']).map((p) => ({
                    label: p.name,
                    value: p.id,
                  }))}
                  onChange={(event) => rootStore.channel.model.crudStore.storeData('group_ids', event)}
                />
              ),
              label: 'Group',
              multiple: [],
              rules: 'array',
              utaType: 'array',
            },
            {
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.group_ids.length,
              id: 'groups',
              label: 'Groups',
              multiple: [],
              name: 'groups',
              rules: 'array',
              show: true,
              show_value: (row) => row.group_ids.length,
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              form: 'password',
              label: 'password',
              rules: 'string',
              utaType: 'string',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['brandsFilterOptions'],
          childModelEndpoints: ['groups'], // set to endpoints
          childModelFilter: (root) => childModelFilter(root, 'brand_id'),
          codename: 'AdminBrand',
          controller: 'brand',
          endpoint: 'brands',
          form: () => <GenericForm numButtons={2} />,
          genericModelPromiseEndpoints: [
            'areas',
            'brands',
            'brand-channels',
            'business-units',
            'channels',
            'groups',
            'permission-levels',
            'users',
          ],
          modelPromiseEndpoints: (apiStore) => ([
            apiStore.brandsCrud.getFilterOptions('', ''),
            apiStore.usersCrud.getFilterOptions('', ''),
            apiStore.groupsCrud.getFilterOptions('', ''),
            apiStore.channelsCrud.getFilterOptions('', ''),
          ]),
          modelFilter: (root) => { return {}; },
          name: 'Brands',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'brands',
          send2domo: false,
          singular: 'brand',
          sortField: 'name',
          tabIndex: 1,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults('admin'),
          crud: (rootStore) => rootStore.brandsCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              form: 'name',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Brand"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 20,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Brand',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              sortable: true,
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              form: 'abbrev',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              form: 'path',
              formField: (rootStore) => (<UccInputText
                core="path"
                fieldName="path"
                form={rootStore.channel.model.crudStore.form}
                label="Path"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('path', event)}
              />),
              formOrder: 33,
              grid: true,
              grid_order: 33,
              grid_value: (row) => row.path,
              id: 'path',
              label: 'Path',
              name: 'path',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'string',
              show: true,
              show_value: (row) => row.path,
              show_order: 33,
            },
            {
              grid: true,
              grid_order: 40,
              grid_value: (row) => {
                return row.group_ids.length
              },
              id: 'groups',
              label: 'Groups',
              name: 'groups',
              rules: 'array',
              show: true,
              show_value: (row) => row.group_ids.length,
              show_order: 40,
              utaType: 'array',
              sortable: false
            },

            {
              belongsTo: ['channels', 'channels'],
              copyEditable: true,
              form: 'channel_ids',
              copyEditable: true,
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={rootStore.apiStore.sortedChannels.filter(p => p.id != 4).map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 36,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.channel_ids.length,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channel_ids.length,
              show_order: 50,
              utaType: 'array',
              sortable: false
            },

            {
              belongsTo: ['business_unit', 'businessUnits'],
              form: 'business_unit_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="business-unit"
                fieldName="business_unit_id"

                form={rootStore.channel.model.crudStore.form}
                label="Business Unit"
                menuItems={_.compact(rootStore.apiStore.businessUnits.map((business_unit) => rootStore.uiStore.makePrimeRegularOption(business_unit)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('business_unit_id', event)}
              />),
              formOrder: 60,
              label: 'business_unit',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'business_unit',
              domo_value: (crudStore) => property(crudStore.storedData.business_unit, 'name'),
              grid: true,
              grid_order: 60,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.businessUnits,
              grid_value: (row) => property(row.business_unit, 'name'),
              id: 'business_unit',
              label: 'Business Unit',
              name: 'business_unit',
              ns: (crudStore) => abbrev(crudStore.storedData.business_unit),
              // ns_order: 15,
            },
            {
              belongsTo: ['area', 'areas'],
              form: 'area_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="area"
                fieldName="area_id"

                form={rootStore.channel.model.crudStore.form}
                label="Therapeutic Area"
                menuItems={_.compact(rootStore.apiStore.areas.map((area) => rootStore.uiStore.makePrimeRegularOption(area)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('area_id', event)}
              />),
              formOrder: 60,
              label: 'area',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'area',
              domo_value: (crudStore) => property(crudStore.storedData.area, 'name'),
              grid: true,
              grid_order: 60,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.areas,
              grid_value: (row) => property(row.area, 'name'),
              id: 'area',
              label: 'Therapeutic Area',
              name: 'area',
              ns: (crudStore) => abbrev(crudStore.storedData.area),
              // ns_order: 15,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['groupsFilterOptions'],
          childModelEndpoints: ['users', 'brands'], // set to endpoints
          childModelFilter: (root) => childModelFilter(root, 'group_id'),
          codename: 'AdminGroup',
          controller: 'group',
          disableBulkEdit: true,
          endpoint: 'groups',
          form: (rootStore) => <GenericForm numButtons={2} />,
          genericModelPromiseEndpoints: [
            'areas',
            'brands',
            'brand-channels',
            'business-units',
            'channels',
            'groups',
            'permission-levels',
            'users',
          ],
          modelPromiseEndpoints: (apiStore) => ([
            apiStore.brandsCrud.getFilterOptions('', ''),
            apiStore.usersCrud.getFilterOptions('', ''),
            apiStore.groupsCrud.getFilterOptions('', ''),
            apiStore.channelsCrud.getFilterOptions('', ''),
          ]),
          modelFilter: (root) => { return {}; },
          name: 'Groups',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'groups',
          send2domo: false,
          singular: 'group',
          sortField: 'name',
          tabIndex: 2,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults('admin'),
          crud: (rootStore) => rootStore.groupsCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Group Name"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 20,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Name',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 8,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              belongsTo: ['users', 'users'],
              form: 'user_ids',
              formField: (rootStore) => (
                <UccMultiSelect
                  core="users"
                  fieldName="user_ids"
                  form={rootStore.channel.model.crudStore.form}
                  label="User(s)"
                  filter={true}
                  menuItems={rootStore.apiStore.users.map((p) => ({
                    label: p.name,
                    value: p.id,
                  }))}
                  onChange={(event) => rootStore.channel.model.crudStore.storeData('user_ids', event)}
                />
              ),
              label: 'User',
              multiple: [],
              rules: 'array',
              utaType: 'array',
            },
            {
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.user_ids.length,
              id: 'users',
              label: 'Users',
              multiple: [],
              name: 'users',
              rules: 'array',
              show: true,
              show_value: (row) => row.user_ids.length,
              show_order: 40,
              utaType: 'array',
              sortable: false

            },
            {
              belongsTo: ['brands', 'brands'],
              form: 'brand_ids',
              label: 'Brand',
              multiple: [],
              rules: 'array',
              utaType: 'array',
            },
            {
              belongsTo: ['brand_channels', 'brandChannels'],
              form: 'brand_channel_ids',
              formField: (rootStore) => (
                <UccBrandChannelGroupDataTable
                  model={rootStore.channel.model}
                  core='acg'
                />
              ),
              label: 'BrandChannelId',
              multiple: [],
              rules: 'array',
              utaType: 'array',
            },
            {
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.brand_ids.length + ' / ' + row.brand_channel_ids.length,
              id: 'brands_channels',
              label: 'Brands / Channels',
              multiple: [],
              name: 'brands_channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.brand_ids.length + ' / ' + row.brand_channel_ids.length,
              show_order: 50,
              utaType: 'array',
              sortable: false

            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['permissionLevels'],
          codename: 'AdminPermissionLevel',
          controller: 'permission_level',
          crud: (rootStore) => rootStore.permissionLevelsCrud,
          disableBulkEdit: true,
          endpoint: 'permission-levels',
          form: () => <GenericForm datePicker={2} numButtons={2} />,
          modelFilter: (root) => { return {}; },
          name: 'Permission Levels',
          noDataText: (root) => '',
          permCreateAndEdit: 80,
          permDelete: 80,
          permListAndView: 80,
          permRequest: false,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults('admin'),
          route: 'permission-levels',
          showFormat: 'definitionList',
          send2domo: false,
          singular: 'Permission Level',
          sortField: 'level',
          tabDisabled: () => false,
          tabIndex: 0,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              show: true,
              show_value: (row) => row.id,
              show_order: 3,
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'level_name',
              formField: (rootStore) => (<UccInputText
                core="level_name"
                fieldName="level_name"
                form={rootStore.channel.model.crudStore.form}
                label="Level Name"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('level_name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 20,
              grid_value: (row) => row.level_name,
              id: 'level_name',
              label: 'Level Name',
              name: 'level_name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.level_name,
              show_order: 20,
            },
            {
              colSize: 15,
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'level',
              formField: (rootStore) => (<UccSelect
                core="level"
                fieldName="level"
                form={rootStore.channel.model.crudStore.form}
                label="Level"
                menuItems={[
                  { label: '0', value: '0' },
                  { label: '10', value: '10' },
                  { label: '20', value: '20' },
                  { label: '50', value: '50' },
                  { label: '70', value: '70' },
                  { label: '80', value: '80' },
                  { label: '99', value: '99' }]}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('level', event)}
              />),
              formOrder: 40,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.level,
              id: 'level',
              label: 'Position',
              name: 'level',
              rules: 'required|integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.level,
              show_order: 40,
            },
          ],
        },
      ],
    },
    {
      name: 'Attributes & Values',
      abbrev: 'AttrValues',
      codename: 'attrValues',
      endpoint: 'attributes-values',
      icon: AdminAttrValues,
      adminChannel: true,
      showViewIcon: false,
      showFilter: true,
      subtitle: 'Add, delete, or edit Attributes & Values',
      models: [
        {
          allFilterData: (apiStore) => apiStore['audiencesFilterOptions'],
          codename: 'AdminAudiences',
          controller: 'audience',
          disableBulkEdit: true,
          endpoint: 'audiences',
          genericModelPromiseEndpoints: ['audiences', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.audiencesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Audiences',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          route: 'audiences',
          permRequest: 20,
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Audience',
          sortField: 'name',
          tabIndex: 1,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.audiencesCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Audience"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Audience',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['marketingCampaignsFilterOptions'],
          codename: 'AdminMarketingCampaigns',
          controller: 'marketing_campaign',
          endpoint: 'marketing-campaigns',
          genericModelPromiseEndpoints: ['brands', 'marketing-campaigns', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.marketingCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          modelFilter: (root) => { return {}; },
          name: 'Campaign Names',
          permCreateAndEdit: 20,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'marketing-campaigns',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Campaign Name',
          sortField: 'name',
          tabIndex: 2,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.marketingCampaignsCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Name"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Campaign Name',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },

            {
              copyEditable: true,
              belongsTo: ['brands', 'brands'],
              form: 'brand_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="brands"
                fieldName="brand_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Brands"
                menuItems={rootStore.apiStore.brands.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('brand_ids', event)}
              />),
              formOrder: 40,
              label: 'brand',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.brands.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.brands,
              id: 'brands',
              label: 'Brands',
              multiple: [],
              name: 'brands',
              rules: 'array',
              show: true,
              show_value: (row) => row.brands.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 200,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 200,
              utaType: 'status',
            },
            // {
            //   belongsTo: ['channels', 'channels'],
            //   form: 'channel_ids',
            //   formField: (rootStore) => (<UccMultiSelect
            //     core="channels"
            //     fieldName="channel_ids"
            //     form={rootStore.channel.model.crudStore.form}
            //     label="Channels"
            //     menuItems={rootStore.apiStore.channels.map((p) => ({
            //       label: p.name,
            //       value: p.id,
            //     }))}
            //     onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
            //   />),
            //   formOrder: 40,
            //   label: 'Channel',
            //   multiple: [],
            //   rules: 'required|array',
            //   utaType: 'array',
            // },
            // {
            //   grid: true,
            //   grid_order: 40,
            //   grid_value: (row) => row.channels.map(c => c.name).join(', '),
            //   filter: (dt, col) => multiSelectFilter(dt, col),
            //   options: (apiStore) => apiStore.channels,
            //   id: 'channels',
            //   label: 'Channels',
            //   name: 'channels',
            //   rules: 'array',
            //   show: true,
            //   show_value: (row) => row.channels.map(c => c.name).join(', '),
            //   show_order: 50,
            //   utaType: 'array',
            //   sortable: false
            // },
          ].concat(
            dateField(
              'start_date',
              'Start Date',
              'blank',
              false,
              100,
              130,
              'S.',
            ),
            dateField('end_date', 'End Date', 'blank', false, 110, 140, 'E.'))
        },
        {
          allFilterData: (apiStore) => apiStore['campaignTypesFilterOptions'],
          codename: 'AdminCampaignTypes',
          controller: 'campaign_type',
          endpoint: 'campaign-types',
          genericModelPromiseEndpoints: ['campaign-types', 'channels', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.campaignTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Campaign Types',
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'campaign-types',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Campaign Type',
          sortField: 'name',
          tabIndex: 3,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.campaignTypesCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Type"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Campaign Type',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              copyEditable: true,
              belongsTo: ['channels', 'channels'],
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'campaign_type')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 40,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 60,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 60,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['creativeTypesFilterOptions'],
          codename: 'AdminCreativeTypes',
          controller: 'creative_type',
          disableBulkEdit: true,
          endpoint: 'creative-types',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['creative-types', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.creativeTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Creative Types',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'creative-types',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Creative Type',
          sortField: 'name',
          tabIndex: 4,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.creativeTypesCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Creative Type"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Creative Type',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['devicesFilterOptions'],
          codename: 'AdminDevices',
          controller: 'device',
          disableBulkEdit: true,
          endpoint: 'devices',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['devices', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.devicesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Devices',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'devices',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Device',
          sortField: 'name',
          tabIndex: 5,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.devicesCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Device"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Device',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['emailServicePlatformsFilterOptions'],
          codename: 'AdminEmailServicePlatforms',
          controller: 'email_service_platform',
          endpoint: 'email-service-platforms',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['email-service-platforms', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.emailServicePlatformsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Sources',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'email-service-platforms',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Source',
          sortField: 'name',
          tabIndex: 6,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.emailServicePlatformsCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Source"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Source',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['emailTypesFilterOptions'],
          codename: 'AdminEmailTypes',
          controller: 'email_type',
          disableBulkEdit: true,
          endpoint: 'email-types',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['email-types', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.emailTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Email Types',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'email-types',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Email Type',
          sortField: 'name',
          tabIndex: 7,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.emailTypesCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Email Type"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Email Type',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['gendersFilterOptions'],
          codename: 'AdminGenders',
          controller: 'gender',
          endpoint: 'genders',
          genericModelPromiseEndpoints: ['genders', 'channels', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.gendersCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Genders',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'genders',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Gender',
          sortField: 'name',
          tabIndex: 8,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.gendersCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Gender"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Gender',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              copyEditable: true,
              belongsTo: ['channels', 'channels'],
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'gender')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 40,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 60,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 60,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['geosFilterOptions'],
          codename: 'AdminGeos',
          controller: 'geo',
          endpoint: 'geos',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['geos', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.geosCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Geos',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'geos',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Geo',
          sortField: 'position',
          tabIndex: 9,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.geosCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Geo"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Geo',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 15,
              form: 'position',
              formField: (rootStore) => (<UccInputText
                core="position"
                fieldName="position"
                form={rootStore.channel.model.crudStore.form}
                label="Position"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
              />),
              formOrder: 40,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.position,
              id: 'position',
              label: 'Position',
              name: 'position',
              rules: 'required|integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.position,
              show_order: 40,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 60,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 60,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['goalsFilterOptions'],
          codename: 'AdminGoals',
          controller: 'goal',
          disableBulkEdit: true,
          endpoint: 'goals',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['goals', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.goalsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Goals',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'goals',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Goal',
          sortField: 'name',
          tabIndex: 10,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.goalsCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Goal"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Goal',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['languagesFilterOptions'],
          codename: 'AdminLanguages',
          controller: 'language',
          disableBulkEdit: true,
          endpoint: 'languages',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['languages', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.languagesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Languages',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'languages',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Language',
          sortField: 'position',
          tabIndex: 11,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.languagesCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Language"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Language',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 15,
              form: 'position',
              formField: (rootStore) => (<UccInputText
                core="position"
                fieldName="position"
                form={rootStore.channel.model.crudStore.form}
                label="Position"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
              />),
              formOrder: 40,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.position,
              id: 'position',
              label: 'Position',
              name: 'position',
              rules: 'required|integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.position,
              show_order: 40,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 60,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 60,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['messageTypesFilterOptions'],
          codename: 'AdminMessageTypes',
          controller: 'message_type',
          endpoint: 'message-types',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['message-types', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.messageTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Primary Topics',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'message-types',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Primary Topic',
          sortField: 'name',
          tabIndex: 12,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.messageTypesCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Primary Topic"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Primary Topic',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['objectivesFilterOptions'],
          codename: 'AdminObjectives',
          controller: 'objective',
          endpoint: 'objectives',
          genericModelPromiseEndpoints: ['campaign-types', 'channels', 'objectives', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.objectivesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Objectives',
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'objectives',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Objective',
          sortField: 'name',
          tabIndex: 13,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.objectivesCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Objective"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Objective',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              copyEditable: true,
              belongsTo: ['campaign-types', 'campaignTypes'],
              form: 'campaign_type_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="campaign_types"
                fieldName="campaign_type_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Types"
                menuItems={rootStore.apiStore.campaignTypes.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('campaign_type_ids', event)}
              />),
              formOrder: 40,
              label: 'Campaign Type',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.campaign_types.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.campaignTypes,
              id: 'campaign_types',
              label: 'Campaign Types',
              multiple: [],
              name: 'campaign_types',
              rules: 'array',
              show: true,
              show_value: (row) => row.campaign_types.map(c => c.name).join(', '),
              show_order: 40,
              utaType: 'array',
              sortable: false
            },
            {
              copyEditable: true,
              belongsTo: ['channels', 'channels'],
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'objective')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 50,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 60,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 60,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['objectiveModifiersFilterOptions'],
          codename: 'AdminObjectiveModifiers',
          controller: 'objective_modifier',
          endpoint: 'objective-modifiers',
          genericModelPromiseEndpoints: ['objectives', 'objective-modifiers', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.objectiveModifiersCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Keyword Category Group',
          permCreateAndEdit: 60,
          permDelete: 80,
          permListAndView: 20,
          route: 'objective-modifiers',
          permRequest: 20,
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Category',
          sortField: 'name',
          tabIndex: 4,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.objectiveModifiersCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Category"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Category',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 15,
              copyEditable: true,
              belongsTo: ['objectives', 'objectives'],
              form: 'objective_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="objectives"
                fieldName="objective_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Objectives"
                menuItems={rootStore.apiStore.objectives.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_ids', event)}
              />),
              formOrder: 80,
              label: 'Objective',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 80,
              grid_value: (row) => row.objectives.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.objectives,
              id: 'objectives',
              label: 'Objectives',
              multiple: [],
              name: 'objectives',
              rules: 'array',
              show: true,
              show_value: (row) => row.objectives.map(c => c.name).join(', '),
              show_order: 80,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',
            },

          ],
        },
        {
          allFilterData: (apiStore) => apiStore['adModifiersFilterOptions'],
          codename: 'AdminAdModifiers',
          controller: 'ad_modifier',
          endpoint: 'ad-modifiers',
          genericModelPromiseEndpoints: ['ad-modifiers', 'objective-modifiers', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.adModifiersCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Matchtype',
          permCreateAndEdit: 60,
          permDelete: 80,
          permListAndView: 20,
          permRequest: 20,
          route: 'ad-modifiers',
          permRequest: false,
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Category 2',
          sortField: 'name',
          tabIndex: 4,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.objectiveModifiersCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Category"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Category',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 15,
              copyEditable: true,
              belongsTo: ['objective_modifier', 'objectiveModifiers'],
              form: 'objective_modifier_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="objective_modifiers"
                fieldName="objective_modifier_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Categories"
                menuItems={rootStore.apiStore.objectiveModifiers.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_modifier_ids', event)}
              />),
              formOrder: 80,
              label: 'Objective Modifier',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 80,
              grid_value: (row) => row.objective_modifiers.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.objectiveModifiers,
              id: 'objective_modifiers',
              label: "Categories",
              multiple: [],
              name: 'objective_modifiers',
              rules: 'array',
              show: true,
              show_value: (row) => row.objective_modifiers.map(c => c.name).join(', '),
              show_order: 80,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['platformsFilterOptions'],
          codename: 'AdminPlatforms',
          controller: 'platform',
          endpoint: 'platforms',
          genericModelPromiseEndpoints: ['channels', 'platforms', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.platformsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Platforms',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'platforms',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Platform',
          sortField: 'name',
          tabIndex: 14,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.platformsCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Platform"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 20,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Platform',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              copyEditable: true,
              belongsTo: ['channels', 'channels'],
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'platform')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 40,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 60,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 60,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['publishersFilterOptions'],
          codename: 'AdminPublishers',
          controller: 'publisher',
          disableBulkEdit: true,
          endpoint: 'publishers',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['statuses', 'publishers'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.publishersCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Publishers',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'publishers',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Publisher',
          sortField: 'name',
          tabIndex: 15,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.publishersCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Publisher"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Publisher',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['secondaryTactics'],
          codename: 'AdminSecondaryTactics',
          controller: 'secondary_tactic',
          endpoint: 'secondary-tactics',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['secondary-tactics', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.secondaryTacticsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Secondary Tactics',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          route: 'secondary-tactics',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Secondary Tactic',
          sortField: 'name',
          tabIndex: 16,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.secondaryTacticsCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Secondary Tactic"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 20,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Secondary Tactic',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['segmentsFilterOptions'],
          codename: 'AdminSegments',
          controller: 'segment',
          endpoint: 'segments',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['segments', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.segmentsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Segments',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'segments',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Segment',
          sortField: 'name',
          tabIndex: 17,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.segmentsCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Segment"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Segment',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['sendTypesFilterOptions'],
          codename: 'AdminSendTypes',
          controller: 'send_type',
          endpoint: 'send-types',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['send-types', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.sendTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Send Types',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'send-types',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Send Type',
          sortField: 'name',
          tabIndex: 18,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.sendTypesCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Send Type"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Send Type',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['sizesFilterOptions'],
          codename: 'AdminSizes',
          controller: 'size',
          disableBulkEdit: true,
          endpoint: 'sizes',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['sizes', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.sizesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Sizes',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'sizes',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Size',
          sortField: 'name',
          tabIndex: 19,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.sizesCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Size"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Size',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['tacticsFilterOptions'],
          codename: 'AdminTactics',
          controller: 'tactic',
          endpoint: 'tactics',
          genericModelPromiseEndpoints: ['channels', 'tactics', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.tacticsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Tactics',
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'tactics',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Tactic',
          sortField: 'name',
          tabIndex: 20,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.tacticsCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Tactic"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Tactic',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              copyEditable: true,
              belongsTo: ['channels', 'channels'],
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'tactic')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 40,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 60,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 60,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['targetingsFilterOptions'],
          codename: 'AdminTargetings',
          controller: 'targeting',
          endpoint: 'targetings',
          genericModelPromiseEndpoints: ['channels', 'tactics', 'targetings', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.targetingsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Targetings',
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'targetings',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Targeting',
          sortField: 'position',
          tabIndex: 21,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.targetingsCrud,
          sortField: 'position',
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Targeting"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Targeting Name',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 15,
              form: 'extra_field_type',
              formField: (rootStore) => (<UccSelect
                core="extra_field_type"
                fieldName="extra_field_type"

                form={rootStore.channel.model.crudStore.form}
                label="Extra Field Type"
                menuItems={[{ label: 'Number', value: 'number' }, { label: 'Text', value: 'text' }]}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('extra_field_type', event)}
              />),
              formOrder: 40,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.extra_field_type,
              id: 'extra_field_type',
              label: 'Extra Field Type',
              name: 'Extra Field Type',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'string',
              utaType: 'string',
              show: true,
              show_value: (row) => row.extra_field_type,
              show_order: 40,
            },
            {
              colSize: 15,
              form: 'extra_required',
              formField: (rootStore) => (<UccCheckBox
                core="extra_required"
                fieldName="extra_required"
                form={rootStore.channel.model.crudStore.form}
                label="Extra Required"
                onChange={(event) => rootStore.channel.model.crudStore.storeData('extra_required', event)}
              />),
              formOrder: 50,
              grid: true,
              grid_order: 50,
              grid_value: (row) => (row.extra_required ? 'Yes' : 'No'),
              id: 'extra_required',
              label: 'Extra Required',
              name: 'extra_required',
              type: 'checkbox',
              utaType: 'boolean',
              rules: 'boolean',
              show: true,
              show_value: (row) => (row.extra_required ? 'Yes' : 'No'),
              show_order: 50
            },
            {
              colSize: 15,
              form: 'position',
              formField: (rootStore) => (<UccInputText
                core="position"
                fieldName="position"
                form={rootStore.channel.model.crudStore.form}
                label="Position"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
              />),
              formOrder: 60,
              grid: true,
              grid_order: 60,
              grid_value: (row) => row.position,
              id: 'position',
              label: 'Position',
              name: 'position',
              rules: 'required|integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.position,
              show_order: 60,
            },
            {
              copyEditable: true,
              belongsTo: ['channels', 'channels'],
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'targeting_ids')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 70,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 70,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 70,
              utaType: 'array',
              sortable: false
            },
            {
              copyEditable: true,
              belongsTo: ['tactics', 'tactics'],
              form: 'tactic_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="tactics"
                fieldName="tactic_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Tactics"
                menuItems={rootStore.apiStore.tactics.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('tactic_ids', event)}
              />),
              formOrder: 80,
              label: 'Tactic',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 80,
              grid_value: (row) => row.tactics.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.tactics,
              id: 'tactics',
              label: 'Tactics',
              multiple: [],
              name: 'tactics',
              rules: 'array',
              show: true,
              show_value: (row) => row.tactics.map(c => c.name).join(', '),
              show_order: 80,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['testGroupsFilterOptions'],
          codename: 'AdminTestGroups',
          controller: 'test_group',
          endpoint: 'test-groups',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['test-types', 'test-groups', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.testGroupsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Test Groups',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: false,
          route: 'test-groups',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Test Group',
          sortField: 'name',
          tabIndex: 22,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.testGroupsCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Test Group"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 20,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Test Group',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_link: 'draft_edit',
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              copyEditable: true,
              belongsTo: ['test_types', 'testTypes'],
              form: 'test_type_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="test_types"
                fieldName="test_type_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Test Types"
                menuItems={rootStore.apiStore.testTypes.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('test_type_ids', event)}
              />),
              formOrder: 40,
              label: 'Test Type',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.test_types.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.testTypes,
              id: 'test_types',
              label: 'Test Types',
              multiple: [],
              name: 'test_types',
              rules: 'array',
              show: true,
              show_value: (row) => row.test_types.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 60,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 60,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['testTypesFilterOptions'],
          codename: 'AdminTestTypes',
          controller: 'test_type',
          endpoint: 'test-types',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['statuses', 'test-types'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.testTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Test Types',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'test-types',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Test Type',
          sortField: 'name',
          tabIndex: 23,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.testTypesCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Test Type"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Test Type',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['wavesFilterOptions'],
          codename: 'AdminWaves',
          controller: 'wave',
          endpoint: 'waves',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['statuses', 'waves'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.wavesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Waves',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'waves',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Wave',
          sortField: 'name',
          tabIndex: 24,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.wavesCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Wave"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Wave',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
      ]
    },
    {
      name: 'Domo Integrations',
      abbrev: 'DomoInt',
      codename: 'domoIntegrations',
      endpoint: 'domo-integrations',
      icon: DomoLogo,
      adminChannel: true,
      showViewIcon: false,
      subtitle: 'Create or manage Domo Integrations',
      hasStatus: false,
      models: [
        {
          allFilterData: (apiStore) => apiStore['domoConnectionsFilterOptions'],
          codename: 'DomoConnections',
          controller: 'domo_connection',
          endpoint: 'domo-connections',
          form: () => <GenericForm datePicker={2} numButtons={2} />,
          genericModelPromiseEndpoints: ['brands'],
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.domoConnectionsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          modelFilter: (root) => { return {}; },
          name: 'Connections',
          permRequest: false,
          route: 'connections',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'connection',
          sortField: 'brand',
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          provisionButton: true,
          tabIndex: 0,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.domoConnectionsCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 8,
              form: 'brand_id',
              formField: (rootStore) => (<UccSelect
                core="brand"
                fieldName="brand_id"
                form={rootStore.channel.model.crudStore.form}
                label="Brand"
                menuItems={rootStore.channel.model.crudStore.action == 'edit' ? rootStore.apiStore.brands.map((p) => rootStore.uiStore.makePrimeRegularOption(p)) : rootStore.apiStore.brands.filter(b => !b.domo_configured).map((p) => rootStore.uiStore.makePrimeRegularOption(p))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('brand_id', event)}
                disabled={(rootStore.channel.model.crudStore.action == 'edit')}
                value={rootStore.channel.model.crudStore.storedData['brand_id']}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => property(row.brand, 'name'),
              id: 'brand',
              label: 'Brand',
              name: 'brand',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.brands,
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.brand, 'name'),
              show_order: 20,
              utaType: 'fk',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.pending_namestrings_count,
              id: 'pending_namestrings_count',
              label: 'Pending',
              name: 'pending_namestrings_count',
              rules: 'integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.pending_namestrings_count,
              show_order: 30,
              sortable: false,
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.pushed_namestrings_count,
              id: 'pushed_namestrings_count',
              label: 'Published',
              name: 'pushed_namestrings_count',
              rules: 'integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.pushed_namestrings_count,
              show_order: 40,
              sortable: false,
            },
            {
              copyEditable: true,
              colSize: 12,
              form: 'domo_instance_url',
              formField: (rootStore) => (<UccInputText
                label="Domo Instance URL"
                core="domo_instance_url"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('domo_instance_url', event)}
                fieldName="domo_instance_url"
              />),
              formOrder: 30,
              grid: true,
              grid_order: 50,
              grid_value: (row) => <a href={row.domo_instance_url} target="_blank">{row.domo_instance_url}</a>,
              id: 'domo_instance_url',
              label: 'Instance URL',
              name: 'domo_instance_url',
              rules: 'required|string',
              show: true,
              show_value: (row) => <a href={row.domo_instance_url} target="_blank">{row.domo_instance_url}</a>,
              show_order: 50,
              sortable: false,
              utaType: 'string',
            },
            {
              form: 'domo_client_id',
              formField: (rootStore) => (<UccInputText
                label="Domo Client"
                core="domo_client_id"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('domo_client_id', event)}
                fieldName="domo_client_id"
              />),
              formOrder: 40,
              label: 'Domo Client',
              rules: 'required|string',
              type: 'hidden',
            },
            {
              form: 'domo_client_secret',
              formField: (rootStore) => (<UccInputText
                label="Domo Client Secret"
                core="domo_client_secret"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('domo_client_secret', event)}
                fieldName="domo_client_secret"
              />),
              formOrder: 50,
              label: 'Domo Client Secret',
              rules: 'required|string',
              type: 'hidden',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['channelDomoDatasetsFilterOptions'],
          codename: 'DomoDatasets',
          controller: 'channel_domo_dataset',
          endpoint: 'channel-domo-datasets',
          form: () => <GenericForm datePicker={2} numButtons={2} />,
          genericModelPromiseEndpoints: ['channels', 'channel-domo-datasets'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.channelDomoDatasetsCrud.getFilterOptions({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          name: 'Datasets',
          noAddButton: true,
          noToolbar: true,
          permRequest: false,
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          route: 'datasets',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'dataset',
          sortField: 'brand',
          tabIndex: 1,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.channelDomoDatasetsCrud,
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => property(row.brand, 'name'),
              id: 'brand',
              label: 'Brand',
              name: 'brand',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.brands,
              rules: 'string',
              show: true,
              show_value: (row) => property(row.brand, 'name'),
              show_order: 20,
              utaType: 'parent',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.status,
              id: 'status',
              label: 'Status',
              name: 'status',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => _.uniq(apiStore.channelDomoDatasets.map(cdd => cdd.status)),
              rules: 'string',
              show: true,
              show_value: (row) => row.status,
              show_order: 30,
              utaType: 'string',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 40,
              grid_value: (row) => property(row.channel, 'name'),
              id: 'channel',
              label: 'Channel',
              name: 'channel',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              rules: 'string',
              show: true,
              show_value: (row) => property(row.channel, 'name'),
              show_order: 40,
              utaType: 'string',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.pending_namestrings_count,
              id: 'pending_namestrings_count',
              label: 'Pending',
              name: 'pending_namestrings_count',
              rules: 'integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.pending_namestrings_count,
              show_order: 50,
              sortable: false,
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 60,
              grid_value: (row) => row.pushed_namestrings_count,
              id: 'pushed_namestrings_count',
              label: 'Published',
              name: 'pushed_namestrings_count',
              rules: 'integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.pushed_namestrings_count,
              show_order: 60,
              sortable: false,
            },
            {
              colSize: 12,
              grid: true,
              grid_order: 70,
              grid_value: (row) => <a href={`${row.brand.domo_instance_url}/datasources/${row.dataset_id}/details/overview`} target="_blank">{`${row.brand.domo_instance_url}/datasources/${row.dataset_id}/details/overview`}</a>,
              id: 'dataset_url',
              label: 'Dataset URL',
              name: 'dataset url',
              rules: 'string',
              show: true,
              show_value: (row) => <a href={`${row.brand.domo_instance_url}/datasources/${row.dataset_id}/details/overview`} target="_blank">{`${row.brand.domo_instance_url}/datasources/${row.dataset_id}/details/overview`}</a>,
              show_order: 70,
              sortable: false,
              utaType: 'string',
            },
          ].concat(dateField('updated_at', 'Last Updated', 'blank', false, 80, 80, 'U.', 80)),
        },
        {
          allFilterData: (apiStore) => apiStore['namestringsFilterOptions'],
          codename: 'DomoNamestrings',
          controller: 'namestring',
          endpoint: 'namestrings',
          form: () => <GenericForm datePicker={2} numButtons={2} />,
          modelFilter: (root) => { return {}; },
          genericModelPromiseEndpoints: ['namestrings'],
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.namestringsCrud.getFilterOptions({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          name: 'Details',
          noAddButton: true,
          noToolbar: true,
          permRequest: false,
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          route: 'details',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'detail',
          tabIndex: 2,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.namestringsCrud,
          sortField: 'created_at',
          sortOrder: 'desc',
          columns: [
            {
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              label: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 6,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 20,
              grid_value: (row) => row.id,
              grid_link: 'draft_edit',
              id: 'id',
              label: 'NSID',
              name: 'id',
              rules: 'integer',
              show: true,
              show_value: (row) => row.id,
              show_order: 20,
              utaType: 'integer',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.status,
              id: 'status',
              label: 'Status',
              name: 'status',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.status)),
              rules: 'string',
              show: true,
              show_value: (row) => row.status,
              show_order: 30,
              utaType: 'string',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.brand,
              id: 'brand',
              label: 'Brand',
              name: 'brand',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.brand)),
              rules: 'string',
              show: true,
              show_value: (row) => row.brand,
              show_order: 40,
              utaType: 'string',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.channel,
              id: 'channel',
              label: 'Channel',
              name: 'channel',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.channel)),
              rules: 'string',
              utaType: 'string',
              show: true,
              show_value: (row) => row.channel,
              show_order: 50,
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 60,
              grid_value: (row) => row.ns_type,
              id: 'ns_type',
              label: 'Type',
              name: 'ns_type',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.ns_type)),
              rules: 'string',
              utaType: 'string',
              show: true,
              show_value: (row) => row.ns_type,
              show_order: 60,
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 62,
              grid_value: (row) => row.business_unit,
              id: 'business_unit',
              label: 'Business Unit',
              name: 'business_unit',
              rules: 'string',
              show: true,
              show_value: (row) => row.business_unit,
              show_order: 62,
              utaType: 'string',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 65,
              grid_value: (row) => row.area,
              id: 'area',
              label: 'Area',
              name: 'area',
              rules: 'string',
              show: true,
              show_value: (row) => row.area,
              show_order: 65,
              utaType: 'string',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 67,
              grid_value: (row) => row.audience,
              id: 'audience',
              label: 'Audience',
              name: 'audience',
              rules: 'string',
              show: true,
              show_value: (row) => row.audience,
              show_order: 67,
              utaType: 'string',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 70,
              grid_value: (row) => row.campaign_type,
              id: 'campaign_type',
              label: 'Campaign Type',
              name: 'campaign_type',
              rules: 'string',
              show: true,
              show_value: (row) => row.campaign_type,
              show_order: 70,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 90,
              grid_value: (row) => row.objective,
              id: 'objective',
              label: 'Objective',
              name: 'objective',
              rules: 'string',
              show: true,
              show_value: (row) => row.objective,
              show_order: 90,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 100,
              grid_value: (row) => row.tactic,
              id: 'tactic',
              label: 'Tactic',
              name: 'tactic',
              rules: 'string',
              show: true,
              show_value: (row) => row.tactic,
              show_order: 100,
              utaType: 'string',
            },
            {
              colSize: 9,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 110,
              grid_value: (row) => row.suppression,
              id: 'suppression',
              label: 'Suppression',
              name: 'suppression',
              rules: 'string',
              show: true,
              show_value: (row) => row.suppression,
              show_order: 110,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 120,
              grid_value: (row) => row.targeting,
              id: 'targeting',
              label: 'Targeting',
              name: 'targeting',
              rules: 'string',
              show: true,
              show_value: (row) => row.targeting,
              show_order: 120,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 130,
              grid_value: (row) => row.device,
              id: 'device',
              label: 'Device',
              name: 'device',
              rules: 'string',
              show: true,
              show_value: (row) => row.device,
              show_order: 130,
              utaType: 'string',
            },
            {
              colSize: 11,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 130,
              grid_value: (row) => row.ad_name,
              id: 'ad_name',
              label: 'Campaign Name',
              name: 'ad_name',
              rules: 'string',
              show: true,
              show_value: (row) => row.ad_name,
              show_order: 130,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 140,
              grid_value: (row) => row.gender,
              id: 'gender',
              label: 'Gender',
              name: 'gender',
              rules: 'string',
              show: true,
              show_value: (row) => row.gender,
              show_order: 140,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 150,
              grid_value: (row) => row.geo,
              id: 'geo',
              label: 'Geo',
              name: 'geo',
              rules: 'string',
              show: true,
              show_value: (row) => row.geo,
              show_order: 150,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 150,
              grid_value: (row) => row.language,
              id: 'language',
              label: 'Language',
              name: 'language',
              rules: 'string',
              show: true,
              show_value: (row) => row.language,
              show_order: 150,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 160,
              grid_value: (row) => row.size,
              id: 'size',
              label: 'Size',
              name: 'size',
              rules: 'string',
              show: true,
              show_value: (row) => row.size,
              show_order: 160,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 170,
              grid_value: (row) => row.publisher,
              id: 'publisher',
              label: 'Publisher',
              name: 'publisher',
              rules: 'string',
              show: true,
              show_value: (row) => row.publisher,
              show_order: 170,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 180,
              grid_value: (row) => row.creative_type,
              id: 'creative_type',
              label: 'Creative Type',
              name: 'creative_type',
              rules: 'string',
              show: true,
              show_value: (row) => row.creative_type,
              show_order: 180,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 185,
              grid_value: (row) => row.segment,
              id: 'segment',
              label: 'Segment',
              name: 'segment',
              rules: 'string',
              show: true,
              show_value: (row) => row.segment,
              show_order: 185,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 190,
              grid_value: (row) => row.test_type,
              id: 'test_type',
              label: 'Test Type',
              name: 'test_type',
              rules: 'string',
              show: true,
              show_value: (row) => row.test_type,
              show_order: 190,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 195,
              grid_value: (row) => row.test_group,
              id: 'test_group',
              label: 'Test Group',
              name: 'test_group',
              rules: 'string',
              show: true,
              show_value: (row) => row.test_group,
              show_order: 195,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 200,
              grid_value: (row) => row.message_type,
              id: 'message_type',
              label: 'Message Type',
              name: 'message_type',
              rules: 'string',
              show: true,
              show_value: (row) => row.message_type,
              show_order: 200,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 205,
              grid_value: (row) => row.veeva_document_number,
              id: 'veeva_document_number',
              label: 'Veeva Document Number',
              name: 'veeva_document_number',
              rules: 'string',
              show: true,
              show_value: (row) => row.veeva_document_number,
              show_order: 205,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 210,
              grid_value: (row) => row.send_type,
              id: 'send_type',
              label: 'Send Type',
              name: 'send_type',
              rules: 'string',
              show: true,
              show_value: (row) => row.send_type,
              show_order: 210,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 215,
              grid_value: (row) => row.wave,
              id: 'wave',
              label: 'Wave',
              name: 'wave',
              rules: 'string',
              show: true,
              show_value: (row) => row.wave,
              show_order: 215,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 215,
              grid_value: (row) => row.email_type,
              id: 'email_type',
              label: 'Email Type',
              name: 'email_type',
              rules: 'string',
              show: true,
              show_value: (row) => row.email_type,
              show_order: 215,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 220,
              grid_value: (row) => row.display_types,
              id: 'display_types',
              label: 'Display Types',
              name: 'display_types',
              rules: 'string',
              show: true,
              show_value: (row) => row.display_types,
              show_order: 220,
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 225,
              grid_value: (row) => row.region,
              id: 'region',
              label: 'Region',
              name: 'region',
              rules: 'string',
              show: true,
              show_value: (row) => row.region,
              show_order: 225,
              utaType: 'string',
            },
            {
              colSize: 9,
              grid: true,
              grid_order: 230,
              grid_value: (row) => row.created_by,
              id: 'created_by',
              label: 'Created By',
              name: 'created_by',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.created_by)),
              rules: 'string',
              utaType: 'string',
              show: true,
              show_value: (row) => row.created_by,
              show_order: 230,
            },
          ].concat(
            dateField('start_date', 'Start Date', 'blank', true, 210, 210, 'S.', 210),
            dateField('end_date', 'End Date', 'blank', false, 220, 220, 'E.', 220),
            dateField('created_at', 'Date Created', 'blank', false, 240, 240, 'C.', 240)
          ),
        },
      ],
    }
  ],
};