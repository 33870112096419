import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import CrudTable from './CrudTable';
import CrudToolbar from './CrudToolbar';
import UccStatsTable from '../PrimeReactComponent/UccStatsTable';
import { observable } from 'mobx';

@inject('rootStore')
@observer
class CrudStats extends React.Component {
  render() {
    const {
      rootStore,
      model,
      childIndex,
    } = this.props;

    // return (
    //   const channel = rootStore.channel;
    //   const crudStore = model.crudStore;
    //   const { order, orderBy, page, rowsPerPage } = this.state;

    // step 1: filter by custom rules defined in metadata:
    const gridColumnsTmp = model.columns.filter(function (element) {
      if (element.grid == undefined) return false;
      return typeof element.grid === "boolean" ? element.grid : element.grid(this);
    }, this);
    // step 2: filter by permissions defined in metadata
    const gridColumnsTmp2 = _.filter(gridColumnsTmp, ({ permListAndView }) => (permListAndView == undefined || parseInt(permListAndView) <= rootStore.apiStore.currentUser.pll))
    // step 3: sort em (feel free to try to get all 3 steps in one line of code!)
    const gridColumns = _.orderBy(gridColumnsTmp2, ['grid_order'], ['asc']);

    if (this.loading) return (<div style={{ textAlign: 'center' }}><CircularProgress /></div>);
    return (
      <UccStatsTable
        columns={model.crudStore.gridColumns}
        model={model}
      />
    )
  }
}

export default CrudStats
