import { Button } from 'primereact/button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import RootStore from '../../store/RootStore';
import Tooltip from '@material-ui/core/Tooltip';
import UccButton from '../PrimeReactComponent/UccButton';
import { between, permRequest } from '../../store/StoreMetadata.js'
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';

import UccToolbarButton from './UccToolbarButton';

@inject('rootStore')
@observer
class UccToolbarStats extends React.Component {
  constructor(props) {
    super(props);
    this.model = this.props.model;
  }

  disableNew = () => {
    if (permRequest(this.props.rootStore)) return false;
    if (this.props.rootStore.apiStore.currentUser.pll < this.props.model.permCreateAndEdit) return true;
    return false;
  }


  getLabel = () => {
    return permRequest(this.props.rootStore) ? 'Request ' : this.props.model.singular;
  }


  handleStatsClick = event => {
    this.props.rootStore.channel.model.crudStore.onStats(this.model);
  };

  handleCreateClose = () => {
    this.props.rootStore.uiStore.createAnchorEl = null;
  }


  render() {
    const { uiStore, channel } = this.props.rootStore;
    const crudStore = channel.model.crudStore
    const id = !!this.props.rootStore.uiStore.createAnchorEl ? 'simple-popover' : undefined;

    return (
      <>
        <UccButton
          icon="pi pi-plus"
          label={'Stats'}
          onClick={this.handleStatsClick}
          buttonType="icon"
          id="toolbar-add"
          className="add-hover-effect"
          disable={false}
        />
      </>
    )

  }
}


export default UccToolbarStats;
