import GenericForm from './../components/CrudAdmin/forms/GenericForm';
import AdminUserManagement from '../../../public/images/admin-user-management.svg';
import AdminAttrValues from '../../../public/images/np_list_875440_000000.svg';
import DomoLogo from '../../../public/images/domo_logo.png';
import FacebookLogo from '../../../public/images/facebook_logo.png';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../components/PrimeReactComponent/UccInputText';
import UccSelect from './../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../components/PrimeReactComponent/UccObjectiveModifier';
import { formatDate } from 'moment';
import { abbrev, adGroupModifierNs, ad_id_value, appendFields, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, disableSubmitButton, formFields, getAdGroupNamestring, generateFilterLabel, getInstanceUrl, getModelUrl, getPlatformList, HeadedCamelCase, inputTextFilter, isDisabled, multiAbbrev, dropDownFilter, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, postgresDate, property, showFields, validDate, getRelationId, } from './StoreMetadata.js'
import jsp from '../lib/jsp';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from '../lib/Calendar';

var moment = require('moment');
const abbrev_regex = 'regex:/^\\w+$/';
const extra_regex = 'regex:/^[a-zA-Z0-9_]+$/';

export const blueMetadata = {
  // don't change codenames!
  channels: [
    {
      abbrev: 'PaidSocial',
      codename: 'paidSocial',
      endpoint: 'paid-social',
      icon: 'PaidSocial',
      id: 1,
      name: 'Paid Social',
      showViewIcon: true,
      models: [
        //    ___   __   _  _  ____   __   __  ___  __ _  ____
        //   / __) / _\ ( \/ )(  _ \ / _\ (  )/ __)(  ( \/ ___)
        //  ( (__ /    \/ \/ \ ) __//    \ )(( (_ \/    /\___ \
        //   \___)\_/\_/\_)(_/(__)  \_/\_/(__)\___/\_)__)(____/

        {
          allFilterData: (apiStore) => apiStore['socialCampaignsFilterOptions'],
          codename: 'SocCamp',
          controller: 'social_campaign',
          domoNsType: 'SocialCampaign',
          endpoint: 'social-campaigns',
          filterOptionsCall: (apiStore) => (val) => apiStore['socialCampaignsFilterOptions'] = val,
          form: () => <GenericForm type="Campaign" datePicker={2} numButtons={3} />,
          genericModelPromiseEndpoints: [
            'ad-sets',
            'campaign-types',
            'campaign-type-channels',
            'campaign-type-objectives',
            'channel-objectives',
            'channel-platforms',
            'objectives',
            'platforms',
            'statuses',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.newCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
          name: 'Campaigns',
          permCreateAndEdit: 10,
          permDelete: 20,
          permListAndView: 20,
          permRequest: false,
          route: 'campaigns',
          send2domo: true,
          singular: 'campaign',
          showFormat: 'definitionList',
          tabData: (apiStore) => apiStore.genericTabPromiseResults(),
          tabPromiseEndpoints: (rootStore, brand_param) => {
            const allFilter = _.merge(
              brand_param,
              rootStore.paidSocial.SocAdSet.modelFilter(rootStore),
            );
            return [
              rootStore.newCampaignsCrud.getTotal(brand_param),
              rootStore.newAdsetsCrud.getTotal(allFilter, ''),
            ];
          },
          tabIndex: 0,
          tabLabel: (m) => {
            return m.crudStore.selectedRowIds.length > 0
              ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)`
              : m.name
          },
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.newCampaignsCrud,
          columns: [
            {
              colSize: 6,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 6,
              // domo_field: 'nsid',
              // domo_value: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 4,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'SocialCampaign',
              rules: 'required',
            },
            {
              form: 'group_id',
              rules: 'required',
              type: 'hidden',
              utaType: 'group',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              form: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              ns_order: 1,
              utaType: 'group',
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => getRelationId(crudStore, 'brand'),
              form: 'brand_id',
              rules: 'required',
              type: 'hidden',
              utaType: 'brand',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              form: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              ns_order: 2,
              utaType: 'brand',
            },
            {
              domo_field: 'channel',
              domo_value: (crudStore) => crudStore.channel.abbrev,
              id: 'channel',
              form: 'channel',
              name: 'channel',
              ns: (crudStore) => crudStore?.channel?.abbrev,
              ns_order: 3,
              type: 'hidden',
              utaType: 'channel',
            },
            {
              colSize: 8,
              domo_field: 'status',
              domo_value: () => 'static',
              // form: 'status_id',
              name: 'status_id',
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 4,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },

            {
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: (crudStore) => crudStore.props.rootStore.apiStore.currentBrand.enable_fb,
              grid_link: 'facebook-campaigns',
              grid_order: 5,
              grid_value: (row) => row.facebook_id,
              id: 'facebook_id',
              label: 'facebook_id',
              name: 'facebook_id',
              permListAndView: 20,
              rules: 'integer',
              utaType: 'string',
              ns: (crudStore) => crudStore.storedData.facebook_id,
            },
            {
              grid: false,
              id: 'facebook_detail',
              label: 'facebook_detail',
              name: 'facebook_detail',
              rules: 'integer',
              utaType: 'string',
              ns: (crudStore) => crudStore.storedData.facebook_detail,
            },
            {
              grid: false,
              id: 'facebook_created_at',
              label: 'facebook_created_at',
              name: 'facebook_created_at',
              rules: 'date',
              utaType: 'date',
              ns: (crudStore) => crudStore.storedData.facebook_created_at,
            },
            {
              belongsTo: ['campaign_type', 'campaignTypes'],
              callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
              copyEditable: true,
              form: 'campaign_type_id',
              formField: (rootStore) => (<UccSelect
                core="campaign-type"
                fieldName="campaign_type_id"
                label={'Campaign Type'}
                form={rootStore.channel.model.crudStore.form}
                // menuItems={rootStore.apiStore.campaignTypes.map((ct) => rootStore.uiStore.makePrimeRegularOption(ct))}
                menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType)))}
                onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
              />),
              formOrder: 2,
              name: 'campaign_type_id',
              label: 'Campaign  Type',
              rules: 'required|integer',

              utaType: 'fk',
            },
            {
              colSize: 12,
              domo_field: 'campaign_type',
              domo_value: (crudStore) => abbrev(crudStore.storedData.campaign_type),
              grid: true,
              grid_order: 6,
              grid_value: (row) => property(row.campaign_type, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'campaign_type',
              label: 'Campaign Type',
              name: 'campaign_type',
              // options: (apiStore) => apiStore.campaignTypes;
              filter_placeholder: 'Type',
              ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
              ns_order: 4,
              options: (apiStore) => apiStore.campaignTypes,
              show: true,
              show_value: (row) => property(row.campaign_type, "name"),
              show_order: 4,
            },
            {
              colSize: 10,
              copyEditable: true,
              domo_field: 'campaign_name',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              form: 'name',
              formField: (rootStore) => (<UccInputText
                label="Description"
                core="name"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
                fieldName="name"
              />),
              formOrder: 7,
              grid: true,
              grid_order: 16,
              grid_value: (row) => row.name,
              grid_label: 'Description',
              filter_placeholder: 'Description',
              id: 'name',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              label: 'Description',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              ns_order: 7,
              utaType: 'name',
              rules: 'string',
              show: true,
              show_value: (row) => row.name,
              show_order: 7,
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 2,
              grid_type: 'namestring',
              id: 'namestring',
              filter: false,
              field: 'namestring',
              label: 'Namestring',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              colSize: 8,
              domo_field: 'objective',
              domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
              grid: true,
              grid_order: 7,
              grid_value: (row) => property(row.objective, 'name'),
              id: 'objective',
              label: 'Objective',
              filter: (dt, col) => multiSelectFilter(dt, col),
              name: 'objective',
              ns: (crudStore) => abbrev(crudStore.storedData.objective),
              ns_order: 5,
              rules: 'required',
              options: (apiStore) => apiStore.objectives,
              show: true,
              show_value: (row) => property(row.objective, "name"),
              show_order: 5,
            },
            {
              belongsTo: ['objective', 'objectives'],
              copyEditable: true,
              form: 'objective_id',
              formField: (rootStore) => (<UccSelect
                core="objective"
                fieldName="objective_id"
                label={'Objective'}
                form={rootStore.channel.model.crudStore.form}
                menuItems={rootStore.uiStore.makeObjectiveOptions()}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_id', event)}
              />),
              formOrder: 3,
              label: 'Objective',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              belongsTo: ['platform', 'platforms'],
              colSize: 8,
              copyEditable: true,
              form: 'platform_id',
              formField: (rootStore) => (<UccSelect
                core="platform"
                fieldName="platform_id"
                label="Platform"
                form={rootStore.channel.model.crudStore.form}
                menuItems={_.compact(rootStore.apiStore.platforms.map((platform) => rootStore.uiStore.makePrimeChannelSpecificOption(platform, 'channelPlatforms')))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
              />),
              formOrder: 4,
              label: 'Platform',
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.platform, "name"),
              show_order: 5,
              utaType: 'fk',
            },
            {
              domo_field: 'platform',
              domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
              grid: true,
              grid_order: 8,
              grid_value: (row) => property(row.platform, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.platforms,
              id: 'platform',
              label: 'Platform',
              name: 'platform',
              ns: (crudStore) => abbrev(crudStore.storedData.platform),
              ns_order: 6,
              rules: 'required',
            },
            {
              colSize: 10,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              grid: true,
              grid_order: 150,
              grid_value: (row) => property(row.user, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'created_by',
              label: 'Created By',
              name: 'created_by',
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.Users,
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
            {
              label: 'Facebook Push Date',
              show_value: (row) => crudStore.storedData.facebook_updated_at,
              show_order: 195,
              id: 'facebook_updated_at',
              name: 'facebook_updated_at',
              ns: (crudStore) => crudStore.storedData.facebook_updated_at,
            },
          ].concat(
            dateField(
              'start_date',
              'Start Date',
              'blank',
              false,
              100,
              130,
              'S.',
            ),
            dateField('end_date', 'End Date', 'blank', false, 110, 140, 'E.'),
          ),
        },

        //    ___      _ _____      _
        //   / _ \    | /  ___|    | |
        //  / /_\ \ __| \ `--.  ___| |_ ___
        //  |  _  |/ _` |`--. \/ _ \ __/ __|
        //  | | | | (_| /\__/ /  __/ |_\__ \
        //  \_| |_/\__,_\____/ \___|\__|___/

        {
          allFilterData: (apiStore) => apiStore['adSetsFilterOptions'],
          codename: 'SocAdSet',
          controller: 'social_ad_set',
          crud: (rootStore) => rootStore.newAdsetsCrud,
          domoNsType: 'SocialAdSet',
          filterOptionsCall: (apiStore) => (val) => apiStore['adSetsFilterOptions'] = val,
          endpoint: 'ad-sets',
          form: () => <GenericForm type="Adset" datePicker={2} numButtons={3} />,
          genericModelPromiseEndpoints: [
            'ad-types',
            'ad-type-channels',
            'campaign-types',
            'channel-genders',
            'channel-platforms',
            'channel-tactics',
            'channel-targetings',
            'devices',
            'genders',
            'geos',
            'languages',
            'objectives',
            'platforms',
            'social-campaigns',
            'statuses',
            'tactics',
            'tactic-targetings',
            'targetings',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.newAdsetsCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, social_campaign_id: root.paidSocial.SocCamp.crudStore.selectedRowIds, }; },
          name: 'AdSets',
          noDataText: (r) => r.paidSocial.SocCamp.crudStore.selectedRowIds.length > 0 ? ' for the selected Campaign(s)' : '',
          parentModel: 'social_campaign',
          parentModelCode: 'SocCamp',
          permCreateAndEdit: 10,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'ad-sets',
          send2domo: true,
          singular: 'adset',
          showFormat: 'definitionList',
          tabData: (apiStore) => apiStore.genericTabPromiseResults(),
          tabPromiseEndpoints: (rootStore, brand_param) => {
            const allFilter = _.merge(
              brand_param,
              rootStore.paidSocial.SocAdSet.modelFilter(rootStore),
            );
            return [
              rootStore.newCampaignsCrud.getTotal(brand_param),
              rootStore.newAdsetsCrud.getTotal(allFilter, ''),
            ];
          },
          tabDisabled: (r) => r.apiStore.adsetTabDisabled ? true : false,
          tabIndex: 1,
          tabLabel: (m) => {
            return m.crudStore.selectedRowIds.length > 0 ?
              `${m.name} (${m.crudStore.selectedRowIds.length} selected)` :
              m.name;
          },
          updateCallback: (root) => root.channel.SocAd.crudStore.adsetOptions = [],
          columns: [
            {
              colSize: 6,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 4,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },

            {
              domo_field: 'ns_type',
              domo_value: () => 'SocialAdSet',
              rules: 'required',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: () => 'group',
              utaType: 'group',
              rules: 'required',
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 2,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'channel',
              domo_value: () => 'PaidSocial',
              ns: () => 'hmm',
              name: 'channel',
              id: 'channel',
              utaType: 'channel',
              rules: 'required',
            },
            {
              colSize: 10,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              name: 'created_by',
              grid: true,
              grid_order: 180,
              id: 'created_by',
              label: 'Created By',
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid_value: (row) => property(row.user, 'name'),
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.Users,
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: () => 'group',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              ns_order: 1,
              utaType: 'brand',
            },
            {

              domo_field: 'brand_id',
              domo_value: (crudStore) => getRelationId(crudStore, 'brand'),
              name: 'brand_id',
              ns: () => 'brand_id',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'channel',
              domo_value: (crudStore) => crudStore.channel.abbrev,
              id: 'channel',
              name: 'channel',
              ns: () => 'store me',
              rules: 'required',
              utaType: 'channel',
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              domo_field: 'status',
              domo_value: () => 'static',
              // form: 'status_id',
              name: 'status_id',
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 5,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: (crudStore) => crudStore.props.rootStore.apiStore.currentBrand.enable_fb,
              grid_link: 'facebook-adsets',
              grid_order: 5,
              grid_value: (row) => row.facebook_id,
              id: 'facebook_id',
              label: 'facebook_id',
              name: 'facebook_id',
              permListAndView: 20,
              rules: 'integer',
              utaType: 'string',
              ns: (crudStore) => crudStore.storedData.facebook_id,
            },
            {
              grid: false,
              id: 'facebook_detail',
              label: 'facebook_detail',
              name: 'facebook_detail',
              rules: 'integer',
              utaType: 'string',
              ns: (crudStore) => crudStore.storedData.facebook_detail,
            },
            {
              grid: false,
              id: 'facebook_created_at',
              label: 'facebook_created_at',
              name: 'facebook_created_at',
              rules: 'date',
              utaType: 'date',
              ns: (crudStore) => crudStore.storedData.facebook_created_at,
            },
            {
              belongsTo: ['social_campaign', 'socialCampaigns'],
              form: 'social_campaign_id',
              label: 'Campaign',
              rules: 'required|integer',
              utaType: 'fk',
              copyEditable: false,
              formField: (rootStore) => (<UccSelect
                core="social_campaign"
                fieldName="social_campaign_id"
                label="Social Campaign"
                // disabled={true}
                disabled={rootStore.paidSocial?.SocCamp?.selectedRowIds?.length == 1 && rootStore.channel.model.crudStore.initialAction == 'bulk_create'}
                form={rootStore.channel.model.crudStore.form}
                menuItems={rootStore.channel.model.crudStore.makePrimeCampaignOptionsArray(_.sortBy(rootStore.apiStore.socialCampaigns, c => - c.namestring_id))}
                onChange={(event) => {
                  disableSubmitButton(rootStore, event);
                  rootStore.channel.model.crudStore.storeData('social_campaign_id', event);
                  if (rootStore.channel.model.crudStore.firstPage || rootStore.channel.model.crudStore.storedData['unique_attribute'] != 'social_campaign_id') {
                    rootStore.channel.model.crudStore.storeData('platform_id', property(property(rootStore.paidSocial.SocAdSet.crudStore.storedData['social_campaign'], 'platform'), 'id'));
                  }
                }
                }
              />),
              formOrder: 1,
              subType: 'inherit',
            },
            {
              name: 'social_campaign',
              ns: (crudStore) => 'PARENT'
            },
            {
              copyEditable: true,
              domo_field: 'campaign_name',
              domo_value: (crudStore) => property(crudStore.storedData.social_campaign, 'name'),
              grid: true,
              grid_order: 20,
              grid_value: (row) => `${property(row.social_campaign, 'name')} #${row.social_campaign.namestring_id}`,
              filter: (dt, col) => multiSelectFilter(dt, col),
              filter_label: (id, name) => generateFilterLabel(id, name),
              options: (apiStore) => apiStore.socialCampaigns,
              id: 'social_campaign',
              label: 'Campaign',
              name: 'campaign_name',
              ns: () => 'PARENT',
              utaType: 'integer',
              show: true,
              show_value: (row) => `${property(row.social_campaign, 'name')} #${row.social_campaign.namestring_id}`,
              show_order: 1,
            },
            {
              colSize: 14,
              grid: true,
              grid_order: 8,
              grid_value: (row) => property(row.social_campaign, 'namestring_string'),
              grid_type: 'namestring',
              grid_label: 'Campaign Namestring',
              id: 'a.b.namestring_string',
              label: 'Campaign Namestring',
              name: 'social_campaign_namestring',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|integer',
              utaType: 'integer',
            },
            {
              domo_field: 'campaign_type',
              domo_value: (crudStore) => parentAbbrev(crudStore.storedData.social_campaign, 'campaign_type'),
              id: 'campaign_type',
              name: 'campaign_type',
              ns: (crudStore) => parentAbbrev(crudStore.storedData.social_campaign, 'campaign_type'),
              ns_order: 2,
              options: (apiStore) => apiStore.campaignTypes,
              utaType: 'parent',
            },
            {
              domo_field: 'objective',
              domo_value: (crudStore) => parentAbbrev(crudStore.storedData.social_campaign, 'objective'),
              id: 'objective',
              name: 'objective',
              ns: (crudStore) => parentAbbrev(crudStore.storedData.social_campaign, 'objective'),
              ns_order: 3,
              utaType: 'parent',
            },
            {
              colSize: 10,
              copyEditable: true,
              domo_field: 'adset_name',
              domo_value: (crudStore) => crudStore.storedData.name,
              form: 'name',
              formField: (rootStore) => (
                <UccInputText
                  label="Description"
                  core="campaign_name"
                  form={rootStore.channel.model.crudStore.form}
                  onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
                  fieldName="name"
                />),
              formOrder: 2,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.name,
              id: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              label: 'Description',
              filter_placeholder: 'Description',
              filter: (dt, col) => inputTextFilter(dt, col),
              name: 'name',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              ns_order: 9,
              utaType: 'name',
              rules: 'string',
              show: true,
              show_value: (row) => row.name,
              show_order: 2,
            },
            {
              belongsTo: ['platform', 'platforms'],
              copyEditable: (rootStore) => { return !isDisabled(getPlatformList(rootStore.paidSocial.SocAdSet.crudStore.selectedRows)) },
              // copyEditable: true,
              form: 'platform_id',
              default: (rootStore) => property(property(rootStore.paidSocial.SocCamp.crudStore.selectedRow, 'platform'), 'id'),
              defaultType: 'platforms',
              formField: (rootStore) => (<UccSelect
                core="platform"
                fieldName="platform_id"
                label="Platform"
                form={rootStore.channel.model.crudStore.form}
                menuItems={rootStore.uiStore.makePlatformOptions(rootStore, ['bulk_edit', 'copy_edit'].includes(rootStore.paidSocial.SocAdSet.crudStore.toolbarAction) ? getPlatformList(rootStore.paidSocial.SocAdSet.crudStore.selectedRows) : property(rootStore.paidSocial.SocAdSet.crudStore.storedData['social_campaign'], 'platform'))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
                disabled={isDisabled(['bulk_edit', 'copy_edit'].includes(rootStore.paidSocial.SocAdSet.crudStore.toolbarAction) ? getPlatformList(rootStore.paidSocial.SocAdSet.crudStore.selectedRows) : property(rootStore.paidSocial.SocAdSet.crudStore.storedData['social_campaign'], 'platform'))}
              />),
              formOrder: 4,
              label: 'Platform',
              rules: 'required|integer',
              utaType: 'fk',
              show: true,
              show_value: (row) => property(row.platform, 'name'),
              show_order: 3,
            },
            {
              domo_field: 'platform',
              domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
              grid: true,
              default: (rootStore) => property(rootStore.paidSocial.SocCamp.crudStore.selectedRow, 'platform'),
              grid_order: 40,
              grid_value: (row) => property(row.platform, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.platforms,
              id: 'platform',
              label: 'Platform',
              name: 'platform',
              ns: (crudStore) => abbrev(crudStore.storedData.platform),
              ns_order: 4,
              rules: 'required',
              utaType: 'platforms',
            },
            {
              belongsTo: ['tactic', 'tactics'],
              form: 'tactic_id',
              formField: (rootStore) => (
                <UccSelect
                  core="tactic"
                  fieldName="tactic_id"
                  label="Tactic"
                  form={rootStore.channel.model.crudStore.form}
                  menuItems={_.compact(rootStore.apiStore.tactics.map((tactic) => rootStore.uiStore.makePrimeChannelSpecificOption(tactic, 'channelTactics')))}
                  onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
                />),
              formOrder: 5,
              label: 'Tactic',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              domo_field: 'tactic',
              domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
              grid: true,
              grid_order: 60,
              grid_value: (row) => property(row.tactic, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.tactics,
              id: 'tactic',
              label: 'Tactic',
              name: 'tactic',
              ns: (crudStore) => abbrev(crudStore.storedData.tactic),
              ns_order: 5,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.tactic, 'name'),
              show_order: 5,
            },
            {
              domo_field: 'targeting',
              domo_value: (crudStore) => NameString.targetingString(crudStore),
              form: 'social_ad_set_targetings_attributes',
              formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
              formOrder: 6,
              grid: true,
              grid_order: 80,
              id: 'targeting_ids',
              label: 'Targeting',
              multiple: [],
              name: 'social_ad_set_targetings_attributes',
              ns: (crudStore) => NameString.targetingString(crudStore),
              ns_order: 8,
              show: true,
              show_value: (row) => NameString.pivotShow(row, "social_ad_set_targetings"),
              show_order: 7,
              sortable: false,
              utaType: 'targeting_array',
            },
            {
              form: 'social_ad_set_targetings_attributes[].id',
            },
            {
              form: 'social_ad_set_targetings_attributes[].social_ad_set_id',
            },
            {
              form: 'social_ad_set_targetings_attributes[].targeting_id',
            },
            {
              form: 'social_ad_set_targetings_attributes[].extra',
            },
            {
              form: 'social_ad_set_targetings_attributes[]._destroy',
              default: 'false'
            },
            {
              form: 'social_ad_set_targetings_attributes[].changed',
              default: 'false'
            },
            {
              form: 'social_ad_set_targetings_attributes[].on',
            },
            {
              belongsTo: ['device', 'devices'],
              copyEditable: true,
              form: 'device_id',
              formField: (rootStore) => (
                <UccSelect
                  core="device"
                  fieldName="device_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Device"
                  menuItems={rootStore.apiStore.devices.map((device) => rootStore.uiStore.makePrimeRegularOption(device))}
                  onChange={(event) => rootStore.channel.model.crudStore.storeData('device_id', event)}
                />),
              formOrder: 7,
              label: 'Device',
              // multiple: [],
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              domo_field: 'device',
              domo_value: (crudStore) => abbrev(crudStore.storedData.device),
              grid: true,
              grid_order: 80,
              grid_value: (row) => property(row.device, 'name'),
              id: 'device',
              label: 'Device',
              name: 'device',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.devices,
              ns: (crudStore) => abbrev(crudStore.storedData.device),
              ns_order: 8,
              rules: 'required|integer',
              sortable: true,
              show: true,
              show_value: (row) => property(row.device, 'name'),
              show_order: 7,
            },
            {
              belongsTo: ['geo', 'geos'],
              copyEditable: true,
              form: 'geo_id',
              formField: (rootStore) => (<UccSelect
                core="geo"
                fieldName="geo_id"
                form={rootStore.channel.model.crudStore.form}
                label="Geo"
                // menuItems={rootStore.apiStore.geos.map((p) => ({ label: p.name, value: p.id }))}
                menuItems={rootStore.apiStore.geos.map((geo) => rootStore.uiStore.makePrimeRegularOption(geo))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('geo_id', event)}
              />),
              formOrder: 8,
              label: 'Geos',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 8,
              domo_field: 'geo',
              domo_value: (crudStore) => abbrev(crudStore.storedData.geo),
              grid: true,
              grid_order: 90,
              grid_value: (row) => property(row.geo, 'name'),
              id: 'geo',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.geos,
              label: 'Geo',
              name: 'geo',
              ns: (crudStore) => abbrev(crudStore.storedData.geo),
              ns_order: 10,
              rules: 'required|integer',
              sortable: true,
              show: true,
              show_value: (row) => property(row.geo, 'name'),
              show_order: 8,
            },
            {
              belongsTo: ['gender', 'genders'],
              copyEditable: true,
              form: 'gender_id',
              formField: (rootStore) => (<UccSelect
                core="gender"
                fieldName="gender_id"
                form={rootStore.channel.model.crudStore.form}
                label="Gender"
                menuItems={_.compact(rootStore.apiStore.genders.map(gender => rootStore.uiStore.makePrimeChannelSpecificOption(gender, 'channelGenders')))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('gender_id', event)}
              />
              ),
              formOrder: 9,
              label: 'Gender',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              domo_field: 'gender',
              domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
              grid: true,
              grid_order: 100,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.genders,
              grid_value: (row) => property(row.gender, 'name'),
              id: 'gender',
              label: 'Gender',
              name: 'gender',
              ns: (crudStore) => abbrev(crudStore.storedData.gender),
              ns_order: 12,
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.gender, 'name'),
              show_order: 9,
            },
            {
              belongsTo: ['language', 'languages'],
              copyEditable: true,
              form: 'language_id',
              formField: (rootStore) => (<UccSelect
                core="language"
                fieldName="language_id"
                form={rootStore.channel.model.crudStore.form}
                label="Language"
                menuItems={rootStore.apiStore.languages.map((language) => rootStore.uiStore.makePrimeRegularOption(language))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('language_id', event)}
              />),
              formOrder: 10,
              label: 'Language',
              rules: 'integer|required',
              utaType: 'fk',
            },
            {
              domo_field: 'language',
              domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
              grid: true,
              grid_order: 110,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.languages,
              grid_value: (row) => property(row.language, 'name'),
              id: 'language',
              label: 'Language',
              name: 'language',
              ns: (crudStore) => abbrev(crudStore.storedData.language),
              ns_order: 11,
              show: true,
              show_value: (row) => property(row.language, 'name'),
              show_order: 10,
            },
            {
              domo_field: 'suppression',
              domo_value: (crudStore) => crudStore.suppressionLabel(),
              form: 'suppression',
              grid: false,
              id: 'suppression',
              label: 'Suppression',
              name: 'suppression',
              ns: (crudStore) => crudStore.suppressionLabel(),
              ns_order: 6,
              show: true,
              show_value: (row) => row.suppression ? 'On' : 'Off',
              show_order: 6.5,
              type: 'checkbox',
              utaType: 'boolean',
            },
            //targeting fields
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ].concat(
            dateField('start_date', 'Start Date', 'curherit', true, 110, 130, 'S.'),
            dateField('end_date', 'End Date', 'blankherit', false, 120, 140, 'E.'),
          ),
        },

        //    _____       .___
        //   /  _  \    __| _/______
        //  /  /_\  \  / __ |/  ___/
        // /    |    \/ /_/ |\___ \
        // \____|__  /\____ /____  >
        //         \/      \/    \/

        {
          allFilterData: (apiStore) => apiStore['adsFilterOptions'],
          codename: 'SocAd',
          controller: 'social_ad',
          crud: (rootStore) => rootStore.adsCrud,
          domoNsType: 'SocialAd',
          endpoint: 'ads',
          filterOptionsCall: (apiStore) => (val) => apiStore['adsFilterOptions'] = val,
          form: () => <GenericForm type="Ad" datePicker={2} numButtons={3} />,
          genericModelPromiseEndpoints: [
            'ad-sets',
            'ad-types',
            'ad-type-channels',
            'campaign-types',
            'channel-targetings',
            'devices',
            'genders',
            'geos',
            'languages',
            'marketing-campaigns',
            'objectives',
            'platforms',
            'social-campaigns',
            'statuses',
            'tactics',
            'tactic-targetings',
            'targetings',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.newAdsCrud.getFilterOptions({ brand_id: brand_id, ...adset_param }, ''),
            apiStore.newAdsetsCrud.getAll({ brand_id: brand_id, ...adset_param }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          grandParentModel: 'social_campaign',
          modelFilter: (root) => _.merge({ social_adset_id: root.paidSocial.SocAdSet.crudStore.selectedRowIds, }, { social_campaign_id: root.paidSocial.SocCamp.crudStore.selectedRowIds, }, { brand_id: root.apiStore.currentBrandId }),
          name: 'Ads',
          noDataText: (r) => r.paidSocial.SocAdSet.crudStore.selectedRowIds.length > 0 ? ' for the selected AdSet(s)' : '',
          parentModel: 'social_ad_set',
          parentModelCode: 'SocAdSet',
          permCreateAndEdit: 10,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'ads',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'ad',
          tabData: (apiStore) => apiStore.genericTabPromiseResults(),
          tabPromiseEndpoints: (rootStore, brand_param) => {
            const allFilter = _.merge(
              brand_param,
              rootStore.paidSocial.SocAdSet.modelFilter(rootStore),
            );
            return [
              rootStore.newCampaignsCrud.getTotal(brand_param),
              rootStore.newAdsetsCrud.getTotal(allFilter, ''),
            ];
          },
          tabDisabled: (r) => (r.apiStore.adTabDisabled) ? true : false,
          tabIndex: 2,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          targeting: 'parent',
          columns: [
            {
              colSize: 6,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 4,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'SocialAd',
              rules: 'required',
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 3,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'utmstring',
              domo_value: (crudStore) => NameString.getAdUtmString(crudStore),
            },
            {
              form: 'utmstring_string',
              name: 'utmstring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'short_utm_string',
              domo_value: (crudStore) => NameString.getNewUtmString(crudStore),
            },
            {
              form: 'short_utm_string',
              name: 'short_utm_string',
              formOrder: 67,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              grid: true,
              grid_order: 180,
              grid_value: (row) => property(row.user, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'created_by',
              label: 'Created By',
              name: 'created_by',
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.Users,
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: () => 'group',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => getRelationId(crudStore, 'brand'),
              name: 'brand_id',
              ns: () => 'brand_id',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              ns_order: 1,
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'channel',
              domo_value: (crudStore) => crudStore.channel.abbrev,
              id: 'channel',
              name: 'channel',
              ns: (crudStore) => crudStore.channel.abbrev,
              rules: 'required',
              utaType: 'channel',
            },
            {
              colSize: 9,
              copyEditable: true,
              domo_field: 'campaign_name',
              domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'name'),
              form: 'campaign_name',
              grid: true,
              grid_order: 40,
              grid_value: (row) => `${property(row.social_ad_set.social_campaign, 'name')} #${property(row.social_ad_set.social_campaign, 'id')}`,
              grid_label: 'Campaign',
              filter_placeholder: 'Campaign',
              filter: (dt, col) => multiSelectFilter(dt, col),
              filter_label: (id, name) => generateFilterLabel(id, name),
              options: (apiStore) => apiStore.socialCampaigns,
              id: 'social_campaign_id',
              name: 'name',
              // filter: (dt, col) => inputTextFilter(dt, col),
              label: 'Description',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              // ns_order: 6,
              utaType: 'name',
              rules: 'string',
            },
            {
              copyEditable: true,
              domo_field: 'campaign_type',
              domo_value: (crudStore) => property(property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'campaign_type'), 'name'),
              id: 'campaign_type',
              name: 'campaign_type',
              ns: (crudStore) => abbrev(property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'campaign_type')),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.campaignTypes,
              ns_order: 2,
              utaType: 'grandParent',
              rules: 'required',
            },
            {
              copyEditable: true,
              domo_field: 'objective',
              domo_value: (crudStore) => property(property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'objective'), 'name'),
              id: 'objective',
              name: 'objective',
              ns: (crudStore) => abbrev(property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'objective')),
              ns_order: 3,
              utaType: 'grandParent',
              rules: 'required',
            },
            {
              belongsTo: ['social_ad_set', 'adSets'],
              form: 'social_ad_set_id',
              copyEditable: false,
              formField: (rootStore) => (<UccSelect
                core="social_ad_set"
                fieldName="social_ad_set_id"
                form={rootStore.channel.model.crudStore.form}
                label="Ad Set"
                menuItems={rootStore.channel.model.crudStore.makePrimeAdsetOptions(_.sortBy(rootStore.apiStore.adSets, as => - as.namestring_id))}
                onChange={(event) => {
                  disableSubmitButton(rootStore, event)
                  isDisabled(property(rootStore.paidSocial.SocCamp.crudStore.selectedRow, 'platform'))
                  rootStore.channel.model.crudStore.storeData('social_ad_set_id', event)
                }
                }
              />),
              formOrder: 1,
              label: 'AdSet',
              rules: 'required|integer',
              utaType: 'fk',
              subType: 'inherit',
            },
            {
              domo_field: 'adset_name',
              domo_value: (crudStore) => HeadedCamelCase(property(property(crudStore.storedData, 'social_ad_set'), 'name')),
            },
            {
              colSize: 14,
              grid: true,
              grid_order: 25,
              grid_value: (row) => property(row.social_ad_set.social_campaign, 'namestring_string'),
              grid_type: 'namestring',
              grid_label: 'Campaign Namestring',
              grand_parent_field: true,
              id: 'a.b.namestring_string',
              label: 'Campaign Namestring',
              name: 'social_campaign_namestring',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|integer',
              utaType: 'integer',
            },
            {
              grid: true,
              grid_order: 50,
              grid_value: (row) => `${property(row.social_ad_set, 'name')} #${property(row.social_ad_set, 'id')}`,
              id: 'social_ad_set',
              label: 'Ad Set',
              name: 'social_ad_set',
              filter: (dt, col) => multiSelectFilter(dt, col),
              filter_label: (id, name) => generateFilterLabel(id, name),
              options: (apiStore) => apiStore.adSets,
              ns: (crudStore) => HeadedCamelCase(property(crudStore.storedData.social_ad_set, 'name')),
              ns_order: 4,
              rules: 'required|integer',
              show: true,
              show_value: (row) => `${property(row.social_ad_set, 'name')} #${property(row.social_ad_set, 'id')}`,
              show_order: 50,
              utaType: 'integer',
            },
            {
              colSize: 14,
              grid: true,
              grid_order: 28,
              grid_value: (row) => property(row.social_ad_set, 'namestring_string'),
              grid_type: 'namestring',
              grid_label: 'Adset Namestring',
              id: 'namestring_string',
              label: 'Adset Namestring',
              name: 'social_adset_namestring',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|integer',
              utaType: 'integer',
            },
            {
              domo_field: 'tactic',
              domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'tactic'), 'name'),
              id: 'tactic',
              name: 'tactic',
              ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'tactic')),
              ns_order: 5,
              utaType: 'parent',
              rules: 'required',
            },
            {
              domo_field: 'device',
              domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'device'), 'name'),
              id: 'device',
              name: 'device',
              utaType: 'parent',
              rules: 'required',
            },
            {
              domo_field: 'suppression',
              domo_value: (crudStore) => property(crudStore.storedData.social_ad_set, 'suppression') ? 'SupOn' : 'SupOff',
              id: 'suppression',
              name: 'suppression',
              ns: (crudStore) => property(crudStore.storedData.social_ad_set, 'suppression') ? 'SupOn' : 'SupOff',
              ns_order: 6,
              utaType: 'parent',
            },
            {
              domo_field: 'targeting',
              domo_value: (crudStore) => NameString.targetingString(crudStore),
              id: 'targeting_ids',
              name: 'targeting',
              ns: (crudStore) => NameString.targetingString(crudStore),
              ns_order: 7,
              utaType: 'targeting',
              rules: 'required',
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              domo_field: 'status',
              domo_value: () => 'Inactive',
              // form: 'status_id',
              name: 'status_id',
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 20,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: (crudStore) => crudStore.props.rootStore.apiStore.currentBrand.enable_fb,
              grid_link: 'facebook-ads',
              grid_order: 5,
              grid_value: (row) => row.facebook_id,
              id: 'facebook_id',
              label: 'facebook_id',
              name: 'facebook_id',
              permListAndView: 20,
              rules: 'integer',
              utaType: 'string',
              ns: (crudStore) => crudStore.storedData.facebook_id,
            },
            {
              grid: false,
              id: 'facebook_detail',
              label: 'facebook_detail',
              name: 'facebook_detail',
              rules: 'integer',
              utaType: 'string',
              ns: (crudStore) => crudStore.storedData.facebook_detail,
            },
            {
              grid: false,
              id: 'facebook_created_at',
              label: 'facebook_created_at',
              name: 'facebook_created_at',
              rules: 'date',
              utaType: 'date',
              ns: (crudStore) => crudStore.storedData.facebook_created_at,
            },
            {
              domo_field: 'ad_name',
              domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
              grid: true,
              grid_order: 60,
              grid_value: (row) => property(row.marketing_campaign, 'name'),
              id: 'marketing_campaign_id',
              label: 'Marketing Campaign',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.marketingCampaigns,
              name: 'marketing_campaign_id',
              ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
              ns_order: 8,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.marketing_campaign, 'name'),
              show_order: 60,
              utaType: 'fk',
            },
            {
              belongsTo: ['marketing_campaign', 'marketingCampaigns'],
              form: 'marketing_campaign_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="marketing-campaign"
                fieldName="marketing_campaign_id"
                form={rootStore.channel.model.crudStore.form}
                label="Marketing Campaign"
                menuItems={_.compact(rootStore.apiStore.marketingCampaigns.map(marketingCampaign => rootStore.uiStore.makePrimeRegularOption(marketingCampaign)))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
              />),
              formOrder: 2,
              label: 'Marketing Campaign',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10,
              grid: true,
              grid_order: 55,
              grid_value: (row) => row.creative_name,
              filter: (dt, col) => inputTextFilter(dt, col),
              id: 'creative_name',
              label: 'Description',
              name: 'creative_name',
              rules: 'string',
              utaType: 'name',
            },

            {
              form: 'brand_url',
              copyEditable: true,
              formField: (rootStore) => (
                <UccInputText
                  core="brand-url"
                  fieldName="brand_url"
                  form={rootStore.channel.model.crudStore.form}
                  label="Brand Url"
                  onInput={(event) => rootStore.channel.model.crudStore.storeData('brand_url', event)}
                />),
              formOrder: 5,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 80,
              grid_value: (row) => row.brand_url,
              id: 'brand_url',
              label: 'Brand Url',
              name: 'brand_url',
              ns: () => 'stored',
              rules: 'string',
              show: true,
              show_value: (row) => row.brand_url,
              show_order: 80,
              utaType: 'text',
            },
            {
              domo_field: 'ad_type',
              domo_value: (crudStore) => property(crudStore.storedData.ad_type, 'name'),
              grid: true,
              grid_order: 70,
              grid_value: (row) => property(row.ad_type, 'name'),
              id: 'ad_type',
              label: 'Ad Type',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.adTypes,
              name: 'ad_type',
              ns: (crudStore) => abbrev(crudStore.storedData.ad_type),
              ns_order: 9,
              show: true,
              show_value: (row) => property(row.ad_type, 'name'),
              show_order: 70,
              rules: 'required',
            },
            {
              belongsTo: ['ad_type', 'adTypes'],
              form: 'ad_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="ad_type"
                fieldName="ad_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Ad Type"
                menuItems={_.compact(rootStore.apiStore.adTypes.map((adType) => rootStore.uiStore.makePrimeChannelSpecificOption(adType, 'adTypeChannels')))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('ad_type_id', event)}
              />),
              formOrder: 3,
              label: 'Ad Type',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
            {
              domo_field: 'platform',
              domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'platform'), 'name'),
              id: 'platform',
              name: 'platform',
              ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'platform')),
              ns_order: 10,
              rules: 'required',
              utaType: 'platforms',
            },
            {
              domo_field: 'gender',
              domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'gender'), 'name'),
              id: 'gender',
              name: 'gender',
              ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'gender')),
              ns_order: 11,
              rules: 'required',
              utaType: 'parent',
            },
            {
              domo_field: 'geo',
              domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'geo'), 'name'),
              id: 'geo',
              name: 'geo',
              ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'geo')),
              ns_order: 12,
              utaType: 'parent',
              rules: 'required',
            },
            {
              domo_field: 'language',
              domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'language'), 'name'),
              id: 'language',
              name: 'language',
              ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'language')),
              ns_order: 13,
              utaType: 'parent',
            },
          ].concat(
            dateField('start_date', 'Start Date', 'curherit', true, 140, 14, 'S.'),
            dateField('end_date', 'End Date', 'blankherit', false, 150, 15, 'E.'),
          ),
        },
      ],
    },
    //////////////////////////////////////////////////
    // ______  _________ _______  _______  _        _______
    // (  __  \ \__   __/(  ____ \(  ____ )( \      (  ___  )|\     /|
    // | (  \  )   ) (   | (    \/| (    )|| (      | (   ) |( \   / )
    // | |   ) |   | |   | (_____ | (____)|| |      | (___) | \ (_) /
    // | |   | |   | |   (_____  )|  _____)| |      |  ___  |  \   /
    // | |   ) |   | |         ) || (      | |      | (   ) |   ) (
    // | (__/  )___) (___/\____) || )      | (____/\| )   ( |   | |
    // (______/ \_______/\_______)|/       (_______/|/     \|   \_/

    {
      name: 'Display',
      endpoint: 'display',
      codename: 'display',
      abbrev: 'Display',
      icon: 'Display',
      id: 2,
      showViewIcon: true,
      models: [].concat(displayPlacementModelMetadata(2, 0))
        .concat(displayAdModelMetadata(2, 1))
        .concat(displayCreativeModelMetadata(2, 2))
        .concat(displayPackageModelMetadata(2, 3)),
    },


    //   ___                                                     _   _      
    //   / _ \_ __ ___   __ _ _ __ __ _ _ __ ___  _ __ ___   __ _| |_(_) ___ 
    //  / /_)/ '__/ _ \ / _` | '__/ _` | '_ ` _ \| '_ ` _ \ / _` | __| |/ __|
    // / ___/| | | (_) | (_| | | | (_| | | | | | | | | | | | (_| | |_| | (__ 
    // \/    |_|  \___/ \__, |_|  \__,_|_| |_| |_|_| |_| |_|\__,_|\__|_|\___|
    //                  |___/                                                

    {
      name: 'Programmatic',
      endpoint: 'programmatic',
      codename: 'programmatic',
      abbrev: 'Programmatic',
      icon: 'Programmatic',
      id: 5,
      showViewIcon: true,
      models: [
        {

          // ,------.                           ,-----.                                 
          // |  .--. ',--.--. ,---.  ,---.     '  .--./ ,--,--.,--,--,--. ,---.  ,---.  
          // |  '--' ||  .--'| .-. || .-. |    |  |    ' ,-.  ||        || .-. |(  .-'  
          // |  | --' |  |   ' '-' '' '-' '    '  '--'\\ '-'  ||  |  |  || '-' '.-'  `) 
          // `--'     `--'    `---' .`-  /      `-----' `--`--'`--`--`--'|  |-' `----'  
          //                        `---'                                `--'           

          allFilterData: (apiStore) => apiStore['programmaticCampaignsFilterOptions'],
          codename: 'ProgCamp',
          controller: 'programmatic_campaign',
          crud: (rootStore) => rootStore.programmaticCampaignsCrud,
          domoNsType: 'ProgrammaticCampaign',
          endpoint: 'programmatic-campaigns',
          filterOptionsCall: (apiStore) => (val) => apiStore['programmaticCampaignsFilterOptions'] = val, form: () => <GenericForm type="Campaign" datePicker={2} numButtons={3} />,
          genericModelPromiseEndpoints: ['channels', 'channel-genders', 'channel-platforms', 'brands', 'devices', 'display-types', 'genders', 'geos', 'groups', 'languages', 'marketing-campaigns', 'platforms', 'statuses'],
          modelPromiseEndpoints: (apiStore, brand_id) => ([

            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
            apiStore.programmaticCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
          name: 'Campaigns',
          noDataText: (root) => '',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          route: 'campaigns',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'campaign',
          tabDisabled: () => false,
          tabIndex: 0,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 6,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 13,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 10,
              grid_type: 'namestring',
              filter: false,
              id: 'namestring',
              filter: false,
              filterField: 'namestring',
              label: 'Namestring',
              rules: 'required'
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'ProgrammaticCampaign',
              rules: 'required',
            },
            {
              domo_field: 'channel',
              domo_value: (crudStore) => abbrev(crudStore.channel),
              ns: () => 'hmm',
              name: 'channel',
              id: 'channel',
              utaType: 'channel',
              rules: 'required',
            },
            {
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              grid: true,
              grid_order: 180,
              label: 'Created By',
              grid_value: (row) => property(row.user, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'created_by',
              name: 'created_by',
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.createdBy
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              domo_field: 'status',
              domo_value: () => 'Inactive',
              // form: 'status_id',
              name: 'status_id',
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 30,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              form: 'group_id',
              type: 'hidden',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              label: 'Group',
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              // ns_order: 10,
              rules: 'required',
              utaType: 'group',
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              label: 'Brand',
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              // ns_order: 20,
              rules: 'required',
              utaType: 'brand',
            },
            {
              belongsTo: ['platform', 'platforms'],
              form: 'platform_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="platform"
                fieldName="platform_id"
                label="Platform"
                form={rootStore.channel.model.crudStore.form}
                menuItems={_.compact(rootStore.apiStore.platforms.map((platform) => rootStore.uiStore.makePrimeChannelSpecificOption(platform, 'channelPlatforms')))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
              />),
              formOrder: 35,
              label: 'Platform',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10,
              domo_field: 'platform',
              domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
              grid: true,
              grid_order: 35,
              grid_value: (row) => property(row.platform, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.platforms,
              id: 'platform',
              label: 'Platform',
              name: 'platform',
              ns: (crudStore) => abbrev(crudStore.storedData.platform),
              ns_order: 35,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.platform, 'name'),
              show_order: 35,

            },
            {
              colSize: 13,
              domo_field: 'campaign_name',
              domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
              grid: true,
              grid_order: 40,
              grid_value: (row) => property(row.marketing_campaign, 'name'),
              id: 'marketing_campaign_id',
              label: 'Marketing Campaign',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.marketingCampaigns,
              name: 'marketing_campaign_id',
              ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
              ns_order: 40,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.marketing_campaign, 'name'),
              show_order: 40,
              utaType: 'fk',
            },
            {
              belongsTo: ['marketing_campaign', 'marketingCampaigns'],
              form: 'marketing_campaign_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="marketing-campaign"
                fieldName="marketing_campaign_id"
                form={rootStore.channel.model.crudStore.form}
                label="Marketing Campaign"
                menuItems={_.compact(rootStore.apiStore.marketingCampaigns.map(marketingCampaign => rootStore.uiStore.makePrimeRegularOption(marketingCampaign)))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
              />),
              formOrder: 40,
              label: 'Marketing Campaign',
              name: 'marketing_campaign_id',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              belongsTo: ['display_types', 'displayTypes'],
              form: 'display_type_ids',
              copyEditable: true,
              formField: (rootStore) => (<UccMultiSelect
                core="display-types"
                fieldName="display_type_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Display Types"
                menuItems={rootStore.apiStore.displayTypes.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('display_type_ids', event)}
              />),
              formOrder: 50,
              label: 'Display Type',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              domo_field: 'display_types',
              domo_value: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.display_types.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.displayTypes,
              id: 'display_types',
              label: 'Display Types',
              name: 'display_types',
              multiple: [],
              ns: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
              ns_order: 50,
              rules: 'array',
              show: true,
              show_value: (row) => row.display_types.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },

            {
              belongsTo: ['device', 'devices'],
              form: 'device_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="device"
                fieldName="device_id"
                form={rootStore.channel.model.crudStore.form}
                label="Device"
                menuItems={_.compact(rootStore.apiStore.devices.map(device => rootStore.uiStore.makePrimeOption(device)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('device_id', event)}
              />),
              formOrder: 60,
              label: 'Device',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.device, 'name'),
              show_order: 60,
              utaType: 'fk',
            },
            {
              domo_field: 'device',
              domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
              grid: true,
              grid_order: 60,
              grid_value: (row) => property(row.device, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.devices,
              id: 'device',
              label: 'Device',
              name: 'device',
              // ns: (crudStore) => abbrev(crudStore.storedData.device),
              // ns_order: 60,
              rules: 'integer',
            },
            {
              belongsTo: ['geo', 'geos'],
              form: 'geo_id',
              copyEditable: true,
              formField: (rootStore) => (
                <UccSelect
                  core="geo"
                  fieldName="geo_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Geo"
                  menuItems={_.compact(rootStore.apiStore.geos.map(geo => rootStore.uiStore.makePrimeOption(geo)))}
                  onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
                />
              ),
              formOrder: 70,
              label: 'Geo',
              show: true,
              show_value: (row) => property(row.geo, 'name'),
              show_order: 70,
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 8,
              domo_field: 'geo',
              domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
              grid: true,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.geos,
              grid_order: 70,
              grid_value: (row) => property(row.geo, 'name'),
              id: 'geo',
              label: 'Geo',
              name: 'geo',
              // ns: (crudStore) => abbrev(crudStore.storedData.geo),
              // ns_order: 70,
              rules: 'integer',
            },
            {
              colSize: 15,
              domo_field: 'region',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.region),
              form: 'region',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="region"
                fieldName="region"
                form={rootStore.channel.model.crudStore.form}
                label="Region"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
              />),
              formOrder: 80,
              grid: true,
              grid_order: 80,
              grid_value: (row) => row.region,
              id: 'region',
              name: 'region',
              // ns: (crudStore) => crudStore.storedData.region,
              // ns_order: 80,
              label: 'Region',
              utaType: 'string',
              rules: ['string', abbrev_regex],
              filter: (dt, col) => inputTextFilter(dt, col),
              show: true,
              show_value: (row) => row.region,
              show_order: 80,
            },
            {
              belongsTo: ['language', 'languages'],
              form: 'language_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="language"
                fieldName="language_id"
                form={rootStore.channel.model.crudStore.form}
                label="Language"
                menuItems={_.compact(rootStore.apiStore.languages.map(language => rootStore.uiStore.makePrimeRegularOption(language)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('language_id', event)}
              />),
              formOrder: 90,
              label: 'Language',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.language, 'name'),
              show_order: 90,
              rules: 'integer',
              utaType: 'fk',
            },
            {
              domo_field: 'language',
              domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
              grid: true,
              grid_order: 90,
              grid_value: (row) => property(row.language, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.languages,
              id: 'language',
              label: 'Language',
              name: 'language',
              // ns: (crudStore) => abbrev(crudStore.storedData.language),
              // ns_order: 90,
              rules: 'integer',
            },
            {
              belongsTo: ['gender', 'genders'],
              form: 'gender_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="gender"
                fieldName="gender_id"
                form={rootStore.channel.model.crudStore.form}
                label="Gender"
                menuItems={_.compact(rootStore.apiStore.genders.map((gender) => rootStore.uiStore.makePrimeChannelSpecificOption(gender, 'channelGenders')))}
                onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
              />),
              formOrder: 100,
              label: 'Gender',
              show: true,
              show_value: (row) => property(row.gender, 'name'),
              show_order: 100,
              rules: 'integer',
              utaType: 'fk',
            },
            {
              domo_field: 'gender',
              domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
              grid: true,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.genders,
              grid_order: 100,
              grid_value: (row) => property(row.gender, 'name'),
              id: 'gender',
              label: 'Gender',
              name: 'gender',
              // ns: (crudStore) => abbrev(crudStore.storedData.gender),
              // ns_order: 100,
              rules: 'integer',
            },

            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ].concat(
            dateField('start_date', 'Start Date', 'blank', true, 110, 130, 'S.', 130),
            dateField('end_date', 'End Date', 'blank', false, 120, 140, 'E.', 140),
          ),
        },


        {
          //  ____  ____   __    ___        __  __  
          // (  _ \(  _ \ /  \  / __)      (  )/  \ 
          //  ) __/ )   /(  O )( (_ \       )((  O )
          // (__)  (__\_) \__/  \___/      (__)\__/ 

          allFilterData: (apiStore) => apiStore['programmaticInsertionOrdersFilterOptions'],
          codename: 'ProgIO',
          controller: 'programmatic_insertion_order',
          crud: (rootStore) => rootStore.programmaticInsertionOrdersCrud,
          domoNsType: 'ProgrammaticInsertionOrder',
          endpoint: 'programmatic-insertion-orders',
          filterOptionsCall: 'setProgrammaticInsertionOrdersFilterOptions',
          form: () => <GenericForm type="Insertion Order" datePicker={2} numButtons={3} />,
          genericModelPromiseEndpoints: ['campaign-types', 'campaign-type-channels', 'channels', 'channel-genders', 'channel-platforms', 'brands', 'devices', 'display-types', 'genders', 'geos', 'groups', 'languages', 'marketing-campaigns', 'optimization-goals', 'platforms', 'statuses'],
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
            apiStore.programmaticInsertionOrdersCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
          name: 'Insertion Orders',
          noDataText: (root) => '',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          route: 'insertion-orders',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'insertion order',
          tabDisabled: () => false,
          tabIndex: 1,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 6,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 12,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 10,
              grid_type: 'namestring',
              filter: false,
              id: 'namestring',
              filter: false,
              filterField: 'namestring',
              label: 'Namestring',
              rules: 'required'
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'ProgrammaticInsertionOrder',
              rules: 'required',
            },
            {
              domo_field: 'channel',
              domo_value: (crudStore) => abbrev(crudStore.channel),
              ns: () => 'hmm',
              name: 'channel',
              id: 'channel',
              utaType: 'channel',
              rules: 'required',
            },
            {
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              grid: true,
              grid_order: 180,
              label: 'Created By',
              grid_value: (row) => property(row.user, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'created_by',
              name: 'created_by',
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.createdBy
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              domo_field: 'status',
              domo_value: () => 'Inactive',
              // form: 'status_id',
              name: 'status_id',
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 22,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              form: 'group_id',
              type: 'hidden',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              label: 'Group',
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              // ns_order: 10,
              rules: 'required',
              utaType: 'group',
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              label: 'Brand',
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              // ns_order: 20,
              rules: 'required',
              utaType: 'brand',
            },

            {
              belongsTo: ['platform', 'platforms'],
              form: 'platform_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="platform"
                fieldName="platform_id"
                label="Platform"
                form={rootStore.channel.model.crudStore.form}
                menuItems={_.compact(rootStore.apiStore.platforms.map((platform) => rootStore.uiStore.makePrimeChannelSpecificOption(platform, 'channelPlatforms')))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
              />),
              formOrder: 20,
              label: 'Platform',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10,
              domo_field: 'platform',
              domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
              grid: true,
              grid_order: 20,
              grid_value: (row) => property(row.platform, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.platforms,
              id: 'platform',
              label: 'Platform',
              name: 'platform',
              ns: (crudStore) => abbrev(crudStore.storedData.platform),
              ns_order: 20,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.platform, 'name'),
              show_order: 20,

            },

            {
              colSize: 13,
              domo_field: 'campaign_name',
              domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
              grid: true,
              grid_order: 25,
              grid_value: (row) => property(row.marketing_campaign, 'name'),
              id: 'marketing_campaign_id',
              label: 'Marketing Campaign',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.marketingCampaigns,
              name: 'marketing_campaign_id',
              // ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
              // ns_order: 10,
              rules: '',
              show: true,
              show_value: (row) => property(row.marketing_campaign, 'name'),
              show_order: 25,
              utaType: 'fk',
            },
            {
              belongsTo: ['marketing_campaign', 'marketingCampaigns'],
              form: 'marketing_campaign_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="marketing-campaign"
                fieldName="marketing_campaign_id"
                form={rootStore.channel.model.crudStore.form}
                label="Marketing Campaign"
                menuItems={_.compact(rootStore.apiStore.marketingCampaigns.map(marketingCampaign => rootStore.uiStore.makePrimeRegularOption(marketingCampaign)))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
              />),
              formOrder: 25,
              label: 'Marketing Campaign',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              belongsTo: ['campaign_type', 'campaignTypes'],
              callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
              copyEditable: true,
              form: 'campaign_type_id',
              formField: (rootStore) => (<UccSelect
                core="campaign-type"
                fieldName="campaign_type_id"
                label={'Campaign Type'}
                form={rootStore.channel.model.crudStore.form}
                menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campType) => rootStore.uiStore.makePrimeChannelSpecificOption(campType, 'campaignTypeChannels')))}
                // menuItems={rootStore.apiStore.campaignTypes.map((ct) => rootStore.uiStore.makePrimeRegularOption(ct))}
                // menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType)))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('campaign_type_id', event)}
              />),
              formOrder: 30,
              name: 'campaign_type_id',
              label: 'Campaign  Type',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 12,
              domo_field: 'campaign_type',
              domo_value: (crudStore) => abbrev(crudStore.storedData.campaign_type),
              grid: true,
              grid_order: 30,
              grid_value: (row) => property(row.campaign_type, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'campaign_type',
              label: 'Campaign Type',
              name: 'campaign_type',
              // options: (apiStore) => apiStore.campaignTypes;
              filter_placeholder: 'Type',
              ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
              ns_order: 30,
              options: (apiStore) => apiStore.campaignTypes,
              show: true,
              show_value: (row) => property(row.campaign_type, "name"),
              show_order: 30,
            },
            {
              belongsTo: ['display_types', 'displayTypes'],
              form: 'display_type_ids',
              copyEditable: true,
              formField: (rootStore) => (<UccMultiSelect
                core="display-types"
                fieldName="display_type_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Display Types"
                menuItems={rootStore.apiStore.displayTypes.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('display_type_ids', event)}
              />),
              formOrder: 50,
              label: 'Display Type',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              domo_field: 'display_types',
              domo_value: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.display_types.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.displayTypes,
              id: 'display_types',
              label: 'Display Types',
              multiple: [],
              name: 'display_types',
              ns: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
              ns_order: 50,
              rules: 'array',
              show: true,
              show_value: (row) => row.display_types.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              belongsTo: ['optimization_goal', 'optimizationGoals'],
              form: 'optimization_goal_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="optimization-goal"
                fieldName="optimization_goal_id"
                form={rootStore.channel.model.crudStore.form}
                label="Optimization Goal"
                menuItems={_.compact(rootStore.apiStore.optimizationGoals.map(optimization_goal => rootStore.uiStore.makePrimeOption(optimization_goal)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('optimization_goal_id', event)}
              />),
              formOrder: 60,
              label: 'Optimization Goal',
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.optimization_goal, 'name'),
              show_order: 60,
              utaType: 'fk',
            },
            {
              domo_field: 'optimization_goal',
              domo_value: (crudStore) => property(crudStore.storedData.optimization_goal, 'name'),
              grid: true,
              grid_order: 60,
              grid_value: (row) => property(row.optimization_goal, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.optimization_goals,
              id: 'optimization_goal',
              label: 'Optimization Goal',
              name: 'optimization_goal',
              ns: (crudStore) => abbrev(crudStore.storedData.optimization_goal),
              ns_order: 60,
              rules: 'required|integer',
            },

            {
              belongsTo: ['device', 'devices'],
              form: 'device_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="device"
                fieldName="device_id"
                form={rootStore.channel.model.crudStore.form}
                label="Device"
                menuItems={_.compact(rootStore.apiStore.devices.map(device => rootStore.uiStore.makePrimeOption(device)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('device_id', event)}
              />),
              formOrder: 65,
              label: 'Device',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.device, 'name'),
              show_order: 65,
              utaType: 'fk',
            },
            {
              domo_field: 'device',
              domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
              grid: true,
              grid_order: 65,
              grid_value: (row) => property(row.device, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.devices,
              id: 'device',
              label: 'Device',
              name: 'device',
              ns: (crudStore) => abbrev(crudStore.storedData.device),
              ns_order: 65,
              rules: 'integer',
            },
            {
              belongsTo: ['geo', 'geos'],
              form: 'geo_id',
              copyEditable: true,
              formField: (rootStore) => (
                <UccSelect
                  core="geo"
                  fieldName="geo_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Geo"
                  menuItems={_.compact(rootStore.apiStore.geos.map(geo => rootStore.uiStore.makePrimeOption(geo)))}
                  onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
                />
              ),
              formOrder: 70,
              label: 'Geo',
              show: true,
              show_value: (row) => property(row.geo, 'name'),
              show_order: 70,
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 8,
              domo_field: 'geo',
              domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
              grid: true,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.geos,
              grid_order: 70,
              grid_value: (row) => property(row.geo, 'name'),
              id: 'geo',
              label: 'Geo',
              name: 'geo',
              ns: (crudStore) => abbrev(crudStore.storedData.geo),
              ns_order: 70,
              rules: 'integer',
            },
            {
              colSize: 15,
              domo_field: 'region',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.region),
              form: 'region',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="region"
                fieldName="region"
                form={rootStore.channel.model.crudStore.form}
                label="Region"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
              />),
              formOrder: 80,
              grid: true,
              grid_order: 80,
              grid_value: (row) => row.region,
              id: 'region',
              name: 'region',
              ns: (crudStore) => crudStore.storedData.region,
              ns_order: 80,
              label: 'Region',
              utaType: 'string',
              rules: ['string', abbrev_regex],
              filter: (dt, col) => inputTextFilter(dt, col),
              show: true,
              show_value: (row) => row.region,
              show_order: 80,
            },
            {
              belongsTo: ['language', 'languages'],
              form: 'language_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="language"
                fieldName="language_id"
                form={rootStore.channel.model.crudStore.form}
                label="Language"
                menuItems={_.compact(rootStore.apiStore.languages.map(language => rootStore.uiStore.makePrimeRegularOption(language)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('language_id', event)}
              />),
              formOrder: 90,
              label: 'Language',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.language, 'name'),
              show_order: 90,
              rules: 'integer',
              utaType: 'fk',
            },
            {
              domo_field: 'language',
              domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
              grid: true,
              grid_order: 90,
              grid_value: (row) => property(row.language, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.languages,
              id: 'language',
              label: 'Language',
              name: 'language',
              // ns: (crudStore) => abbrev(crudStore.storedData.language),
              // ns_order: 90,
              rules: 'integer',
            },
            {
              belongsTo: ['gender', 'genders'],
              form: 'gender_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="gender"
                fieldName="gender_id"
                form={rootStore.channel.model.crudStore.form}
                label="Gender"
                menuItems={_.compact(rootStore.apiStore.genders.map((gender) => rootStore.uiStore.makePrimeChannelSpecificOption(gender, 'channelGenders')))}
                onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
              />),
              formOrder: 100,
              label: 'Gender',
              show: true,
              show_value: (row) => property(row.gender, 'name'),
              show_order: 100,
              rules: 'integer',
              utaType: 'fk',
            },
            {
              domo_field: 'gender',
              domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
              grid: true,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.genders,
              grid_order: 100,
              grid_value: (row) => property(row.gender, 'name'),
              id: 'gender',
              label: 'Gender',
              name: 'gender',
              ns: (crudStore) => abbrev(crudStore.storedData.gender),
              ns_order: 100,
              rules: 'integer',
            },

            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ].concat(
            dateField('start_date', 'Start Date', 'blank', true, 110, 130, 'dns', 130),
            dateField('end_date', 'End Date', 'blank', false, 120, 140, 'dns', 140),
          ),
        },

        {

          //    _   _   _   _     _   _   _   _     _   _   _   _   _  
          //   / \ / \ / \ / \   / \ / \ / \ / \   / \ / \ / \ / \ / \ 
          //  ( P | r | o | g ) ( L | i | n | e ) ( I | t | e | m | s )
          //   \_/ \_/ \_/ \_/   \_/ \_/ \_/ \_/   \_/ \_/ \_/ \_/ \_/ 

          allFilterData: (apiStore) => apiStore['programmaticLineItemsFilterOptions'],
          codename: 'ProgrammaticLineItem',
          controller: 'programmatic_line_item',
          crud: (rootStore) => rootStore.programmaticLineItemsCrud,
          domoNsType: 'ProgrammaticLineItem',
          dontDisplayDate: true,
          endpoint: 'programmatic-line-items',
          filterOptionsCall: 'setProgrammaticLineItemsFilterOptions',
          form: () => <GenericForm type="Line Item" numButtons={3} />,
          genericModelPromiseEndpoints: ['channels', 'channel-genders', 'channel-platforms', 'brands', 'devices', 'genders', 'geos', 'groups', 'languages', 'optimization-goals', 'platforms', 'statuses', 'tactics', 'targetings', 'channel-tactics', 'tactic-targetings', 'channel-targetings'],
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
            apiStore.programmaticLineItemsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
          name: 'Line Items',
          noDataText: (root) => '',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          route: 'line-items',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'line item',
          tabDisabled: () => false,
          tabIndex: 2,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 6,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 13,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 10,
              grid_type: 'namestring',
              filter: false,
              id: 'namestring',
              filter: false,
              filterField: 'namestring',
              label: 'Namestring',
              rules: 'required'
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'ProgrammaticLineItem',
              rules: 'required',
            },
            {
              domo_field: 'channel',
              domo_value: (crudStore) => abbrev(crudStore.storedData.channel),
              ns: () => 'hmm',
              name: 'channel',
              id: 'channel',
              utaType: 'channel',
              rules: 'required',
            },
            {
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              grid: true,
              grid_order: 180,
              label: 'Created By',
              grid_value: (row) => property(row.user, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'created_by',
              name: 'created_by',
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.createdBy
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              domo_field: 'status',
              domo_value: () => 'Inactive',
              // form: 'status_id',
              name: 'status_id',
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 22,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              form: 'group_id',
              type: 'hidden',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              label: 'Group',
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              // ns_order: 10,
              rules: 'required',
              utaType: 'group',
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              label: 'Brand',
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              // ns_order: 20,
              rules: 'required',
              utaType: 'brand',
            },
            {
              belongsTo: ['platform', 'platforms'],
              form: 'platform_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="platform"
                fieldName="platform_id"
                label="Platform"
                form={rootStore.channel.model.crudStore.form}
                menuItems={_.compact(rootStore.apiStore.platforms.map((platform) => rootStore.uiStore.makePrimeChannelSpecificOption(platform, 'channelPlatforms')))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
              />),
              formOrder: 50,
              label: 'Platform',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10,
              domo_field: 'platform',
              domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
              grid: true,
              grid_order: 50,
              grid_value: (row) => property(row.platform, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.platforms,
              id: 'platform',
              label: 'Platform',
              name: 'platform',
              ns: (crudStore) => abbrev(crudStore.storedData.platform),
              ns_order: 50,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.platform, 'name'),
              show_order: 50,
            },

            {
              belongsTo: ['tactic', 'tactics'],
              form: 'tactic_id',
              formField: (rootStore) => (
                <UccSelect
                  core="tactic"
                  fieldName="tactic_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Tactic"
                  menuItems={_.compact(rootStore.apiStore.tactics.map((tactic) => rootStore.uiStore.makePrimeChannelSpecificOption(tactic, 'channelTactics')))}
                  onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
                />),
              formOrder: 60,
              label: 'Tactic',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              domo_field: 'tactic',
              domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
              grid: true,
              grid_order: 60,
              grid_value: (row) => property(row.tactic, 'name'),
              id: 'tactic',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.tactics,
              label: 'Tactic',
              name: 'tactic',
              ns_order: 60,
              ns: (crudStore) => abbrev(crudStore.storedData.tactic),
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.tactic, 'name'),
              show_order: 60,
            },
            {
              domo_field: 'suppression',
              domo_value: (crudStore) => crudStore.suppressionLabel(),
              form: 'suppression',
              grid: false,
              id: 'suppression',
              label: 'Suppression',
              name: 'suppression',
              ns_order: 65,
              ns: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
              type: 'checkbox',
              show: true,
              show_value: (row) => row.suppression ? 'On' : 'Off',
              show_order: 65,
              utaType: 'boolean',
            },

            {
              domo_field: 'targeting',
              domo_value: (crudStore) => NameString.targetingString(crudStore),
              form: 'programmatic_line_item_targetings_attributes',
              formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
              formOrder: 70,
              grid: true,
              grid_order: 70,
              id: 'targeting_ids',
              label: 'Targeting',
              multiple: [],
              name: 'programmatic_line_item_targetings_attributes',
              ns: (crudStore) => NameString.targetingString(crudStore),
              ns_order: 70,
              show: true,
              show_value: (row) => NameString.pivotShow(row, "programmatic_line_item_targetings"),
              show_order: 70,
              sortable: false,
              utaType: 'targeting_array',
            },
            {
              form: 'programmatic_line_item_targetings_attributes[].id',
            },
            {
              form: 'programmatic_line_item_targetings_attributes[].programmatic_line_item_id',
            },
            {
              form: 'programmatic_line_item_targetings_attributes[].targeting_id',
            },
            {
              form: 'programmatic_line_item_targetings_attributes[].extra',
            },
            {
              form: 'programmatic_line_item_targetings_attributes[]._destroy',
              default: 'false'
            },
            {
              form: 'programmatic_line_item_targetings_attributes[].changed',
              default: 'false'
            },
            {
              form: 'programmatic_line_item_targetings_attributes[].on',
            },

            {
              belongsTo: ['optimization_goal', 'optimizationGoals'],
              form: 'optimization_goal_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="optimization-goal"
                fieldName="optimization_goal_id"
                form={rootStore.channel.model.crudStore.form}
                label="Optimization Goal"
                menuItems={_.compact(rootStore.apiStore.optimizationGoals.map(optimization_goal => rootStore.uiStore.makePrimeOption(optimization_goal)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('optimization_goal_id', event)}
              />),
              formOrder: 90,
              label: 'Optimization Goal',
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.optimization_goal, 'name'),
              show_order: 90,
              utaType: 'fk',
            },
            {
              domo_field: 'goal',
              domo_value: (crudStore) => property(crudStore.storedData.optimization_goal, 'name'),
              grid: true,
              grid_order: 90,
              grid_value: (row) => property(row.optimization_goal, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.optimization_goals,
              id: 'optimization_goal',
              label: 'Optimization Goal',
              name: 'optimization_goal',
              ns: (crudStore) => abbrev(crudStore.storedData.optimization_goal),
              ns_order: 90,
              rules: 'required|integer',
            },

            {
              belongsTo: ['device', 'devices'],
              form: 'device_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="device"
                fieldName="device_id"
                form={rootStore.channel.model.crudStore.form}
                label="Device"
                menuItems={_.compact(rootStore.apiStore.devices.map(device => rootStore.uiStore.makePrimeOption(device)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('device_id', event)}
              />),
              formOrder: 100,
              label: 'Device',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.device, 'name'),
              show_order: 100,
              utaType: 'fk',
            },
            {
              domo_field: 'device',
              domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
              grid: true,
              grid_order: 100,
              grid_value: (row) => property(row.device, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.devices,
              id: 'device',
              label: 'Device',
              name: 'device',
              ns: (crudStore) => abbrev(crudStore.storedData.device),
              ns_order: 100,
              rules: 'integer',
            },
            {
              belongsTo: ['geo', 'geos'],
              form: 'geo_id',
              copyEditable: true,
              formField: (rootStore) => (
                <UccSelect
                  core="geo"
                  fieldName="geo_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Geo"
                  menuItems={_.compact(rootStore.apiStore.geos.map(geo => rootStore.uiStore.makePrimeOption(geo)))}
                  onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
                />
              ),
              formOrder: 110,
              label: 'Geo',
              show: true,
              show_value: (row) => property(row.geo, 'name'),
              show_order: 110,
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 8,
              domo_field: 'geo',
              domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
              grid: true,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.geos,
              grid_order: 110,
              grid_value: (row) => property(row.geo, 'name'),
              id: 'geo',
              label: 'Geo',
              name: 'geo',
              ns: (crudStore) => abbrev(crudStore.storedData.geo),
              // ns_order: 110,
              rules: 'integer',
            },
            {
              colSize: 15,
              domo_field: 'region',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.region),
              form: 'region',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="region"
                fieldName="region"
                form={rootStore.channel.model.crudStore.form}
                label="Region"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
              />),
              formOrder: 120,
              grid: true,
              grid_order: 120,
              grid_value: (row) => row.region,
              id: 'region',
              name: 'region',
              ns: (crudStore) => crudStore.storedData.region,
              // ns_order: 80,
              label: 'Region',
              utaType: 'string',
              rules: ['string', abbrev_regex],
              filter: (dt, col) => inputTextFilter(dt, col),
              show: true,
              show_value: (row) => row.region,
              show_order: 120,
            },
            {
              belongsTo: ['language', 'languages'],
              form: 'language_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="language"
                fieldName="language_id"
                form={rootStore.channel.model.crudStore.form}
                label="Language"
                menuItems={_.compact(rootStore.apiStore.languages.map(language => rootStore.uiStore.makePrimeRegularOption(language)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('language_id', event)}
              />),
              formOrder: 130,
              label: 'Language',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.language, 'name'),
              show_order: 130,
              rules: 'integer',
              utaType: 'fk',
            },
            {
              domo_field: 'language',
              domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
              grid: true,
              grid_order: 130,
              grid_value: (row) => property(row.language, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.languages,
              id: 'language',
              label: 'Language',
              name: 'language',
              ns: (crudStore) => abbrev(crudStore.storedData.language),
              // ns_order: 130,
              rules: 'integer',
            },
            {
              belongsTo: ['gender', 'genders'],
              form: 'gender_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="gender"
                fieldName="gender_id"
                form={rootStore.channel.model.crudStore.form}
                label="Gender"
                menuItems={_.compact(rootStore.apiStore.genders.map((gender) => rootStore.uiStore.makePrimeChannelSpecificOption(gender, 'channelGenders')))}
                onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
              />),
              formOrder: 140,
              label: 'Gender',
              show: true,
              show_value: (row) => property(row.gender, 'name'),
              show_order: 140,
              rules: 'integer',
              utaType: 'fk',
            },
            {
              domo_field: 'gender',
              domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
              grid: true,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.genders,
              grid_order: 140,
              grid_value: (row) => property(row.gender, 'name'),
              id: 'gender',
              label: 'Gender',
              name: 'gender',
              ns: (crudStore) => abbrev(crudStore.storedData.gender),
              ns_order: 140,
              rules: 'integer',
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ]
        },
      ].concat(displayPlacementModelMetadata(5, 3))
        .concat(displayAdModelMetadata(5, 4))
        .concat(displayCreativeModelMetadata(5, 5))
    },

    // ___________              .__.__
    // \_   _____/ _____ _____  |__|  |
    //  |    __)_ /     \\__  \ |  |  |
    //  |        \  Y Y  \/ __ \|  |  |__
    // /_______  /__|_|  (____  /__|____/
    //         \/      \/     \/
    {
      name: 'Email',
      endpoint: 'email',
      codename: 'email',
      abbrev: 'Email',
      icon: 'Email',
      id: 3,
      disabled: false,
      showViewIcon: true,
      models: [
        {
          allFilterData: (apiStore) => apiStore['emailCampaignsFilterOptions'],
          codename: 'EmailCamp',
          controller: 'email_campaign',
          crud: (rootStore) => rootStore.emailCampaignsCrud,
          domoNsType: 'EmailCampaign',
          endpoint: 'email-campaigns',
          filterOptionsCall: (apiStore) => (val) => apiStore['emailCampaignsFilterOptions'] = val,
          form: () => <GenericForm type="Email" datePicker={1} numButtons={3} />,
          genericModelPromiseEndpoints: [
            'ab-types',
            'campaign-types',
            'ad-types',
            'email-service-platforms',
            'geos',
            'campaign-type-objectives',
            'channel-objectives',
            'channel-platforms',
            'channel-tactics',
            'channel-targetings',
            'tactic-targetings',
            'objectives',
            'tactics',
            'targetings',
            'campaign-type-channels',
            'channel-genders',
            'genders',
            'ad-type-channels',
            'users',
            'audiences',
            'statuses',
            'test-types',
            'test-groups',
            'segments',
            'message-types',
            'marketing-campaigns',
            'waves',
            'send-types',
            'test-group-test-types',
            'email-types',
          ],
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
            apiStore.emailCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
          name: 'Campaigns',
          nameField: 'name',
          noDataText: (root) => '',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'campaigns',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'campaign',
          tabDisabled: () => false,
          tabIndex: 0,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 6,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 6,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 5,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
              colSize: 8
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'EmailCampaign',
              rules: 'required',
            },

            {
              domo_field: 'channel',
              domo_value: (crudStore) => abbrev(crudStore.storedData.channel),
              ns: () => 'hmm',
              name: 'channel',
              id: 'channel',
              utaType: 'channel',
              rules: 'required',
            },
            {
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              name: 'created_by',
              options: (apiStore) => apiStore.Users,
              label: 'Created By',
              id: 'created_by',
              grid: true,
              grid_order: 190,
              grid_value: (row) => property(row.user, 'name'),
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              colSize: 9
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              domo_field: 'status',
              domo_value: () => 'Inactive',
              // form: 'status_id',
              name: 'status_id',
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 9,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              form: 'group_id',
              type: 'hidden',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              // ns_order: 20,
              rules: 'required',
              utaType: 'brand',
            },
            {
              colSize: 13,
              domo_field: 'campaign_name',
              domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
              grid: true,
              grid_order: 15,
              grid_value: (row) => property(row.marketing_campaign, 'name'),
              id: 'marketing_campaign_id',
              label: 'Marketing Campaign',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.marketingCampaigns,
              name: 'marketing_campaign_id',
              ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
              ns_order: 15,
              rules: 'required',
              utaType: 'fk',
            },
            {
              belongsTo: ['marketing_campaign', 'marketingCampaigns'],
              form: 'marketing_campaign_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="marketing-campaign"
                fieldName="marketing_campaign_id"
                form={rootStore.channel.model.crudStore.form}
                label="Marketing Campaign"
                menuItems={_.compact(rootStore.apiStore.marketingCampaigns.map(marketingCampaign => rootStore.uiStore.makePrimeRegularOption(marketingCampaign)))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
              />),
              formOrder: 2,
              label: 'Marketing Campaign',
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.marketing_campaign, 'name'),
              show_order: 20,
              utaType: 'fk',
            },
            {
              colSize: 7,
              grid: true,
              grid_order: 20,
              grid_value: (row) => row.name,
              id: 'name',
              name: 'name',
              label: 'Description',
              filter_placeholder: 'Description',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'string',
              utaType: 'name',
              colSize: 9
            },

            {
              belongsTo: ['email_service_platform', 'emailServicePlatforms'],
              form: 'email_service_platform_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="email-service-platform"
                fieldName="email_service_platform_id"
                form={rootStore.channel.model.crudStore.form}
                label="Email Service Platform"
                menuItems={rootStore.apiStore.emailServicePlatforms.map(platform => rootStore.uiStore.makePrimeOption(platform))}
                onChange={event => rootStore.channel.model.crudStore.storeData('email_service_platform_id', event)}
              />),
              formOrder: 30,
              label: 'Email Service Platform',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.email_service_platform, 'name'),
              show_order: 30,
              utaType: 'fk',
            },
            {
              colSize: 14,
              domo_field: 'platform',
              domo_value: (crudStore) => property(crudStore.storedData.email_service_platform, 'name'),
              grid: true,
              grid_order: 30,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.emailServicePlatforms,
              grid_value: (row) => property(row.email_service_platform, 'name'),
              id: 'email_service_platform',
              label: 'Email Service Platform',
              name: 'email_service_platform',
              ns: (crudStore) =>
                abbrev(crudStore.storedData.email_service_platform),
            },
            {
              colSize: 12,
              belongsTo: ['campaign_type', 'campaignTypes'],
              form: 'campaign_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="campaign-type"
                fieldName="campaign_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Type"
                menuItems={rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType))}
                onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
              />),
              formOrder: 40,
              label: 'Campaign Type',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              domo_field: 'campaign_type',
              domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
              grid: true,
              grid_order: 40,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.campaignTypes,
              grid_value: (row) => property(row.campaign_type, 'name'),
              id: 'campaign_type',
              label: 'Campaign Type',
              name: 'campaign_type',
              ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
              show: true,
              show_value: (row) => property(row.campaign_type, 'name'),
              show_order: 40,
              // ns_order: 30,
            },
            {
              domo_field: 'objective',
              domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
              grid: true,
              grid_order: 50,
              grid_value: (row) => property(row.objective, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.objectives,
              id: 'objective',
              label: 'Objective',
              name: 'objective',
              ns: (crudStore) => abbrev(crudStore.storedData.objective),
            },
            {
              belongsTo: ['objective', 'objectives'],
              form: 'objective_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="objective"
                fieldName="objective_id"
                form={rootStore.channel.model.crudStore.form}
                label="Objective"
                menuItems={rootStore.uiStore.makeObjectiveOptions()}
                onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
              />),
              formOrder: 50,
              label: 'Objective',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.objective, 'name'),
              show_order: 50,
              utaType: 'fk',
            },
            {
              belongsTo: ['tactic', 'tactics'],
              form: 'tactic_id',
              formField: (rootStore) => (
                <UccSelect
                  core="tactic"
                  fieldName="tactic_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Tactic"
                  menuItems={_.compact(rootStore.apiStore.tactics.map((tactic) => rootStore.uiStore.makePrimeChannelSpecificOption(tactic, 'channelTactics')))}
                  onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
                />),
              formOrder: 60,
              label: 'Tactic',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              domo_field: 'tactic',
              domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
              grid: true,
              grid_order: 60,
              grid_value: (row) => property(row.tactic, 'name'),
              id: 'tactic',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.tactics,
              label: 'Tactic',
              name: 'tactic',
              ns: (crudStore) => abbrev(crudStore.storedData.tactic),
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.tactic, 'name'),
              show_order: 60,
            },
            {
              domo_field: 'suppression',
              domo_value: (crudStore) => crudStore.suppressionLabel(),
              form: 'suppression',
              grid: false,
              id: 'suppression',
              label: 'Suppression',
              name: 'suppression',
              ns: (crudStore) => crudStore.suppressionLabel(),
              type: 'checkbox',
              show: true,
              show_value: (row) => row.suppression ? 'On' : 'Off',
              show_order: 65,
              utaType: 'boolean',
            },

            {
              domo_field: 'targeting',
              domo_value: (crudStore) => NameString.targetingString(crudStore),
              form: 'email_campaign_targetings_attributes',
              formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
              formOrder: 70,
              grid: true,
              grid_order: 80,
              id: 'targeting_ids',
              label: 'Targeting',
              multiple: [],
              name: 'email_campaign_targetings_attributes',
              // ns: (crudStore) => NameString.targetingString(crudStore),
              // ns_order: 80,
              show: true,
              show_value: (row) => NameString.pivotShow(row, "email_campaign_targetings"),
              show_order: 80,
              sortable: false,
              utaType: 'targeting_array',
            },

            {
              form: 'email_campaign_targetings_attributes[].id',
            },
            {
              form: 'email_campaign_targetings_attributes[].email_campaign_id',
            },
            {
              form: 'email_campaign_targetings_attributes[].targeting_id',
            },
            {
              form: 'email_campaign_targetings_attributes[].extra',
            },
            {
              form: 'email_campaign_targetings_attributes[]._destroy',
              default: 'false'
            },
            {
              form: 'email_campaign_targetings_attributes[].changed',
              default: 'false'
            },
            {
              form: 'email_campaign_targetings_attributes[].on',
            },

            {
              belongsTo: ['geo', 'geos'],
              form: 'geo_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="geo"
                fieldName="geo_id"
                form={rootStore.channel.model.crudStore.form}
                label="Geo"
                menuItems={rootStore.apiStore.geos.map(geo => rootStore.uiStore.makePrimeOption(geo))}
                onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
              />),
              formOrder: 80,
              label: 'Geo',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 8,
              domo_field: 'geo',
              domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
              grid: true,
              grid_order: 80,
              grid_value: (row) => property(row.geo, 'name'),
              id: 'geo',
              label: 'Geo',
              name: 'geo',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.geos,
              ns: (crudStore) => abbrev(crudStore.storedData.geo),
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.geo, 'name'),
              show_order: 80,
            },
            {
              belongsTo: ['gender', 'genders'],
              form: 'gender_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="gender"
                fieldName="gender_id"
                form={rootStore.channel.model.crudStore.form}
                label="Gender"
                menuItems={_.compact(rootStore.apiStore.genders.map(gender => rootStore.uiStore.makePrimeChannelSpecificOption(gender, 'channelGenders')))}
                onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
              />),
              formOrder: 90,
              label: 'Gender',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              domo_field: 'gender',
              domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
              grid: true,
              grid_order: 90,
              grid_value: (row) => property(row.gender, 'name'),
              id: 'gender',
              label: 'Gender',
              name: 'gender',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.genders,
              ns: (crudStore) => abbrev(crudStore.storedData.gender),
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.gender, 'name'),
              show_order: 90,
            },
            {
              belongsTo: ['ad_type', 'adTypes'],
              form: 'ad_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="ad-type"
                fieldName="ad_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Creative Type"
                menuItems={_.compact(rootStore.apiStore.adTypes.map(adType => rootStore.uiStore.makePrimeChannelSpecificOption(adType, 'adTypeChannels')))}
                onChange={event => rootStore.channel.model.crudStore.storeData('ad_type_id', event)}
              />),
              formOrder: 100,
              label: 'Creative Type',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.ad_type, 'name'),
              show_order: 100,
              utaType: 'fk',
            },
            {
              domo_field: 'ad_type',
              domo_value: (crudStore) => property(crudStore.storedData.ad_type, 'name'),
              grid: true,
              grid_order: 100,
              grid_value: (row) => property(row.ad_type, 'name'),
              id: 'ad_type',
              label: 'Creative Type',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.adTypes,
              name: 'ad_type',
              ns: (crudStore) => abbrev(crudStore.storedData.ad_type),
              colSize: 10
            },
            {
              belongsTo: ['ab_type', 'abTypes'],
              form: 'ab_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="ab-type"
                fieldName="ab_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="A - B Testing Type"
                menuItems={_.compact(rootStore.apiStore.abTypes.map(abType => rootStore.uiStore.makePrimeOption(abType)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('ab_type_id', event)}
              />),
              formOrder: 110,
              label: 'A - B Testing',
              rules: 'integer',
              show: true,
              show_value: (row) => property(row.ab_type, 'name'),
              show_order: 110,
              utaType: 'fk',
            },
            {
              colSize: 9,
              domo_field: 'ab_type',
              domo_value: (crudStore) => property(crudStore.storedData.ab_type, 'name'),
              grid: true,
              grid_order: 110,
              grid_value: (row) => property(row.ab_type, 'name'),
              id: 'ab_type',
              label: 'A - B Testing',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.abTypes,
              name: 'ab_type',
              ns: (crudStore) => abbrev(crudStore.storedData.ab_type),
              rules: 'integer',
            },
            {
              colSize: 9,
              domo_field: 'ab_version',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.ab_version),
              form: 'ab_version',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="ab_version"
                fieldName="ab_version"
                form={rootStore.channel.model.crudStore.form}
                label="A - B Testing Version"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('ab_version', event)}
              />),
              formOrder: 120,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 120,
              grid_value: (row) => row.ab_version,
              id: 'ab_version',
              name: 'ab_version',
              label: 'A - B Version',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.ab_version),
              utaType: 'string',
              rules: 'string',
              show: true,
              show_value: (row) => row.ab_version,
              show_order: 120,
            },
            {
              label: 'Created At',
              show_value: (row) => crudStore.storedData.created_at,
              show_order: 160,
              rules: 'required|integer',
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ].concat(
            dateField('send_date', 'Send Date', 'blank', true, 10, 10, '', 10),
          ),
        },
      ],
    },


    //                               ___                                                         ___       
    //                     .-.      (   )                                                       (   )      
    //    .-..     .---.  ( __)   .-.| |        .--.      .--.     .---.   ___ .-.      .--.     | | .-.   
    //   /    \   / .-, \ (''")  /   \ |      /  _  \    /    \   / .-, \ (   )   \    /    \    | |/   \  
    //  ' .-,  ; (__) ; |  | |  |  .-. |     . .' `. ;  |  .-. ; (__) ; |  | ' .-. ;  |  .-. ;   |  .-. .  
    //  | |  . |   .'`  |  | |  | |  | |     | '   | |  |  | | |   .'`  |  |  / (___) |  |(___)  | |  | |  
    //  | |  | |  / .'| |  | |  | |  | |     _\_`.(___) |  |/  |  / .'| |  | |        |  |       | |  | |  
    //  | |  | | | /  | |  | |  | |  | |    (   ). '.   |  ' _.' | /  | |  | |        |  | ___   | |  | |  
    //  | |  ' | ; |  ; |  | |  | '  | |     | |  `\ |  |  .'.-. ; |  ; |  | |        |  '(   )  | |  | |  
    //  | `-'  ' ' `-'  |  | |  ' `-'  /     ; '._,' '  '  `-' / ' `-'  |  | |        '  `-' |   | |  | |  
    //  | \__.'  `.__.'_. (___)  `.__,'       '.___.'    `.__.'  `.__.'_. (___)        `.__,'   (___)(___) 
    //  | |                                                                                                
    // (___)                                                                                               


    {
      name: 'Paid Search',
      endpoint: 'paid-search',
      codename: 'paidSearch',
      abbrev: 'PaidSearch',
      icon: 'PaidSearch',
      id: 4,
      disabled: false,
      showViewIcon: true,
      models: [
        {
          allFilterData: (apiStore) => apiStore['paidSearchCampaignsFilterOptions'],
          codename: 'PaidSearchCamp',
          controller: 'paid_search_campaign',
          crud: (rootStore) => rootStore.paidSearchCampaignsCrud,
          domoNsType: 'PaidSearchCampaign',
          dontDisplayDate: true,
          endpoint: 'paid-search-campaigns',
          filterOptionsCall: (apiStore) => (val) => apiStore['paidSearchCampaignsFilterOptions'] = val,
          form: () => <GenericForm type="Campaign" numButtons={3} />,
          genericModelPromiseEndpoints: [
            'areas',
            'audiences',
            'business-units',
            'campaign-types',
            'campaign-type-channels',
            'campaign-type-objectives',
            'channel-objectives',
            'channel-platforms',
            'devices',
            'extra-field-objective-modifiers',
            'geos',
            'goals',
            'languages',
            'objectives',
            'objective-modifiers',
            'objective-modifier-objectives',
            'platforms',
            'secondary-tactics',
            'segments',
            'statuses',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.paidSearchCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.extraFieldsCrud.getAll({ model_id: '9' }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
          name: 'Paid Search Campaigns',
          name: 'Campaigns',
          nameField: 'name',
          noDataText: (root) => '',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'campaigns',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'campaign',
          tabData: (apiStore) => apiStore.genericTabPromiseResults(),
          tabPromiseEndpoints: (rootStore, brand_param) => {
            const allFilter = _.merge(
              brand_param,
              rootStore.paidSearch.PaidSearchAdGroup.modelFilter(rootStore),
            );
            return [
              rootStore.paidSearchCampaignsCrud.getTotal(brand_param),
              rootStore.paidSearchAdGroupsCrud.getTotal(allFilter, ''),
            ];
          },
          tabDisabled: () => false,
          tabIndex: 0,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 6,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 6,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {

              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              colSize: 9,
              domo_field: 'status',
              domo_value: () => 'Inactive',
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 10,
              label: 'Status',
              name: 'status_id',
              options: (apiStore) => apiStore.statuses,
              id: 'status_id',
              rules: 'required|integer',
              utaType: 'fk',
            },

            {
              colSize: 7,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 5,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'PaidSearchCampaign',
              rules: 'required',
            },

            {
              domo_field: 'channel',
              domo_value: () => 'PaidSearch',
              ns: () => 'PaidSearch',
              name: 'channel',
              id: 'channel',
              utaType: 'channel',
              rules: 'required',
            },
            {
              form: 'group_id',
              type: 'hidden',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              ns_order: 10,
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              utaType: 'brand',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              ns_order: 20,
              rules: 'required',
              utaType: 'brand',
            },
            {
              belongsTo: ['campaign_type', 'campaignTypes'],
              form: 'campaign_type_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="campaign-type"
                fieldName="campaign_type_id"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Type"
                menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('campaign_type_id', event)}
              />),
              formOrder: 20,
              name: 'campaign_type_id',
              label: 'Campaign Type',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 11,
              domo_field: 'campaign_type',
              domo_value: (crudStore) => abbrev(crudStore.storedData.campaign_type),
              filter: (dt, col) => multiSelectFilter(dt, col),
              filter_placeholder: 'Type',
              grid: true,
              grid_order: 20,
              grid_value: (row) => property(row.campaign_type, 'name'),
              id: 'campaign_type',
              label: 'Campaign Type',
              name: 'campaign_type',
              options: (apiStore) => apiStore.campaignTypes,
              show: true,
              show_value: (row) => property(row.campaign_type, 'name'),
              show_order: 20,
            },
            {
              belongsTo: ['platform', 'platforms'],
              form: 'platform_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="platform"
                fieldName="platform_id"
                label="Platform"
                form={rootStore.channel.model.crudStore.form}
                menuItems={_.compact(rootStore.apiStore.platforms.map((platform) => rootStore.uiStore.makePrimeChannelSpecificOption(platform, 'channelPlatforms')))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
              />),
              formOrder: 40,
              label: 'Platform',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10,
              domo_field: 'platform',
              domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
              grid: true,
              grid_order: 40,
              grid_value: (row) => property(row.platform, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.platforms,
              id: 'platform',
              label: 'Platform',
              name: 'platform',
              ns: (crudStore) => abbrev(crudStore.storedData.platform),
              ns_order: 40,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.platform, 'name'),
              show_order: 40,

            },
            {
              belongsTo: ['objective', 'objectives'],
              callback: (crudStore, thing, selected) => crudStore.clearObjectiveModifier(crudStore, thing, selected),
              form: 'objective_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="objective"
                fieldName="objective_id"
                form={rootStore.channel.model.crudStore.form}
                label="Objective"
                menuItems={_.compact(rootStore.apiStore.objectives.map(objective => rootStore.uiStore.makePrimeObjectiveOption(objective)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
              />),
              formOrder: 50,
              label: 'Objective',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 11,
              domo_field: 'objective',
              domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
              grid: true,
              grid_order: 50,
              grid_value: (row) => property(row.objective, 'name'),
              id: 'objective',
              label: 'Objective',
              filter: (dt, col) => multiSelectFilter(dt, col),
              name: 'objective',
              ns: (crudStore) => abbrev(crudStore.storedData.objective),
              ns_order: 50,
              rules: 'required',
              options: (apiStore) => apiStore.objectives,
              show: true,
              show_value: (row) => property(row.objective, 'name'),
              show_order: 50,
            },
            {
              belongsTo: ['objective_modifier', 'objectiveModifiers'],
              form: 'objective_modifier_id',
              formField: (rootStore) => (<UccSelect
                core="objective-modifier"
                fieldName="objective_modifier_id"
                label="Objective Modifier"
                form={rootStore.channel.model.crudStore.form}
                menuItems={_.compact(rootStore.apiStore.objectiveModifiers.map((objective_modifier) => rootStore.uiStore.makePrimeObjectiveModifierOption(objective_modifier)))}
                onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('objective_modifier', 'extra_field', event)}
              />),
              formOrder: 60,
              label: 'Objective Mod.',
              rules: 'required|integer',
              utaType: 'fk',
            },

            {
              colSize: 13,
              contrivance: (crudStore) => [abbrev(crudStore.storedData.objective_modifier), NameString.pivotNamestring(crudStore, 'objective_modifier', 'extra_field')].filter(e => e.length > 0).join('+'),
              domo_field: 'objective_modifier',
              domo_value: (crudStore) => [abbrev(crudStore.storedData.objective_modifier), NameString.pivotNamestring(crudStore, 'objective_modifier', 'extra_field')].filter(e => e.length > 0).join('+'),
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'obj_mod_string',
              grid: true,
              grid_order: 60,
              grid_value: (row) => row.obj_mod_string,
              id: 'obj_mod_string',
              label: 'Objective Mod.',
              name: 'objective_modifier',
              ns: (crudStore) => {
                return [abbrev(crudStore.storedData.objective_modifier), NameString.pivotNamestring(crudStore, 'objective_modifier', 'extra_field')].filter(e => e != undefined && e.length > 0).join('+')
              },
              ns_order: 60,
              options: (apiStore) => apiStore.objectiveModifiers,
              placeholder: 'Objective Modifier',
              type: 'hidden',
              show: true,
              show_value: (row) => row.obj_mod_string,
              show_order: 60,
            },

            {
              // domo_field: 'extra_field',
              // domo_value: (crudStore) => NameString.extraFieldString(crudStore),
              colSize: 14,
              form: 'paid_search_campaign_extra_fields_attributes',
              formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} control="objective_modifier" dependent="extra_field" />),
              formOrder: 80,
              // grid: true,
              // grid_order: 80,
              // grid_value: (row) => NameString.pivotShow(row, "paid_search_campaign_extra_fields", "extra_field"),
              id: 'extra_field_ids',
              label: 'Extra Fields',
              multiple: [],
              name: 'paid_search_campaign_extra_fields_attributes',
              ns: (crudStore) => NameString.pivotNamestring(crudStore, 'objective_modifier', 'extra_field'),
              // ns_order: 80,
              show: true,
              show_value: (row) => NameString.pivotShow(row, "paid_search_campaign_extra_fields", "extra_field"),
              show_order: 80,
              sortable: false,
              utaType: 'targeting_array',
            },
            {
              form: 'paid_search_campaign_extra_fields_attributes[].id',
            },
            {
              form: 'paid_search_campaign_extra_fields_attributes[].paid_search_campaign_id',
            },
            {
              form: 'paid_search_campaign_extra_fields_attributes[].extra_field_id',
            },
            {
              form: 'paid_search_campaign_extra_fields_attributes[].extra',
            },
            {
              form: 'paid_search_campaign_extra_fields_attributes[]._destroy',
              default: 'false'
            },
            {
              form: 'paid_search_campaign_extra_fields_attributes[].changed',
              default: 'false'
            },
            {
              form: 'paid_search_campaign_extra_fields_attributes[].on',
            },  //no geo or language for astellas



            // {
            //   // domo_field: 'objective_modifier',
            //   // domo_value: (crudStore) => NameString.targetingString(crudStore),
            //   form: 'paid_search_campaign_objective_modifiers_attributes',
            //   formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} control="objective" dependent="objective_modifier" />),
            //   formOrder: 60,
            //   grid: false,
            //   grid_order: 60,
            //   id: 'objective_modifier_ids',
            //   label: 'Objective Modifier',
            //   multiple: [],
            //   name: 'paid_search_campaign_objective_modifiers_attributes',
            //   // ns: (crudStore) => NameString.pivotNamestring(crudStore).name
            //   ns: (crudStore) => { NameString.pivotNamestring(crudStore, 'objective', 'objetcive_modifier') },
            //   ns_order: 60,
            //   show: true,
            //   //   show_value: (row) => row.obj_mod_string,
            //   show_value: (row) => NameString.pivotShow(row, "paid_search_campaign_objective_modifiers", "objective_modifier"),
            //   show_order: 60,
            //   utaType: 'targeting_array',
            // },
            // // display_placement_targetings_attributes
            // {
            //   form: 'paid_search_campaign_objective_modifiers_attributes[].id',
            // },
            // {
            //   form: 'paid_search_campaign_objective_modifiers_attributes[].paid_search_campaign_id',
            // },
            // {
            //   form: 'paid_search_campaign_objective_modifiers_attributes[].objective_modifier_id',
            // },
            // {
            //   form: 'paid_search_campaign_objective_modifiers_attributes[].extra',
            // },
            // {
            //   form: 'paid_search_campaign_objective_modifiers_attributes[]._destroy',
            //   default: 'false'
            // },
            // {
            //   form: 'paid_search_campaign_objective_modifiers_attributes[].changed',
            //   default: 'false'
            // },
            // {
            //   form: 'paid_search_campaign_objective_modifiers_attributes[].on',
            // },

            // {
            //   // are term and product needed in meta data?
            //   form: 'term',
            //   filter: (dt, col) => inputTextFilter(dt, col),
            //   grid_value: (row) => row.term,
            //   id: 'term',
            //   label: 'Term',
            //   name: 'term',
            //   ns: (crudStore) => crudStore.storedData.term,
            //   rules: 'string',
            //   utaType: 'text',
            // },
            // {
            //   // is product needed in meta data?
            //   form: 'product',
            //   filter: (dt, col) => inputTextFilter(dt, col),
            //   grid_value: (row) => row.product,
            //   id: 'product',
            //   label: 'Product',
            //   name: 'product',
            //   ns: (crudStore) => crudStore.storedData.product,
            //   rules: 'string',
            //   utaType: 'text',
            // },
            // {
            //   form: 'product_details',

            //   formField: (rootStore) => (<UccInputText
            //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'product_details')}
            //     core="product-details"
            //     fieldName="product_details"
            //     form={rootStore.channel.model.crudStore.form}
            //     label="Product Details"
            //     onInput={(event) => rootStore.channel.model.crudStore.storeData('product_details', event)}
            //   // className={classes.modifierField}
            //   />),
            //   formOrder: 66,
            //   filter: (dt, col) => inputTextFilter(dt, col),
            //   grid_value: (row) => row.product_details,
            //   id: 'product_details',
            //   label: 'Product Details',
            //   name: 'product_details',
            //   ns: (crudStore) => crudStore.storedData.product_details,
            //   rules: 'string',
            //   utaType: 'text',
            //   show: true,
            //   show_value: (row) => row.product_details,
            //   show_order: 60,
            // },
            // {
            //   // is general needed in meta data?
            //   form: 'general',
            //   filter: (dt, col) => inputTextFilter(dt, col),
            //   grid_value: (row) => row.general,
            //   id: 'general',
            //   label: 'General',
            //   name: 'general',
            //   ns: (crudStore) => crudStore.storedData.general,
            //   rules: 'string',
            //   utaType: 'text',
            // },
            // {
            //   form: 'category',

            //   formField: (rootStore) => (<UccInputText
            //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'category')}
            //     core="category"
            //     fieldName="category"
            //     form={rootStore.channel.model.crudStore.form}
            //     label="Category"
            //     onInput={(event) => rootStore.channel.model.crudStore.storeData('category', event)}
            //   // className={classes.modifierField}
            //   />),
            //   formOrder: 64,
            //   filter: (dt, col) => inputTextFilter(dt, col),
            //   grid_value: (row) => row.category,
            //   id: 'category',
            //   label: 'Category',
            //   name: 'category',
            //   ns: (crudStore) => crudStore.storedData.category,
            //   rules: 'string',
            //   show: true,
            //   show_value: (row) => row.category,
            //   show_order: 64,
            //   utaType: 'text',
            // },
            // {
            //   form: 'custom_modifier',

            //   formField: (rootStore) => (<UccInputText
            //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'custom_modifier')}
            //     core="custom_modifier"
            //     fieldName="custom_modifier"
            //     form={rootStore.channel.model.crudStore.form}
            //     label="Custom Modifier"
            //     onInput={(event) => rootStore.channel.model.crudStore.storeData('custom_modifier', event)}
            //   // className={classes.modifierField}
            //   />),
            //   formOrder: 68,
            //   filter: (dt, col) => inputTextFilter(dt, col),
            //   grid_value: (row) => row.custom_modifier,
            //   id: 'custom_modifier',
            //   label: 'Custom Modifier',
            //   name: 'custom_modifier',
            //   ns: (crudStore) => crudStore.storedData.custom_modifier,
            //   rules: 'string',
            //   show: true,
            //   show_value: (row) => row.custom_modifier,
            //   show_order: 68,
            //   utaType: 'text',
            // },
            // {
            //   form: 'three_p_brand',

            //   formField: (rootStore) => (<UccInputText
            //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'three_p_brand')}
            //     core="three_p_brand"
            //     fieldName="three_p_brand"
            //     form={rootStore.channel.model.crudStore.form}
            //     label="3p Brand"
            //     onInput={(event) => rootStore.channel.model.crudStore.storeData('three_p_brand', event)}
            //   // className={classes.modifierField}
            //   />),
            //   formOrder: 62,
            //   filter: (dt, col) => inputTextFilter(dt, col),
            //   grid_value: (row) => row.three_p_brand,
            //   id: 'three_p_brand',
            //   label: '3p Brand',
            //   name: 'three_p_brand',
            //   ns: (crudStore) => crudStore.storedData.three_p_brand,
            //   rules: 'string',
            //   utaType: 'text',
            // },
            {
              belongsTo: ['goal', 'goals'],
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="goal"
                fieldName="goal_id"
                form={rootStore.channel.model.crudStore.form}
                label="Goal"
                menuItems={rootStore.apiStore.goals.map((goal) => rootStore.uiStore.makePrimeRegularOption(goal))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('goal_id', event)}
              />),
              formOrder: 80,
              default: 1,
              form: 'goal_id',
              label: 'Goal',
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.goal, 'name'),
              show_order: 80,
              utaType: 'fk',
            },
            {
              colSize: 8.5,
              domo_field: 'goal',
              domo_value: (crudStore) => property(crudStore.storedData.goal, 'name'),
              default: 1,
              defaultType: 'goals',
              grid: true,
              grid_order: 80,
              grid_value: (row) => property(row.goal, 'name'),
              id: 'goal',
              label: 'Goal',
              filter: (dt, col) => multiSelectFilter(dt, col),
              name: 'goal',
              ns: (crudStore) => abbrev(crudStore.storedData.goal),
              ns_order: 80,
              rules: 'required',
              options: (apiStore) => apiStore.goals,

              utaType: 'goals',
            },

            {
              belongsTo: ['device', 'devices'],
              default: 1,
              form: 'device_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="device"
                fieldName="device_id"
                form={rootStore.channel.model.crudStore.form}
                label="Device"
                menuItems={rootStore.apiStore.devices.map((device) => rootStore.uiStore.makePrimeRegularOption(device))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('device_id', event)}
              />),
              formOrder: 90,
              label: 'Device',
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.device, 'name'),
              show_order: 90,
              utaType: 'fk',
            },
            {
              colSize: 9,
              domo_field: 'device',
              domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
              default: 1,
              grid: true,
              grid_order: 90,
              grid_value: (row) => property(row.device, 'name'),
              id: 'device',
              label: 'Device',
              name: 'device',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.devices,
              rules: 'integer',
              sortable: true,
              utaType: 'devices',
            },
            {
              belongsTo: ['geo', 'geos'],
              default: 13,
              form: 'geo_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="geo"
                fieldName="geo_id"
                form={rootStore.channel.model.crudStore.form}
                label="Geo"
                // menuItems={rootStore.apiStore.geos.map((p) => ({ label: p.name, value: p.id }))}
                menuItems={rootStore.apiStore.geos.map((geo) => rootStore.uiStore.makePrimeRegularOption(geo))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('geo_id', event)}
              />),
              formOrder: 95,
              label: 'Geos',
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.geo, 'name'),
              show_order: 95,
              utaType: 'fk',
            },
            {
              colSize: 8.5,
              domo_field: 'geo',
              domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
              default: 13,
              grid: true,
              grid_order: 95,
              grid_value: (row) => property(row.geo, 'name'),
              id: 'geo',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.geos,
              label: 'Geo',
              name: 'geo',
              ns: (crudStore) => abbrev(crudStore.storedData.geo),
              ns_order: 95,
              rules: 'integer',
              sortable: true,
              utaType: 'geos'
            },
            {
              belongsTo: ['language', 'languages'],
              default: 1,
              form: 'language_id',
              copyEditable: true,
              formField: (rootStore) => (
                <UccSelect
                  core="language"
                  fieldName="language_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Language"
                  menuItems={rootStore.apiStore.languages.map((language) => rootStore.uiStore.makePrimeRegularOption(language))}
                  onChange={(event) => rootStore.channel.model.crudStore.storeData('language_id', event)}
                />),
              formOrder: 100,
              label: 'Language',
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.language, 'name'),
              show_order: 100,
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'language',
              domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
              default: 1,
              grid: true,
              grid_order: 100,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.languages,
              grid_value: (row) => property(row.language, 'name'),
              id: 'language',
              label: 'Language',
              name: 'language',
              ns: (crudStore) => abbrev(crudStore.storedData.language),
              ns_order: 100,
              utaType: 'languages'

            },
            {
              colSize: 11,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              grid: true,
              grid_order: 150,
              grid_value: (row) => property(row.user, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'created_by',
              label: 'Created By',
              name: 'created_by',
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.Users,
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ],
        },

        //   _____       _      _____                                 
        //  (_____)     (_)    (_____)  _                        ____ 
        // (_)___(_)  __(_)   (_)  ___ (_)__  ___   _   _  ____ (____)
        // (_______) (____)   (_) (___)(____)(___) (_) (_)(____)(_)__ 
        // (_)   (_)(_)_(_)   (_)___(_)(_)  (_)_(_)(_)_(_)(_)_(_)_(__)
        // (_)   (_) (____)    (_____) (_)   (___)  (___) (____)(____)
        //                                                (_)         
        //                                                (_)         

        {
          allFilterData: (apiStore) => apiStore['paidSearchAdGroupsFilterOptions'],
          disableBulkCreate: true,
          disableCopyEdit: true,
          disableBulkEdit: true,
          codename: 'PaidSearchAdGroup',
          controller: 'paid_search_ad_group',
          crud: (rootStore) => rootStore.paidSearchAdGroupsCrud,
          domoNsType: 'PaidSearchAdGroup',
          endpoint: 'paid-search-ad-groups',
          dontDisplayDate: true,
          filterOptionsCall: (apiStore) => (val) => apiStore['paidSearchAdGroupsFilterOptions'] = val,
          form: () => <GenericForm type="Ad Group" numButtons={3} />,
          genericModelPromiseEndpoints: [
            'ad-modifiers',
            'ad-modifier-objective-modifiers',
            'campaign-types',
            'campaign-type-channels',
            'campaign-type-objectives',
            'channel-objectives',
            'channel-platforms',
            'devices',
            'extra-field-objective-modifiers',
            'geos',
            'goals',
            'languages',
            'objectives',
            'objective-modifiers',
            'objective-modifier-objectives',
            'paid-search-ad-groups',
            'paid-search-campaigns',
            'platforms',
            'statuses',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.paidSearchCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.paidSearchAdGroupsCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.extraFieldsCrud.getAll({ model_id: '10' }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, paid_search_campaign_id: root.paidSearch.PaidSearchCamp.crudStore.selectedRowIds, }; },
          name: 'Ad Groups',
          nameField: 'name',
          noDataText: (root) => '',
          parentModel: 'paid_search_campaign',
          parentModelCode: 'PaidSearchCamp',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'ad-groups',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'ad group',
          tabData: (apiStore) => apiStore.genericTabPromiseResults(),
          tabPromiseEndpoints: (rootStore, brand_param) => {
            const allFilter = _.merge(
              brand_param,
              rootStore.paidSearch.PaidSearchAdGroup.modelFilter(rootStore),
            );
            return [
              rootStore.paidSearchCampaignsCrud.getTotal(brand_param),
              rootStore.paidSearchAdGroupsCrud.getTotal(allFilter, ''),
            ];
          },
          tabDisabled: (r) => r.apiStore.adGroupTabDisabled ? true : false,
          tabIndex: 1,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          extra_field: 'parent',
          columns: [
            {
              colSize: 6,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 6,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 160,
              utaType: 'namestring_id'
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              colSize: 9,
              domo_field: 'status',
              domo_value: () => 'Inactive',
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 7,
              label: 'Status',
              name: 'status_id',
              options: (apiStore) => apiStore.statuses,
              id: 'status_id',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 2,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'PaidSearchAdGroup',
              rules: 'required',
            },

            {
              domo_field: 'channel',
              domo_value: () => 'PaidSearch',
              // ns: () => 'PaidSearch',
              name: 'channel',
              id: 'channel',
              utaType: 'channel',
              rules: 'required',
            },
            {
              colSize: 11,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              grid: true,
              grid_order: 150,
              grid_value: (row) => property(row.user, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'created_by',
              label: 'Created By',
              name: 'created_by',
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.Users,
            },

            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.rootStore.apiStore.currentGroup),
              name: 'group',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => abbrev(crudStore.rootStore.apiStore.currentBrand),
              name: 'brand',
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.rootStore.apiStore.currentBrand, 'id'),
              name: 'brand_id',
              ns: () => 'brand_id',
              utaType: 'brand',
              rules: 'required',
            },
            {
              belongsTo: ['paid_search_campaign', 'paidSearchCampaigns'],
              form: 'paid_search_campaign_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="paid_search_campaign"
                fieldName="paid_search_campaign_id"
                label="Paid Search Campaign"
                form={rootStore.channel.model.crudStore.form}
                menuItems={rootStore.channel.model.crudStore.makePrimePaidSearchCampaignOptions(rootStore.apiStore.paidSearchCampaigns)}
                onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('paid_search_campaign', 'extra_field', event)}
              />),
              formOrder: 20,
              label: 'Campaign',
              rules: 'required|integer',
              utaType: 'fk',
              subType: 'inherit',
            },
            {
              name: 'paid_search_campaign',
              ns: (crudStore) => 'PARENT'
            },
            {
              colSize: 14,
              grid: true,
              grid_order: 9,
              grid_value: (row) => property(row.paid_search_campaign, 'namestring_string'),
              filter: (dt, col) => inputTextFilter(dt, col),
              id: 'paid_search_campaigns.namestring_string',
              label: 'Campaign Namestring',
              name: 'campaign_name',
              ns: () => 'PARENT',
              show: true,
              show_value: (row) => property(row.paid_search_campaign, 'namestring_string'),
              show_order: 20,
              utaType: 'integer',
            },
            // {
            //   belongsTo: ['ad_modifier', 'adModifiers'],
            //   form: 'ad_modifier_id',
            //   formField: (rootStore) => (<UccSelect
            //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'ad_modifier_id')}
            //     core="ad_modifier"
            //     fieldName="ad_modifier_id"
            //     label="Ad Group Modifier"
            //     form={rootStore.channel.model.crudStore.form}
            //     menuItems={_.compact(rootStore.apiStore.adModifiers.map(ad_modifier => rootStore.uiStore.makePrimeAdModifierOption(ad_modifier)))}
            //     filter={false}
            //     onChange={(event) => rootStore.channel.model.crudStore.storeData('ad_modifier_id', event)}
            //   />),
            //   formOrder: 30,
            //   label: 'Ad Group Mod.',
            //   rules: 'integer',
            //   show: true,
            //   show_value: (row) => row.ad_mod_string,
            //   show_order: 30,
            //   utaType: 'fk',
            // },
            // {
            //   colSize: 11,
            //   contrivance: (crudStore) => adGroupModifierNs(crudStore.storedData),
            //   domo_field: 'ad_modifier',
            //   domo_value: (crudStore) => adGroupModifierNs(crudStore.storedData),
            //   filter: (dt, col) => inputTextFilter(dt, col),
            //   form: 'ad_mod_string',
            //   grid: true,
            //   grid_order: 60,
            //   grid_value: (row) => row.ad_mod_string,
            //   id: 'ad_mod_string',
            //   name: 'ad_modifier',
            //   label: 'Ad Group Mod.',
            //   ns: (crudStore) => adGroupModifierNs(crudStore.storedData),
            //   ns_order: 50,
            //   options: (apiStore) => apiStore.adModifiers,
            //   type: 'hidden',
            // },
            {
              domo_field: 'campaign_type',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'campaign_type'), 'name'),
              id: 'campaign_type',
              name: 'campaign_type',
              options: (apiStore) => apiStore.campaignTypes,
              utaType: 'parent',
            },

            {
              colSize: 10,
              domo_field: 'platform',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'platform'), 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid: true,
              grid_order: 10,
              grid_value: (row) => property(row.paid_search_campaign.platform, 'name'),
              id: 'platform',
              label: 'Platform',
              name: 'platform',
              ns: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'platform'),
              ns_order: 10,
              options: (apiStore) => apiStore.platforms,
              sortField: 'platforms.name',
              utaType: 'parent',

            },
            {
              domo_field: 'objective',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'objective'), 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid: true,
              grid_order: 20,
              grid_value: (row) => property(row.paid_search_campaign.objective, 'name'),
              id: 'objective',
              label: 'Objective',
              name: 'objective',
              ns: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'objective'),
              ns_order: 20,
              options: (apiStore) => apiStore.objectives,
              sortField: 'objectives.name',
              utaType: 'parent'
            },
            {
              domo_field: 'objective_modifier',
              domo_value: (crudStore) => objectiveModifierNs('campaign', crudStore.storedData.row ? crudStore.storedData.row.paid_search_campaign : crudStore.storedData.paid_search_campaign ? crudStore.storedData.paid_search_campaign : crudStore.storedData),
              id: 'objective_modifier',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 30,
              grid_value: (row) => property(property(row, 'paid_search_campaign'), 'obj_mod_string'),
              label: 'Objective Mod.',
              name: 'objective_modifier',
              nestedFilterField: 'paid_search_campaigns.obj_mod_string',
              ns: (crudStore) => crudStore.storedData.paid_search_campaign == undefined ? '' : [crudStore.storedData.paid_search_campaign.obj_mod_string].join('_'),
              ns_order: 30,
              sortField: 'paid_search_campaigns.obj_mod_string',
              options: (apiStore) => apiStore.objectiveModifiers,
              utaType: 'parent',
            },
            {
              domo_field: 'extra_field',
              domo_value: (crudStore) => { NameString.pivotNamestring(crudStore, 'paid_search_campaign', 'extra_field') },
              contrivance: (crudStore) => NameString.pivotNamestring(crudStore, 'paid_search_campaign', 'extra_field'),
              form: 'paid_search_ad_group_extra_fields_attributes',
              formField: (rootStore) => <UtaTargeting form={rootStore.channel.model.crudStore.form} control="paid_search_campaign" dependent="extra_field" />,
              formOrder: 80,
              grid: true,
              grid_order: 80,
              grid_value: (row) => row["paid_search_ad_group_extra_fields"].map(a => a.extra).join('+'),
              id: 'extra_field_ids',
              label: 'Ad Group Mod.',
              multiple: [],
              name: 'paid_search_ad_group_extra_fields_attributes',
              ns: (crudStore) => NameString.pivotNamestring(crudStore, 'objective_modifier', 'extra_field'),
              ns_order: 40,
              show: true,
              show_value: (row) => NameString.pivotShow(row, "paid_search_ad_group_extra_fields", "extra_field"),
              show_order: 80,
              sortable: false,
              utaType: 'targeting_array',
            },
            {
              form: 'paid_search_ad_group_extra_fields_attributes[].id',
            },
            {
              form: 'paid_search_ad_group_extra_fields_attributes[].paid_search_ad_group_id',
            },
            {
              form: 'paid_search_ad_group_extra_fields_attributes[].extra_field_id',
            },
            {
              form: 'paid_search_ad_group_extra_fields_attributes[].extra',
            },
            {
              form: 'paid_search_ad_group_extra_fields_attributes[]._destroy',
              default: 'false'
            },
            {
              form: 'paid_search_ad_group_extra_fields_attributes[].changed',
              default: 'false'
            },
            {
              form: 'paid_search_ad_group_extra_fields_attributes[].on',
            },  //no geo or language for astellas




            // {
            //   form: 'product_details',
            //   formField: (rootStore) => (
            //     <UccInputText
            //       display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'product_details')}
            //       core="product_details"
            //       fieldName="product_details"
            //       form={rootStore.channel.model.crudStore.form}
            //       label="Product Details"
            //       onInput={(event) => rootStore.channel.model.crudStore.storeData('product_details', event)}
            //     />),
            //   formOrder: 50,
            //   filter: (dt, col) => inputTextFilter(dt, col),
            //   grid_value: (row) => row.product_details,
            //   id: 'product_details',
            //   label: 'Product Details',
            //   name: 'product_details',
            //   ns: (crudStore) => crudStore.storedData.product_details,
            //   rules: 'string',
            //   utaType: 'text',
            // },

            // {
            //   form: 'category',
            //   formField: (rootStore) => (<UccInputText
            //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'category')}
            //     core="category"
            //     fieldName="category"
            //     form={rootStore.channel.model.crudStore.form}
            //     label="Category"
            //     onInput={(event) => rootStore.channel.model.crudStore.storeData('category', event)}
            //   />),
            //   formOrder: 40,
            //   filter: (dt, col) => inputTextFilter(dt, col),
            //   grid_value: (row) => row.category,
            //   id: 'category',
            //   label: 'Category',
            //   name: 'category',
            //   ns: (crudStore) => crudStore.storedData.category,
            //   rules: 'string',
            //   utaType: 'text',
            // },

            // {
            //   form: 'custom_modifier',

            //   formField: (rootStore) => (<UccInputText
            //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'custom_modifier')}
            //     core="custom_modifier"
            //     fieldName="custom_modifier"
            //     form={rootStore.channel.model.crudStore.form}
            //     label="Custom Modifier"
            //     onInput={(event) => rootStore.channel.model.crudStore.storeData('custom_modifier', event)}
            //   />),
            //   formOrder: 60,
            //   filter: (dt, col) => inputTextFilter(dt, col),
            //   grid_value: (row) => row.custom_modifier,
            //   id: 'custom_modifier',
            //   label: 'Custom Modifier',
            //   name: 'custom_modifier',
            //   ns: (crudStore) => crudStore.storedData.custom_modifier,
            //   rules: 'string',
            //   utaType: 'text',
            // },

            // {
            //   label: '3p Brand',
            //   name: 'three_p_brand',
            //   ns: (crudStore) => crudStore.storedData.three_p_brand,
            //   rules: 'string',
            //   utaType: 'text',
            // },

            {
              colSize: 8.5,
              domo_field: 'goal',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'goal'), 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid: true,
              grid_order: 40,
              grid_value: (row) => property(row.paid_search_campaign.goal, 'name'),
              id: 'goal',
              label: 'Goal',
              name: 'goal',
              ns: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'goal'),
              options: (apiStore) => apiStore.goals,
              sortField: 'goals.name',
              utaType: 'parent',
            },
            {
              domo_field: 'device',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'device'), 'name'),
              id: 'device',
              name: 'device',
              options: (apiStore) => apiStore.devices,
              utaType: 'parent',
            },
            {
              domo_field: 'geo',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'geo'), 'name'),
              id: 'geo',
              name: 'geo',
              options: (apiStore) => apiStore.geos,
              utaType: 'parent',
            },
            {
              domo_field: 'language',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'language'), 'name'),
              id: 'language',
              name: 'language',
              options: (apiStore) => apiStore.languages,
              utaType: 'parent',
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ],
        },






        //    ('-.     _ .-') _             .-') _    ('-.     _   .-')       ('-.    .-')    
        //    ( OO ).-.( (  OO) )           ( OO ) )  ( OO ).-.( '.( OO )_   _(  OO)  ( OO ).  
        //   / . --. / \     .'_       ,--./ ,--,'   / . --. / ,--.   ,--.)(,------.(_)---\_) 
        //   | \-.  \  ,`'--..._)      |   \ |  |\   | \-.  \  |   `.'   |  |  .---'/    _ |  
        // .-'-'  |  | |  |  \  '      |    \|  | ).-'-'  |  | |         |  |  |    \  :` `.  
        //  \| |_.'  | |  |   ' |      |  .     |/  \| |_.'  | |  |'.'|  | (|  '--.  '..`''.) 
        //   |  .-.  | |  |   / :      |  |\    |    |  .-.  | |  |   |  |  |  .--' .-._)   \ 
        //   |  | |  | |  '--'  /      |  | \   |    |  | |  | |  |   |  |  |  `---.\       / 
        //   `--' `--' `-------'       `--'  `--'    `--' `--' `--'   `--'  `------' `-----'  
        {
          allFilterData: (apiStore) => apiStore['paidSearchAdNamesFilterOptions'],
          codename: 'PaidSearchAdName',
          controller: 'paid_search_ad_name',
          crud: (rootStore) => rootStore.paidSearchAdNamesCrud,
          domoNsType: 'PaidSearchAdName',
          endpoint: 'paid-search-ad-names',
          filterOptionsCall: (apiStore) => (val) => apiStore['paidSearchAdNamesFilterOptions'] = val,
          form: (rootStore) => <GenericForm type="Ad Name" datePicker={2} numButtons={3} extraFields={['version']} />,
          genericModelPromiseEndpoints: [
            'marketing-campaigns',
            'paid-search-ad-groups',
            'paid-search-ad-names',
            'paid-search-campaigns',
            'statuses',
            'users',
          ],
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.paidSearchAdNamesCrud.getFilterOptions({ brand_id: brand_id }, ''),
            apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          grandParentModel: 'paid_search_campaign',
          modelFilter: (root) => _.merge({ paid_search_ad_group_id: root.paidSearch.PaidSearchAdGroup.crudStore.selectedRowIds, }, { paid_search_campaign_id: root.paidSearch.PaidSearchCamp.crudStore.selectedRowIds, }, { brand_id: root.apiStore.currentBrandId }),
          name: 'Ad Names',
          nameField: 'name',
          noDataText: (root) => '',
          parentModel: 'paid_search_ad_group',
          parentModelCode: 'PaidSearchAdGroup',
          permCreateAndEdit: 20,
          permDelete: 20,
          permListAndView: 20,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          permRequest: false,
          route: 'ad-names',
          send2domo: true,
          showFormat: 'definitionList',
          singular: 'ad name',
          tabData: (apiStore) => apiStore.genericTabPromiseResults(),
          tabPromiseEndpoints: (rootStore, brand_param) => {
            const allFilter = _.merge(
              brand_param,
              rootStore.paidSearch.PaidSearchAdGroup.modelFilter(rootStore),
            );
            return [
              rootStore.paidSearchCampaignsCrud.getTotal(brand_param),
              rootStore.paidSearchAdGroupsCrud.getTotal(allFilter, ''),
            ];
          },
          tabDisabled: (r) => (r.apiStore.adNameTabDisabled) ? true : false,
          tabIndex: 2,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 6,
              domo_field: 'nsid',
              domo_value: (crudStore) => crudStore.storedData.id,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              ns: (row) => row.id,
              permListAndView: 80,
              rules: 'integer|required',
              type: 'hidden',
              utaType: 'id',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 3,
              grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
              ns_order: 250,
              utaType: 'namestring_id'
            },
            {
              belongsTo: ['status', 'statuses'],
              form: 'status_id',
              name: 'status_id',
              label: 'Status',
              rules: 'required|integer',
              utaType: 'status',
              type: 'hidden',
            },
            {
              colSize: 9,
              domo_field: 'status',
              domo_value: () => 'Inactive',
              filter: (dt, col) => multiSelectFilter(dt, col),
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 4,
              label: 'Status',
              name: 'status_id',
              options: (apiStore) => apiStore.statuses,
              id: 'status_id',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 8,
              domo_field: 'namestring',
              domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
              format: 'action',
              grid: true,
              grid_order: 2,
              grid_type: 'namestring',
              id: 'namestring',
              label: 'Namestring',
              rules: 'required',
            },
            {
              form: 'namestring_string',
              name: 'namestring_string',
              formOrder: 66,
              rules: 'required',
              type: 'hidden',
              utaType: 'string'
            },
            {
              domo_field: 'ns_type',
              domo_value: () => 'PaidSearchAdName',
              rules: 'required',
            },

            {
              domo_field: 'channel',
              domo_value: () => 'PaidSearch',
              // ns: () => 'PaidSearch',
              name: 'channel',
              id: 'channel',
              utaType: 'channel',
              rules: 'required',
            },
            {
              colSize: 14,
              domo_field: 'grand_parent_namestring',
              domo_value: (crudStore) => property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'namestring_string'),
              form: 'campaign_name',
              grid: true,
              grid_order: 4,
              grid_value: (row) => `${property(row.paid_search_ad_group.paid_search_campaign, 'namestring_string')} `,
              grid_label: 'Campaign Namestring',
              filter_placeholder: 'Campaign',
              id: 'a.b.namestring_string',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              label: 'Description',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              utaType: 'name',
              rules: 'string',
            },
            {
              colSize: 11,
              domo_field: 'created_by',
              domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
              grid: true,
              grid_order: 150,
              grid_value: (row) => property(row.user, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              id: 'created_by',
              label: 'Created By',
              name: 'created_by',
              ns: () => 'user',
              utaType: 'user',
              rules: 'required',
              options: (apiStore) => apiStore.Users,
            },
            {
              form: 'group_id',
              type: 'hidden',
              utaType: 'group',
              rules: 'required',
            },
            {
              domo_field: 'group',
              domo_value: (crudStore) => abbrev(crudStore.storedData.group),
              name: 'group',
              ns: (crudStore) => abbrev(crudStore.storedData.group),
              utaType: 'group',
              rules: 'required',
            },

            {
              domo_field: 'brand_id',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
              form: 'brand_id',
              type: 'hidden',
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'brand',
              domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
              name: 'brand',
              ns: (crudStore) => abbrev(crudStore.storedData.brand),
              rules: 'required',
              utaType: 'brand',
            },
            {
              domo_field: 'audience',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'audience'), 'name'),
              id: 'audience',
              name: 'audience',
              utaType: 'parent',
            },
            {
              domo_field: 'campaign_type',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'campaign_type'), 'name'),
              id: 'campaign_type',
              name: 'campaign_type',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'segment',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'segment'), 'name'),
              id: 'segment',
              name: 'segment',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'device',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'device'), 'name'),
              id: 'device',
              name: 'device',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'goal',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'goal'), 'name'),
              id: 'goal',
              name: 'goal',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'ad_modifier',
              domo_value: (crudStore) => adGroupModifierNs(crudStore.storedData.paid_search_ad_group),
              id: 'ad_modifier',
              name: 'ad_modifier',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'objective_modifier',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'objective_modifier'), 'name'),
              id: 'objective_modifier',
              name: 'objective_modifier',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'secondary_tactic',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'secondary_tactic'), 'name'),
              id: 'secondary_tactic',
              name: 'secondary_tactic',
              utaType: 'parent',
              rules: ''
            },
            {
              // this is tactic?
              domo_field: 'objective',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'objective'), 'name'),
              id: 'objective',
              name: 'objective',
              utaType: 'parent',
              rules: 'required'
            },
            {
              domo_field: 'geo',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'geo'), 'name'),
              id: 'geo',
              name: 'geo',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'platform',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'platform'), 'name'),
              id: 'platform',
              name: 'platform',
              utaType: 'parent',
              rules: ''
            },
            {
              domo_field: 'language',
              domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'language'), 'name'),
              id: 'language',
              name: 'language',
              utaType: 'parent',
              rules: ''
            },
            {
              belongsTo: ['paid_search_ad_group', 'paidSearchAdGroups'],
              copyEditable: false,
              form: 'paid_search_ad_group_id',
              formField: (rootStore) => (<UccSelect
                core="paid_search_ad_group"
                fieldName="paid_search_ad_group_id"
                form={rootStore.channel.model.crudStore.form}
                label="Ad Group"
                menuItems={rootStore.channel.model.crudStore.makePrimeAdGroupOptions(rootStore.apiStore.paidSearchAdGroups)}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('paid_search_ad_group_id', event)}
              />),
              formOrder: 10,
              label: 'Ad Group',
              rules: 'required|integer',
              utaType: 'fk',
              subType: 'inherit',
            },
            {
              domo_field: 'parent_namestring',
              domo_value: (crudStore) => property(crudStore.storedData.paid_search_ad_group, 'namestring_string'),
            },
            {
              colSize: 14,
              copyEditable: true,
              grid: true,
              grid_order: 5,
              grid_value: (row) => property(row.paid_search_ad_group, 'namestring_string'),
              grid_type: 'namestring',
              grid_label: 'Ad Group Namestring',
              id: 'namestring_string',
              label: 'Ad Group Namestring',
              name: 'paid_search_ad_group',
              filter: (dt, col) => inputTextFilter(dt, col),
              options: (apiStore) => apiStore.paidSearchAdGroups,
              ns: (crudStore) => HeadedCamelCase(property(crudStore.storedData.paid_search_ad_group, 'name')),
              show: true,
              show_value: (row) => property(row.paid_search_ad_group, 'namestring_string'),
              show_order: 20,
              rules: 'required|integer',
              utaType: 'integer',
            },
            {
              colSize: 14,
              domo_field: 'ad_name',
              domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
              grid: true,
              grid_order: 6,
              grid_value: (row) => property(row.marketing_campaign, 'name'),
              id: 'marketing_campaign_id',
              label: 'Marketing Campaign',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.marketingCampaigns,
              name: 'marketing_campaign_id',
              ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
              ns_order: 8,
              rules: 'required',
              show: true,
              show_value: (row) => property(row.marketing_campaign, 'name'),
              show_order: 30,
              utaType: 'fk',
            },
            {
              belongsTo: ['marketing_campaign', 'marketingCampaigns'],
              form: 'marketing_campaign_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="marketing-campaign"
                fieldName="marketing_campaign_id"
                form={rootStore.channel.model.crudStore.form}
                label="Marketing Campaign"
                menuItems={_.compact(rootStore.apiStore.marketingCampaigns.map(marketingCampaign => rootStore.uiStore.makePrimeRegularOption(marketingCampaign)))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
              />),
              formOrder: 30,
              label: 'Marketing Campaign',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 10,
              filter: (dt, col) => inputTextFilter(dt, col),
              filter_placeholder: 'Description',
              grid: true,
              grid_order: 25,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Description',
              name: 'name',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
              utaType: 'name',
              rules: 'string',
            },
            {
              domo_field: 'ab_version',
              domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.version),
              form: 'version',
              formOrder: 160,
              grid: true,
              grid_order: 145,
              grid_value: (row) => row.version,
              id: 'version',
              label: 'Version',
              copyEditable: true,
              formField: (rootStore) => (<UccInputText
                core="version"
                fieldName="version"
                form={rootStore.channel.model.crudStore.form}
                label="Version"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('version', event)}
              />),
              filter: (dt, col) => inputTextFilter(dt, col),
              hideForm: true,
              name: 'version',
              ns: (crudStore) => HeadedCamelCase(crudStore.storedData.version),
              ns_order: 160,
              rules: 'string',
              show: true,
              show_value: (row) => row.version,
              show_order: 250,
              utaType: 'text',
            },
            {
              id: 'created_at',
              name: 'created_at',
              label: 'Created At',
              utaType: 'date',
              rules: 'date',
              ns: (crudStore) => crudStore.storedData.created_at,
            },
            {
              label: 'Domo Push Date',
              show_value: (row) => crudStore.storedData.push_date,
              show_order: 195,
              id: 'push_date',
              name: 'push_date',
              ns: (crudStore) => crudStore.storedData.push_date,
            },
          ].concat(
            dateField('start_date', 'Start Date', 'blank', true, 130, 130, 'S.', 200),
            dateField('end_date', 'End Date', 'blank', false, 140, 140, 'E.', 210),
          ),
        },




      ],
    },
    {
      name: 'Affiliate',
      endpoint: 'affiliate',
      codename: 'affiliate',
      abbrev: 'affiliate',
      icon: 'Affiliate',
      disabled: true,
      showViewIcon: true,
      models: [
        {
          name: 'Affiliate Campaigns',
          singular: 'campaign',
          endpoint: 'affiliate-campaigns',
          permListAndView: 20,
          permCreateAndEdit: 20,
          permDelete: 20,
          route: 'campaigns',
        },
      ],
    },

    // ____ ___                        _____                   __   
    // |    |   \______ ___________    /     \    ____   ______/  |_ 
    // |    |   /  ___// __ \_  __ \  /  \ /  \  / ___\ /     \   __\
    // |    |  /\___ \\  ___/|  | \/ /    Y    \/ /_/  >  Y Y  \  |  
    // |______//____  >\___  >__|    \____|__  /\___  /|__|_|  /__|  
    //              \/     \/                \//_____/       \/      

    {
      name: 'User Management',
      abbrev: 'UserManagement',
      codename: 'userManagement',
      endpoint: 'user-management',
      icon: AdminUserManagement,
      adminChannel: true,
      showViewIcon: true,
      subtitle: 'Manage Users, Groups, Brands and adjust permissions',
      models: [
        {
          allFilterData: (apiStore) => apiStore['usersFilterOptions'],
          childModelEndpoints: ['groups'], // set to endpoints
          childModelFilter: (root) => childModelFilter(root, 'user_id'),
          codename: 'AdminUser',
          controller: 'user',
          endpoint: 'users',
          form: (rootStore) => <GenericForm numButtons={2} />,
          genericModelPromiseEndpoints: [
            'areas',
            'brands',
            'brand-channels',
            'business-units',
            'channels',
            'groups',
            'permission-levels',
            'users',
          ],
          modelPromiseEndpoints: (apiStore) => ([
            apiStore.brandsCrud.getFilterOptions('', ''),
            apiStore.usersCrud.getFilterOptions('', ''),
            apiStore.groupsCrud.getFilterOptions('', ''),
            apiStore.channelsCrud.getFilterOptions('', ''),
          ]),
          modelFilter: (root) => { return {}; },
          name: 'Users',
          onCreateCallback: (root) => root.apiStore.sendWelcomeEmail(root.channel.model.crudStore.form),
          permCreateAndEdit: 20,
          permDelete: 60,
          permListAndView: 20,
          route: 'users',
          send2domo: false,
          singular: 'user',
          sortField: 'name',
          tabIndex: 0,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults('admin'),
          crud: (rootStore) => rootStore.usersCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 8,
              form: 'name',
              formField: (rootStore) => (
                <UccInputText
                  core="name"
                  fieldName="name"
                  form={rootStore.channel.model.crudStore.form}
                  label="Name"
                  onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
                />
              ),
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 20,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Name',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
              utaType: 'string',
            },
            {
              colSize: 12,
              form: 'email',
              formField: (rootStore) => (
                <UccInputText
                  core="email"
                  fieldName="email"
                  form={rootStore.channel.model.crudStore.form}
                  label="Email"
                  onInput={(event) => rootStore.channel.model.crudStore.storeData('email', event)}
                />
              ),
              grid: true,
              grid_link: 'email',
              grid_order: 30,
              grid_value: (row) => row.email,
              id: 'email',
              label: 'Email',
              name: 'email',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              show: true,
              show_value: (row) => row.email,
              show_order: 30,
              utaType: 'string',
            },

            {
              belongsTo: ['permission_level', 'permissionLevels'],
              copyEditable: true,
              formOrder: 70,
              default: 3,
              form: 'permission_level_id',
              formField: (rootStore) => (
                <UccSelect
                  core="permission_level_id"
                  fieldName="permission_level_id"
                  form={rootStore.channel.model.crudStore.form}
                  label="Permission Level"
                  menuItems={rootStore.apiStore.permissionLevels.filter(p => p.id != 1 && p.id != 2).filter(p => p.level <= rootStore.apiStore.currentUser.pll).map((p) => ({
                    label: p.level_name,
                    value: `${p.id}`
                  }))}
                  onChange={(event) => rootStore.channel.model.crudStore.storeData('permission_level_id', event)}
                />
              ),
              label: 'Permission Level',

              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.permission_level, 'level_name'),
              show_order: 70,
              utaType: 'fk',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 70,
              grid_value: (row) => property(row.permission_level, 'level_name'),
              id: 'permission_level',
              label: 'Permission Level',
              filter_label: 'level_name',
              filter: (dt, col) => multiSelectFilter(dt, col),
              name: 'permission_level',
              rules: 'required',
              options: (apiStore) => apiStore.permissionLevels,
              utaType: 'other',
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',
            },

            // {
            //   form: 'admin',
            //   grid: true,
            //   grid_order: 40,
            //   grid_value: (row) => (row.admin ? 'Admin' : 'Standard'),
            //   id: 'admin',
            //   label: 'Account Type',
            //   name: 'admin',
            //   rules: 'boolean',
            //   show: true,
            //   show_value: (row) => (row.admin ? 'Admin' : 'Standard'),
            //   show_order: 40,
            //   utaType: 'boolean',
            // },
            {
              belongsTo: ['groups', 'groups'],
              form: 'group_ids',
              copyEditable: true,
              formField: (rootStore) => (
                <UccMultiSelect
                  core="groups"
                  fieldName="group_ids"
                  form={rootStore.channel.model.crudStore.form}
                  filter={true}
                  label="Groups"
                  menuItems={_.sortBy(rootStore.apiStore.groups, ['name']).map((p) => ({
                    label: p.name,
                    value: p.id,
                  }))}
                  onChange={(event) => rootStore.channel.model.crudStore.storeData('group_ids', event)}
                />
              ),
              label: 'Group',
              multiple: [],
              rules: 'array',
              utaType: 'array',
            },
            {
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.group_ids.length,
              id: 'groups',
              label: 'Groups',
              name: 'groups',
              rules: 'array',
              show: true,
              show_value: (row) => row.group_ids.length,
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              form: 'password',
              label: 'password',
              rules: 'string',
              utaType: 'string',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['brandsFilterOptions'],
          childModelEndpoints: ['groups'], // set to endpoints
          childModelFilter: (root) => childModelFilter(root, 'brand_id'),
          codename: 'AdminBrand',
          controller: 'brand',
          endpoint: 'brands',
          form: () => <GenericForm numButtons={2} />,
          genericModelPromiseEndpoints: [
            'areas',
            'brands',
            'brand-channels',
            'business-units',
            'channels',
            'groups',
            'permission-levels',
            'users',
          ],
          modelPromiseEndpoints: (apiStore) => ([
            apiStore.brandsCrud.getFilterOptions('', ''),
            apiStore.usersCrud.getFilterOptions('', ''),
            apiStore.groupsCrud.getFilterOptions('', ''),
            apiStore.channelsCrud.getFilterOptions('', ''),
          ]),
          modelFilter: (root) => { },
          name: 'Brands',
          permCreateAndEdit: 40,
          permDelete: 50,
          permListAndView: 20,
          send2domo: false,
          singular: 'brand',
          sortField: 'name',
          permRequest: 20,
          route: 'brands',
          tabIndex: 1,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults('admin'),
          crud: (rootStore) => rootStore.brandsCrud,
          columns: [
            {
              colSize: 6,
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'id',
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 20,
              grid_value: (row) => row.id,
              id: 'id',
              label: 'id',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              show: true,
              show_order: 20,
              show_value: (row) => row.id,
              type: 'hidden',
            },
            {
              colSize: 8,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Brand"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_value: (row) => row.name,
              grid_link: 'draft_edit',
              id: 'name',
              label: 'Brand',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 6,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              form: 'path',
              formField: (rootStore) => (<UccInputText
                core="path"
                fieldName="path"
                form={rootStore.channel.model.crudStore.form}
                label="Path"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('path', event)}
              />),
              formOrder: 33,
              grid: true,
              grid_order: 33,
              grid_value: (row) => row.path,
              id: 'path',
              label: 'Path',
              name: 'path',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'string',
              show: true,
              show_value: (row) => row.path,
              show_order: 33,
            },
            {
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.group_ids.length,
              id: 'groups',
              label: 'Groups',
              name: 'groups',
              rules: 'array',
              show: true,
              show_value: (row) => row.group_ids.length,
              show_order: 40,
              utaType: 'array',
              sortable: false
            },

            {
              belongsTo: ['channels', 'channels'],
              copyEditable: true,
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={rootStore.apiStore.sortedChannels.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 36,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channel_ids.length,
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['groupsFilterOptions'],
          childModelEndpoints: ['users', 'brands'], // set to endpoints
          childModelFilter: (root) => childModelFilter(root, 'group_id'),
          codename: 'AdminGroup',
          controller: 'group',
          disableBulkEdit: false,
          endpoint: 'groups',
          form: (rootStore) => <GenericForm numButtons={2} />,
          genericModelPromiseEndpoints: [
            'areas',
            'brands',
            'brand-channels',
            'business-units',
            'channels',
            'groups',
            'permission-levels',
            'users',
          ],
          modelPromiseEndpoints: (apiStore) => ([
            apiStore.brandsCrud.getFilterOptions('', ''),
            apiStore.usersCrud.getFilterOptions('', ''),
            apiStore.groupsCrud.getFilterOptions('', ''),
            apiStore.channelsCrud.getFilterOptions('', ''),
          ]),
          modelFilter: (root) => { return {}; },
          name: 'Groups',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'groups',
          send2domo: false,
          singular: 'group',
          sortField: 'name',
          tabIndex: 2,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults('admin'),
          crud: (rootStore) => rootStore.groupsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 8,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Group Name"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              grid: true,
              grid_order: 20,
              grid_value: (row) => row.name,
              grid_link: 'draft_edit',
              id: 'name',
              label: 'Name',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 6,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              belongsTo: ['users', 'users'],
              copyEditable: true,
              form: 'user_ids',
              formField: (rootStore) => (
                <UccMultiSelect
                  core="users"
                  fieldName="user_ids"
                  form={rootStore.channel.model.crudStore.form}
                  label="User(s)"
                  filter={true}
                  menuItems={rootStore.apiStore.users.map((p) => ({
                    label: p.name,
                    value: p.id,
                  }))}
                  onChange={(event) => rootStore.channel.model.crudStore.storeData('user_ids', event)}
                />
              ),
              label: 'User',
              multiple: [],
              rules: 'array',
              utaType: 'array',
            },
            {
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.user_ids.length,
              id: 'users',
              label: 'Users',
              multiple: [],
              name: 'users',
              rules: 'array',
              show: true,
              show_value: (row) => row.user_ids.length,
              show_order: 40,
              utaType: 'array',
              sortable: false

            },
            {
              belongsTo: ['brands', 'brands'],
              form: 'brand_ids',
              label: 'Brand',
              multiple: [],
              rules: 'array',
              utaType: 'array',
            },
            {
              belongsTo: ['brand_channels', 'brandChannels'],
              form: 'brand_channel_ids',
              formField: (rootStore) => (
                <UccBrandChannelGroupDataTable
                  model={rootStore.channel.model}
                  core='acg'
                />
              ),
              label: 'BrandChannelId',
              multiple: [],
              rules: 'array',
              utaType: 'array',
            },
            {
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.brand_ids.length + ' / ' + row.brand_channel_ids.length,
              id: 'brands_channels',
              label: 'Brands / Channels',
              multiple: [],
              name: 'brands_channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.brand_ids.length + ' / ' + row.brand_channel_ids.length,
              show_order: 50,
              utaType: 'array',
              sortable: false

            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['permissionLevels'],
          codename: 'AdminPermissionLevel',
          controller: 'permission_level',
          crud: (rootStore) => rootStore.permissionLevelsCrud,
          disableBulkEdit: true,
          endpoint: 'permission-levels',
          form: () => <GenericForm datePicker={2} numButtons={2} />,
          modelFilter: (root) => { return {}; },
          name: 'Permission Levels',
          noDataText: (root) => '',
          permCreateAndEdit: 80,
          permDelete: 80,
          permListAndView: 80,
          requiredData: (apiStore) => apiStore.genericModelPromiseResults('admin'),
          permRequest: false,
          route: 'permission-levels',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Permission Level',
          sortField: 'level',
          tabDisabled: () => false,
          tabIndex: 0,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          columns: [
            {
              colSize: 6,
              form: 'id',
              label: 'id',
              rules: 'integer',
              type: 'hidden',
              show: true,
              show_value: (row) => row.id,
              show_order: 3,
            },
            {
              colSize: 15,
              form: 'level_name',
              formField: (rootStore) => (<UccInputText
                core="level_name"
                fieldName="level_name"
                form={rootStore.channel.model.crudStore.form}
                label="Level Name"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('level_name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_value: (row) => row.level_name,
              id: 'level_name',
              label: 'Level Name',
              name: 'level_name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.level_name,
              show_order: 20,
            },
            {
              colSize: 15,
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'level',
              formField: (rootStore) => (<UccSelect
                core="level"
                fieldName="level"
                form={rootStore.channel.model.crudStore.form}
                label="Level"
                menuItems={[
                  { label: '0', value: '0' },
                  { label: '10', value: '10' },
                  { label: '20', value: '20' },
                  { label: '50', value: '50' },
                  { label: '70', value: '70' },
                  { label: '80', value: '80' },
                  { label: '99', value: '99' }]}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('level', event)}
              />),
              formOrder: 40,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.level,
              id: 'level',
              label: 'Position',
              name: 'level',
              rules: 'required|integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.level,
              show_order: 40,
            },
          ],
        },
      ],
    },

    {
      name: 'Attributes & Values',
      abbrev: 'AttrValues',
      codename: 'attrValues',
      endpoint: 'attributes-values',
      icon: AdminAttrValues,
      adminChannel: true,
      showViewIcon: false,
      showFilter: true,
      subtitle: 'Add, delete, or edit Attributes & Values',
      models: [
        {
          allFilterData: (apiStore) => apiStore['abTypesFilterOptions'],
          codename: 'AdminABTypes',
          controller: 'ab_type',
          disableBulkEdit: true,
          endpoint: 'ab-types',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          filterOptionsCall: 'setAbTypeFilterOptions',
          genericModelPromiseEndpoints: ['ab-types', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.abTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'AB Types',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'ab-types',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'AB Type',
          sortField: 'name',
          tabIndex: 0,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.abTypesCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="AB Type"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'AB Type',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['adTypesFilterOptions'],
          codename: 'AdminAdTypes',
          controller: 'ad_type',
          endpoint: 'ad-types',
          genericModelPromiseEndpoints: ['ad-types', 'channels', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.adTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Ad Types',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'ad-types',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Ad Type',
          sortField: 'name',
          tabIndex: 1,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.adTypesCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Ad Type"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Ad Type',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: false,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              belongsTo: ['channels', 'channels'],
              form: 'channel_ids',
              copyEditable: true,
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'ad_type')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 40,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',

            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['campaignTypesFilterOptions'],
          codename: 'AdminCampaignTypes',
          controller: 'campaign_type',
          endpoint: 'campaign-types',
          genericModelPromiseEndpoints: ['campaign-types', 'channels', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.campaignTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Campaign Types',
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'campaign-types',
          send2domo: false,
          singular: 'Campaign Type',
          showFormat: 'definitionList',
          sortField: 'name',
          tabIndex: 2,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.campaignTypesCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Type"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Campaign Type',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              belongsTo: ['channels', 'channels'],
              copyEditable: true,
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'campaign_type')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 40,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },



        {
          allFilterData: (apiStore) => apiStore['channelFilterOptions'],
          codename: 'AdminChannels',
          controller: 'channel',
          disableBulkEdit: true,
          endpoint: 'channels',
          form: () => <AttrValuesForm />,
          // genericModelPromiseEndpoints: ['statuses'],
          modelFilter: (root) => { return {}; },
          name: 'Channels',
          permCreateAndEdit: 70,
          permDelete: 80,
          permListAndView: 70,
          permRequest: false,
          route: 'channels',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Channel Type',
          sortField: 'name',
          tabIndex: 3,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.channelsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Channel"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Channel',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'codename',
              formField: (rootStore) => (<UccInputText
                core="codename"
                fieldName="codename"
                form={rootStore.channel.model.crudStore.form}
                label="codename"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('codename', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.codename,
              id: 'codename',
              label: 'codename',
              name: 'codename',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required'],
              utaType: 'string',
              show: true,
              show_value: (row) => row.codename,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },






        {
          allFilterData: (apiStore) => apiStore['creativeTypesFilterOptions'],
          codename: 'AdminCreativeTypes',
          controller: 'creative_type',
          disableBulkEdit: true,
          endpoint: 'creative-types',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['creative-types', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.creativeTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Creative Types',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'creative-types',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Creative Type',
          sortField: 'name',
          tabIndex: 3,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.creativeTypesCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Creative Type"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Creative Type',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['devicesFilterOptions'],
          codename: 'AdminDevices',
          controller: 'device',
          disableBulkEdit: true,
          endpoint: 'devices',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['devices', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.devicesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Devices',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'devices',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Device',
          sortField: 'name',
          tabIndex: 4,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.devicesCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Device"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Device',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['displayTypesFilterOptions'],
          codename: 'AdminDisplayTypes',
          controller: 'display_type',
          endpoint: 'display-types',
          genericModelPromiseEndpoints: ['display-types', 'channels', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.displayTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Display Types',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'display-types',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Display type',
          sortField: 'name',
          tabIndex: 15,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.displayTypesCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Display Type"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Display Type',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              copyEditable: true,
              belongsTo: ['channels', 'channels'],
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'display_types')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 40,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['emailServicePlatformsFilterOptions'],
          codename: 'AdminEmailServicePlatforms',
          controller: 'email_service_platform',
          disableBulkEdit: true,
          endpoint: 'email-service-platforms',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['email-service-platforms', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.emailServicePlatformsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Email Service Platforms',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'email-service-platforms',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Email Service Platform',
          sortField: 'name',
          tabIndex: 5,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.emailServicePlatformsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              label: 'id',
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Email Service Platform"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Email Service Platform',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['extraFieldsFilterOptions'],
          codename: 'AdminExtraFields',
          controller: 'extra_field',
          endpoint: 'extra-fields',
          genericModelPromiseEndpoints: ['extra-fields', 'models', 'objective-modifiers', 'statuses'],
          hasStatus: false,
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.extraFieldsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Paid Search Extra Fields',
          route: 'extra-fields',
          permCreateAndEdit: 60,
          permDelete: 80,
          permListAndView: 20,
          permRequest: 20,
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'ExtraField',
          sortField: 'name',
          tabIndex: 6,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.extraFieldsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Paid Search Extra Field"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 20,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Paid Search Extra Field',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', extra_regex],
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'position',
              formField: (rootStore) => (<UccInputText
                core="position"
                fieldName="position"
                form={rootStore.channel.model.crudStore.form}
                label="Position"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.position,
              id: 'position',
              label: 'Position',
              name: 'position',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.position,
              show_order: 30,
            },
            {
              belongsTo: ['model', 'models'],
              copyEditable: true,
              form: 'model_id',
              formField: (rootStore) => (<UccSelect
                core="model"
                fieldName="model_id"
                form={rootStore.channel.model.crudStore.form}
                label="Model"
                menuItems={rootStore.apiStore.models.filter(m => m.name.includes('Paid Search')).map(p => ({ label: p.name, value: p.id.toString(), }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('model_id', event)}
              />),
              formOrder: 40,
              label: 'Model',
              rules: 'required|integer',
              utaType: 'fk',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => property(row.model, 'name'),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.models,
              id: 'model',
              label: 'Model',
              name: 'model',
              show: true,
              show_value: (row) => property(row.model, "name"),
              show_order: 50,
              // utaType: 'array',
              sortable: false
            },
            {
              belongsTo: ['objective-modifiers', 'objectiveModifiers'],
              copyEditable: false, // not sure why this doesnt work as true. tbd.
              form: 'objective_modifier_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="objective_modifiers"
                fieldName="objective_modifier_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Objective Modifiers"
                menuItems={rootStore.apiStore.objectiveModifiers.map((p) => ({ label: p.name, value: p.id, }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_modifier_ids', event)}
              />),
              formOrder: 40,
              label: 'Objective Modifiers',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.objective_modifiers.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.objective_modifiers,
              id: 'objective_modifiers',
              label: 'Objective Modifiers',
              multiple: [],
              name: 'objective_modifiers',
              rules: 'array',
              show: true,
              show_value: (row) => row.objective_modifiers.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 15,
              form: 'extra_field_type',
              formField: (rootStore) => (<UccSelect
                core="extra_field_type"
                fieldName="extra_field_type"
                form={rootStore.channel.model.crudStore.form}
                label="Extra Field Type"
                // menuItems={[{ label: 'Number', value: 'number' }, { label: 'Text', value: 'text' }, { label: 'Select', value: 'select' }]}
                menuItems={[{ label: 'Text', value: 'text' }]}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('extra_field_type', event)}
              />),
              formOrder: 60,
              grid: true,
              grid_order: 60,
              grid_value: (row) => row.extra_field_type,
              id: 'extra_field_type',
              label: 'Extra Field Type',
              name: 'Extra Field Type',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'string',
              utaType: 'string',
              show: true,
              show_value: (row) => row.extra_field_type,
              show_order: 60,
            },
            // {
            //   colSize: 15,
            //   form: 'campaign_required',
            //   formField: (rootStore) => (<UccCheckBox
            //     core="campaign_required"
            //     fieldName="campaign_required"
            //     form={rootStore.channel.model.crudStore.form}
            //     label="Required on Campaign"
            //     onChange={(event) => rootStore.channel.model.crudStore.storeData('campaign_required', event)}
            //   />),
            //   formOrder: 50,
            //   grid: true,
            //   grid_order: 50,
            //   grid_value: (row) => (row.campaign_required ? 'Yes' : 'No'),
            //   id: 'campaign_required',
            //   label: 'Required on Campaign',
            //   name: 'campaign_required',
            //   type: 'checkbox',
            //   utaType: 'boolean',
            //   rules: 'boolean',
            //   show: true,
            //   show_value: (row) => (row.campaign_required ? 'Yes' : 'No'),
            //   show_order: 50
            // },

            // {
            //   colSize: 15,
            //   form: 'ad_group_required',
            //   formField: (rootStore) => (<UccCheckBox
            //     core="ad_group_required"
            //     fieldName="ad_group_required"
            //     form={rootStore.channel.model.crudStore.form}
            //     label="Required on ad_group"
            //     onChange={(event) => rootStore.channel.model.crudStore.storeData('ad_group_required', event)}
            //   />),
            //   formOrder: 50,
            //   grid: true,
            //   grid_order: 50,
            //   grid_value: (row) => (row.ad_group_required ? 'Yes' : 'No'),
            //   id: 'ad_group_required',
            //   label: 'Required on Ad Group',
            //   name: 'ad_group_required',
            //   type: 'checkbox',
            //   utaType: 'boolean',
            //   rules: 'boolean',
            //   show: true,
            //   show_value: (row) => (row.ad_group_required ? 'Yes' : 'No'),
            //   show_order: 50
            // },

          ],
        },

        {
          allFilterData: (apiStore) => apiStore['gendersFilterOptions'],
          codename: 'AdminGenders',
          controller: 'gender',
          endpoint: 'genders',
          genericModelPromiseEndpoints: ['genders', 'channels', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.gendersCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Genders',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'genders',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Gender',
          sortField: 'name',
          tabIndex: 6,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.gendersCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Gender"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Gender',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              copyEditable: true,
              belongsTo: ['channels', 'channels'],
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'gender')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 40,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['geosFilterOptions'],
          codename: 'AdminGeos',
          controller: 'geo',
          endpoint: 'geos',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['geos', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.geosCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Geos',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'geos',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Geo',
          sortField: 'position',
          tabIndex: 7,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.geosCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Geo"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Geo',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 15,
              form: 'position',
              formField: (rootStore) => (<UccInputText
                core="position"
                fieldName="position"
                form={rootStore.channel.model.crudStore.form}
                label="Position"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
              />),
              formOrder: 40,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.position,
              id: 'position',
              label: 'Position',
              name: 'position',
              rules: 'required|integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.position,
              show_order: 40,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['goalsFilterOptions'],
          codename: 'AdminGoals',
          controller: 'goal',
          disableBulkEdit: true,
          endpoint: 'goals',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['goals', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.goalsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Goals',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'goals',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Goal',
          sortField: 'name',
          tabIndex: 8,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.goalsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Goal"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Goal',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['languagesFilterOptions'],
          codename: 'AdminLanguages',
          controller: 'language',
          disableBulkEdit: true,
          endpoint: 'languages',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['languages', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.languagesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Languages',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'languages',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Language',
          sortField: 'position',
          tabIndex: 9,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.languagesCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Language"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Language',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 15,
              form: 'position',
              formField: (rootStore) => (<UccInputText
                core="position"
                fieldName="position"
                form={rootStore.channel.model.crudStore.form}
                label="Position"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
              />),
              formOrder: 40,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.position,
              id: 'position',
              label: 'Position',
              name: 'position',
              rules: 'required|integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.position,
              show_order: 40,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['marketingCampaignsFilterOptions'],
          codename: 'AdminMarketingCampaigns',
          controller: 'marketing_campaign',
          endpoint: 'marketing-campaigns',
          genericModelPromiseEndpoints: ['marketing-campaigns', 'brands', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.marketingCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Marketing Campaigns',
          permCreateAndEdit: 20,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 15,
          route: 'marketing-campaigns',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Marketing Campaign',
          sortField: 'name',
          tabIndex: 10,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.marketingCampaignsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Marketing Campaign"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Marketing Campaign',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              copyEditable: true,
              belongsTo: ['brands', 'brands'],
              form: 'brand_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="brands"
                fieldName="brand_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Brands"
                menuItems={rootStore.apiStore.brands.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('brand_ids', event)}
              />),
              formOrder: 40,
              label: 'brand',
              multiple: [],
              rules: 'array',
              utaType: 'array',
            },
            {
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.brands.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.brands,
              id: 'brands',
              label: 'Brands',
              multiple: [],
              name: 'brands',
              rules: 'array',
              show: true,
              show_value: (row) => row.brands.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',

            },
            // {
            //   belongsTo: ['channels', 'channels'],
            //   form: 'channel_ids',
            //   formField: (rootStore) => (<UccMultiSelect
            //     core="channels"
            //     fieldName="channel_ids"
            //     form={rootStore.channel.model.crudStore.form}
            //     label="Channels"
            //     menuItems={rootStore.apiStore.channels.map((p) => ({
            //       label: p.name,
            //       value: p.id,
            //     }))}
            //     onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
            //   />),
            //   formOrder: 40,
            //   label: 'Channel',
            //   multiple: [],
            //   rules: 'required|array',
            //   utaType: 'array',
            // },
            // {
            //   grid: true,
            //   grid_order: 40,
            //   grid_value: (row) => row.channels.map(c => c.name).join(', '),
            //   filter: (dt, col) => multiSelectFilter(dt, col),
            //   options: (apiStore) => apiStore.channels,
            //   id: 'channels',
            //   label: 'Channels',
            //   name: 'channels',
            //   rules: 'array',
            //   show: true,
            //   show_value: (row) => row.channels.map(c => c.name).join(', '),
            //   show_order: 50,
            //   utaType: 'array',
            //   sortable: false
            // },
          ].concat(
            dateField(
              'start_date',
              'Start Date',
              'blank',
              false,
              100,
              130,
              'S.',
            ),
            dateField('end_date', 'End Date', 'blank', false, 110, 140, 'E.'))
        },


        {
          allFilterData: (apiStore) => apiStore['modelsFilterOptions'],
          codename: 'AdminModels',
          controller: 'model',
          endpoint: 'models',
          genericModelPromiseEndpoints: ['models', 'channels', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.modelsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Models',
          permCreateAndEdit: 80,
          permDelete: 80,
          permListAndView: 80,
          route: 'models',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Model',
          sortField: 'name',
          tabIndex: 6,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.modelsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              grid: true,
              grid_order: 10,
              grid_value: (row) => row.id,
              id: 'id',
              label: 'id',
              rules: 'integer',
              sortable: true,
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Model"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Model',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'codename',
              formField: (rootStore) => (<UccInputText
                core="codename"
                fieldName="codename"
                form={rootStore.channel.model.crudStore.form}
                label="Code Name"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('codename', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.codename,
              id: 'codename',
              label: 'Code Name',
              name: 'codename',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.codename,
              show_order: 30,
            },
            {
              colSize: 15,
              form: 'position',
              formField: (rootStore) => (<UccInputText
                core="position"
                fieldName="position"
                form={rootStore.channel.model.crudStore.form}
                label="Position"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
              />),
              formOrder: 40,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.position,
              id: 'position',
              label: 'Position',
              name: 'position',
              rules: 'required|integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.position,
              show_order: 40,
            },

            {
              belongsTo: ['channel', 'channels'],
              form: 'channel_id',
              copyEditable: true,
              formField: (rootStore) => (<UccSelect
                core="channel"
                fieldName="channel_id"

                form={rootStore.channel.model.crudStore.form}
                label="Channel"
                menuItems={_.compact(rootStore.apiStore.channels.map((channel) => rootStore.uiStore.makePrimeRegularOption(channel)))}
                onChange={event => rootStore.channel.model.crudStore.storeData('channel_id', event)}
              />),
              formOrder: 60,
              label: 'channel',
              rules: 'integer',
              utaType: 'fk',
            },
            {
              colSize: 10.5,
              domo_field: 'channel',
              domo_value: (crudStore) => property(crudStore.storedData.channel, 'name'),
              grid: true,
              grid_order: 60,
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              grid_value: (row) => property(row.channel, 'name'),
              id: 'channel',
              label: 'Channel',
              name: 'channel',
              ns: (crudStore) => abbrev(crudStore.storedData.channel),
              // ns_order: 15,
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['objectivesFilterOptions'],
          codename: 'AdminObjectives',
          controller: 'objective',
          endpoint: 'objectives',
          genericModelPromiseEndpoints: ['objectives', 'campaign-types', 'channels', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.objectivesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Objectives',
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'objectives',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Objective',
          sortField: 'name',
          tabIndex: 11,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.objectivesCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Objective"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Objective',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 15,
              belongsTo: ['campaign-types', 'campaignTypes'],
              form: 'campaign_type_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="campaign_types"
                fieldName="campaign_type_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Campaign Types"
                menuItems={rootStore.apiStore.campaignTypes.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('campaign_type_ids', event)}
              />),
              formOrder: 40,
              label: 'Campaign Type',
              multiple: [],
              rules: 'required|integer',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.campaign_types.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.campaignTypes,
              id: 'campaign_types',
              label: 'Campaign Types',
              name: 'campaign_types',
              rules: 'array',
              show: true,
              show_value: (row) => row.campaign_types.map(c => c.name).join(', '),
              show_order: 60,
              utaType: 'array',
              sortable: false
            },
            {
              belongsTo: ['channels', 'channels'],
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'objective')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 50,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 60,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 60,
              utaType: 'status',
            },
          ],
        },


        {
          allFilterData: (apiStore) => apiStore['objectiveModifiersFilterOptions'],
          codename: 'AdminObjectiveModifiers',
          controller: 'objective_modifier',
          endpoint: 'objective-modifiers',
          genericModelPromiseEndpoints: ['objectives', 'objective-modifiers', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.objectiveModifiersCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Objective Modifiers',
          permCreateAndEdit: 60,
          permDelete: 80,
          permListAndView: 20,
          route: 'objective-modifiers',
          permRequest: false,
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Objective Modifier',
          sortField: 'name',
          tabIndex: 11,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.objectiveModifiersCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              label: 'id',
              rules: 'integer',
              type: 'hidden',
              grid: true,
              grid_order: 10,
              grid_value: (row) => row.id,
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Objective Modifier"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Objective Modifier',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },

            {
              colSize: 15,
              belongsTo: ['objectives', 'objectives'],
              form: 'objective_ids',
              copyEditable: true,
              formField: (rootStore) => (<UccMultiSelect
                core="objectives"
                fieldName="objective_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Objectives"
                menuItems={rootStore.apiStore.objectives.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_ids', event)}
              />),
              formOrder: 80,
              label: 'Objective',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 80,
              grid_value: (row) => row.objectives.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.objectives,
              id: 'objectives',
              label: 'Objectives',
              multiple: [],
              name: 'objectives',
              rules: 'array',
              show: true,
              show_value: (row) => row.objectives.map(c => c.name).join(', '),
              show_order: 80,
              utaType: 'array',
              sortable: false
            },
          ],
        },


        {
          allFilterData: (apiStore) => apiStore['optimizationGoalsFilterOptions'],
          codename: 'AdminOptimizationGoals',
          controller: 'optimization_goal',
          disableBulkEdit: true,
          endpoint: 'optimization-goals',
          filterOptionsCall: 'setOptimizationGoalFilterOptions',
          genericModelPromiseEndpoints: ['optimization-goals', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([apiStore.optimizationGoalsCrud.getFilterOptions({ brand_id: brand_id }, '')]),
          name: 'Optimization Goal',
          permCreateAndEdit: 60,
          permDelete: 80,
          permListAndView: 20,
          permRequest: 20,
          route: 'optimization-goals',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Optimization Goal',
          sortField: 'name',
          tabIndex: 12,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.optimizationGoalsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Ad Modifier"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Optimization Goal',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['adModifiersFilterOptions'],
          codename: 'AdminAdModifiers',
          controller: 'ad_modifier',
          endpoint: 'ad-modifiers',
          filterOptionsCall: 'setAdModifierFilterOptions',
          genericModelPromiseEndpoints: ['ad-modifiers', 'objective-modifiers', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.adModifiersCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Ad Modifier',
          permCreateAndEdit: 60,
          permDelete: 80,
          permListAndView: 20,
          permRequest: 20,
          route: 'ad-modifiers',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Ad Modifier',
          sortField: 'name',
          tabIndex: 4,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.objectiveModifiersCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Ad Modifier"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Ad Modifier',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 15,
              belongsTo: ['objective_modifiers', 'objectiveModifiers'],
              copyEditable: true,
              form: 'objective_modifier_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="objective_modifiers"
                fieldName="objective_modifier_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Objective Modifiers"
                menuItems={rootStore.apiStore.objectiveModifiers.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_modifier_ids', event)}
              />),
              formOrder: 80,
              label: 'Objective Modifier',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 80,
              grid_value: (row) => row.objective_modifiers.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.objectiveModifiers,
              id: 'objective_modifiers',
              label: "Objective Modifiers",
              multiple: [],
              name: 'objective_modifiers',
              multiple: [],
              rules: 'array',
              show: true,
              show_value: (row) => row.objective_modifiers.map(c => c.name).join(', '),
              show_order: 80,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',
            },
          ],
        },

        {
          allFilterData: (apiStore) => apiStore['platformsFilterOptions'],
          codename: 'AdminPlatforms',
          controller: 'platform',
          endpoint: 'platforms',
          genericModelPromiseEndpoints: ['platforms', 'channels', 'statuses', 'channel-platforms'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.platformsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Platforms',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'platforms',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Platform',
          sortField: 'name',
          tabIndex: 13,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.platformsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Platform"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_link: 'draft_edit',
              grid_order: 20,
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Platform',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              belongsTo: ['channels', 'channels'],
              copyEditable: true,
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'platform')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 40,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['publishersFilterOptions'],
          codename: 'AdminPublishers',
          controller: 'publisher',
          disableBulkEdit: true,
          endpoint: 'publishers',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['publishers', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.publishersCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Publishers',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'publishers',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Publisher',
          sortField: 'name',
          tabIndex: 14,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.publishersCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'id',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Publisher"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Publisher',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['sizesFilterOptions'],
          codename: 'AdminSizes',
          controller: 'size',
          disableBulkEdit: true,
          endpoint: 'sizes',
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          genericModelPromiseEndpoints: ['sizes', 'statuses'],
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.sizesCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Sizes',
          permCreateAndEdit: 40,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'sizes',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Size',
          sortField: 'name',
          tabIndex: 15,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.sizesCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'id',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Size"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Size',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['tacticsFilterOptions'],
          codename: 'AdminTactics',
          controller: 'tactic',
          endpoint: 'tactics',
          genericModelPromiseEndpoints: ['tactics', 'channels', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.tacticsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Tactics',
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          permRequest: 20,
          route: 'tactics',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Tactic',
          sortField: 'name',
          tabIndex: 16,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.tacticsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'id',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Tactic"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Tactic',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              belongsTo: ['channels', 'channels'],
              copyEditable: true,
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'tactic')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 40,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 50,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 40,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 40,
              utaType: 'status',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['targetingsFilterOptions'],
          codename: 'AdminTargetings',
          controller: 'targeting',
          endpoint: 'targetings',
          genericModelPromiseEndpoints: ['targetings', 'channels', 'tactics', 'statuses'],
          form: () => <GenericForm datePicker={2} numButtons="approve" />,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
            apiStore.targetingsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Targetings',
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          permRequest: false,
          route: 'targetings',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'Targeting',
          sortField: 'position',
          tabIndex: 17,
          tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.targetingsCrud,
          sortField: 'position',
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'id',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              show: true,
              show_value: (row) => row.id,
              show_order: 10,
              type: 'hidden',
            },
            {
              colSize: 15,
              form: 'name',
              formField: (rootStore) => (<UccInputText
                core="name"
                fieldName="name"
                form={rootStore.channel.model.crudStore.form}
                label="Targeting"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => row.name,
              id: 'name',
              label: 'Targeting Name',
              name: 'name',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'required|string',
              utaType: 'name',
              show: true,
              show_value: (row) => row.name,
              show_order: 20,
            },
            {
              colSize: 15,
              form: 'abbrev',
              formField: (rootStore) => (<UccInputText
                core="abbrev"
                fieldName="abbrev"
                form={rootStore.channel.model.crudStore.form}
                label="Abbreviation"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.abbrev,
              id: 'abbrev',
              label: 'Abbreviation',
              name: 'abbrev',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: ['required', abbrev_regex],
              utaType: 'string',
              show: true,
              show_value: (row) => row.abbrev,
              show_order: 30,
            },
            {
              colSize: 15,
              form: 'extra_field_type',
              formField: (rootStore) => (<UccSelect
                core="extra_field_type"
                fieldName="extra_field_type"
                form={rootStore.channel.model.crudStore.form}
                label="Extra Field Type"
                menuItems={[{ label: 'Number', value: 'number' }, { label: 'Text', value: 'text' }]}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('extra_field_type', event)}
              />),
              formOrder: 40,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.extra_field_type,
              id: 'extra_field_type',
              label: 'Extra Field Type',
              name: 'Extra Field Type',
              filter: (dt, col) => inputTextFilter(dt, col),
              rules: 'string',
              utaType: 'string',
              show: true,
              show_value: (row) => row.extra_field_type,
              show_order: 40,
            },
            {
              colSize: 15,
              form: 'extra_required',
              formField: (rootStore) => (<UccCheckBox
                core="extra_required"
                fieldName="extra_required"
                form={rootStore.channel.model.crudStore.form}
                label="Extra Required"
                onChange={(event) => rootStore.channel.model.crudStore.storeData('extra_required', event)}
              />),
              formOrder: 50,
              grid: true,
              grid_order: 50,
              grid_value: (row) => (row.extra_required ? 'Yes' : 'No'),
              id: 'extra_required',
              label: 'Extra Required',
              name: 'extra_required',
              type: 'checkbox',
              utaType: 'boolean',
              rules: 'boolean',
              show: true,
              show_value: (row) => (row.extra_required ? 'Yes' : 'No'),
              show_order: 50
            },
            {
              colSize: 15,
              form: 'position',
              formField: (rootStore) => (<UccInputText
                core="position"
                fieldName="position"
                form={rootStore.channel.model.crudStore.form}
                label="Position"
                onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
              />),
              formOrder: 60,
              grid: true,
              grid_order: 60,
              grid_value: (row) => row.position,
              id: 'position',
              label: 'Position',
              name: 'position',
              rules: 'required|integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.position,
              show_order: 60,
            },
            {
              copyEditable: true,
              belongsTo: ['channels', 'channels'],
              form: 'channel_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="channels"
                fieldName="channel_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Channels"
                menuItems={channelSpecificOptions(rootStore, 'targeting_ids')}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
              />),
              formOrder: 70,
              label: 'Channel',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 70,
              grid_value: (row) => row.channels.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              id: 'channels',
              label: 'Channels',
              multiple: [],
              name: 'channels',
              rules: 'array',
              show: true,
              show_value: (row) => row.channels.map(c => c.name).join(', '),
              show_order: 70,
              utaType: 'array',
              sortable: false
            },
            {
              copyEditable: true,
              colSize: 15,
              belongsTo: ['tactics', 'tactics'],
              form: 'tactic_ids',
              formField: (rootStore) => (<UccMultiSelect
                core="tactics"
                fieldName="tactic_ids"
                form={rootStore.channel.model.crudStore.form}
                label="Tactics"
                menuItems={rootStore.apiStore.tactics.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('tactic_ids', event)}
              />),
              formOrder: 80,
              label: 'Tactic',
              multiple: [],
              rules: 'required|array',
              utaType: 'array',
            },
            {
              colSize: 15,
              grid: true,
              grid_order: 80,
              grid_value: (row) => row.tactics.map(c => c.name).join(', '),
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.tactics,
              id: 'tactics',
              label: 'Tactics',
              multiple: [],
              name: 'tactics',
              rules: 'array',
              show: true,
              show_value: (row) => row.tactics.map(c => c.name).join(', '),
              show_order: 80,
              utaType: 'array',
              sortable: false
            },
            {
              colSize: 8,
              name: 'status_id',
              type: 'hidden',
              filter: (dt, col) => multiSelectFilter(dt, col),
              form: 'status_id',
              id: 'status_id',
              grid_value: (row) => property(row.status, 'name'),
              grid: true,
              grid_order: 90,
              label: 'Status',
              options: (apiStore) => apiStore.statuses,
              rules: 'required|integer',
              show_value: (row) => property(row.status, 'name'),
              show_order: 90,
              utaType: 'status',
            },
          ],
        },
      ]
    },
    {
      name: 'Domo Integrations',
      abbrev: 'DomoInt',
      codename: 'domoIntegrations',
      endpoint: 'domo-integrations',
      icon: DomoLogo,
      adminChannel: true,
      showViewIcon: false,
      subtitle: 'Create or manage Domo Integrations',
      hasStatus: false,
      models: [
        {
          allFilterData: (apiStore) => apiStore['domoConnectionsFilterOptions'],
          codename: 'DomoConnections',
          controller: 'domo_connection',
          disableBulkEdit: false,
          childModelFilter: (root) => { return {}; },
          endpoint: 'domo-connections',
          form: () => <GenericForm datePicker={2} numButtons={2} />,
          genericModelPromiseEndpoints: ['brands'],
          hasStatus: false,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.domoConnectionsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          name: 'Connections',
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          provisionButton: true,
          permRequest: false,
          route: 'connections',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'connection',
          sortField: 'brand',
          tabIndex: 0,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.domoConnectionsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'id',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 8,
              form: 'brand_id',
              formField: (rootStore) => (<UccSelect
                core="brand"
                fieldName="brand_id"
                form={rootStore.channel.model.crudStore.form}
                label="Brand"
                menuItems={rootStore.channel.model.crudStore.action == 'edit' ? rootStore.apiStore.brands.map((p) => rootStore.uiStore.makePrimeRegularOption(p)) : rootStore.apiStore.brands.filter(b => !b.domo_configured).map((p) => rootStore.uiStore.makePrimeRegularOption(p))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('brand_id', event)}
                disabled={(rootStore.channel.model.crudStore.action == 'edit')}
                value={rootStore.channel.model.crudStore.storedData['brand_id']}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => property(row.brand, 'name'),
              id: 'brand',
              label: 'Brand',
              name: 'brand',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.brands,
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.brand, 'name'),
              show_order: 20,
              utaType: 'fk',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.pending_namestrings_count,
              id: 'pending_namestrings_count',
              label: 'Pending',
              name: 'pending_namestrings_count',
              rules: 'integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.pending_namestrings_count,
              show_order: 30,
              sortable: false,
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.pushed_namestrings_count,
              id: 'pushed_namestrings_count',
              label: 'Published',
              name: 'pushed_namestrings_count',
              rules: 'integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.pushed_namestrings_count,
              show_order: 40,
              sortable: false,
            },
            {
              copyEditable: true,
              colSize: 12,
              form: 'domo_instance_url',
              formField: (rootStore) => (<UccInputText
                label="Domo Instance URL"
                core="domo_instance_url"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('domo_instance_url', event)}
                fieldName="domo_instance_url"
              />),
              formOrder: 30,
              grid: true,
              grid_order: 50,
              grid_value: (row) => <a href={row.domo_instance_url} target="_blank">{row.domo_instance_url}</a>,
              id: 'domo_instance_url',
              label: 'Instance URL',
              name: 'domo_instance_url',
              rules: 'required|string',
              show: true,
              show_value: (row) => <a href={row.domo_instance_url} target="_blank">{row.domo_instance_url}</a>,
              show_order: 50,
              sortable: false,
              utaType: 'string',
            },
            {
              form: 'domo_client_id',
              formField: (rootStore) => (<UccInputText
                label="Domo Client"
                core="domo_client_id"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('domo_client_id', event)}
                fieldName="domo_client_id"
              />),
              formOrder: 40,
              label: 'Domo Client',
              rules: 'required|string',
              type: 'hidden',
            },
            {
              form: 'domo_client_secret',
              formField: (rootStore) => (<UccInputText
                label="Domo Client Secret"
                core="domo_client_secret"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('domo_client_secret', event)}
                fieldName="domo_client_secret"
              />),
              formOrder: 50,
              label: 'Domo Client Secret',
              rules: 'required|string',
              type: 'hidden',
            },
          ],
        },
        {
          allFilterData: (apiStore) => apiStore['channelDomoDatasetsFilterOptions'],
          codename: 'DomoDatasets',
          controller: 'channel_domo_dataset',
          disableBulkEdit: true,
          endpoint: 'channel-domo-datasets',
          form: () => <GenericForm datePicker={2} numButtons={2} />,
          genericModelPromiseEndpoints: ['channels', 'channel-domo-datasets'],
          hasStatus: false,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.channelDomoDatasetsCrud.getFilterOptions({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          name: 'Datasets',
          noAddButton: true,
          noToolbar: true,
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          route: 'datasets',
          permRequest: false,
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'dataset',
          sortField: 'brand',
          tabIndex: 1,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.channelDomoDatasetsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'id',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => property(row.brand, 'name'),
              id: 'brand',
              label: 'Brand',
              name: 'brand',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.brands,
              rules: 'string',
              show: true,
              show_value: (row) => property(row.brand, 'name'),
              show_order: 20,
              utaType: 'parent',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.status,
              id: 'status',
              label: 'Status',
              name: 'status',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => _.uniq(apiStore.channelDomoDatasets.map(cdd => cdd.status)),
              rules: 'string',
              show: true,
              show_value: (row) => row.status,
              show_order: 30,
              utaType: 'string',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 40,
              grid_value: (row) => property(row.channel, 'name'),
              id: 'channel',
              label: 'Channel',
              name: 'channel',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.channels,
              rules: 'string',
              show: true,
              show_value: (row) => property(row.channel, 'name'),
              show_order: 40,
              utaType: 'string',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.pending_namestrings_count,
              id: 'pending_namestrings_count',
              label: 'Pending',
              name: 'pending_namestrings_count',
              rules: 'integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.pending_namestrings_count,
              show_order: 50,
              sortable: false,
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 60,
              grid_value: (row) => row.pushed_namestrings_count,
              id: 'pushed_namestrings_count',
              label: 'Published',
              name: 'pushed_namestrings_count',
              rules: 'integer',
              utaType: 'integer',
              show: true,
              show_value: (row) => row.pushed_namestrings_count,
              show_order: 60,
              sortable: false,
            },
            {
              colSize: 12,
              grid: true,
              grid_order: 70,
              grid_value: (row) => <a href={`${row.brand.domo_instance_url}/datasources/${row.dataset_id}/details/overview`} target="_blank">{`${row.brand.domo_instance_url}/datasources/${row.dataset_id}/details/overview`}</a>,
              id: 'dataset_url',
              label: 'Dataset URL',
              name: 'dataset url',
              rules: 'string',
              show: true,
              show_value: (row) => <a href={`${row.brand.domo_instance_url}/datasources/${row.dataset_id}/details/overview`} target="_blank">{`${row.brand.domo_instance_url}/datasources/${row.dataset_id}/details/overview`}</a>,
              show_order: 70,
              sortable: false,
              utaType: 'string',
            },
          ].concat(dateField('updated_at', 'Last Updated', 'blank', false, 80, 80, 'U.', 80)),
        },
        {
          allFilterData: (apiStore) => apiStore['namestringsFilterOptions'],
          codename: 'DomoNamestrings',
          controller: 'namestring',
          disableBulkEdit: true,
          doesStats: true,
          endpoint: 'namestrings',
          form: () => <GenericForm datePicker={2} numButtons={2} />,
          genericModelPromiseEndpoints: ['brands', 'channels', 'namestrings'],
          hasStatus: false,
          modelFilter: (root) => { return {}; },
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.namestringsCrud.getFilterOptions({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
          ]),
          name: 'Details',
          noAddButton: true,
          noToolbar: true,
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 20,
          route: 'details',
          permRequest: false,
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'detail',
          tabIndex: 2,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.namestringsCrud,
          sortField: 'created_at',
          sortOrder: 'desc',
          columns: [
            {
              colSize: 8,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 20,
              id: 'id',
              label: 'Item Id',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              stat_name: 'nsid',
              stat_type: 'id',
              type: 'hidden',
            },
            {
              colSize: 6,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 1,
              grid_value: (row) => row.nsid,
              grid_link: 'draft_edit',
              id: 'nsid',
              label: 'NSID',
              name: 'nsid',
              permListAndView: 80,
              rules: 'integer',
              show: true,
              show_value: (row) => row.nsid,
              show_order: 5,
              stat_name: 'id',
              stat_type: 'id',
              utaType: 'integer',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: false,
              grid_order: 7,
              grid_value: (row) => row.namestring,
              id: 'namestring',
              label: 'Namestring String',
              name: 'namestring',
              rules: 'string',
              show: true,
              show_value: (row) => row.namestring,
              show_order: 7,
              stat_type: 'string',
              utaType: 'string',
            },
            {
              colSize: 10,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: false,
              grid_order: 7.5,
              grid_value: (row) => row.utm_string,
              id: 'utm_string',
              label: 'UTM String',
              name: 'utm_string',
              rules: 'string',
              show: true,
              show_value: (row) => row.utm_string,
              show_order: 7.5,
              stat_name: 'utmstring',
              stat_type: 'string',
              utaType: 'string',
            },
            {
              colSize: 13,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: false,
              grid_order: 7.6,
              grid_value: (row) => row.utm_string,
              id: 'short_utm_string',
              label: 'Short UTM String',
              name: 'short_utm_string',
              rules: 'string',
              show: true,
              show_value: (row) => row.utm_string,
              show_order: 7.6,
              stat_type: 'string',
              utaType: 'string',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.status,
              id: 'status',
              label: 'Status',
              name: 'status',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.status)),
              rules: 'string',
              show: true,
              show_value: (row) => row.status,
              show_order: 30,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.brand,
              id: 'brand',
              label: 'Brand',
              name: 'brand',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.brand)),
              rules: 'string',
              show: true,
              show_value: (row) => row.brand,
              show_order: 40,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.channel,
              id: 'channel',
              label: 'Channel',
              name: 'channel',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.channel)),
              rules: 'string',
              utaType: 'string',
              show: true,
              show_value: (row) => row.channel,
              show_order: 50,
              stat_type: 'distinct',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 60,
              grid_value: (row) => row.ns_type,
              id: 'ns_type',
              label: 'Type',
              name: 'ns_type',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.ns_type)),
              rules: 'string',
              utaType: 'string',
              show: true,
              show_value: (row) => row.ns_type,
              show_order: 60,
              stat_type: 'distinct',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 70,
              grid_value: (row) => row.campaign_type,
              id: 'campaign_type',
              label: 'Campaign Type',
              name: 'campaign_type',
              rules: 'string',
              show: true,
              show_value: (row) => row.campaign_type,
              show_order: 70,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 90,
              grid_value: (row) => row.objective,
              id: 'objective',
              label: 'Objective',
              name: 'objective',
              rules: 'string',
              show: true,
              show_value: (row) => row.objective,
              show_order: 90,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 100,
              grid_value: (row) => row.tactic,
              id: 'tactic',
              label: 'Tactic',
              name: 'tactic',
              rules: 'string',
              show: true,
              show_value: (row) => row.tactic,
              show_order: 100,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 9,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 110,
              grid_value: (row) => row.suppression,
              id: 'suppression',
              label: 'Suppression',
              name: 'suppression',
              rules: 'string',
              show: true,
              show_value: (row) => row.suppression,
              show_order: 110,
              utaType: 'string',
              stat_type: 'distinct',

            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 120,
              grid_value: (row) => row.targeting,
              id: 'targeting',
              label: 'Targeting',
              name: 'targeting',
              rules: 'string',
              show: true,
              show_value: (row) => row.targeting,
              show_order: 120,
              stat_type: 'distinct',
              utaType: 'string',

            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 130,
              grid_value: (row) => row.device,
              id: 'device',
              label: 'Device',
              name: 'device',
              rules: 'string',
              show: true,
              show_value: (row) => row.device,
              show_order: 130,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 11,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 130,
              grid_value: (row) => row.ad_name,
              id: 'ad_name',
              label: 'Ad Name',
              name: 'ad_name',
              rules: 'string',
              show: true,
              show_value: (row) => row.ad_name,
              show_order: 130,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 11,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 132,
              grid_value: (row) => row.ad_type,
              id: 'ad_type',
              label: 'Ad Type',
              name: 'ad_type',
              rules: 'string',
              show: true,
              show_value: (row) => row.ad_type,
              show_order: 132,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 140,
              grid_value: (row) => row.gender,
              id: 'gender',
              label: 'Gender',
              name: 'gender',
              rules: 'string',
              show: true,
              show_value: (row) => row.gender,
              show_order: 140,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 150,
              grid_value: (row) => row.geo,
              id: 'geo',
              label: 'Geo',
              name: 'geo',
              rules: 'string',
              show: true,
              show_value: (row) => row.geo,
              show_order: 150,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 150,
              grid_value: (row) => row.language,
              id: 'language',
              label: 'Language',
              name: 'language',
              rules: 'string',
              show: true,
              show_value: (row) => row.language,
              show_order: 150,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 160,
              grid_value: (row) => row.platform,
              id: 'platform',
              label: 'Plaform',
              name: 'platform',
              rules: 'string',
              show: true,
              show_value: (row) => row.platform,
              show_order: 160,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 170,
              grid_value: (row) => row.display_types,
              id: 'display_types',
              label: 'Display Types',
              name: 'display_types',
              rules: 'string',
              show: true,
              show_value: (row) => row.display_types,
              show_order: 170,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 180,
              grid_value: (row) => row.region,
              id: 'region',
              label: 'Region',
              name: 'region',
              rules: 'string',
              show: true,
              show_value: (row) => row.region,
              show_order: 180,
              stat_type: 'distinct',
              utaType: 'string',
            },
            {
              colSize: 8,
              grid: true,
              grid_order: 225,
              grid_value: (row) => row.created_by,
              id: 'created_by',
              label: 'Created By',
              name: 'created_by',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.created_by)),
              rules: 'string',
              utaType: 'string',
              show: true,
              show_value: (row) => row.created_by,
              show_order: 225,
              stat_type: 'distinct',
            },
          ].concat(
            dateField('start_date', 'Start Date', 'blank', true, 210, 210, 'S.', 210),
            dateField('end_date', 'End Date', 'blank', false, 220, 220, 'E.', 220),
            dateField('created_at', 'Date Created', 'blank', false, 230, 230, 'C.', 230)
          ),
        },
      ],
    },
    {
      abbrev: 'FaceInt',
      adminChannel: true,
      codename: 'facebookIntegrations',
      endpoint: 'facebook-integrations',
      icon: FacebookLogo,
      name: 'Facebook Integrations',
      subtitle: 'Create or manage Facebook Integrations',
      hasStatus: false,
      models: [
        {
          allFilterData: (apiStore) => apiStore['facebookConnectionsFilterOptions'],
          codename: 'FacebookConnections',
          controller: 'facebook_connection',
          disableBulkEdit: true,
          endpoint: 'facebook-connections',
          filterOptionsCall: (apiStore) => (val) => apiStore['facebookConnectionsFilterOptions'] = val,
          form: (rootStore) => <GenericForm numButtons={2} />,
          genericModelPromiseEndpoints: ['brands'],
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.facebookConnectionsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          // modelFilter: (root) => _.merge({ social_adset_id: root.paidSocial.SocAdSet.crudStore.selectedRowIds, }, { social_campaign_id: root.paidSocial.SocCamp.crudStore.selectedRowIds, }, { brand_id: root.apiStore.currentBrandId }),
          modelFilter: (root) => { return {}; },
          name: 'Connections',
          noExport: true,
          permCreateAndEdit: 60,
          permDelete: 60,
          permListAndView: 60,
          route: 'connections',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'connection',
          sortField: 'enable_fb',
          sortOrder: 'desc',
          tabIndex: 0,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.facebookConnectionsCrud,
          columns: [
            {
              colSize: 6,
              form: 'id',
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_value: (row) => row.id,
              grid_order: 1,
              id: 'id',
              label: 'ID',
              name: 'id',
              permListAndView: 80,
              rules: 'integer',
              type: 'hidden',
            },
            {
              colSize: 14,
              form: 'brand_id',
              formField: (rootStore) => (<UccSelect
                core="brand"
                fieldName="brand_id"
                form={rootStore.channel.model.crudStore.form}
                label="Brand"
                menuItems={rootStore.channel.model.crudStore.action == 'edit' ? rootStore.apiStore.brands.filter(b => b.facebook_configured).map((p) => rootStore.uiStore.makePrimeRegularOption(p)) : rootStore.apiStore.brands.filter(b => !b.facebook_configured).map((p) => rootStore.uiStore.makePrimeRegularOption(p))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('brand_id', event)}
                disabled={(rootStore.channel.model.crudStore.action == 'edit')}
                value={rootStore.channel.model.crudStore.storedData['brand_id']}
              />),
              formOrder: 20,
              grid: true,
              grid_order: 20,
              grid_link: 'draft_edit',
              grid_value: (row) => property(row.brand, 'name'),
              id: 'brand',
              label: 'Brand',
              name: 'brand',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.brands,
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.brand, 'name'),
              show_order: 20,
              utaType: 'fk',
            },

            {
              colSize: 8,
              form: 'enable_fb',
              formField: (rootStore) => (<UccCheckBox
                core="enable_fb"
                fieldName="enable_fb"
                form={rootStore.channel.model.crudStore.form}
                label="API Enabled"
                onChange={(event) => rootStore.channel.model.crudStore.storeData('enable_fb', event)}
              />),
              formOrder: 30,
              grid: true,
              grid_order: 30,
              grid_value: (row) => (row.enable_fb ? 'Yes' : 'No'),
              id: 'enable_fb',
              label: 'Enabled',
              name: 'enable_fb',
              type: 'checkbox',
              utaType: 'boolean',
              rules: 'boolean',
              show: true,
              show_value: (row) => (row.enable_fb ? 'Yes' : 'No'),
              show_order: 30
            },
            {
              form: 'facebook_access_token',
              formField: (rootStore) => (<UccInputText
                label="FB Access Token"
                core="facebook_access_token"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_access_token', event)}
                fieldName="facebook_access_token"
              />),
              formOrder: 40,
              label: 'FB Access Token',
              rules: 'required|string',
              type: 'hidden',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'facebook_ad_account_id',
              formField: (rootStore) => (<UccInputText
                label="FB Ad Account"
                core="facebook_ad_account_id"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_ad_account_id', event)}
                fieldName="facebook_ad_account_id"
              />),
              formOrder: 50,
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.facebook_ad_account_id,
              id: 'facebook_ad_account_id',
              label: 'FB Ad Account',
              name: 'facebook_ad_account_id',
              rules: 'required|string',
              show: true,
              show_value: (row) => row.facebook_ad_account_id,
              show_order: 50,
              sortable: true,
              utaType: 'string',
            },

            {
              form: 'facebook_app_secret',
              formField: (rootStore) => (<UccInputText
                label="FB App Secret"
                core="facebook_app_secret"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_app_secret', event)}
                fieldName="facebook_app_secret"
              />),
              formOrder: 60,
              label: 'Facebook App Secret',
              rules: 'required|string',
              type: 'hidden',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'facebook_application_id',
              formField: (rootStore) => (<UccInputText
                label="FB Application Id"
                core="facebook_application_id"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_application_id', event)}
                fieldName="facebook_application_id"
              />),
              formOrder: 70,
              grid: true,
              grid_order: 70,
              grid_value: (row) => row.facebook_application_id,
              id: 'facebook_application_id',
              label: 'FB Application Id',
              name: 'facebook_application_id',
              rules: 'required|string',
              show: true,
              show_value: (row) => row.facebook_application_id,
              show_order: 70,
              sortable: true,
              utaType: 'string',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'facebook_business_id',
              formField: (rootStore) => (<UccInputText
                label="FB Business Id"
                core="facebook_business_id"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_business_id', event)}
                fieldName="facebook_business_id"
              />),
              formOrder: 80,
              grid: true,
              grid_order: 80,
              grid_value: (row) => row.facebook_business_id,
              id: 'facebook_business_id',
              label: 'FB Business Id',
              name: 'facebook_business_id',
              rules: 'required|string',
              show: true,
              show_value: (row) => row.facebook_business_id,
              show_order: 80,
              sortable: true,
              utaType: 'string',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'facebook_creative_default_id',
              formField: (rootStore) => (<UccInputText
                label="Default Creative Id"
                core="facebook_creative_default_id"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_creative_default_id', event)}
                fieldName="facebook_creative_default_id"
              />),
              formOrder: 90,
              grid: true,
              grid_order: 90,
              grid_value: (row) => row.facebook_creative_default_id,
              id: 'facebook_creative_default_id',
              label: 'Default Creative Id',
              name: 'facebook_creative_default_id',
              rules: 'required|string',
              show: true,
              show_value: (row) => row.facebook_creative_default_id,
              show_order: 90,
              sortable: true,
              utaType: 'string',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'facebook_creative_default_message',
              formField: (rootStore) => (<UccInputText
                label="Default Creative Message"
                core="facebook_creative_default_message"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_creative_default_message', event)}
                fieldName="facebook_creative_default_message"
              />),
              formOrder: 100,
              grid: true,
              grid_order: 100,
              grid_value: (row) => row.facebook_creative_default_message,
              id: 'facebook_creative_default_message',
              label: 'Default Creative Message',
              name: 'facebook_creative_default_message',
              rules: 'required|string',
              show: true,
              show_value: (row) => row.facebook_creative_default_message,
              show_order: 100,
              sortable: true,
              utaType: 'string',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'facebook_creative_link',
              formField: (rootStore) => (<UccInputText
                label="Default Creative Link"
                core="facebook_creative_link"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_creative_link', event)}
                fieldName="facebook_creative_link"
              />),
              formOrder: 110,
              grid: true,
              grid_order: 110,
              grid_value: (row) => row.facebook_creative_link,
              id: 'facebook_creative_link',
              label: 'Default Creative Link',
              name: 'facebook_creative_link',
              rules: 'required|string',
              show: true,
              show_value: (row) => row.facebook_creative_link,
              show_order: 110,
              sortable: true,
              utaType: 'string',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'facebook_image_hash',
              formField: (rootStore) => (<UccInputText
                label="Creative Image Hash"
                core="facebook_image_hash"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_image_hash', event)}
                fieldName="facebook_image_hash"
              />),
              formOrder: 120,
              grid: true,
              grid_order: 120,
              grid_value: (row) => row.facebook_image_hash,
              id: 'facebook_image_hash',
              label: 'Creative Image Hash',
              name: 'facebook_image_hash',
              rules: 'required|string',
              show: true,
              show_value: (row) => row.facebook_image_hash,
              show_order: 120,
              sortable: true,
              utaType: 'string',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'facebook_page_id',
              formField: (rootStore) => (<UccInputText
                label="FB Page Id"
                core="facebook_page_id"
                form={rootStore.channel.model.crudStore.form}
                onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_page_id', event)}
                fieldName="facebook_page_id"
              />),
              formOrder: 85,
              grid: true,
              grid_order: 85,
              grid_value: (row) => row.facebook_page_id,
              id: 'facebook_page_id',
              label: 'FB Page Id',
              name: 'facebook_page_id',
              rules: 'required|string',
              show: true,
              show_value: (row) => row.facebook_page_id,
              show_order: 85,
              sortable: true,
              utaType: 'string',
            },
          ]
        },

        {
          allFilterData: (apiStore) => apiStore['facebookObjectsFilterOptions'],
          codename: 'FacebookObjects',
          controller: 'facebook-object',
          disableBulkEdit: true,
          endpoint: 'facebook-objects',
          filterOptionsCall: (apiStore) => (val) => apiStore['facebookObjectsFilterOptions'] = val,
          form: () => <AttrValuesForm />,
          genericModelPromiseEndpoints: ['facebook-objects'],
          modelPromiseEndpoints: (apiStore, brand_id) => ([
            apiStore.facebookObjectsCrud.getFilterOptions({ brand_id: brand_id }, ''),
          ]),
          // modelFilter: (root) => _.merge({ social_adset_id: root.paidSocial.SocAdSet.crudStore.selectedRowIds, }, { social_campaign_id: root.paidSocial.SocCamp.crudStore.selectedRowIds, }, { brand_id: root.apiStore.currentBrandId }),
          modelFilter: (root) => { return {}; },
          noAddButton: true,
          noToolbar: true,
          noEdit: true,
          name: 'Response Detail',
          permCreateAndEdit: 80,
          permDelete: 80,
          permListAndView: 60,
          route: 'objects',
          send2domo: false,
          showFormat: 'definitionList',
          singular: 'object',
          sortField: 'id',
          sortOrder: 'desc',
          tabIndex: 0,
          tabLabel: (m) => m.name,
          tabDisabled: () => false,
          noDataText: (root) => '',
          requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
          crud: (rootStore) => rootStore.facebookObjectsCrud,
          columns: [
            {
              colSize: 6,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 20,
              grid_value: (row) => row.id,
              id: 'id',
              label: 'id',
              name: 'id',
              permListAndView: 80,
              show: true,
              show_value: (row) => row.id,
              show_order: 20,
            },
            {
              colSize: 8,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 22,
              grid_value: (row) => row.namestring_id,
              id: 'namestring_id',
              label: 'NSID',
              name: 'namestring_id',
              show: true,
              show_value: (row) => row.namestring_id,
              show_order: 22,
            },
            // {
            //   colSize: 8,
            //   filter: (dt, col) => multiSelectFilter(dt, col),
            //   grid: true,
            //   grid_order: 22,
            //   grid_value: row) => property(row.brand, 'name'),
            //   grid_link: 'draft_edit',
            //   id: 'brand',
            //   label: 'Brand',
            //   name: 'brand',
            //   show: true,
            //   show_value: row) => property(row.brand, 'name'),
            //   show_order: 22,
            //   utaType: 'fk',
            // },
            {
              colSize: 14,
              grid: true,
              grid_order: 22,
              grid_link: 'draft_edit',
              grid_value: (row) => property(row.brand, 'name'),
              id: 'brand',
              label: 'Brand',
              name: 'brand_id',
              filter: (dt, col) => multiSelectFilter(dt, col),
              options: (apiStore) => apiStore.brands,
              rules: 'required|integer',
              show: true,
              show_value: (row) => property(row.brand, 'name'),
              show_order: 22,
              utaType: 'fk',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              form: 'business_model_type',
              grid: true,
              grid_order: 30,
              grid_value: (row) => row.business_model_type.replace('Social', ''),
              id: 'business_model_type',
              label: 'Namestring Type',
              name: 'business_model_type',
              show: true,
              show_value: (row) => row.business_model_type,
              show_order: 30,
              sortable: true,
            },
            {
              colSize: 13,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 40,
              grid_value: (row) => row.business_model_id,
              id: 'business_model_id',
              label: 'Business Resource Id',
              name: 'business_model_id',
              permListAndView: 80,
              show: true,
              show_value: (row) => row.business_model_id,
              show_order: 40,
              sortable: true,
              utaType: 'id',
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: false,
              grid_order: 45,
              grid_value: (row) => (<a href={`/app/admin/user-management/brands/${row.brand_id}`} target="_blank">{row.brand_id}</a>),
              id: 'brand_id',
              label: 'Brand Id',
              name: 'brand_id',
              show: true,
              show_value: (row) => row.brand_id,
              show_order: 45,
              sortable: true,
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 50,
              grid_value: (row) => row.status,
              id: 'status',
              label: 'Status',
              name: 'status',
              show: true,
              show_value: (row) => row.status,
              show_order: 50,
              sortable: true,
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 60,
              grid_value: (row) => row.facebook_id,
              id: 'facebook_id',
              label: 'facebook_id',
              name: 'facebook_id',
              show: true,
              show_value: (row) => row.facebook_id,
              show_order: 60,
              sortable: true,
            },
            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 70,
              grid_value: (row) => row.pushed_namestring,
              id: 'pushed_namestring',
              label: 'Pushed Namestring',
              name: 'pushed_namestring',
              show: true,
              show_value: (row) => row.pushed_namestring,
              show_order: 70,
              sortable: true,
            },

            {
              colSize: 12,
              filter: (dt, col) => inputTextFilter(dt, col),
              grid: true,
              grid_order: 80,
              grid_value: (row) => row.details,
              id: 'details',
              label: 'Details',
              name: 'details',
              show: true,
              show_value: (row) => row.details,
              show_order: 80,
              sortable: true,
            },
            {
              colSize: 12,
              filter: (dt, col) => dateFilter(dt, col),
              grid: true,
              grid_order: 90,
              grid_value: (row) => row.created_at,
              id: 'created_at',
              label: 'Push Date',
              name: 'created_at',
              show: true,
              show_value: (row) => row.created_at,
              show_order: 90,
              sortable: true,
            },
            {
              colSize: 12,
              filter: (dt, col) => dateFilter(dt, col),
              grid: true,
              grid_order: 100,
              grid_value: (row) => row.updated_at,
              id: 'updated_at',
              label: 'Updated Date',
              name: 'updated_at',
              show: true,
              show_value: (row) => row.updated_at,
              show_order: 100,
              sortable: true,
            },
          ],
        },
      ],
    }
  ],
};

function displayPlacementModelMetadata(channel, tabIndex) {
  // ______ _                                     _
  // | ___ \ |                                   | |
  // | |_/ / | __ _  ___ ___ _ __ ___   ___ _ __ | |_ ___
  // |  __/| |/ _` | / __/ _ \ '_ ` _ \ / _ \ '_ \| __ / __ |
  // | |   | | (_| | (_|  __/ | | | | |  __/ | | | |_\__ \
  // \_|   |_|\__,_|\___\___|_| |_| |_|\___|_| |_|\__|___/
  return [
    {
      allFilterData: (apiStore) => apiStore[apiStore.rootStore.channel.codename + 'PlacementsFilterOptions'],
      codename: 'DispPlace',
      controller: 'display_placement',
      crud: (rootStore) => rootStore.displayPlacementsCrud,
      domoNsType: (channel) => { return ((channel == 'display' || channel == 2) ? 'DisplayPlacement' : 'ProgrammaticPlacement') },
      endpoint: 'display-placements',
      filterOptionsCall: (apiStore) => (val) => apiStore[apiStore.rootStore.channel.codename + 'PlacementsFilterOptions'] = val,
      form: (rootStore) => <GenericForm type="Placement" datePicker={2} numButtons={3} extraFields={['size_id']} />,
      genericModelPromiseEndpoints: [
        'audiences',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-genders',
        'channel-objectives',
        'channel-tactics',
        'channel-targetings',
        'creative-types',
        'devices',
        'display-types',
        'display-type-channels',
        'genders',
        'geos',
        'languages',
        'marketing-campaigns',
        'objectives',
        'publishers',
        'sizes',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => {
        return ([
          apiStore[apiStore.channel.codename + 'PlacementsCrud'].getFilterOptions({ brand_id: brand_id, channel_id: channel }, ''),
          apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType(channel), brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
        ])
      },

      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, channel_id: channel }; },
      name: 'Placements',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'placements',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'placement',
      tabDisabled: () => false,
      tabIndex: tabIndex,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 13,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 10,
          grid_type: 'namestring',
          filter: false,
          id: 'namestring',
          filter: false,
          filterField: 'namestring',
          label: 'Namestring',
          rules: 'required'
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: (crudStore) => crudStore.channel.name + 'Placement',
          rules: 'required',
        },
        {
          default: channel,
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          form: 'channel_id',
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: () => 'hmm',
          rules: 'required',
          type: 'hidden',
          utaType: 'channel_id',
        },
        {
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 180,
          label: 'Created By',
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.Users
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: () => 'Inactive',
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 30,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          label: 'Group',
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          ns_order: 10,
          rules: 'required',
          utaType: 'group',
        },
        {
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          type: 'hidden',
          utaType: 'brand',
          rules: 'required',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          label: 'Brand',
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },

        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType)))}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 1,
          label: 'Campaign Type',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 11,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
          grid: true,
          grid_order: 40,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          grid_value: (row) => property(row.campaign_type, 'name'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          ns_order: 30,
          show: true,
          show_value: (row) => property(row.campaign_type, "name"),
          show_order: 34,
          rules: 'required',
        },
        {
          colSize: 9,
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 50,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectives,
          grid_value: (row) => property(row.objective, 'name'),
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          ns: (crudStore) => abbrev(crudStore.storedData.objective),
          ns_order: 35,
          show: true,
          show_value: (row) => property(row.objective, "name"),
          show_order: 35,
          rules: 'required',
        },
        {
          belongsTo: ['objective', 'objectives'],
          form: 'objective_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            form={rootStore.channel.model.crudStore.form}
            label="Objective"
            menuItems={_.compact(rootStore.apiStore.objectives.map(objective => rootStore.uiStore.makePrimeObjectiveOption(objective)))}
            onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 2,
          label: 'Objective',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 9,
          // domo_field: 'ad_name',
          // domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          // form: 'name',
          // copyEditable: true,
          // formField: (rootStore) => (
          //   <UccInputText
          //     core="name"
          //     fieldName="name"
          //     form={rootStore.channel.model.crudStore.form}
          //     label="Placement Description"
          //     onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          //   />),
          // formOrder: 3,
          grid: true,
          filter: true,
          grid_order: 60,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          filter_placeholder: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          name: 'name',
          // ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          // ns_order: 45,
          // rules: 'required|string',
          show: true,
          show_value: (row) => row.name,
          show_order: 45,
          utaType: 'name',
        },
        {
          colSize: 13,
          domo_field: (crudStore) => crudStore.channel.codename == 'programmatic' ? 'campaign_name' : 'ad_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 55,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Marketing Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 45,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.marketing_campaign, 'name'),
          show_order: 60,
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Marketing Campaign"
            menuItems={_.compact(rootStore.apiStore.marketingCampaigns.map(marketingCampaign => rootStore.uiStore.makePrimeRegularOption(marketingCampaign)))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 2,
          label: 'Marketing Campaign',
          rules: 'required|integer',
          utaType: 'fk',
        },

        {
          domo_field: 'publisher',
          domo_value: (crudStore) => property(crudStore.storedData.publisher, 'name'),
          grid: true,
          grid_order: 60,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.publishers,
          grid_value: (row) => property(row.publisher, 'name'),
          id: 'publisher',
          label: 'Publisher',
          name: 'publisher',
          ns: (crudStore) => abbrev(crudStore.storedData.publisher),
          ns_order: 50,
          show: true,
          show_value: (row) => property(row.publisher, "name"),
          show_order: 50,
          rules: 'required',
        },
        {
          belongsTo: ['publisher', 'publishers'],
          form: 'publisher_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="publisher"
            fieldName="publisher_id"
            form={rootStore.channel.model.crudStore.form}
            label="Publisher"
            menuItems={_.compact(rootStore.apiStore.publishers.map(publisher => rootStore.uiStore.makePrimeOption(publisher)))}
            onChange={event => rootStore.channel.model.crudStore.storeData('publisher_id', event)}
          />),
          formOrder: 5,
          label: 'Publisher',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (<UccSelect
            core="tactic"
            fieldName="tactic_id"
            form={rootStore.channel.model.crudStore.form}
            label="Tactic"
            menuItems={_.compact(rootStore.apiStore.tactics.map(tactic => rootStore.uiStore.makePrimeChannelSpecificOption(tactic, 'channelTactics')))}
            onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
          />),
          formOrder: 6,
          label: 'Tactic',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 70,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          grid_value: (row) => property(row.tactic, 'name'),
          id: 'tactic',
          label: 'Tactic',
          name: 'tactic',
          ns: (crudStore) => abbrev(crudStore.storedData.tactic),
          ns_order: 60,
          show: true,
          show_value: (row) => property(row.tactic, "name"),
          show_order: 60,
          rules: 'required|integer',
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          ns: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          ns_order: 75,
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 75,
          type: 'checkbox',
          utaType: 'boolean',
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          form: 'display_placement_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
          formOrder: 7,
          grid: true,
          grid_order: 80,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'display_placement_targetings_attributes',
          ns: (crudStore) => NameString.targetingString(crudStore),
          ns_order: 80,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "display_placement_targetings"),
          show_order: 80,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'display_placement_targetings_attributes[].id',
        },
        {
          form: 'display_placement_targetings_attributes[].display_placement_id',
        },
        {
          form: 'display_placement_targetings_attributes[].targeting_id',
        },
        {
          form: 'display_placement_targetings_attributes[].extra',
        },
        {
          form: 'display_placement_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'display_placement_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'display_placement_targetings_attributes[].on',
        },
        {
          belongsTo: ['device', 'devices'],
          form: 'device_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="device"
            fieldName="device_id"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            menuItems={_.compact(rootStore.apiStore.devices.map(device => rootStore.uiStore.makePrimeOption(device)))}
            onChange={event => rootStore.channel.model.crudStore.storeData('device_id', event)}
          />),
          formOrder: 9,
          label: 'Device',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.device, 'name'),
          show_order: 90,
          utaType: 'fk',
        },
        {
          domo_field: 'device',
          domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
          grid: true,
          grid_order: 80,
          grid_value: (row) => property(row.device, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.devices,
          id: 'device',
          label: 'Device',
          name: 'device',
          ns: (crudStore) => abbrev(crudStore.storedData.device),
          ns_order: 90,
          rules: 'required|integer',
        },
        {
          belongsTo: ['display_types', 'displayTypes'],
          form: 'display_type_ids',
          copyEditable: true,
          formField: (rootStore) => (<UccMultiSelect
            core="display-types"
            fieldName="display_type_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Display Types"
            menuItems={rootStore.apiStore.displayTypes.map((p) => ({ label: p.name, value: p.id, }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('display_type_ids', event)}
          />),
          formOrder: 3,
          label: 'Display Type',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 12,
          domo_field: 'display_types',
          domo_value: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
          grid: true,
          grid_order: 56,
          grid_value: (row) => row.display_types.map(c => c.name).sort().join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.displayTypes,
          id: 'display_types',
          label: 'Display Types',
          multiple: [],
          name: 'display_types',
          ns: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
          ns_order: 46,
          rules: 'array',
          show: true,
          show_value: (row) => row.display_types.map(c => c.name).join(', '),
          show_order: 61,
          utaType: 'array',
          sortable: false
        },
        {
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          copyEditable: true,
          formField: (rootStore) => (
            <UccSelect
              core="geo"
              fieldName="geo_id"
              form={rootStore.channel.model.crudStore.form}
              label="Geo"
              menuItems={_.compact(rootStore.apiStore.geos.map(geo => rootStore.uiStore.makePrimeOption(geo)))}
              onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
            />
          ),
          formOrder: 10,
          label: 'Geo',
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 100,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          grid_order: 100,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          label: 'Geo',
          name: 'geo',
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          ns_order: 100,
          rules: 'required|integer',
        },
        {
          colSize: 15,
          domo_field: 'region',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.region),
          form: 'region',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="region"
            fieldName="region"
            form={rootStore.channel.model.crudStore.form}
            label="Region"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
          />),
          formOrder: 11,
          grid: true,
          grid_order: 105,
          grid_value: (row) => row.region,
          id: 'region',
          name: 'region',
          ns: (crudStore) => crudStore.storedData.region,
          ns_order: 103,
          label: 'Region',
          utaType: 'string',
          rules: ['string', abbrev_regex],
          // rules: ['string'],
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.region,
          show_order: 105,
        },
        {
          belongsTo: ['language', 'languages'],
          form: 'language_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="language"
            fieldName="language_id"
            form={rootStore.channel.model.crudStore.form}
            label="Language"
            menuItems={_.compact(rootStore.apiStore.languages.map(language => rootStore.uiStore.makePrimeRegularOption(language)))}
            onChange={event => rootStore.channel.model.crudStore.storeData('language_id', event)}
          />),
          formOrder: 12,
          label: 'Language',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 110,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 110,
          grid_value: (row) => property(row.language, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          id: 'language',
          label: 'Language',
          name: 'language',
          ns: (crudStore) => abbrev(crudStore.storedData.language),
          ns_order: 105,
          rules: 'integer',
        },
        {
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={_.compact(rootStore.apiStore.genders.map((gender) => rootStore.uiStore.makePrimeChannelSpecificOption(gender, 'channelGenders')))}
            onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 13,
          label: 'Gender',
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 120,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          grid_order: 105,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 110,
          rules: 'required|integer',
        },
        {
          belongsTo: ['size', 'sizes'],
          form: 'size_id',
          copyEditable: true,
          hideForm: true,
          formField: (rootStore) => (<UccSelect
            core="size"
            fieldName="size_id"
            form={rootStore.channel.model.crudStore.form}
            label="Size"
            menuItems={_.compact(rootStore.apiStore.sizes.map(size => rootStore.uiStore.makePrimeRegularOption(size)))}
            onChange={event => rootStore.channel.model.crudStore.storeData('size_id', event)}
          />),
          label: 'Size',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.size, 'name'),
          show_order: 130,
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'size',
          copyEditable: true,
          domo_value: (crudStore) => property(crudStore.storedData.size, 'name'),
          grid: true,
          grid_order: 130,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.sizes,
          grid_value: (row) => property(row.size, 'name'),
          id: 'size',
          label: 'Size',
          name: 'size',
          ns: (crudStore) => abbrev(crudStore.storedData.size),
          ns_order: 150,
          rules: 'required|integer',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 110, 130, 'S.', 130),
        dateField('end_date', 'End Date', 'blank', false, 120, 140, 'E.', 140),
      ),
    }
  ]
}


function displayAdModelMetadata(channel, tabIndex) {
  // ___   _            _                 _       _
  // |   \ (_) ___ _ __ | | __ _  _  _    /_\   __| | ___
  // | |) || |(_-<| '_ \| |/ _` || || |  / _ \ / _` |(_-<
  // |___/ |_|/__/| .__/|_|\__,_| \_, | /_/ \_\\__,_|/__/
  //              |_|             |__/
  return [
    {
      allFilterData: (apiStore) => apiStore[apiStore.rootStore.channel.codename + 'AdsFilterOptions'],
      codename: 'DispAd',
      controller: 'display_ad',
      crud: (rootStore) => rootStore.displayAdsCrud,
      domoNsType: (channel) => { return ((channel == 'display' || channel == 2) ? 'DisplayAd' : 'ProgrammaticAd') },
      endpoint: 'display-ads',
      filterOptionsCall: (apiStore) => (val) => apiStore[apiStore.rootStore.channel.codename + 'AdsFilterOptions'] = val,
      form: (rootStore) => <GenericForm type="Display Ad" datePicker={2} numButtons={3} extraFields={['size_id']} />,
      genericModelPromiseEndpoints: [
        'audiences',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-genders',
        'channel-objectives',
        'channel-tactics',
        'channel-targetings',
        'creative-types',
        'devices',
        'display-types',
        'display-type-channels',
        'genders',
        'geos',
        'languages',
        'marketing-campaigns',
        'objectives',
        'publishers',
        'sizes',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore[apiStore.channel.codename + 'AdsCrud'].getFilterOptions({ brand_id: brand_id, channel_id: channel }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType(channel), brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, channel_id: channel }; },
      name: 'Ads',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'ads',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'ad',
      tabDisabled: () => false,
      tabIndex: tabIndex,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 13,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 5,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'

        },
        {
          domo_field: 'ns_type',
          domo_value: (crudStore) => crudStore.channel.name + 'Ad',
          rules: 'required',
        },

        {
          default: channel,
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          form: 'channel_id',
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: () => 'hmm',
          rules: 'required',
          type: 'hidden',
          utaType: 'channel_id',
        },
        {
          colSize: 10,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'created_by',
          label: 'Created By',
          id: 'created_by',
          options: (apiStore) => apiStore.Users,
          grid: true,
          grid_order: 190,
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: () => 'Inactive',
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 15,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          ns_order: 10,
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          type: 'hidden',
          utaType: 'brand',
          rules: 'required',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType)))}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 10,
          label: 'Campaign Type',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 12,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
          grid: true,
          grid_order: 25,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          grid_value: (row) => property(row.campaign_type, 'name'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          ns_order: 30,
          show: true,
          show_value: (row) => property(row.campaign_type, "name"),
          show_order: 10,
        },
        {
          colSize: 9,
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 30,
          grid_value: (row) => property(row.objective, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectives,
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          ns: (crudStore) => abbrev(crudStore.storedData.objective),
          show: true,
          show_value: (row) => property(row.objective, "name"),
          show_order: 20,
          ns_order: 40,
        },
        {
          belongsTo: ['objective', 'objectives'],
          form: 'objective_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            form={rootStore.channel.model.crudStore.form}
            label="Objective"
            menuItems={_.compact(rootStore.apiStore.objectives.map((objective) => rootStore.uiStore.makePrimeObjectiveOption(objective)))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 20,
          label: 'Objective',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 13,
          domo_field: (crudStore) => crudStore.channel.codename == 'programmatic' ? 'campaign_name' : 'ad_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 27,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Marketing Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 45,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.marketing_campaign, "name"),
          show_order: 15,
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Marketing Campaign"
            menuItems={_.compact(rootStore.apiStore.marketingCampaigns.map(marketingCampaign => rootStore.uiStore.makePrimeRegularOption(marketingCampaign)))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 30,
          label: 'Marketing Campaign',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 9,
          grid: true,
          grid_order: 28,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          name: 'name',
          rules: 'string',
          utaType: 'name',
        },
        {
          belongsTo: ['display_types', 'displayTypes'],
          form: 'display_type_ids',
          copyEditable: true,
          formField: (rootStore) => (<UccMultiSelect
            core="display-types"
            fieldName="display_type_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Display Types"
            menuItems={rootStore.apiStore.displayTypes.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('display_type_ids', event)}
          />),
          formOrder: 40,
          label: 'Display Type',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          domo_field: 'display_types',
          domo_value: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
          grid: true,
          grid_order: 56,
          grid_value: (row) => row.display_types.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.displayTypes,
          id: 'display_types',
          label: 'Display Types',
          multiple: [],
          name: 'display_types',
          ns: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
          ns_order: 46,
          rules: 'array',
          show: true,
          show_value: (row) => row.display_types.map(c => c.name).join(', '),
          show_order: 61,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 9,
          domo_field: 'publisher',
          domo_value: (crudStore) => property(crudStore.storedData.publisher, 'name'),
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.publisher, 'name'),
          id: 'publisher',
          label: 'Publisher',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.publishers,
          name: 'publisher',
          ns: (crudStore) => abbrev(crudStore.storedData.publisher),
          ns_order: 60,
          show: true,
          show_value: (row) => property(row.publisher, "name"),
          show_order: 40,
        },
        {
          belongsTo: ['publisher', 'publishers'],
          form: 'publisher_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="publisher"
            fieldName="publisher_id"
            form={rootStore.channel.model.crudStore.form}
            label="Publisher"
            menuItems={_.compact(rootStore.apiStore.publishers.map((publisher) => rootStore.uiStore.makePrimeOption(publisher)))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('publisher_id', event)}
          />),
          formOrder: 45,
          label: 'Publisher',
          rules: 'integer',
          utaType: 'fk',
        },

        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (<UccSelect
            core="tactic"
            fieldName="tactic_id"
            form={rootStore.channel.model.crudStore.form}
            label="Tactic"
            menuItems={_.compact(rootStore.apiStore.tactics.map((tactic) => rootStore.uiStore.makePrimeChannelSpecificOption(tactic, 'channelTactics')))}
            onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
          />),
          formOrder: 50,
          label: 'Tactic',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 50,
          grid_value: (row) => property(row.tactic, 'name'),
          id: 'tactic',
          label: 'Tactic',
          name: 'tactic',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          ns: (crudStore) => abbrev(crudStore.storedData.tactic),
          ns_order: 70,
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.tactic, "name"),
          show_order: 50,
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          ns: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          ns_order: 75,
          type: 'checkbox',
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 75,
          utaType: 'boolean',
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          form: 'display_ad_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
          formOrder: 80,
          grid: true,
          grid_order: 80,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'display_ad_targetings_attributes',
          ns: (crudStore) => NameString.targetingString(crudStore),
          ns_order: 80,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "display_ad_targetings"),
          show_order: 80,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'display_ad_targetings_attributes[].id',
        },
        {
          form: 'display_ad_targetings_attributes[].display_ad_id',
        },
        {
          form: 'display_ad_targetings_attributes[].targeting_id',
        },
        {
          form: 'display_ad_targetings_attributes[].extra',
        },
        {
          form: 'display_ad_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'display_ad_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'display_ad_targetings_attributes[].on',
        },
        // {
        //   name: 'targetings',
        //   ns: () => 'targetings',
        //   utaType: 'array',
        // },
        // {
        //   name: 'display_ad_targetings',
        //   ns: () => 'targetings',
        //   utaType: 'array',
        // },
        {
          colSize: 9,
          belongsTo: ['device', 'devices'],
          form: 'device_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="device"
            fieldName="device_id"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            menuItems={_.compact(rootStore.apiStore.devices.map((device) => rootStore.uiStore.makePrimeOption(device)))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('device_id', event)}
          />),
          formOrder: 90,
          label: 'Device',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'device',
          domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
          grid: true,
          grid_order: 90,
          grid_value: (row) => property(row.device, 'name'),
          id: 'device',
          label: 'Device',
          name: 'device',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.devices,
          ns: (crudStore) => abbrev(crudStore.storedData.device),
          ns_order: 90,
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.device, 'name'),
          show_order: 90,
        },
        {
          colSize: 9,
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="geo"
            fieldName="geo_id"
            form={rootStore.channel.model.crudStore.form}
            label="Geo"
            menuItems={_.compact(rootStore.apiStore.geos.map((geo) => rootStore.uiStore.makePrimeOption(geo)))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('geo_id', event)}
          />),
          formOrder: 100,
          label: 'Geo',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          grid_order: 100,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          label: 'Geo',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          name: 'geo',
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          ns_order: 100,
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 100,
        },
        {
          colSize: 15,
          domo_field: 'region',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.region),
          form: 'region',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="region"
            fieldName="region"
            form={rootStore.channel.model.crudStore.form}
            label="Region"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
          />),
          formOrder: 105,
          grid: true,
          grid_order: 105,
          grid_value: (row) => row.region,
          id: 'region',
          name: 'region',
          ns_order: 102,
          ns: (crudStore) => crudStore.storedData.region,
          label: 'Region',
          utaType: 'string',
          rules: ['string', abbrev_regex],
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.region,
          show_order: 105,
        },
        {
          colSize: 10,
          belongsTo: ['language', 'languages'],
          form: 'language_id',
          copyEditable: true,
          formField: (rootStore) => (
            <UccSelect
              core="language"
              fieldName="language_id"
              form={rootStore.channel.model.crudStore.form}
              label="Language"
              menuItems={_.compact(rootStore.apiStore.languages.map((language) => rootStore.uiStore.makePrimeRegularOption(language)))}
              onChange={(event) => rootStore.channel.model.crudStore.storeData('language_id', event)}
            />),
          formOrder: 110,
          label: 'Language',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 110,
          grid_value: (row) => property(row.language, 'name'),
          id: 'language',
          label: 'Language',
          name: 'language',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns: (crudStore) => abbrev(crudStore.storedData.language),
          ns_order: 110,
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 110,
        },
        {
          colSize: 10,
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={_.compact(rootStore.apiStore.genders.map((gender) => rootStore.uiStore.makePrimeChannelSpecificOption(gender, 'channelGenders')))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 120,
          label: 'Gender',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          grid_order: 120,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 120,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 120,
        },
        {
          colSize: 9,
          belongsTo: ['size', 'sizes'],
          form: 'size_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="size"
            fieldName="size_id"
            form={rootStore.channel.model.crudStore.form}
            label="Size"
            menuItems={_.compact(rootStore.apiStore.sizes.map(size => rootStore.uiStore.makePrimeRegularOption(size)))}
            onChange={event => rootStore.channel.model.crudStore.storeData('size_id', event)}
          />),
          //form still in jsx
          hideForm: true,
          label: 'Size',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'size',
          domo_value: (crudStore) => property(crudStore.storedData.size, 'name'),
          grid: true,
          grid_order: 150,
          grid_value: (row) => property(row.size, 'name'),
          id: 'size',
          label: 'Size',
          name: 'size',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.sizes,
          ns: (crudStore) => abbrev(crudStore.storedData.size),
          ns_order: 150,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.size, 'name'),
          show_order: 110,
        },
        {
          label: 'Created At',
          show_value: (row) => crudStore.storedData.created_at,
          show_order: 160,
          rules: 'required|integer',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 130, 130, 'S.'),
        dateField('end_date', 'End Date', 'blank', false, 140, 140, 'E.'),
      ),
    }
  ]
}


function displayCreativeModelMetadata(channel, tabIndex) {
  //    _    _    _    _    _    _    _    _
  //   / \  / \  / \  / \  / \  / \  / \  / \
  //  ( C )( r )( e )( a )( t )( i )( v )( e )
  //   \_/  \_/  \_/  \_/  \_/  \_/  \_/  \_/
  return [
    {
      allFilterData: (apiStore) => apiStore[apiStore.rootStore.channel.codename + 'CreativesFilterOptions'],

      codename: 'DispCreat',
      controller: 'display_creative',
      crud: (rootStore) => rootStore.displayCreativesCrud,
      domoNsType: (channel) => { return ((channel == 'display' || channel == 2) ? 'DisplayCreative' : 'ProgrammaticCreative') },
      endpoint: 'display-creatives',
      filterOptionsCall: (apiStore) => (val) => apiStore[apiStore.rootStore.channel.codename + 'CreativesFilterOptions'] = val,
      form: (rootStore) => <GenericForm type="Creative" datePicker={2} numButtons={3} extraFields={['size_id']} />,
      genericModelPromiseEndpoints: [
        'audiences',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-genders',
        'channel-objectives',
        'channel-tactics',
        'channel-targetings',
        'creative-types',
        'devices',
        'genders',
        'geos',
        'languages',
        'marketing-campaigns',
        'message-types',
        'objectives',
        'publishers',
        'sizes',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore[apiStore.channel.codename + 'CreativesCrud'].getFilterOptions({ brand_id: brand_id, channel_id: channel }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType(channel), brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, channel_id: channel }; },
      name: 'Creatives',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'creatives',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'creative',
      tabDisabled: () => false,
      tabIndex: tabIndex,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,

      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          rules: 'integer|required',
          permListAndView: 80,
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 8,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 5,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: (crudStore) => { return crudStore.channel.name == 'Display' ? 'DisplayCreative' : 'ProgrammaticCreative' },
        },
        {
          default: channel,
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          form: 'channel_id',
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: () => 'hmm',
          rules: 'required',
          type: 'hidden',
          utaType: 'channel_id',
        },
        {
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'created_by',
          label: 'Created By',
          id: 'created_by',
          grid: true,
          options: (apiStore) => apiStore.Users,
          grid_order: 190,
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: () => 'Inactive',
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 9,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          ns_order: 10,
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'brand_id',
          domo_value: (crudStore) =>
            property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          type: 'hidden',
          utaType: 'brand',
          rules: 'required',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },

        {
          belongsTo: ['creative_type', 'creativeTypes'],
          form: 'creative_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="creative-type"
            fieldName="creative_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Creative Type"
            menuItems={_.compact(rootStore.apiStore.creativeTypes.map((creativeType) => rootStore.uiStore.makePrimeOption(creativeType)))}
            onChange={event => rootStore.channel.model.crudStore.storeData('creative_type_id', event)}
          />),
          formOrder: 10,
          label: 'Creative Type',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10,
          domo_field: 'creative_type',
          domo_value: (crudStore) => property(crudStore.storedData.creative_type, 'name'),
          grid: true,
          grid_order: 10,
          grid_value: (row) => property(row.creative_type, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.creativeTypes,
          id: 'creative_type',
          label: 'Creative Type',
          name: 'creative_type',
          ns: (crudStore) => abbrev(crudStore.storedData.creative_type),
          ns_order: 30,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.creative_type, 'name'),
          show_order: 10,
        },
        {
          colSize: 9,
          // domo_field: 'ad_name',
          // domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          // form: 'name',
          // copyEditable: true,
          // formField: (rootStore) => (<UccInputText
          //   core="name"
          //   fieldName="name"
          //   form={rootStore.channel.model.crudStore.form}
          //   label="Creative Description"
          //   onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          // />
          // ),
          // formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          // name: 'name',
          // ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          // ns_order: 35,
          // rules: 'required|string',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
          utaType: 'name',
        },
        {
          colSize: 13,
          domo_field: (crudStore) => crudStore.channel.codename == 'programmatic' ? 'campaign_name' : 'ad_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 60,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Marketing Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 35,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.marketing_campaign, 'name'),
          show_order: 60,
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Marketing Campaign"
            menuItems={_.compact(rootStore.apiStore.marketingCampaigns.map(marketingCampaign => rootStore.uiStore.makePrimeRegularOption(marketingCampaign)))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 2,
          label: 'Marketing Campaign',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'publisher',
          domo_value: (crudStore) => property(crudStore.storedData.publisher, 'name'),
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.publisher, 'name'),
          id: 'publisher',
          label: 'Publisher',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.publishers,
          name: 'publisher',
          ns: (crudStore) => abbrev(crudStore.storedData.publisher), //if blank 'Multiple Publishers'
          ns_order: 60,
          show: true,
          show_value: (row) => property(row.publisher, 'name'),
          show_order: 40,
        },
        {
          belongsTo: ['publisher', 'publishers'],
          form: 'publisher_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="publisher"
            fieldName="publisher_id"

            form={rootStore.channel.model.crudStore.form}
            label="Publisher"
            menuItems={_.compact(rootStore.apiStore.publishers.map(publisher => rootStore.uiStore.makePrimeOption(publisher)))}
            onChange={event => rootStore.channel.model.crudStore.storeData('publisher_id', event)}
          />),
          formOrder: 40,
          label: 'Publisher',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (
            <UccSelect
              core="tactic"
              fieldName="tactic_id"
              form={rootStore.channel.model.crudStore.form}
              label="Tactic"
              menuItems={_.compact(rootStore.apiStore.tactics.map(tactic => rootStore.uiStore.makePrimeChannelSpecificOption(tactic, 'channelTactics')))}
              onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
            />),
          formOrder: 50,
          label: 'Tactic',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 50,
          grid_value: (row) => property(row.tactic, 'name'),
          id: 'tactic',
          label: 'Tactic',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          name: 'tactic',
          ns: (crudStore) => abbrev(crudStore.storedData.tactic),
          ns_order: 70,
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.tactic, 'name'),
          show_order: 50,
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          ns: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          ns_order: 75,
          type: 'checkbox',
          utaType: 'boolean',
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 55,
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.pivotNamestring(crudStore, 'tactic', 'targeting'),
          form: 'display_creative_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
          formOrder: 55,
          grid: true,
          grid_order: 80,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'display_creative_targetings_attributes',
          ns: (crudStore) => NameString.pivotNamestring(crudStore, 'tactic', 'targeting'),
          ns_order: 80,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "display_creative_targetings"),
          show_order: 80,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'display_creative_targetings_attributes[].id',
        },
        {
          form: 'display_creative_targetings_attributes[].display_creative_id',
        },
        {
          form: 'display_creative_targetings_attributes[].targeting_id',
        },
        {
          form: 'display_creative_targetings_attributes[].extra',
        },
        {
          form: 'display_creative_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'display_creative_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'display_creative_targetings_attributes[].on',
        },
        {
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          formField: (rootStore) => (<UccSelect
            core="geo"
            fieldName="geo_id"
            form={rootStore.channel.model.crudStore.form}
            label="Geo"
            menuItems={_.compact(rootStore.apiStore.geos.map(geo => rootStore.uiStore.makePrimeOption(geo)))}
            onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
          />),
          formOrder: 100,
          label: 'Geo',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          grid_order: 100,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          label: 'Geo',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          name: 'geo',
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          ns_order: 100,
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 100,
          rules: 'integer',
        },
        {
          colSize: 15,
          domo_field: 'region',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.region),
          form: 'region',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="region"
            fieldName="region"
            form={rootStore.channel.model.crudStore.form}
            label="Region"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
          />),
          formOrder: 105,
          grid: true,
          grid_order: 105,
          grid_value: (row) => row.region,
          id: 'region',
          name: 'region',
          ns: (crudStore) => crudStore.storedData.region,
          ns_order: 102,
          label: 'Region',
          utaType: 'string',
          rules: ['string', abbrev_regex],
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.region,
          show_order: 105,
        },
        {
          belongsTo: ['language', 'languages'],
          form: 'language_id',
          copyEditable: true,
          formField: (rootStore) => (
            <UccSelect
              core="language"
              fieldName="language_id"
              form={rootStore.channel.model.crudStore.form}
              label="Language"
              menuItems={_.compact(rootStore.apiStore.languages.map(language => rootStore.uiStore.makePrimeRegularOption(language)))}
              onChange={event => rootStore.channel.model.crudStore.storeData('language_id', event)}
            />),
          formOrder: 110,
          label: 'Language',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 110,
          grid_value: (row) => property(row.language, 'name'),
          id: 'language',
          label: 'Language',
          name: 'language',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          ns: (crudStore) => abbrev(crudStore.storedData.language),
          ns_order: 110,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 110
        },
        {
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={_.compact(rootStore.apiStore.genders.map((gender) => rootStore.uiStore.makePrimeChannelSpecificOption(gender, 'channelGenders')))}
            onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 120,
          label: 'Gender',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          grid_order: 120,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 120,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 120
        },
        {
          belongsTo: ['size', 'sizes'],
          form: 'size_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="size"
            fieldName="size_id"
            form={rootStore.channel.model.crudStore.form}
            label="Size"
            menuItems={_.compact(rootStore.apiStore.sizes.map(size => rootStore.uiStore.makePrimeRegularOption(size)))}
            onChange={event => rootStore.channel.model.crudStore.storeData('size_id', event)}
          />),
          //form still in jsx
          hideForm: true,
          label: 'Size',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'size',
          domo_value: (crudStore) => property(crudStore.storedData.size, 'name'),
          grid: true,
          grid_order: 150,
          grid_value: (row) => property(row.size, 'name'),
          id: 'size',
          label: 'Size',
          name: 'size',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.sizes,
          ns: (crudStore) => abbrev(crudStore.storedData.size),
          ns_order: 150,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.size, 'name'),
          show_order: 150
        },
        {
          label: 'Created At',
          show_value: (row) => crudStore.storedData.created_at,
          show_order: 160,
          rules: 'required|integer',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 130, 130, 'S.'),
        dateField('end_date', 'End Date', 'blank', false, 140, 140, 'E.'),
      )
    }
  ]
}


function displayPackageModelMetadata(channel, tabIndex) {
  // ,---.          |
  // |---',---.,---.|__/ ,---.,---.,---.,---.
  // |    ,---||    |  \ ,---||   ||---'`---.
  // `    `---^`---'`   ``---^`---|`---'`---'
  //                          `---'
  return [
    {
      allFilterData: (apiStore) => apiStore['displayPackagesFilterOptions'],
      codename: 'DispPack',
      controller: 'display_package',
      crud: (rootStore) => rootStore.displayPackagesCrud,
      domoNsType: 'DisplayPackage',
      endpoint: 'display-packages',
      filterOptionsCall: (apiStore) => (val) => apiStore['displayPackagesFilterOptions'] = val,
      form: () => <GenericForm type="Package" datePicker={2} numButtons={3} />,
      genericModelPromiseEndpoints: [
        'audiences',
        'campaign-types',
        'campaign-type-channels',
        'creative-types',
        'publishers',
        'statuses',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.displayPackagesCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, channel_id: channel }; },
      name: 'Packages',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'packages',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'package',
      tabDisabled: () => false,
      tabIndex: tabIndex,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,

      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 11,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 5,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: (crudStore) => crudStore.channel.name + 'Package',
          rules: 'required',
        },
        {
          default: channel,
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          form: 'channel_id',
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: () => 'hmm',
          rules: 'required',
          type: 'hidden',
          utaType: 'channel_id',
        },
        {
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'created_by',
          options: (apiStore) => apiStore.Users,
          label: 'Created By',
          id: 'created_by',
          grid: true,
          grid_order: 190,
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          rules: 'required',
          utaType: 'user',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: () => 'Inactive',
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 12,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          rules: 'required',
          utaType: 'group',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          ns_order: 10,
          rules: 'required',
          utaType: 'group',
        },
        {
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          type: 'hidden',
          rules: 'required',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },
        {
          colSize: 9,
          domo_field: 'ad_name',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          form: 'name',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Package Description"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 10,
          grid: true,
          grid_order: 20,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          name: 'name',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          ns_order: 30,
          rules: 'required|string',
          show: true,
          show_value: (row) => row.name,
          show_order: 10,
          utaType: 'name',
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType)))}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 20,
          label: 'Campaign Type',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 12,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
          grid: true,
          grid_order: 25,
          grid_value: (row) => property(row.campaign_type, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          ns_order: 40,
          show: true,
          show_value: (row) => property(row.campaign_type, 'name'),
          show_order: 25,
        },
        {
          domo_field: 'publisher',
          domo_value: (crudStore) => property(crudStore.storedData.publisher, 'name'),
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.publisher, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.publishers,
          id: 'publisher',
          label: 'Publisher',
          name: 'publisher',
          ns: (crudStore) => abbrev(crudStore.storedData.publisher),
          ns_order: 50,
          show: true,
          show_value: (row) => property(row.publisher, 'name'),
          show_order: 30,
        },
        {
          belongsTo: ['publisher', 'publishers'],
          form: 'publisher_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="publisher"
            fieldName="publisher_id"
            form={rootStore.channel.model.crudStore.form}
            label="Publisher"
            menuItems={_.compact(rootStore.apiStore.publishers.map(publisher => rootStore.uiStore.makePrimeOption(publisher)))}
            onChange={event => rootStore.channel.model.crudStore.storeData('publisher_id', event)}
          />),
          formOrder: 30,
          label: 'Publisher',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 100, 130, 'S.'),
        dateField('end_date', 'End Date', 'blank', false, 110, 140, 'E.'),
      ),
      // concat(() => )
    }

  ]

}