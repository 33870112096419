import React, { Component } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import './PrimeStyle.css';
import { InputNumber } from 'primereact/inputnumber';
import { withStyles } from '@material-ui/core/styles';
import { InputText } from 'primereact/inputtext';
import UccDoubleDatePicker from './UccDoubleDatePicker';
import UccInputText from './UccInputText';
import UccRadio from './UccRadio';
import UccSelect from './UccSelect';
import { copyEditDropDownList, getFormElement, disableSubmitButton, removeTrailingValue } from '../../store/StoreMetadata';
import UccDoubleButton from './UccDoubleButton';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import UccBulkNamestringModal from './UccBulkNamestringModal';
import UccBulkCalendar from './UccBulkCalendar';
import UccErrorHandling from './UccErrorHandling';

@inject('rootStore')
@observer
class UccBulkForm extends Component {

  @observable formElement;
  @observable fullForm;
  @observable fullList;
  @observable firstSteps = true;
  @observable isError = false;


  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.onDropDownChangeHandler = this.onDropDownChangeHandler.bind(this);
    this.onContinueHandler = this.onContinueHandler.bind(this);
    this.onChangeForCheckbox = this.onChangeForCheckbox.bind(this);
    this.setIsError = this.setIsError.bind(this);
    this.onRadioEdit = this.onRadioEdit.bind(this);
    const crudStore = this.props.rootStore.channel.model.crudStore;
    this.crudStore = crudStore;
    this.setfullList(copyEditDropDownList(this.props.rootStore));
    crudStore.validatesRequiredFieldsForCopyEdit(false);
    crudStore.clearValidationError();
    crudStore.serverValidationErrors = [];
  }

  componentDidMount = () => {
    this.crudStore.setBulkCrudStores(this.crudStore.selectedRows);
    this.crudStore.initBulkCrudStoreForms('edit');
    this.crudStore.storedData['keep_original_dates'] = true;
  }

  @action.bound
  setfullList(value) {
    this.fullList = value;
    this.setFullForm();
  }
  @action.bound
  setFullForm() {
    this.fullForm = this.fullList.map(fe => fe.formField(this.props.rootStore));
  }

  @action.bound
  setFormElement(value) {
    this.formElement = _.compact(value);
    if (!_.isEmpty(value.find(v => v.props)?.type?.displayName?.match(/ultiSelect/))) {
      this.crudStore.multiselectDisabled[value.find(v => v.props).props.fieldName] = true
    }
  }

  onRadioEdit = (event) => {
    this.crudStore.setValueOperation(event.value);
    this.crudStore.multiselectDisabled[this.formElement[0].props.fieldName] = false;
    this.crudStore.radioDisabled['addRemove'] = true
  };

  checkAllValid(crudStore, list) {

    const listofRequiredA = list.filter(a => a.rules.includes('required'));
    const listofRequired = listofRequiredA.map(a =>
      a.form.slice(-3) == '_id' ?
        a.form.slice(0, -3) :
        (a.form.slice(-4) == '_ids' ?
          a.form.slice(0, -4) + 's' :
          a.form)
    );
    const emptyRequireds = listofRequired.map(requiredField => {
      const bcs_data = crudStore.bulkCrudStores.map(bcs => {
        // console.log(requiredField);
        // console.log(jsp(bcs['storedData'][requiredField]));
        // console.log(_.isEmpty(bcs['storedData'][requiredField]));
        return _.isEmpty(bcs['storedData'][requiredField]);
      });
      // console.log(jsp(bcs_data))
      return bcs_data;
    });
    return emptyRequireds.filter(er => !er.every(el => !el)).length > 0
  }

  @action.bound
  setIsError(call_from = "onChange") {

    const crudStore = this.props.rootStore.channel.model.crudStore;
    const isAdmin = this.props.rootStore.channel.adminChannel;
    const attribute_to_be_edited = crudStore.storedData['attribute_to_be_edited'];

    if (call_from == "onContinue" && !isAdmin && !!crudStore.storedData['keep_original_dates'] && (!attribute_to_be_edited || attribute_to_be_edited.length == 0)) {
      crudStore.validationErrors.push('Please provide an edit');
      this.isError = true;
      return;
    } else {
      this.isError = false;
    }
    const isValueRequired = this.fullList.filter(a => a.form == attribute_to_be_edited);
    const isRequired = isValueRequired.length != 0 ? isValueRequired[0].rules.includes('required') : false;
    crudStore.validatesRequiredFieldsForCopyEdit(this.checkAllValid(crudStore, this.fullList));

    if (call_from == "onContinue") {
      let bcsInvalid = isRequired;
      if (isRequired) {

        bcsInvalid = crudStore.bulkCrudStores.map(bcs => bcs.storedData).map(sd => {
          return sd[attribute_to_be_edited].length
        }).includes(0);
      }
      if (bcsInvalid && crudStore.operationType == 'remove') {
        if (crudStore.validationErrors.filter((n, i) => n == "You can't remove all those options. The channel_ids field would be invalid in at least one record. Resetting Choices.").length == 0) {
          crudStore.validationErrors.push(`You can't remove all those options. The ${attribute_to_be_edited} field would be invalid in at least one record. Resetting Choices.`)
        }
        crudStore.bulkCrudStores.forEach((cs, index) => {
          cs.storedData[cs.storedData['attribute_to_be_edited']] = cs.storedData['attribute_to_be_edited_original_values'].find(pr => pr[0] == cs.storedData['id']);
        });
        crudStore.storedData[attribute_to_be_edited] = [];
        this.isError = true;
        crudStore.validatesRequiredFieldsForCopyEdit(false);
      }
      crudStore.setDisplayError(crudStore.requiredFieldsForCopyEditInvalid || (this.isError) || (isRequired && crudStore.storedData[attribute_to_be_edited].length == 0 || (bcsInvalid && crudStore.operationType == 'remove')))
      crudStore.setDateValidationEdit();
    }
  }

  onChangeForCheckbox(event) {
    const crudStore = this.props.rootStore.channel.model.crudStore;
    crudStore.storeData('keep_original_dates', event);
    if (crudStore.storedData['keep_original_dates'] == false) {
      crudStore.deleteStoredDataProperty('start_date', true);
      crudStore.deleteStoredDataProperty('end_date', true);
      crudStore.deleteStoredDataProperty('send_date', true);
    } else {
      crudStore.bulkCrudStores.forEach(bcs => {
        bcs.storeData('start_date', bcs.storedData['initial_start_date']);
        bcs.storeData('end_date', bcs.storedData['initial_end_date']);
        bcs.storeData('send_date', bcs.storedData['initial_send_date']);
      });
    }
    this.setIsError();
  }

  onDropDownChangeHandler(event) {
    const crudStore = this.props.rootStore.channel.model.crudStore;
    crudStore.storeData('attribute_to_be_edited', event);
    crudStore.bulkAttributeSelected = true;
    const val = event.value;
    this.setIsError();
    if (crudStore.selectedRows.length > 1) {
      crudStore.bulkCrudStores.forEach((bcs, index) => bcs.storedData['attribute_to_be_edited_original_values'] = crudStore.selectedRows[index][crudStore.storedData['attribute_to_be_edited']])
      crudStore.storedData[val] = ''
    }
    this.setFormElement(this.fullForm.map((component, index) => {
      return component.props.fieldName == val ? component : '';
    }
    ));

    disableSubmitButton(this.props.rootStore, event);
  }

  onContinueHandler() {
    const crudStore = this.props.rootStore.channel.model.crudStore;
    this.setIsError("onContinue");
    if (this.isError || crudStore.requiredFieldsForCopyEditInvalid || crudStore.displayError || (!crudStore.storedData['keep_original_dates'] && (!crudStore.startDateValid || !crudStore.endDateValid || !crudStore.sendDateValid))) {
      this.forceUpdate();
    } else {
      crudStore.setDisplayError(false);
      if (crudStore.initialAction == 'bulk_edit') {
        crudStore.onBulkEditContinue();
      } else {

        crudStore.onCopy('copy_edit', 'Predraft');
      }

    }
  }

  render() {
    const { rootStore, form, label, classes, display, onCancel, isMultiple } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const multiple = this.props.isMultiple;

    return (
      <div>
        <div className={"p-field p-col-12 p-md-3 container"}>
          <br />
          <div className="p-float-label dialog-dropdown uccItems">
            <Dropdown id="attribute_to_be_edited" disabled={crudStore.bulkAttributeSelected} options={_.compact(this.fullForm.map((full) => rootStore.uiStore.makePrimeOptionForEdit(full.props)))} filter={false} onChange={this.onDropDownChangeHandler} value={crudStore.storedData['attribute_to_be_edited']} style={!this.isError ? { border: '' } : { border: '2px solid #FF5656' }} />
            <label htmlFor='attribute_to_be_edited' className='label'>Attribute To Be Edited</label>
          </div>
          {this.isError && <UccErrorHandling fieldName='attribute_to_be_edited' callFrom='modal' />}
        </div>
        {multiple && (
          <UccRadio
            onChange={this.onRadioEdit}
            crudStore={this.crudStore}
            name={'addRemove'}
            disabled={false}
            buttons={[
              { value: 'add', label: 'Add', checked: this.crudStore.operationType == 'add' },
              { value: 'remove', label: 'Remove', checked: this.crudStore.operationType == 'remove' },
              { value: 'replace', label: 'Replace', checked: this.crudStore.operationType == 'replace' },
            ]}
          />
        )}
        {this.formElement && <div style={{ marginTop: '25px', marginLeft: '-17px', marginBottom: '-25px', paddingTop: '20px' }}>{this.formElement}</div>}

        {(!channel.model.dontDisplayDate) && !channel.adminChannel && <div style={{ marginTop: '25px', paddingTop: '20px', color: '#495057' }}>
          <Checkbox inputId="date_checkbox" id="date-checkbox" onChange={this.onChangeForCheckbox} checked={crudStore.storedData['keep_original_dates']}></Checkbox>
          <label htmlFor="date_checkbox" className="p-checkbox-label" >Keep Original Dates</label>
        </div>}


        {
          !crudStore.storedData['keep_original_dates'] && !channel.adminChannel && <UccBulkCalendar parent={this} />
        }
        {crudStore.validationErrorsPrint()}
        { /* added to disallow copying of things that were old and are missing data that is required now */
          crudStore.requiredFieldsForCopyEditInvalid && 'The Namestring isn\'t copyable because an attribute and/or the namestring format has changed. Please cancel and create a new namestring.'
        }
        <div style={{ paddingBottom: '20px' }}><UccDoubleButton label='Continue' onSubmit={this.onContinueHandler} onCancel={onCancel} new_width='455px' new_paddingLeft='0px' cancelLeftPadding='37px' new_paddingTop='25px' disabled={crudStore.requiredFieldsForCopyEditInvalid} /></div>
      </div>
    );
  }
}

export default UccBulkForm;